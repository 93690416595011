import React, { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { Table } from "semantic-ui-react";
import { createUseStyles } from "react-jss";

import { getDateIndex, getStartOfWeekDate } from "@/views/reporting/functions/over-time";
import { incrementDateRange } from "@/views/reporting/functions";

const useStyles = createUseStyles((theme) => ({
	container: {
		display: "flex",
		flexDirection: "row",
		overflow: "scroll",
		maxHeight: "376px",
		marginBottom: "20px",
		width: "calc(97vw - 90px)",
	},
	errorContainer: {
		display: "flex",
		marginBottom: "32px",
	},
	header: {
		fontFamily: "Stolzl",
		fontSize: "15px",
		fontWeight: "bold",
		fontStretch: "normal",
		fontStyle: "normal",
		lineHeight: "normal",
		letterSpacing: "normal",
		textAlign: "left",
		marginBottom: "20px",
		color: "black",
	},
	headerCellBase: {
		backgroundColor: "#2e475f !important",
		color: "white !important",
		fontFamily: "Stolzl !important",
		fontSize: "17px !important",
		fontStretch: "normal !important",
		fontStyle: "normal !important",
		lineHeight: "normal !important",
		letterSpacing: "normal !important",
		borderLeft: "1px solid #c4c4c4 !important",
		minWidth: "150px",
		height: "80px",
	},
	headerCell: {
		composes: "$headerCellBase",
		fontWeight: "500 !important",
	},
	headerCellTotal: {
		composes: "$headerCellBase",
		fontWeight: "800 !important",
	},
	dataCell: {
		composes: "$headerCellBase",
		"& >span:first-child": {
			fontWeight: "800 !important",
		},
		"& >span:last-child": {
			fontWeight: "500 !important",
		},
	},
	siteRow: {
		backgroundColor: "#BEC6CA !important",
		color: "black !important",
		fontFamily: "Stolzl !important",
		fontSize: "17px !important",
		fontStretch: "normal !important",
		fontStyle: "normal !important",
		lineHeight: "normal !important",
		letterSpacing: "normal !important",
		borderLeft: "1px solid #c4c4c4 !important",
	},
	contentRow: {
		color: "#353430 !important",
		fontFamily: "Stolzl !important",
		fontSize: "17px !important",
		fontStretch: "normal !important",
		fontStyle: "normal !important",
		lineHeight: "normal !important",
		letterSpacing: "normal !important",
		"& > td": {
			border: "1px solid #c4c4c4 !important",
		},
	},
	evenRow: {
		composes: "$contentRow",
		backgroundColor: "#FFF !important",
	},
	oddRow: {
		composes: "$contentRow",
		backgroundColor: "rgba(0, 0, 50, 0.02) !important",
	},
	totalCell: {
		composes: "$contentRow",
		backgroundColor: "#EAECEE !important",
		fontStyle: "bold !important",
		fontWeight: "800 !important",
	},
	tableHeader: {
		position: "sticky",
		top: 0,
	},
}));

function CartonProductionOverTimePerMachineTable({ reportData, dateRange }) {
	const classes = useStyles();
	const [aggregation, setAggregation] = useState([]);
	const [headers, setHeaders] = useState([]);

	function mapData(data, dateRange, sites) {
		const key = getDateIndex(incrementDateRange(dateRange));
		let aggregation = data.overTime.map((item) => {
			return {
				...item,
				tenantId: "",
				siteId: "",
				siteName: "",
			};
		});

		// Sort data per group
		aggregation = aggregation.map((group) => {
			group.data.sort((a, b) => {
				const aDate = new Date(a.year, a.month - 1, a.day, a.hour);
				const bDate = new Date(b.year, b.month - 1, b.day, b.hour);
				return aDate - bDate;
			});
			group.data.sort((a, b) => {
				return a.week - b.week;
			});
			return group;
		});

		let indexWithMostData = 0;
		let mostItems = 0;
		aggregation.forEach((item, i) => {
			if (item.data && item.data.length > mostItems) {
				indexWithMostData = i;
				mostItems = item.data.length;
			}
		});
		const records = aggregation.length ? JSON.parse(JSON.stringify(aggregation[indexWithMostData].data)) : [];
		for (const record of records) {
			record.machineName = "Total";
			record.cartonsProduced = 0;
		}

		for (const machine of aggregation) {
			for (let i = 0; i < machine.data.length; i++) {
				records[i].cartonsProduced += machine.data[i].cartonsProduced;
			}
		}

		if (records.length) {
			aggregation.push({ data: records });
		}

		setHeaders(aggregation.length ? aggregation[indexWithMostData].data.map((a) => getDateColumn(key, a)) : []);
		setAggregation(aggregation);
	}

	function getDateColumn(dateKey, item) {
		switch (dateKey) {
			case "Hours":
				return new Date(item.year, item.month - 1, item.day, item.hour).toLocaleTimeString([], {
					hour: "2-digit",
					minute: "2-digit",
				});
			case "Days":
				return new Date(item.year, item.month - 1, item.day).toLocaleDateString();
			case "Weeks":
			case "Months":
				return new Date(item.year, item.month - 1, item.day).toLocaleDateString();
			default:
				return "";
		}
	}

	function getRow(machine, mName) {
		const total = machine.reduce((accumulator, obj) => {
			return (accumulator += obj.cartonsProduced);
		}, 0);
		const machineName = mName ? mName : machine[0].machineName;
		const columns = [
			<Table.Cell className={classes.dataCell} key={`over-time-table-body-${machineName}-name`}>
				<span>{machineName}</span>
			</Table.Cell>,
		];
		columns.push(
			...machine.map((d, i) => {
				return (
					<Table.Cell textAlign="right" key={`over-time-table-body-${machineName}-${i}`}>
						{d.cartonsProduced}
					</Table.Cell>
				);
			})
		);
		columns.push(
			<Table.Cell textAlign="right" className={classes.totalCell} key={`over-time-table-body-${machineName}-total`}>
				{total}
			</Table.Cell>
		);
		return columns;
	}

	useEffect(() => {
		mapData(reportData, dateRange);
	}, [reportData, dateRange]);

	let coloredRow = false;
	return aggregation.length ? (
		<div className={classes.container}>
			<Table singleLine={true}>
				<Table.Header className={classes.tableHeader}>
					<Table.Row>
						<Table.HeaderCell className={classes.headerCell} key="over-time-table-header-blank">
							{""}
						</Table.HeaderCell>
						{headers.map((h, i) => {
							return (
								<Table.HeaderCell
									textAlign="right"
									className={classes.headerCell}
									key={`over-time-table-header-data-${i}`}
								>
									{h}
								</Table.HeaderCell>
							);
						})}
						<Table.HeaderCell className={classes.headerCellTotal} key="over-time-table-header-total">
							<FormattedMessage id="Grand Total" />
						</Table.HeaderCell>
					</Table.Row>
				</Table.Header>
				<Table.Body>
					{aggregation.map((machine, i) => {
						coloredRow = !coloredRow;
						return (
							<Table.Row
								className={coloredRow ? classes.evenRow : classes.oddRow}
								key={`over-time-table-body-site-row-${i}`}
							>
								{getRow(machine.data, machine.machineName)}
							</Table.Row>
						);
					})}
				</Table.Body>
			</Table>
		</div>
	) : (
		<div className={classes.errorContainer}>
			<FormattedMessage id="No Cartons Have Been Produced" />
		</div>
	);
}

export default CartonProductionOverTimePerMachineTable;
