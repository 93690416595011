import { createUseStyles } from "react-jss";

import DataTable from "react-data-table-component";
import { FormattedMessage } from "react-intl";
import { useCallback, useLayoutEffect, useMemo, useState } from "react";
import ReactDiffViewer from "react-diff-viewer-continued";

const useStyles = createUseStyles((theme) => ({
	header: {
		border: "0 !important",
		alignItems: "center",
		padding: "24px 32px !important",
		margin: "0 !important",
		backgroundColor: `${theme.colors.light} !important`,
		borderRadius: "0px !important",
		minHeight: "96px",
	},
	headerText: {
		color: `${theme.colors.text} !important`,
		fontWeight: "normal !important",
		fontSize: "40px !important",
		margin: "0 !important",
		lineHeight: "36px !important",
		letterSpacing: "-.5px",
	},
	headerButton: {
		margin: "0 !important",
	},
	modal: {
		"& > :first-child": {
			padding: "0px !important",
		},
	},
	listView: {
		maxHeight: "calc(100vh - 224px)",
		height: "calc(100vh - 224px)",
		overflowY: "auto",
	},
}));

const sanitizeRecord = (record, additionalDataTransform) => {
	var data = JSON.parse(record.data);
	delete data.TenantId;
	delete data.MessageId;
	delete data.Id;
	if (additionalDataTransform) data = additionalDataTransform(data);

	return JSON.stringify(data, null, 2);
};

const DiffView = ({
	data,
	additionalDataTransform,
	compareAll,
	showTypeColumn = true,
	showActivityColumn = true,
	autoExpand = true,
	actionColumn,
}) => {
	const [expandedRows, setExpandedRows] = useState([]);

	useLayoutEffect(() => {
		setExpandedRows([]);
	}, [data]);

	const classes = useStyles();
	const columns = useMemo(() => {
		const arr = [
			{
				name: "Date",
				selector: (row) => new Date(row.createDateUTC).toLocaleString(),
				maxWidth: "225px",
			},
			// {
			//   name: "ID",
			//   selector: (row) => row.entityId,
			// },
			{
				name: "Type",
				selector: (row) => row.auditType,
				omit: !showTypeColumn,
			},
			{
				name: "Activity",
				selector: (row) => row.routingKey.split(".").at(-1),
				omit: !showActivityColumn,
			},
			{
				name: "User",
				selector: (row) => row.userId,
			},
		];
		if (actionColumn) {
			arr.push({
				...actionColumn,
				cell: (...args) => actionColumn.cell(...args, expandedRows),
			});
		}
		return arr;
	}, [actionColumn, expandedRows, showActivityColumn, showTypeColumn]);

	const onToggleExpand = useCallback((toggleState, row) => {
		setExpandedRows((prev) => {
			if (toggleState) {
				return [...prev, row.id];
			}
			return prev.filter((i) => i !== row.id);
		});
	}, []);

	if (!data.length)
		return (
			<>
				<FormattedMessage id="No audit history exists for this item" />
			</>
		);

	if (autoExpand) {
		data[0].defaultExpanded = true;
	}

	return (
		<DataTable
			noHeader
			striped
			expandableRows
			expandableRowExpanded={(row) => row.defaultExpanded}
			onRowExpandToggled={onToggleExpand}
			expandableRowsComponent={(expandedRow) => {
				const index = data.indexOf(expandedRow.data);
				const newValue = sanitizeRecord(data[index], additionalDataTransform);
				let oldValue = "{ }";

				if (!compareAll) {
					if (data.length > index + 1) {
						oldValue = sanitizeRecord(data[index + 1], additionalDataTransform);
					}
				} else {
					if (data.length > index + 1) {
						const auditType = data[index].auditType;
						const entityId = data[index].entityId;
						const nextIndex = data.findIndex(
							(i, ind) => ind > index && i.auditType === auditType && i.entityId === entityId,
						);
						if (nextIndex > 0) oldValue = sanitizeRecord(data[nextIndex], additionalDataTransform);
					}
				}

				return (
					<ReactDiffViewer
						newValue={newValue}
						oldValue={oldValue}
						splitView={true}
						leftTitle="Previous"
						rightTitle="Changes"
					/>
				);
			}}
			columns={columns}
			data={data}
			className={classes.dataTable}
		/>
	);
};

export default DiffView;
