import ScrollMenu, { filterAuthorizedAndLicensedTabs } from "@/components/scroll-menu/scroll-menu";
import CartonPropertyGroups from "@/views/configuration/carton-property-groups";
import Classifications from "@/views/configuration/classifications";
import ConfigurationAudit from "@/views/configuration/configuration-audit";
import MachineGroups from "@/views/configuration/machine-groups";
import MachineService from "@/views/configuration/machine-service/machine-service";
import { Outlet } from "react-router-dom";
import PickZones from "@/views/configuration/pick-zones";
import PlcManagement from "@/views/configuration/plc-management";
import ProductionGroups from "@/views/configuration/production-groups";
import Roles from "@/views/configuration/roles";
import { Segment } from "semantic-ui-react";
import ServerSettings from "@/views/configuration/server-settings/server-settings";
import Users from "@/views/configuration/users";
import { createUseStyles } from "react-jss";
import { useIntl } from "react-intl";
import { lazy, useMemo } from "react";
import { LazyRoute } from "@/components/lazy-route";

const LazyEventNotifications = lazy(() => import("./event-notifications"));
const LazyMaterials = lazy(() => import("@/views/configuration/materials/materials"));
const LazyProductionScheduleView = lazy(() => import("@/views/configuration/planned-production"));
const LazySecurePrintConfigurationView = lazy(() => import("./secure-print"));
const LazyMachinesView = lazy(() => import("@/views/configuration/machines"));

const useStyles = createUseStyles({
	submenu: {
		height: "64px",
		"border-bottom": "0px !important",
		background: "transparent !important",
		margin: "0px !important",
		padding: "0px 30px 2px 20px !important",
		border: "none !important",
	},
});

export const getAuthorizedConfigurationTabs = (currentUser, intl) => {
	const tabs = [
		{
			defaultMenuItem: true,
			parentLink: "configuration",
			link: "server-settings",
			text: intl.formatMessage({ id: "Server Settings" }),
			component: <ServerSettings />,
			authorization: "ManageSettings",
			licensed: true,
		},
		{
			link: "machines",
			text: intl.formatMessage({ id: "Machines" }),
			component: <LazyRoute Component={LazyMachinesView} />,
			authorization: "ManageMachines",
			licensed: "pack-net-machines",
		},
		{
			link: "materials",
			text: intl.formatMessage({ id: "Materials" }),
			component: <LazyRoute Component={LazyMaterials} />,
			authorization: ["ManageCorrugates", "ManageDesigns", "ManageLabelTemplates", "ManagePrintImages"],
			licensed: "pack-net-materials",
		},
		{
			link: "machine-groups",
			text: intl.formatMessage({ id: "Machine Groups" }),
			component: <MachineGroups />,
			authorization: "ManageMachineGroups",
			licensed: "pack-net-machine-groups",
		},
		{
			link: "production-groups",
			text: intl.formatMessage({ id: "Production Groups" }),
			component: <ProductionGroups />,
			authorization: "ManageProductionGroups",
			licensed: "pack-net-production-groups",
		},
		{
			link: "import-pipeline",
			text: intl.formatMessage({ id: "Import Pipeline" }),
			authorization: "ManageImportPipelines",
			licensed: "pack-net-import",
			subPaths: ["import-source", "data-mapping", "rules-configuration"],
		},
		{
			link: "machine-service",
			text: intl.formatMessage({ id: "Machine Service" }),
			component: <MachineService />,
			authorization: "AccessServiceModePage",
			licensed: "pack-net-machines",
		},
		{
			link: "classifications",
			text: intl.formatMessage({ id: "Classifications" }),
			component: <Classifications />,
			authorization: "ManageClassifications",
			licensed: "pack-net-classifications",
		},
		{
			link: "carton-property-groups",
			text: intl.formatMessage({ id: "Carton Property Groups" }),
			component: <CartonPropertyGroups />,
			authorization: "ManageCPGs",
			licensed: "pack-net-carton-property-groups",
		},
		{
			link: "pick-zones",
			text: intl.formatMessage({ id: "Pick Zones" }),
			component: <PickZones />,
			authorization: "ManagePickZones",
			licensed: "pack-net-pick-zones",
		},
		{
			link: "plc-management",
			text: intl.formatMessage({ id: "Machine Software Updates" }),
			component: <PlcManagement />,
			authorization: "ManageMachines",
			licensed: "pack-net-plc-management",
		},
		{
			link: "roles",
			text: intl.formatMessage({ id: "Roles" }),
			component: <Roles />,
			authorization: "ManageRoles",
			licensed: "pack-net-roles",
		},
		{
			link: "users",
			text: intl.formatMessage({ id: "Users" }),
			component: <Users />,
			authorization: "ManageUsers",
			licensed: "pack-net-users",
		},
		{
			link: "event-notifications",
			text: intl.formatMessage({ id: "Event Notifications" }),
			component: <LazyRoute Component={LazyEventNotifications} />,
			authorization: "ManageEventNotifications",
			licensed: "pack-net-event-notifications",
		},
		{
			link: "planned-production",
			text: intl.formatMessage({ id: "Planned Production" }),
			component: <LazyRoute Component={LazyProductionScheduleView} />,
			authorization: "ManagePlannedProduction",
			licensed: "pack-net-planned-production",
		},
		{
			link: "configuration-audit",
			text: intl.formatMessage({ id: "Configuration Audit" }),
			authorization: "AccessConfigurationAudit",
			component: <ConfigurationAudit />,
		},
		{
			link: "secure-print",
			text: intl.formatMessage({ id: "Secure Print" }),
			component: <LazyRoute Component={LazySecurePrintConfigurationView} />,
			authorization: "ManageImportPipelines", // todo use its own authorization
			licensed: "secure-print",
		},
	];

	return filterAuthorizedAndLicensedTabs(tabs, currentUser);
};

const ConfigurationRoutes = ({ currentUser }) => {
	const classes = useStyles();
	const intl = useIntl();
	const tabs = useMemo(() => getAuthorizedConfigurationTabs(currentUser, intl), [currentUser, intl]);

	return (
		<>
			<Segment className={classes.submenu}>
				<ScrollMenu scrollVisible currentUser={currentUser} list={tabs.map((tab) => ({ ...tab }))} />
			</Segment>
			<Outlet />
		</>
	);
};

export default ConfigurationRoutes;
