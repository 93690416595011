import ListView from "@/components/list-view";
import New from "./new-machine-group";
import React from "react";
import Table from "./machine-groups";
import { useIntl } from "react-intl";

function MachineGroups() {
	const intl = useIntl();
	const title = intl.formatMessage({ id: "Machine Groups" });

	return <ListView title={title} Table={Table} AddItem={New} />;
}

export default MachineGroups;
