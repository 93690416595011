import React from "react";
import { createUseStyles, useTheme } from "react-jss";

import TextValueGroup from "./text-value-group";

const useStyles = createUseStyles((theme) => ({
	container: {
		display: "flex",
		flexDirection: "column",
		"& > div:last-child": {
			paddingBottom: "10px",
		},
	},
}));

function TextValueList({ items }) {
	const theme = useTheme();
	const classes = useStyles({ theme });

	return (
		<div className={classes.container}>
			{items.map((item, i) => (
				<div key={`text-value-group-div-${i.headerText}-${i}`}>
					<TextValueGroup headerText={item.headerText} value={item.value} />
				</div>
			))}
		</div>
	);
}

export default TextValueList;
