export function getOriginalUOM(value, preferredIsMetric) {
	//const allEqual = value.every((v) => v.hasOwnProperty("uom") && v.uom === value[0].uom && v.uom !== "unknown");
	// return selectUOM(value[0].uom, preferredIsMetric, allEqual);
	return selectUOM("unknown", preferredIsMetric, false);
}

function selectUOM(itemUOM, preferredIsMetric, useIncomingDataUOM) {
	let originalUOM = "";

	if (useIncomingDataUOM) {
		if (itemUOM === "metric") {
			originalUOM = "squaredMillimeters";
		} else {
			originalUOM = "squaredInches";
		}
	} else {
		if (preferredIsMetric) {
			originalUOM = "squaredMillimeters";
		} else {
			originalUOM = "squaredInches";
		}
	}
	return [useIncomingDataUOM, originalUOM];
}

export function getTargetUOM(knownFromData, originalUOM, preferredIsMetric) {
	if (knownFromData) {
		if (originalUOM === "squaredMillimeters") {
			return "squaredMeters";
		}
		return "squaredFeet";
	} else {
		if (preferredIsMetric) {
			return "squaredMeters";
		}
		return "squaredFeet";
	}
}

export function convertUomRawValue(value, current, preferred) {
	if (current === preferred && ["squaredInches", "squaredMillimeters"].includes(current)) return value;
	if (current === "squaredInches") {
		if (preferred === "squaredFeet") return value / 144;
		if (preferred === "squaredMeters") return value / 1_550;
		if (preferred === "squaredMillimeters") return value * 645.16;
	}
	if (current === "squaredMillimeters") {
		if (preferred === "squaredMeters") return value / 1_000_000;
		if (preferred === "squaredFeet") return value / 92_900;
		if (preferred === "squaredInches") return value / 645.16;
	}
	return null;
}
