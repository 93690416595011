import React, { Fragment, useContext, useEffect, useState } from "react";
import { useTheme, createUseStyles } from "react-jss";
import { useIntl } from "react-intl";
import { Button, Container, Header, Image, Modal } from "semantic-ui-react";
import { ReactComponent as ErrorIcon } from "@/assets/images/error-current.svg";
import { ReactComponent as IconRightArrow } from "@/assets/images/icons_right_arrow.svg";
import { ReactComponent as IconModalError } from "@/assets/images/icons_modal_error.svg";
import { ReactComponent as IconModalWarning } from "@/assets/images/icons_modal_warning.svg";
import { ReactComponent as WarningIcon } from "@/assets/images/warning-current.svg";
import { ProductionContext } from "@/contexts/production-context";
import machineTypes from "@/constants/machineTypes";
import EmErrorsAndWarnings from "./errors-and-warnings-em";
import FusionErrorsAndWarnings from "./errors-and-warnings-fusion";
import X4ErrorsAndWarnings from "./errors-and-warnings-x4-new";
import X5ErrorsAndWarnings from "./errors-and-warnings-x5";
import X6ErrorsAndWarnings from "./errors-and-warnings-x6";
import ZebraErrorsAndWarnings from "./errors-and-warnings-zebra";
import CloseButton from "./close-button";
import X7ErrorsAndWarnings from "./errors-and-warnings-x7";
import Flex from "./flex";

const useStyles = createUseStyles((theme) => ({
	Overlay: {
		width: "1366 !Important",
		height: "768px !Important",
		padding: "62px 101px !Important",
		opacity: 0.9,
		backgroundColor: "#353430",
	},
	OuterContainer: {
		display: "flex !important",
		padding: "0 25% !important",
		boxSizing: "border-box",
		flexDirection: "row",
		justifyContent: "center",
		borderRadius: "8px",
	},
	ModalLeftPane: {
		minWidth: "100%",
		width: "50vw",
		height: "644px",
		backgroundColor: "#ffffff",
		borderRadius: "8px",
	},
	ModalRightPane: {
		minWidth: "100%",
		width: "50vw",
		height: "644px",
		flexGrow: "0",
		backgroundColor: "#f2f4f5",
		textAlign: "right",
		borderRadius: "8px",
	},

	LeftTopBuffer: {
		height: "30%",
	},
	ErrorMessageAndIconLayout: {
		width: "90%",
		height: "194px",
		flexGrow: "0",
		display: "flex !important",
		flexDirection: "column",
		justifyContent: "center",
		alignItems: "center",
		gap: "16px",
		margin: "0 0 153px",
		textAlign: "center",
	},
	ErrorIconContainer: {
		width: "56px !important",
		height: "56px !important",
		margin: "0px 231px 16px",
		padding: "10px",
		flexGrow: 0,
		opacity: "0.9",
		borderRadius: "12px",
		textAlign: "center",
	},
	ErrorIcon: {
		width: "30px !important",
		height: "30px !important",
	},
	ErrorHeader: {
		height: "48px !important",
		alignSelf: "stretch !important",
		flexGrow: 0,
		opacity: 0.8,
		fontFamily: "Stolzl",
		fontSize: "40px !important",
		fontWeight: "500 !important",
		fontStretch: "normal",
		fontStyle: "normal",
		lineHeight: "normal",
		letterSpacing: "normal",
		textAlign: "center",
		color: "#353430",
	},
	ErrorInstructionText: {
		width: "518px !important",
		height: "58px !important",
		flexGrow: 0,
		opacity: 0.8,
		fontFamily: "Stolzl",
		fontSize: "24px !important",
		fontWeight: "normal",
		fontStretch: "normal",
		fontStyle: "normal",
		lineHeight: "normal",
		letterSpacing: "normal",
		textAlign: "center",
		padding: "32px !important",
		color: "#353430",
	},

	RightTopBuffer: {
		height: "13%",
		textAlign: "right",
		padding: "32px !important",
	},
	CloseButtonIcon: {
		width: "36px",
		height: "36px",
	},
	ErrorImageContainer: {
		height: "70%",
		textAlign: "center",
		padding: "10% !important",
	},
	ErrorImage: {
		width: "100%",
		height: "100%",
	},

	PreviousError: {
		alignSelf: "center",
		textAlign: "left",
		verticalAlign: "bottom",
		padding: "5% !Important",
		display: "flex",
	},
	LeftArrow: {
		width: "24px !Important",
		height: "24px !Important",
		objectFit: "contain  !Important",
		transform: "rotate(-180deg)",
	},
	NextError: {
		alignSelf: "center",
		textAlign: "right",
		verticalAlign: "bottom",
		padding: "5% !Important",
		display: "flex",
	},
	RightArrow: {
		width: "24px !Important",
		height: "24px !Important",
		objectFit: "contain !Important",
	},
}));

const images = Object.fromEntries(
	Object.entries(
		import.meta.glob("../assets/images/errors-and-warnings/**/*.{png,jpe,jpeg,svg}", {
			query: "?url",
			import: "default",
		}),
	).map(([path, module]) => [path.replace("../assets/images/errors-and-warnings/", ""), module]),
);

export default function ErrorsAndWarningsNew() {
	const classes = useStyles();
	const intl = useIntl();
	const theme = useTheme();
	const { currentMachineGroup } = useContext(ProductionContext);
	const [machines, setMachines] = useState([]);
	const [machineServices, setMachineServices] = useState();
	const [errors, setErrors] = useState([]);
	const [warnings, setWarnings] = useState([]);
	const [selectedItem, setSelectedItem] = useState();
	const [showError, setShowingError] = useState(false);
	const [isModalOpen, setIsModalOpen] = useState(false);

	useEffect(() => {
		const newMachines =
			Object.entries(currentMachineGroup?.configuredMachinesWithMachineType ?? {}).map(([machineId, machineType]) => ({
				machineId,
				machineType,
			})) ?? [];

		const newIds = new Set(newMachines.map((x) => x.machineId));
		const existingIds = new Set(machines.map((x) => x.machineId));
		if (newIds.size !== existingIds.size || ![...newIds].every((x) => existingIds.has(x))) {
			setErrors([]);
			setWarnings([]);
			setMachines(newMachines);
		}
	}, [currentMachineGroup, machines]);

	useEffect(() => {
		function updateErrors(machineId, newErrors) {
			setErrors((oldErrors) => {
				const updatedList = oldErrors.filter((x) => x.machineId !== machineId).concat(newErrors);
				const lastIndex = updatedList.length - 1;
				updatedList.forEach((x, index) => {
					x.index = index;
					x.lastIndex = lastIndex;
				});
				return updatedList;
			});
		}

		function updateWarnings(machineId, newWarnings) {
			setWarnings((oldWarnings) => {
				const updatedList = oldWarnings.filter((x) => x.machineId !== machineId).concat(newWarnings);
				const lastIndex = updatedList.length - 1;
				updatedList.forEach((x, index) => {
					x.index = index;
					x.lastIndex = lastIndex;
				});
				return updatedList;
			});
		}

		const includedMachineTypes = new Set(machines.map((x) => x.machineType));
		setMachineServices(
			[...includedMachineTypes].map((machineType, index) => {
				switch (machineType) {
					case machineTypes.Em:
						return <EmErrorsAndWarnings key={index} machines={machines} onErrorsChanged={updateErrors} />;
					case machineTypes.Fusion:
						return <FusionErrorsAndWarnings key={index} machines={machines} onErrorsChanged={updateErrors} />;
					case machineTypes.X4:
						return (
							<X4ErrorsAndWarnings
								key={index}
								machines={machines}
								onErrorsChanged={updateErrors}
								onWarningsChanged={updateWarnings}
							/>
						);
					case machineTypes.X5:
					case machineTypes.X5wPrint:
						return (
							<X5ErrorsAndWarnings
								key={index}
								machines={machines}
								onErrorsChanged={updateErrors}
								onWarningsChanged={updateWarnings}
							/>
						);
					case machineTypes.X6:
						return (
							<X6ErrorsAndWarnings
								key={index}
								machines={machines}
								onErrorsChanged={updateErrors}
								onWarningsChanged={updateWarnings}
							/>
						);
					case machineTypes.X7:
						return (
							<X7ErrorsAndWarnings
								key={index}
								machines={machines}
								onErrorsChanged={updateErrors}
								onWarningsChanged={updateWarnings}
							/>
						);
					case machineTypes.ZebraPrinter:
						return (
							<ZebraErrorsAndWarnings
								key={index}
								machines={machines}
								onErrorsChanged={updateErrors}
								onWarningsChanged={updateWarnings}
							/>
						);
					default:
						console.error(`No errors and warnings service for ${machineType} machines`);
						return undefined;
				}
			}),
		);
	}, [machines]);

	useEffect(() => {
		if (!isModalOpen) {
			return;
		}

		let currentItem;
		if (!selectedItem) {
			changeSelectedItem(showError ? errors[0] : warnings[0]);
		} else if (showError) {
			currentItem =
				errors.find(
					(x) =>
						x.machineId === selectedItem.machineId &&
						x.code === selectedItem.code &&
						x.parameter === selectedItem.parameter,
				) ?? errors[0];
		} else {
			currentItem =
				warnings.find(
					(x) =>
						x.machineId === selectedItem.machineId &&
						x.code === selectedItem.code &&
						x.parameter === selectedItem.parameter,
				) ?? warnings[0];
		}

		if (currentItem === selectedItem) {
			return;
		}

		if (currentItem) {
			changeSelectedItem(currentItem);
		} else {
			closeModal();
		}
	}, [isModalOpen, errors, warnings, showError, selectedItem]);

	function closeModal() {
		setIsModalOpen(false);
		changeSelectedItem();
	}

	async function changeSelectedItem(newItem) {
		if (newItem) {
			newItem.title = newItem.title ?? getTitle(newItem);
			newItem.instruction = newItem.instruction ?? getInstruction(newItem);
			newItem.image = newItem.image ?? (await getImagePath(newItem));
		}
		setSelectedItem(newItem);
	}

	function getTitle(item) {
		const translationKeyPrefix = `${item.machineType} ${item.severity}`;
		const translationKeyCategory = item.category ? item.category : item.code; // this can be either a specific error code, or a category encompassing a set of error codes (e.g. four different "door open" errors may map to a single string) - note "category" is a string while "code" is a number
		const translationKey = `${translationKeyPrefix} ${translationKeyCategory}${
			item.parameter ? `-${item.parameter}` : ""
		}`;
		let actionMessage = "";
		if (item.actionKey) {
			const actionKey = `${item.machineType} Action ${item.actionKey}`;
			actionMessage = intl.formatMessage({ id: actionKey });
			if (actionMessage === actionKey) {
				actionMessage = "";
			}
		}
		return `${intl.formatMessage({ id: translationKey })}${actionMessage}`;
	}

	function getInstruction(item) {
		const translationKeyPrefix = `${item.machineType} Action`;
		const translationKeyCategory = item.category ? item.category : item.code; // this can be either a specific error code, or a category encompassing a set of error codes (e.g. four different "door open" errors may map to a single string) - note "category" is a string while "code" is a number
		const translationKey = `${translationKeyPrefix} ${translationKeyCategory}${
			item.parameter ? `-${item.parameter}` : ""
		}`;

		let message;
		if (item.instructionValues) message = `${intl.formatMessage({ id: translationKey }, item.instructionValues)}`;
		else message = `${intl.formatMessage({ id: translationKey })}`;

		if (message === translationKey) return "";
		return message;
	}

	async function getImagePath(item) {
		const module =
			images[(item.machineType + "/" + item.severity + "/" + item.code + ".svg").toLowerCase()] ??
			images[(item.machineType + "/" + item.severity + "/" + item.code + ".png").toLowerCase()] ??
			images[(item.machineType + "/" + item.severity + "/" + item.category + ".svg").toLowerCase()] ??
			images[(item.machineType + "/" + item.severity + "/" + item.category + ".png").toLowerCase()] ??
			images[(item.machineType + "/" + item.severity + "/default.svg").toLowerCase()] ??
			images[(item.machineType + "/" + item.severity + "/default.png").toLowerCase()];

		if (module) return await module();
	}

	function displayPreviousItem() {
		if (showError) {
			changeSelectedItem(errors[selectedItem.index - 1]);
		} else {
			changeSelectedItem(warnings[selectedItem.index - 1]);
		}
	}

	function displayNextItem() {
		if (showError) {
			changeSelectedItem(errors[selectedItem.index + 1]);
		} else {
			changeSelectedItem(warnings[selectedItem.index + 1]);
		}
	}

	return (
		<Fragment>
			{machineServices}
			<Modal
				open={isModalOpen}
				dimmer="blurring"
				size="large"
				centered={true}
				closeOnEscape={false}
				closeOnDimmerClick={false}
				trigger={
					<Flex alignCenter>
						<Button
							disabled={errors.length < 1}
							onClick={() => {
								setShowingError(true);
								setIsModalOpen(true);
							}}
							style={{
								backgroundColor: theme.error.replace(" !important", ""),
								marginRight: "24px",
								width: "40px",
								height: "40px",
								padding: "0px",
								visibility: errors.length > 0 ? "visible" : "hidden",
							}}
						>
							<ErrorIcon fill="#fff" />
						</Button>
						<Button
							disabled={warnings.length < 1}
							onClick={() => {
								setShowingError(false);
								setIsModalOpen(true);
							}}
							style={{
								backgroundColor: theme.warning.replace(" !important", ""),
								marginLeft: "auto",
								width: "40px",
								height: "40px",
								padding: "0px ",
								visibility: warnings.length > 0 ? "visible" : "hidden",
							}}
						>
							<WarningIcon fill="#fff" />
						</Button>
					</Flex>
				}
			>
				<Modal.Content className={classes.OuterContainer}>
					<div className={classes.ModalLeftPane}>
						<Container className={classes.LeftTopBuffer}>&nbsp;</Container>
						<Container className={classes.ErrorMessageAndIconLayout}>
							<Container className={classes.ErrorIconContainer}>
								{showError ? <IconModalError /> : <IconModalWarning />}
							</Container>
							<Header className={classes.ErrorHeader}>{selectedItem?.title}</Header>
							<span className={classes.ErrorInstructionText}>{selectedItem?.instruction}</span>
						</Container>
						<Container className={classes.PreviousError}>
							{selectedItem?.index > 0 && (
								<Button icon onClick={displayPreviousItem}>
									<IconRightArrow className={classes.LeftArrow} />
								</Button>
							)}
						</Container>
					</div>
					<div className={classes.ModalRightPane}>
						<Container className={classes.RightTopBuffer}>
							<CloseButton className={classes.CloseButtonIcon} onClick={closeModal} />
						</Container>
						<Container className={classes.ErrorImageContainer}>
							<Image src={selectedItem?.image} className={classes.ErrorImage} />
						</Container>
						<Container className={classes.NextError}>
							{selectedItem?.index !== selectedItem?.lastIndex && (
								<Button icon onClick={displayNextItem}>
									<IconRightArrow className={classes.RightArrow} />
								</Button>
							)}
						</Container>
					</div>
				</Modal.Content>
			</Modal>
		</Fragment>
	);
}
