import { SmallBook, SmallerBook } from "@/components/typography";
import Flex from "@/components/flex";
import { FormattedMessage } from "react-intl";
import styled from "styled-components";

const SmallerBookGrey = styled(SmallerBook)`
	color: ${(props) => props.theme.colors.darkGreyText};
`;

function TwoLineDropdownItem({ mainTextId, subTextId }) {
	return (
		<Flex column>
			<SmallBook>
				<FormattedMessage id={mainTextId} defaultMessage={mainTextId} />
			</SmallBook>
			<SmallerBookGrey>
				<FormattedMessage id={subTextId} defaultMessage={subTextId} />
			</SmallerBookGrey>
		</Flex>
	);
}

export default TwoLineDropdownItem;
