import { Button, Grid, Header, Radio, Tab } from "semantic-ui-react";
import { FormattedMessage, useIntl } from "react-intl";
import React, { useState } from "react";
import { createUseStyles, useTheme } from "react-jss";
import { routeStrings } from "@/constants";
import { readLocalStorage } from "@/api/local-storage";
import useAxios from "@/api/useAxios";

const useStyles = createUseStyles((theme) => ({
	radio: {
		margin: "16px !important",
		marginBottom: "-6px !important",
	},
}));

const TrackControl = ({ classes, track, rollerTools, selectedMachine, setSelectedMachine, fusionServiceApi }) => {
	return (
		<>
			<Grid.Row columns={3}>
				<Grid.Column width={2}>
					<Header as="h4">
						<Header.Content>
							<FormattedMessage id="Track {trackNumber}" values={{ trackNumber: track.trackNumber }} />
						</Header.Content>
					</Header>
				</Grid.Column>
				<Grid.Column textAlign="center">
					<Header as="h5">
						<Header.Content>
							<FormattedMessage id="Pressure Roller" />
							<Header.Subheader>
								<FormattedMessage id="Off" />
								<Radio
									className={classes.radio}
									toggle
									onChange={async () => {
										const current = rollerTools.includes(track.trackNumber);
										await fusionServiceApi.addWithUrl(
											current
												? `v1/machines/${selectedMachine.id}/tracks/${track.trackNumber}/deactivatepressureroller`
												: `v1/machines/${selectedMachine.id}/tracks/${track.trackNumber}/activatepressureroller`
										);
										setSelectedMachine({
											...selectedMachine,
											rollerTools: current
												? rollerTools.filter((i) => i !== track.trackNumber)
												: [...rollerTools, track.trackNumber],
										});
									}}
									checked={rollerTools.includes(track.trackNumber)}
								/>
								<FormattedMessage id="On" />
							</Header.Subheader>
						</Header.Content>
					</Header>
				</Grid.Column>
				<Grid.Column textAlign="center">
					<Header as="h5">
						<Header.Content>
							<FormattedMessage id="Feed Roller" />
							<Header.Subheader>
								<Button
									className={classes.radio}
									onClick={async () => {
										await fusionServiceApi.addWithUrl(
											`v1/machines/${selectedMachine.id}/tracks/${track.trackNumber}/feedforward`
										);
									}}
								>
									<FormattedMessage id="Feed Roller Forward" />
								</Button>
							</Header.Subheader>
						</Header.Content>
					</Header>
				</Grid.Column>
			</Grid.Row>
		</>
	);
};

const CrossHeadControl = ({ classes, selectedMachine, setSelectedMachine, fusionServiceApi }) => (
	<Grid relaxed stackable doubling divided="vertically" centered verticalAlign="middle" textAlign="center">
		<Grid.Row columns={2}>
			<Grid.Column textAlign="center">
				<Header as="h5">
					<FormattedMessage id="Cut" />
					<Header.Subheader>
						<FormattedMessage id="Off" />
						<Radio
							className={classes.radio}
							toggle
							onChange={async () => {
								await fusionServiceApi.addWithUrl(
									selectedMachine.activeCrossHeadCut
										? `v1/machines/${selectedMachine.id}/deactivatecrossheadknife`
										: `v1/machines/${selectedMachine.id}/activatecrossheadknife`
								);
								setSelectedMachine({
									...selectedMachine,
									activeCrossHeadCut: !selectedMachine.activeCrossHeadCut,
								});
							}}
							checked={selectedMachine.activeCrossHeadCut}
						/>
						<FormattedMessage id="On" />
					</Header.Subheader>
				</Header>
			</Grid.Column>
			<Grid.Column textAlign="center">
				<Header as="h5">
					<FormattedMessage id="Latch" />
					<Header.Subheader>
						<FormattedMessage id="Off" />
						<Radio
							className={classes.radio}
							toggle
							onChange={async () => {
								await fusionServiceApi.addWithUrl(
									selectedMachine.activeCrossHeadLatch
										? `v1/machines/${selectedMachine.id}/deactivatecrossheadlatch`
										: `v1/machines/${selectedMachine.id}/activatecrossheadlatch`
								);
								setSelectedMachine({
									...selectedMachine,
									activeCrossHeadLatch: !selectedMachine.activeCrossHeadLatch,
								});
							}}
							checked={selectedMachine.activeCrossHeadLatch}
						/>
						<FormattedMessage id="On" />
					</Header.Subheader>
				</Header>
			</Grid.Column>
		</Grid.Row>
	</Grid>
);

const CalibrationControl = ({ classes, fusionServiceApi, selectedMachine }) => (
	<Grid
		relaxed
		stackable
		doubling
		divided="vertically"
		verticalAlign="middle"
		centered
		stretched
		className={classes.Grid}
	>
		<Grid.Row columns={3}>
			<Grid.Column>
				<Button
					className={classes.Button}
					disabled={selectedMachine.status === "Calibrating"}
					onClick={() => fusionServiceApi.addWithUrl(`v1/machines/${selectedMachine.id}/calibrate`)}
				>
					<FormattedMessage id="Calibrate" />
				</Button>
			</Grid.Column>
			<Grid.Column>
				<Button
					className={classes.Button}
					onClick={() => fusionServiceApi.addWithUrl(`v1/machines/${selectedMachine.id}/cycleallcrossheadtools`)}
				>
					<FormattedMessage id="Cycle CrossHead Tools" />
				</Button>
			</Grid.Column>
			<Grid.Column>
				<Button
					className={classes.Button}
					onClick={() => fusionServiceApi.addWithUrl(`v1/machines/${selectedMachine.id}/cyclealltracktools`)}
				>
					<FormattedMessage id="Cycle Tracks" />
				</Button>
			</Grid.Column>
		</Grid.Row>
	</Grid>
);

function FusionService(props) {
	const intl = useIntl();
	const theme = useTheme();
	const classes = useStyles({ theme });
	const token = readLocalStorage("BEARER");
	const [selectedMachine, setSelectedMachine] = useState(props.selectedMachine);
	const FusionServiceApi = useAxios(routeStrings.FusionApi, token);

	const panes = [
		{
			menuItem: intl.formatMessage({ id: "Calibrate" }),
			render: () => (
				<Tab.Pane className={classes.tabPane} padded basic>
					<CalibrationControl classes={classes} fusionServiceApi={FusionServiceApi} selectedMachine={selectedMachine} />
				</Tab.Pane>
			),
		},
		{
			menuItem: intl.formatMessage({ id: "CrossHead" }),
			render: () => (
				<Tab.Pane className={classes.tabPane} padded basic>
					<CrossHeadControl
						classes={classes}
						fusionServiceApi={FusionServiceApi}
						selectedMachine={selectedMachine}
						setSelectedMachine={setSelectedMachine}
					/>
				</Tab.Pane>
			),
		},
		{
			menuItem: intl.formatMessage({ id: "Tracks" }),
			render: () => (
				<Tab.Pane className={classes.tabPane} padded basic>
					<Grid relaxed stackable doubling divided="vertically" verticalAlign="middle">
						{selectedMachine.tracks.map((t) => (
							<TrackControl
								classes={classes}
								key={t.trackNumber}
								track={t}
								brakeTools={selectedMachine.brakeTools ?? []}
								rollerTools={selectedMachine.rollerTools ?? []}
								selectedMachine={selectedMachine}
								setSelectedMachine={setSelectedMachine}
								fusionServiceApi={FusionServiceApi}
							/>
						))}
					</Grid>
				</Tab.Pane>
			),
		},
	];

	return <Tab menu={{ fluid: true, vertical: true, tabular: true }} panes={panes} className={classes.tab} />;
}

export default FusionService;
