import useCurrentUser from "@/hooks/use-current-user";
import { useEffect } from "react";

function Pendo({ children }) {
	const currentUser = useCurrentUser();

	useEffect(() => {
		if (currentUser && window?.pendo && currentUser?.id && currentUser?.Tenant && currentUser?.Company) {
			window.pendo.initialize({
				visitor: {
					id: currentUser.id,
					email: currentUser.email,
					// full_name:    // Recommended if using Pendo Feedback
					// role:         // Optional
					userName: currentUser.sub,
					// You can add any additional visitor level key-values here,
					// as long as it's not one of the above reserved names.
				},

				account: {
					id: currentUser.Tenant,
					name: currentUser.Company,
					// is_paying:    // Recommended if using Pendo Feedback
					// monthly_value:// Recommended if using Pendo Feedback
					// planLevel:    // Optional
					// planPrice:    // Optional
					// creationDate: // Optional

					// You can add any additional account level key-values here,
					// as long as it's not one of the above reserved names.
				},
			});
		}
	}, [currentUser]);

	return <>{children}</>;
}

export default Pendo;
