import { useEffect, useState } from "react";

const useLocalStorage = (key) => {
    const [value, setValue] = useState(localStorage.getItem(key) || "");

    useEffect(() => {
        if (value == null) {
            localStorage.removeItem(key);
        } else {
            localStorage.setItem(key, value);
        }
    }, [key, value]);

    return [value, setValue];
};

export { useLocalStorage };
