import styled from "styled-components";
import { Message, Button } from "semantic-ui-react";
import { FormattedMessage } from "react-intl";
import { useState, useContext } from "react";
import { PublicSettingsContext } from "@/contexts/public-settings-context";

const StyledMessage = styled(Message)`
	&&& {
		position: fixed;
		top: 30px;
		left: calc(100vw - 440px);
		z-index: 10;
		margin-right: 20px;
		margin-top: 0;
	}
`;

function VersionWarning() {
	const [refreshDisplayed, setRefreshDisplayed] = useState(false);
	const [currentVersion, setCurrentVersion] = useState(null);

	const { version: newVersion, setRefreshSettingsEnabled } = useContext(PublicSettingsContext);

	// When we first get a version from context
	if (newVersion !== "" && !currentVersion) {
		setCurrentVersion(newVersion);
	}

	// When when actually get a new version
	if (newVersion !== "" && currentVersion && currentVersion !== newVersion) {
		setRefreshDisplayed(true);
		setCurrentVersion(newVersion);
	}

	const ignoreRefreshPrompt = () => {
		// This will turn off the query
		setRefreshSettingsEnabled(false);
		setRefreshDisplayed(false);
	};

	if (!refreshDisplayed) return <></>;

	return (
		<StyledMessage floating compact onDismiss={ignoreRefreshPrompt}>
			<Message.Header>
				<FormattedMessage id="Software Update Available" />
			</Message.Header>
			<Message.Content>
				<div style={{ margin: "0 16px" }}>
					<FormattedMessage
						id="This page is running an old version of PackNet. Refresh your page to run the latest version."
					/>
				</div>
				<div>
					<Button floated="right" primary onClick={() => window.location.reload()}>
						<FormattedMessage id="Reload Now" />
					</Button>
				</div>
			</Message.Content>
		</StyledMessage>
	);
}

export default VersionWarning;
