import { Segment } from "semantic-ui-react";
import CheckboxButton from "@/components/checkbox-button";
import { FormattedMessage } from "react-intl";
import { createUseStyles } from "react-jss";

const useStyles = createUseStyles(() => ({
	checkboxSegment: {
		padding: "0 !important",
		margin: "1px !important",
		marginRight: "24px !important",
		"& .ui.button:disabled": {
			boxShadow: "0px 0px 0px 1px #e3e7e9 inset !important",
			opacity: ".3 !important",
		},
	},
}));

function UnorderedCheckbox({ pipeline, category, name, label, conflictsWith, handleClick }) {
	const classes = useStyles();
	if (!pipeline) return <></>;

	const names = pipeline[category]?.map((i) => i.name) ?? [];
	const stepConfigured = names.some((step) => step === name);
	const hasConflict = !stepConfigured && conflictsWith && names.some((name) => conflictsWith.includes(name));

	return (
		<Segment basic className={classes.checkboxSegment}>
			<CheckboxButton
				disabled={hasConflict}
				floated="left"
				checked={stepConfigured}
				onClick={handleClick}
				style={{ width: "fit-content" }}
			>
				<FormattedMessage id={label} />
			</CheckboxButton>
		</Segment>
	);
}

export default UnorderedCheckbox;
