import React, { Fragment, useEffect, useState } from "react";
import { createUseStyles, useTheme } from "react-jss";
import { findProduct, flattenCustomAttributes } from "@/api/dim-product-api";
import { Divider } from "semantic-ui-react";

import FailedRetry from "@/components/failed-retry";
import Loading from "@/components/loading";

import { FormattedMessage, useIntl } from "react-intl";

const identifiers = [
	{ key: "UPC", name: "UPC", isGlobal: true },
	{ key: "GTIN", name: "GTIN", isGlobal: true },
	{ key: "GTIN_8", name: "GTIN-8", isGlobal: true },
	{ key: "GTIN_12", name: "GTIN-12", isGlobal: true },
	{ key: "GTIN_13", name: "GTIN-13", isGlobal: true },
	{ key: "GTIN_14", name: "GTIN-14", isGlobal: true },
	{ key: "EAN", name: "EAN", isGlobal: true },
	{ key: "MPN", name: "MPN", isGlobal: true },
	{ key: "ISBN", name: "ISBN", isGlobal: true },
	{ key: "ISBN_10", name: "ISBN-10", isGlobal: true },
	{ key: "ISBN_13", name: "ISBN-13", isGlobal: true },
	{ key: "SKU", name: "SKU", isGlobal: false },
	{ key: "ItemNumber", name: "ItemNumber", isGlobal: false },
];

const useStyles = createUseStyles((theme) => ({
	grid: {
		display: "flex",
		flexWrap: "nowrap",
		justifyContent: "space-between",
		backgroundColor: theme.light,
	},
	rightColumn: {
		margin: "0 33px",
		backgroundColor: "white",
		padding: "1.5em !important",
		paddingRight: "2.3em !important",
		height: "calc(100vh - 256px)",
		overflow: "auto",
		overflowY: "scroll !important",
		width: "100%",
		borderRadius: "8px",
	},
	header: {
		padding: "1em !important",
		paddingTop: "3em !important",
	},
	subHeader: {
		paddingTop: "1em !important",
	},
	attributeName: {
		size: "16px",
		margin: "0 0 0.5em !important",
	},
	attributeValue: {
		size: "16px",
		fontFamily: "Stolzl-Book",
	},
	formEntry: {
		borderTop: "2px !important",
		marginTop: "0px !important",
	},
	footer: {
		position: "fixed",
		bottom: "0",
		display: "flex",
		flexDirection: "row",
		flexWrap: "wrap",
		justifyContent: "flex-start",
		alignItems: "center",
		alignSelf: "auto",
		paddingLeft: "1.5rem",
		paddingBottom: "1.5rem",
	},
	breakLine: {
		marginLeft: "-30px !important",
		marginRight: "-30px !important",
	},
}));

function ProductDetails({ config, productId }) {
	const intl = useIntl();
	const theme = useTheme();
	const classes = useStyles({ theme });
	const [loading, setLoading] = useState(true);
	const [failed, setFailed] = useState(false);
	const defaultProduct = {
		attributes: {},
		customAttributes: {},
		identifiers: {},
		dimensions: { length: 0, width: 0, height: 0 },
		quantity: 1,
		voidFill: { length: 0, width: 0, height: 0 },
	};
	const [product, setProduct] = useState(defaultProduct);

	async function load() {
		setFailed(false);
		setLoading(true);

		try {
			let newProduct;
			if (productId) {
				const result = await findProduct(productId);
				newProduct = flattenCustomAttributes(result.product, config);
				let ids = newProduct.identifiers;
				identifiers.forEach((i) => {
					if (i.key === config.mainIdentifierType) {
						ids = { ...ids, [config.mainIdentifierType]: productId };
					} else {
						ids = { ...ids, [i.key]: newProduct.identifiers[i.key] };
					}
				});

				const finalProduct = {
					...newProduct,
					identifiers: ids,
				};
				setProduct(finalProduct);
			}
		} catch (err) {
			console.error(err);
			setFailed(true);
		}

		setLoading(false);
	}

	useEffect(() => {
		load();
	}, []);

	function getFormComponent(step) {
		let value;
		switch (step.type) {
			case "String":
				const id = identifiers.find((id) => id.key === step.name);
				if (id) {
					if (product.identifiers) value = product.identifiers[step.name] ?? product.identifiers[step.key];
				} else if (step.name === "Name") {
					value = product.name;
				} else if (step.name === "Description") {
					value = product.description;
				} else if (step.name === "Product Image") {
					value = product.productImage;
				} else {
					value = product[step.name];
				}
				return (
					<div>
						<p className={classes.attributeName}>{step.name}</p>
						<p className={classes.attributeValue}>{value?.toString() || "--"}</p>
					</div>
				);
			case "Date":
				return (
					<div>
						<p className={classes.attributeName}>{step.name}</p>
						<p className={classes.attributeValue}>{product[step.name]}</p>
					</div>
				);
			case "Dimensions":
				return (
					<div>
						<p className={classes.attributeName}>{step.name}</p>
						<p className={classes.attributeValue}>
							{product.dimensions.length} X {product.dimensions.width} X {product.dimensions.height}
						</p>
					</div>
				);
			case "Nesting":
				return (
					<div>
						<p className={classes.attributeName}>{step.name}</p>
						<p className={classes.attributeValue}>
							{product.canNest
								? intl.formatMessage({
										id: "Yes, the product can nest",
								  })
								: intl.formatMessage({
										id: "No, the product cannot nest",
								  })}
						</p>
						{product.canNest && (
							<div>
								<p className={classes.attributeValue}>
									<FormattedMessage id="DimLengthWithOneNest" />: {product?.nesting?.nestGrowthDimension1 ?? 0}
								</p>
								<p className={classes.attributeValue}>
									<FormattedMessage id="DimWidthWithOneNest" />: {product?.nesting?.nestGrowthDimension2 ?? 0}
								</p>
								<p className={classes.attributeValue}>
									<FormattedMessage id="DimHeightWithOneNest" />: {product?.nesting?.nestGrowthDimension3 ?? 0}
								</p>
								<p className={classes.attributeValue}>
									<FormattedMessage id="DimMaxNumberOfProductsNest" />: {product?.nesting?.nestMax ?? 0}
								</p>
							</div>
						)}
					</div>
				);
			case "Number":
				return (
					<div>
						<p className={classes.attributeName}>{step.name}</p>
						<p className={classes.attributeValue}>{step.name === "Quantity" ? product.quantity : product[step.name]}</p>
					</div>
				);
			case "Orientation":
				value = product.orientation ?? "Any";
				return (
					<div>
						<p className={classes.attributeName}>{step.name}</p>
						<p className={classes.attributeValue}>{intl.formatMessage({ id: value })}</p>
					</div>
				);
			case "Select":
				return (
					<div>
						<p className={classes.attributeName}>{step.name}</p>
						<p className={classes.attributeValue}>{product[step.name] ?? "--"}</p>
					</div>
				);
			case "Bool":
				return (
					<div>
						<p className={classes.attributeName}>{step.name}</p>
						<p className={classes.attributeValue}>{product[step.name] ? "True" : "False"}</p>
					</div>
				);
			case "VoidFill":
				return (
					<div>
						<p className={classes.attributeName}>{step.name}</p>
						<p className={classes.attributeValue}>
							{product.canContain
								? intl.formatMessage({
										id: "Yes, it can contain other products",
								  })
								: intl.formatMessage({
										id: "No, it cannot contain other products",
								  })}
						</p>
						{product.canContain && (
							<div>
								<p className={classes.attributeValue}>
									<FormattedMessage id="Length" />: {product?.voidFill?.length ?? 0}
								</p>
								<p className={classes.attributeValue}>
									<FormattedMessage id="Width" />: {product?.voidFill?.width ?? 0}
								</p>
								<p className={classes.attributeValue}>
									<FormattedMessage id="Height" />: {product?.voidFill?.height ?? 0}
								</p>
							</div>
						)}
					</div>
				);
			case "Weight":
				value = product.weight;
				return (
					<div>
						<p className={classes.attributeName}>{step.name}</p>
						<p className={classes.attributeValue}>{value || "--"}</p>
					</div>
				);
			default:
				return <span>{step.name}</span>;
		}
	}

	return (
		<Fragment>
			{loading ? (
				<Loading />
			) : failed || !config?.attributeCaptureSteps ? (
				<FailedRetry retry={load} />
			) : (
				<Fragment>
					<div className={classes.grid}>
						<div className={classes.rightColumn}>
							{config.attributeCaptureSteps?.map((step, index) => (
								<div className={classes.formEntry} key={index}>
									{getFormComponent(step)}
									{index < config.attributeCaptureSteps.length - 1 && <Divider section className={classes.breakLine} />}
								</div>
							))}
						</div>
					</div>
				</Fragment>
			)}
		</Fragment>
	);
}

export default ProductDetails;
