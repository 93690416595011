import React from "react";
import { createUseStyles } from "react-jss";
import { FormattedMessage } from "react-intl";
import { Popup } from "semantic-ui-react";

const useStyles = createUseStyles((theme) => ({
	header: {
		fontFamily: "Stolzl",
		fontSize: "14px",
		fontWeight: "400",
		lineHeight: "16.8px",
		textAlign: "left",
	},
	line: {
		borderTop: "1px solid #7c7b79",
		margin: "10px 0",
	},
	content: {
		fontFamily: "Stolzl-Book",
		fontSize: "14px",
		fontWeight: "400",
		lineHeight: "16.8px",
		textAlign: "left",
	},
}));

function InvertedPopup({ title, contentText }) {
	const classes = useStyles();

	return (
		<Popup
			header={<div className={classes.header}>{title}</div>}
			content={
				<div>
					<div className={classes.line}></div>
					<div className={classes.content}>{contentText}</div>
				</div>
			}
			trigger={
				<div>
					{typeof title === "string" && <FormattedMessage id={title} />}
					{typeof title !== "string" && title}
				</div>
			}
			inverted
			hideOnScroll
			flowing
			hoverable
		/>
	);
}

export default InvertedPopup;
