import { Grid, Table } from "semantic-ui-react";
import { createUseStyles, useTheme } from "react-jss";
import { useIntl } from "react-intl";

const useStyles = createUseStyles((theme) => ({
	...theme.optimalProductionTable,
}));

const totalItems = (count, intl) => {
	const suffix = intl.formatMessage({ id: "Items Effected" });
	return `${count} ${suffix}`;
};

const OptimalProductionFailed = ({ details, count }) => {
	const theme = useTheme();
	const classes = useStyles({ theme });
	const intl = useIntl();

	return (
		<div className={classes.outer}>
			<Grid className={classes.grid}>
				<Table className={classes.table}>
					<Table.Body>
						<Table.Row className={classes.tableRow}>
							<Table.Cell className={classes.columnLabelText}>{totalItems(count, intl)}</Table.Cell>
						</Table.Row>
						{details.map((item, i) => (
							<Table.Row className={classes.tableRow} key={i}>
								<Table.Cell className={classes.tableItemText}>{item}</Table.Cell>
							</Table.Row>
						))}
					</Table.Body>
				</Table>
			</Grid>
		</div>
	);
};

export default OptimalProductionFailed;
