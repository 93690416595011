import React, { Fragment, useEffect, useState } from "react";

import NumericUpDown from "@/components/numeric-up-down/numeric-up-down";

const DimensioningNumber = (props) => {
	const [formValue, setFormValue] = useState(0);

	useEffect(() => {
		if (props.callback) props.callback(formValue);
	}, [formValue]);
	return (
		<Fragment>
			<div>{props.prompt ? props.prompt : props.defaultPrompt}</div>
			<NumericUpDown number={formValue} setNumber={setFormValue} />
		</Fragment>
	);
};

export default DimensioningNumber;
