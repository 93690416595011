import { createContext, useState, useEffect, useContext, useCallback } from "react";
import { ProductionContext } from "@/contexts/production-context";
import useAxios from "@/api/useAxios";
import { useQueries } from "@tanstack/react-query";
import useSignalRHub from "@/hooks/use-signalr-hub/use-signalr-hub";
import useToken from "@/hooks/use-token";

export const JobQueueContext = createContext({
	selectedJobs: [],
	selectedBatches: [],
	jobHistory: [],
	jobsLoading: true,
	historyLoading: true,
	batchesLoading: true,
});

function JobQueueProvider({ children }) {
	const token = useToken();
	const { currentMachineGroup } = useContext(ProductionContext);
	const [selectedJobs, setSelectedJobs] = useState([]);
	const [selectedBatches, setSelectedBatches] = useState([]);
	const [jobHistory, setJobHistory] = useState([]);

	const hubCleanup = useCallback(
		(hubConnection) => {
			if (currentMachineGroup) {
				hubConnection.send("UnSubscribeFromMachineGroupEvents", currentMachineGroup.id);
			}
		},
		[currentMachineGroup],
	);

	const { hubConnection, hubConnectionEstablished, usePolling } = useSignalRHub({
		hubUrl: "/PipelineAuditApi/hubs/jobQueue",
		hubCleanup,
		token,
	});

	// Wiring up callbacks for when Audit publishes messages about the current machine group
	useEffect(() => {
		if (!hubConnection || !currentMachineGroup) return;

		hubConnection.on("OnProductionQueueUpdated", (tenantId, machineGroupId, selectedJobs) => {
			if (tenantId !== currentMachineGroup?.tenantId || machineGroupId !== currentMachineGroup?.id) {
				console.warn("OnProductionQueueUpdated: Tenant id or machine group id did not match");
				return;
			}
			setSelectedJobs(selectedJobs);
		});

		hubConnection.on("OnBatchJobQueueUpdated", (tenantId, machineGroupId, selectedBatches) => {
			if (tenantId !== currentMachineGroup?.tenantId || machineGroupId !== currentMachineGroup?.id) {
				console.warn("OnBatchJobQueueUpdated: Tenant id or machine group id did not match");
				return;
			}
			setSelectedBatches(selectedBatches);
		});

		hubConnection.on("OnJobHistoryUpdated", (tenantId, machineGroupId, history) => {
			if (tenantId !== currentMachineGroup?.tenantId || machineGroupId !== currentMachineGroup?.id) {
				console.warn("OnJobHistoryUpdated: Tenant id or machine group id did not match");
				return;
			}
			setJobHistory(
				history.map((j) => ({
					...j,
					data: typeof j.data === "string" ? JSON.parse(j.data) : j.data,
					date: new Date(j.createDateUTC),
				})),
			);
		});

		hubConnection.on("OnJobQueueVersion", (message) => {});

		hubConnection.on("OnSubscribed", () => {});

		hubConnection.on("OnUnsubscribed", () => {
			setSelectedJobs([]);
		});

		// Cleanup block
		return () => {
			hubConnection.off("OnProductionQueueUpdated");
			hubConnection.off("OnBatchJobQueueUpdated");
			hubConnection.off("OnJobHistoryUpdated");
			hubConnection.off("OnSubscribed");
			hubConnection.off("OnUnsubscribed");
		};
	}, [hubConnection, currentMachineGroup]);

	// Manages the subscription to the current machine group through the Audit hub
	useEffect(() => {
		if (!currentMachineGroup || !hubConnectionEstablished) return;

		hubConnection.send("SubscribeToMachineGroupEvents", currentMachineGroup.id);

		return () => {
			if (hubConnectionEstablished && currentMachineGroup)
				hubConnection.send("UnSubscribeFromMachineGroupEvents", currentMachineGroup.id);
		};
	}, [hubConnection, hubConnectionEstablished, currentMachineGroup]);

	const AuditCurrentStateApi = useAxios("/PipelineAuditApi/api/v1/CurrentState", token);
	const AuditPackagingSolutionsApi = useAxios("/PipelineAuditApi/api/v1/Audit/PackagingSolutions", token);

	const selectedJobsQueryFn = async () => {
		const response = await AuditCurrentStateApi.getWithUrl(
			`PackagingSolutions/byStatus/InProgress/byMachineGroup/${currentMachineGroup.id}`,
		);
		setSelectedJobs(response.data);
		return response.data;
	};

	const selectedBatchesQueryFn = async () => {
		const response = await AuditCurrentStateApi.getWithUrl(
			`InProgressBatches/byMachineGroup/${currentMachineGroup.id}`,
		);
		setSelectedBatches(response.data);
		return response.data;
	};

	const jobHistoryQueryFn = async () => {
		const response = await AuditPackagingSolutionsApi.getWithUrl(
			`byMachineGroupHistory/${currentMachineGroup.id}/limit/10/latest`,
		);
		const jobHistory = response.data.map((j) => ({
			...j,
			data: typeof j.data === "string" ? JSON.parse(j.data) : j.data,
			date: new Date(j.createDateUTC),
		}));
		setJobHistory(jobHistory);
		return jobHistory;
	};

	const selectedJobsQueryKey = ["machineGroupJobQueue", { machineGroupId: currentMachineGroup?.id }];
	const selectedBatchesQueryKey = ["machineGroupBatchQueue", { machineGroupId: currentMachineGroup?.id }];
	const jobHistoryQueryKey = ["machineGroupJobHistory", { machineGroupId: currentMachineGroup?.id }];

	const [jobData, batchData, historyData] = useQueries({
		queries: [
			{
				queryKey: selectedJobsQueryKey,
				queryFn: selectedJobsQueryFn,
				refetchInterval: 3000,
				enabled: usePolling && Boolean(currentMachineGroup),
			},
			{
				queryKey: selectedBatchesQueryKey,
				queryFn: selectedBatchesQueryFn,
				refetchInterval: 3000,
				enabled: usePolling && Boolean(currentMachineGroup),
			},
			{
				queryKey: jobHistoryQueryKey,
				queryFn: jobHistoryQueryFn,
				refetchInterval: 3000,
				enabled: usePolling && Boolean(currentMachineGroup),
			},
		],
	});

	if (!currentMachineGroup) return <>{children}</>;

	const { isLoading: selectedJobsApiLoading } = jobData;
	const { isLoading: selectedBatchesApiLoading } = batchData;
	const { isLoading: jobHistoryApiLoading } = historyData;

	return (
		<JobQueueContext.Provider
			value={{
				jobHistory,
				selectedJobs,
				selectedBatches,
				jobsLoading: !hubConnectionEstablished && selectedJobsApiLoading,
				historyLoading: !hubConnectionEstablished && jobHistoryApiLoading,
				batchesLoading: !hubConnectionEstablished && selectedBatchesApiLoading,
			}}
		>
			{children}
		</JobQueueContext.Provider>
	);
}

export default JobQueueProvider;
