import axios from "axios";
import { readLocalStorage } from "./local-storage";

const transformProductionGroups = (rawData) => {
	return rawData.reduce((productionGroups, data) => {
		try {
			return [
				...productionGroups,
				{
					id: data.id,
					alias: data.alias,
					productionMode: data.selectionAlgorithm,
					configuredMachineGroups: data.configuredMachineGroups,
				},
			];
		} catch {
			console.error("Invalid production group: ", rawData);
			return productionGroups;
		}
	}, []);
};

export async function fetchProductionGroups(callback = () => {}) {
	const bearer = readLocalStorage("BEARER");
	return await axios("/ProductionGroupApi/api/v1/ProductionGroups", {
		headers: {
			Authorization: bearer,
			"Content-Type": "application/json",
		},
	})
		.then(function (response) {
			return response.data;
		})
		.then(function (data) {
			const groups = transformProductionGroups(data);
			callback(groups);
			return groups;
		});
}

export async function fetchProductionGroupAliasByMachineGroup(machineGroupId, token) {
	let url = `/ProductionGroupApi/api/v1/ProductionGroups/bymachinegroup/${machineGroupId}/alias`;
	const response = await axios.get(url, {
		headers: { Authorization: token },
	});
	if (response.status === 200) {
		return response.data.productionGroupAlias;
	}
}
