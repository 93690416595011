import { Button, Card, Grid, Header, Icon, Image, Radio, Segment } from "semantic-ui-react";
import React, { useState } from "react";
import { createUseStyles, useTheme } from "react-jss";

import Em from "@/assets/images/machine-type/em.png";
import { FormattedMessage } from "react-intl";
import { readLocalStorage } from "@/api/local-storage";
import useAxios from "@/api/useAxios";

const useStyles = createUseStyles((theme) => ({
	tracks: {
		margin: "0px !important",
		paddingTop: "0px !important",
	},
	machineHeading: {
		marginTop: "40px !important",
	},
	error: { marginTop: "100px !important" },
	view: {
		maxHeight: "calc(100vh - 128px)",
		height: "calc(100vh - 128px)",
		overflowY: "auto",
		backgroundColor: theme.colors.lightBackground,
	},
	machine: {
		backgroundColor: theme.colors.white,
		marginTop: "0px !important",
		borderTopRightRadius: "6px",
		borderBottomRightRadius: "6px",
		paddingLeft: "40px !important",
		maxHeight: "calc(100vh - 215px)",
		height: "calc(100vh - 215px)",
	},
	card: {
		display: "flex !important",
		justifyContent: "center",
	},
}));

function EmManualControl(props) {
	const theme = useTheme();
	const classes = useStyles({ theme });
	const token = readLocalStorage("BEARER");
	const tracks = props.selectedMachine.tracks;
	const [track, setTrack] = useState(0);
	const selectedMachine = props.selectedMachine;
	const EmServiceApi = useAxios("/emMachineApi/api/v1/machines", token);

	return (
		<Grid padded className={classes.view}>
			<Grid.Row>
				<Grid.Column width={3} className={classes.machine}>
					<Grid>
						<Grid.Row>
							<Grid.Column>
								<Header as="h2" textAlign="left" className={classes.machineHeading}>
									<Header.Subheader>
										<FormattedMessage id="Machine" />
									</Header.Subheader>
									{selectedMachine.alias}
									<Header.Subheader>{selectedMachine.machineType}</Header.Subheader>
								</Header>
								<img src={Em} className={classes.machineImage} />
							</Grid.Column>
						</Grid.Row>
					</Grid>
				</Grid.Column>
				{props.selectedMachine.status !== "Paused" && (
					<Grid.Column width={13}>
						<Segment basic textAlign="center" className={classes.error}>
							<Icon name="warning circle" size="huge" color="red" />
							<Header as="h2">
								<FormattedMessage id="Machine must be paused to manually control" />
							</Header>
						</Segment>
					</Grid.Column>
				)}
				{props.selectedMachine.status === "Paused" && (
					<Grid.Column width={13}>
						<Grid padded>
							<Grid.Row columns={tracks.length} className={classes.tracks}>
								{tracks.map((t, index) => (
									<Grid.Column key={index}>
										<Segment fluid onClick={() => setTrack(index)}>
											<Radio
												fitted
												name="track"
												label={`Track ${index + 1}`}
												value={index}
												checked={track === index}
												onChange={(_, { value }) => setTrack(value)}
											/>
										</Segment>
									</Grid.Column>
								))}
							</Grid.Row>
							<Grid.Row columns={3}>
								<Grid.Column className={classes.card}>
									<Card
										onClick={async () => {
											await EmServiceApi.addWithUrl(`${selectedMachine.id}/tracks/${track + 1}/feedforward`);
										}}
									>
										<Image src="/PackNet/images/roll-forward.jpg" wrapped ui={false} />
										<Card.Content extra>
											<Button primary fluid>
												<FormattedMessage id="Roll Forward" />
											</Button>
										</Card.Content>
									</Card>
									<Image />
								</Grid.Column>
								<Grid.Column className={classes.card}>
									<Card
										onClick={async () => {
											await EmServiceApi.addWithUrl(`${selectedMachine.id}/tracks/${track + 1}/feedbackwards`);
										}}
									>
										<Image src="/PackNet/images/roll-backward.jpg" wrapped ui={false} />
										<Card.Content extra>
											<Button primary fluid>
												<FormattedMessage id="Roll Backward" />
											</Button>
										</Card.Content>
									</Card>
									<Image />
								</Grid.Column>
								<Grid.Column className={classes.card}>
									<Card
										onClick={async () => {
											await EmServiceApi.addWithUrl(`${selectedMachine.id}/tracks/${track + 1}/cleancut`);
										}}
									>
										<Image src="/PackNet/images/clean-cut.jpg" wrapped ui={false} />
										<Card.Content extra>
											<Button primary fluid>
												<FormattedMessage id="Clean Cut" />
											</Button>
										</Card.Content>
									</Card>
								</Grid.Column>
							</Grid.Row>
						</Grid>
					</Grid.Column>
				)}
			</Grid.Row>
		</Grid>
	);
}

export default EmManualControl;
