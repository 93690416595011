import { readLocalStorage } from "@/api/local-storage";
import machineTypes from "@/constants/machineTypes";
import errorSeverity from "@/constants/errorSeverity";
import { useEffect, useState } from "react";
import { HubConnectionState, HttpTransportType } from "@microsoft/signalr";
import { createSignalRContext } from "react-signalr";

const machineType = machineTypes.X6;
const SignalRContext = createSignalRContext();
const websocketUrl = "/x6Api/hubs/x6";
const alarmsChangedEventName = "AlarmsChanged";
const warningsChangedEventName = "WarningsChanged";

function getActionKey(code, severity) {
	switch (`${severity}${code}`) {
		default:
			return "";
	}
}

function formatEventData(machineId, machineEvents, severity) {
	return (
		machineEvents?.map((machineEvent) => {
			const code = machineEvent.code;
			const parameter = undefined;
			return {
				machineId,
				machineType,
				severity,
				code,
				parameter,
				actionKey: getActionKey(code, severity),
			};
		}) ?? []
	);
}

export default function X6ErrorsAndWarnings({ machines, onErrorsChanged, onWarningsChanged }) {
	const token = readLocalStorage("BEARER");
	const [machineIds, setMachineIds] = useState([]);
	const [signalRConnectedState, setSignalRConnectedState] = useState(HubConnectionState.Disconnected);

	useEffect(() => {
		setMachineIds(
			machines.filter((m) => m.machineType.toLowerCase() === machineType.toLowerCase()).map((m) => m.machineId)
		);
	}, [machines]);

	useEffect(() => {
		if (!machineIds || SignalRContext.connection?.state !== HubConnectionState.Connected) {
			return;
		}

		async function SubscribeToSignalRGroups(abortController) {
			try {
				const response = await SignalRContext.invoke("joinAlarmsChangedGroup", machineIds);
				for (const { machineId, events } of response) {
					const formattedAlarms = formatEventData(machineId, events, errorSeverity.error);
					onErrorsChanged(machineId, formattedAlarms);
				}
			} catch (error) {
				if (!abortController?.signal?.aborted) {
					console.error("Failed to join alarms changed", error);
				}
			}

			try {
				const response = await SignalRContext.invoke("joinWarningsChangedGroup", machineIds);
				for (const { machineId, events } of response) {
					const formattedWarnings = formatEventData(machineId, events, errorSeverity.warning);
					onWarningsChanged(machineId, formattedWarnings);
				}
			} catch (error) {
				if (!abortController?.signal?.aborted) {
					console.error("Failed to join warnings changed", error);
				}
			}
		}

		const abortController = new AbortController();
		SubscribeToSignalRGroups(abortController);
		return () => {
			abortController.abort();
			if (SignalRContext.connection?.state === HubConnectionState.Connected) {
				SignalRContext.invoke("leaveAlarmsChangedGroup", machineIds);
				SignalRContext.invoke("leaveWarningsChangedGroup", machineIds);
			}
		};
	}, [machineIds, signalRConnectedState]);

	SignalRContext.useSignalREffect(alarmsChangedEventName, (data) => {
		let machineId;
		try {
			const activeAlarms = JSON.parse(data);
			machineId = activeAlarms.machineId;
			if (machineIds.includes(machineId)) {
				console.info(`Processing ${alarmsChangedEventName} SignalR event for machine: ${machineId}`);
				onErrorsChanged(machineId, formatEventData(machineId, activeAlarms.events, errorSeverity.error));
			}
		} catch (error) {
			console.error(
				`There was an error processing ${alarmsChangedEventName} SignalR event for machine: ${machineId}`,
				error
			);
		}
	});

	SignalRContext.useSignalREffect(warningsChangedEventName, (data) => {
		let machineId;
		try {
			const activeWarnings = JSON.parse(data);
			machineId = activeWarnings.machineId;
			if (machineIds.includes(machineId)) {
				console.info(`Processing ${warningsChangedEventName} SignalR event for machine: ${machineId}`);
				onWarningsChanged(machineId, formatEventData(machineId, activeWarnings.events, errorSeverity.warning));
			}
		} catch (error) {
			console.error(
				`There was an error processing ${alarmsChangedEventName} SignalR event for machine: ${machineId}`,
				error
			);
		}
	});

	return (
		<SignalRContext.Provider
			connectEnabled={!!token}
			accessTokenFactory={() => token.replace("BEARER ", "")}
			dependencies={[token]}
			transport={HttpTransportType.None}
			url={websocketUrl}
			onOpen={() => {
				console.info(`${machineType} websocket opened and is in the ${SignalRContext.connection?.state} state`);
				setSignalRConnectedState(SignalRContext.connection?.state ?? HubConnectionState.Disconnected);
			}}
			onReconnect={() => {
				console.info(`${machineType} websocket reconnected and is in the ${SignalRContext.connection?.state} state`);
				setSignalRConnectedState(SignalRContext.connection?.state ?? HubConnectionState.Disconnected);
			}}
			onError={() => {
				console.info(`${machineType} websocket errored`);
				setSignalRConnectedState(SignalRContext.connection?.state ?? HubConnectionState.Disconnected);
			}}
			onClosed={() => {
				console.info(`${machineType} websocket closed`);
				setSignalRConnectedState(SignalRContext.connection?.state ?? HubConnectionState.Disconnected);
			}}
		/>
	);
}
