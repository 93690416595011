import { FormattedMessage } from "react-intl";
import { withErrorBoundary } from "react-error-boundary";

const defaultErrorHandler = (error, info, componentName) => {
	console.error(
		componentName,
		`...An error occurred rendering the component. Exception details ${JSON.stringify(
			error,
		)}/${error}} : Stack ${JSON.stringify(info)}`,
		error,
		info,
	);
};

const FallbackComponent = () => (
	<div>
		<FormattedMessage id="An unexpected error occurred in the component" />
	</div>
);

const errorBoundary = (component, componentToDisplayOnError, errorHandler, name) => {
	const componentName = name || component.displayName || component.name || "Unknown";

	const ComponentWithErrorBoundary = withErrorBoundary(component, {
		FallbackComponent: componentToDisplayOnError ?? FallbackComponent,
		onError(error, info) {
			if (errorHandler) errorHandler(error, info, componentName);
			else defaultErrorHandler(error, info, componentName);
		},
	});
	return ComponentWithErrorBoundary;
};

export default errorBoundary;
