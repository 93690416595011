import ListView from "@/components/list-view";
import New from "./new-classification";
import React from "react";
import Table from "./classifications";
import { useIntl } from "react-intl";

function Classifications() {
	const intl = useIntl();
	const title = intl.formatMessage({ id: "Classifications" });

	return <ListView title={title} Table={Table} AddItem={New} size="small" />;
}

export default Classifications;
