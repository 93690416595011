import { createContext, useCallback, useEffect, useMemo, useState } from "react";
import englishMessages from "@/translations/english";
import { getTranslationLoader } from "@/translations/languages";
import { IntlProvider } from "react-intl";
import usePackNetAppInsightsContext from "@/hooks/use-packnet-appinsights-context.js";

export const LocaleContext = createContext({
	messages: {},
	setLocale: () => {},
});

const LocalizationProvider = ({ children }) => {
	const [locale, setLocale] = useState("en");
	const [messages, setMessages] = useState(englishMessages);
	const appInsights = usePackNetAppInsightsContext();

	useEffect(() => {
		const loadLocale = async () => {
			const translations = await getTranslationLoader(locale)();
			setMessages(translations.default);
		};

		loadLocale();
	}, [locale]);

	const localizationValue = useMemo(() => ({ messages, setLocale }), [messages]);

	const onErrorHandler = useCallback(
		(error) => {
			appInsights.trackException(error);
		},
		[appInsights],
	);

	return (
		<LocaleContext.Provider value={localizationValue}>
			<IntlProvider locale={locale} messages={messages} onError={onErrorHandler}>
				{children}
			</IntlProvider>
		</LocaleContext.Provider>
	);
};

export default LocalizationProvider;
