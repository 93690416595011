import { useContext, useState } from "react";
import { Dropdown, Form, List, Popup } from "semantic-ui-react";
import { FormattedMessage, useIntl } from "react-intl";
import { countryOptions } from "@/translations/languages";
import { createUseStyles, useTheme } from "react-jss";
import { Link } from "react-router-dom";
import { useQueryClient } from "@tanstack/react-query";
import { UserContext } from "@/components/user-context";
import { LocaleContext } from "@/contexts/locale-context";
import { NavItem, NavIcon } from "./navbar";

const useStyles = createUseStyles((theme) => ({
	borderBottom: {
		borderBottom: `solid 2px ${theme.accent} !important`,
	},
	user: {
		marginTop: "8px !important",
		textOverflow: "ellipsis",
		overflow: "hidden",
		whiteSpace: "nowrap",
	},
	userMenu: {
		marginTop: "10px !important",
		marginBottom: "10px !important",
		width: "300px !important",
	},
	supportMenu: {
		display: "flex !important",
		justifyContent: "space-between",
		paddingLeft: "20px !important",
	},
	aboutMenu: {
		display: "flex !important",
		justifyContent: "space-between",
		paddingLeft: "20px !important",
	},
	logoutMenu: {
		display: "flex !important",
		justifyContent: "space-between",
		paddingLeft: "20px !important",
	},
	menuItem: { width: "125px !important" },
	iconItem: {
		zIndex: "9999 !important",
	},
	language: {
		fontSize: ".8em !important",
		paddingLeft: "20px !important",
		paddingBottom: "0px !important",
	},
	languageText: {
		paddingBottom: "4px !important",
	},
	dropdown: {
		"& i": { float: "right" },
	},
}));

function UserMenu() {
	const theme = useTheme();
	const classes = useStyles({ theme });
	const intl = useIntl();
	const [logoutMenuOpen, setLogoutMenuOpen] = useState(false);

	const { logout, currentUser } = useContext(UserContext);
	const { setLocale } = useContext(LocaleContext);
	const queryClient = useQueryClient();

	const handleLogout = () => {
		queryClient.removeQueries();
		logout("User clicked logout", "/PackNet/login");
	};

	return (
		<Popup
			basic
			className={classes.iconItem}
			on="click"
			onClose={() => setLogoutMenuOpen(false)}
			onOpen={() => setLogoutMenuOpen(true)}
			open={logoutMenuOpen}
			position="bottom right"
			trigger={
				<NavItem name="logout" data-cy="user-button" alignCenter>
					<NavIcon name="user circle outline" size="large" />
				</NavItem>
			}
		>
			<List relaxed="very" size="big" className={classes.userMenu}>
				<List.Item className={classes.borderBottom}>
					<div>
						<FormattedMessage id="Signed in as" />
					</div>
					<div className={classes.user}>
						<strong>{currentUser?.sub}</strong>
					</div>
				</List.Item>
				<List.Item className={classes.language}>
					<Form.Field>
						<div className={classes.languageText}>
							<FormattedMessage id="Language" />
						</div>
						<Dropdown
							fluid
							selection
							icon="angle down"
							options={countryOptions}
							tabIndex={0}
							defaultValue={intl.locale}
							onChange={(e, data) => setLocale(data.value)}
							className={classes.dropdown}
							data-cy="language"
						/>
					</Form.Field>
				</List.Item>
				<List.Item
					as={Link}
					to="/support-menu"
					className={classes.supportMenu}
					onClick={() => setLogoutMenuOpen(false)}
					data-cy="support-button"
				>
					<span className={classes.menuItem}>
						<FormattedMessage id="Support" />
					</span>
				</List.Item>
				<List.Item
					as={Link}
					to="/about-menu"
					className={classes.aboutMenu}
					onClick={() => setLogoutMenuOpen(false)}
					data-cy="about-button"
				>
					<span className={classes.menuItem}>
						<FormattedMessage id="About" />
					</span>
				</List.Item>
				<List.Item
					as={Link}
					to="/logout"
					className={classes.logoutMenu}
					onClick={() => handleLogout()}
					data-cy="logout-button"
				>
					<span className={classes.menuItem}>
						<FormattedMessage id="Logout" />
					</span>
				</List.Item>
			</List>
		</Popup>
	);
}

export default UserMenu;
