import styled from "styled-components";

const Card = styled("div")`
	background-color: ${(props) => props.theme.colors.white};
	border-radius: 6px;
	border: 1px solid ${(props) => props.theme.colors.border1};
	padding: ${({ padding = true }) => (padding ? "16px" : null)};
`;

export default Card;
