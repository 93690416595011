import { useEffect, useMemo, useState } from "react";

import { differenceInHours } from "date-fns";

import NoData from "@/views/reporting/no-data";
import ProgressBarList from "@/views/reporting/progress-bar-list";
import { getSwimLaneMaxiumum } from "@/views/reporting/functions/swimlanes";
import SwimLanePagination from "@/views/reporting/swim-lane-pagination";

function AverageErrorsPerHourByMachine({
	reportData,
	dateRange,
	color,
	limit,
	isNewFormat,
	sortByAmount,
	sortByMachine,
}) {
	const [presentationData, setPresentationData] = useState([]);
	const numberOfHours = useMemo(() => {
		const dateStart = new Date(dateRange.start);
		const dateEnd = new Date(dateRange.end);
		const dateDiff = differenceInHours(dateEnd, dateStart);
		return dateDiff > 0 ? Math.round(dateDiff) : 24;
	}, [dateRange]);
	const [pagination, setPagination] = useState({
		perPage: 6,
		page: 1,
	});

	function sortData(data) {
		let newData = [];
		if (sortByAmount === "desc") {
			newData = data.sort((a, b) => {
				return a.count > b.count ? -1 : 1;
			});
		} else if (sortByAmount === "asc") {
			newData = data.sort((a, b) => {
				return a.count > b.count ? 1 : -1;
			});
		}

		if (sortByMachine === "desc") {
			newData = data.sort((a, b) => {
				return b.machineName.localeCompare(a.machineName, undefined, { numeric: true });
			});
		} else if (sortByMachine === "asc") {
			newData = data.sort((a, b) => {
				return a.machineName.localeCompare(b.machineName, undefined, { numeric: true });
			});
		}
		return newData;
	}

	function mapData(data) {
		let aggregation;

		if (isNewFormat) {
			aggregation = data.averageErrorsPerMachinePerHour;
		} else {
			aggregation = data.reduce((groups, item) => {
				const group = groups.find((i) => i.machineId === item.machineId);
				if (group) {
					group.count += 1;
					return groups;
				} else {
					return [
						...groups,
						{
							count: 1,
							machineId: item.machineId,
							machineName: item.machineName,
						},
					];
				}
			}, []);
			aggregation = aggregation.map((a) => {
				return {
					...a,
					count: Math.round(a.count / numberOfHours, 0),
				};
			});
		}
		const maximumConsumed = Math.max.apply(
			Math,
			aggregation.map((a) => a.count)
		);
		const upper = getSwimLaneMaxiumum(maximumConsumed);
		aggregation = aggregation.map((item) => {
			return {
				...item,
				percentage: (item.count / upper) * 100,
			};
		});
		if (isNewFormat) {
			aggregation.sort((a, b) => {
				if (a.count !== b.count) {
					return b.count - a.count;
				}
				return a.machineName.localeCompare(b.machineName);
			});
		} else {
			aggregation.sort((a, b) => {
				if (a.count > b.count) {
					return -1;
				} else if (b.count > a.count) {
					return 1;
				}
				if (a.machineName > b.machineName) {
					return -1;
				} else {
					return 1;
				}
			});
		}
		setPresentationData(sortData(aggregation));
	}

	function getPaginationResult(machines, pageSize, page) {
		if (machines.length === 0) {
			return {
				items: [],
				heading: "",
				hasNext: false,
				hasPrevious: false,
				totalPages: 0,
			};
		}

		const start = (page - 1) * pageSize;
		const items = machines.slice(start, start + pageSize);

		const result = {
			items: items,
			heading: "",
			hasNext: start + pageSize < machines.length,
			hasPrevious: page > 1,
			totalPages: Math.ceil(machines.length / pageSize),
		};
		return result;
	}

	useEffect(() => {
		mapData(reportData, dateRange);
	}, [reportData, dateRange]);

	useEffect(() => {
		if (presentationData.length === 0) return;
		let newData = sortData(presentationData);
		if (newData.length > 0) {
			setPresentationData([...newData]);
		}
	}, [sortByMachine, sortByAmount]);

	let items = presentationData.map((p) => {
		return {
			percent: p.percentage,
			text: p.machineName,
			value: p.count,
		};
	});

	const paginationResult = getPaginationResult(items, pagination.perPage, pagination.page);

	return paginationResult ? (
		<>
			{isNewFormat
				? reportData.averageErrorsPerMachinePerHour.length === 0 && <NoData />
				: reportData.length === 0 && <NoData />}
			{limit === 1000 && (isNewFormat ? reportData.averageErrorsPerMachinePerHour.length : reportData.length) > 0 && (
				<ProgressBarList
					color={color}
					items={items}
					limit={1000}
					key={`average-errors-per-hour-by-machine-dual-progress-bar-list`}
				/>
			)}
			{limit < 1000 && (isNewFormat ? reportData.averageErrorsPerMachinePerHour.length : reportData.length) > 0 && (
				<ProgressBarList
					color={color}
					items={paginationResult.items}
					limit={paginationResult.items.length}
					key={`average-errors-per-hour-by-machine-dual-progress-bar-list`}
				/>
			)}
			<SwimLanePagination
				pagination={pagination}
				setPagination={setPagination}
				paginationResult={paginationResult}
				limit={limit}
				key="top-errors-by-machine-swim-lane-pagination"
			/>
		</>
	) : (
		<div></div>
	);
}

export default AverageErrorsPerHourByMachine;
