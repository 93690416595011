import React from "react";
import { Button } from "semantic-ui-react";
import { createUseStyles } from "react-jss";
import { FormattedMessage } from "react-intl";

const subItem = {
    color: "#353430",
    opacity: "0.7 !important",
    backgroundColor: "#FFFFFF !important",
    fontSize: "14px !important",
    fontFamily: "Stolzl-medium !important",
    border: "1px solid #E3E7E9 !important",
    textTransform: "none !important",
    marginRight: "8px !important",
    height: "32px",
    padding: "8px 12px !important",
  };

const useStyles = createUseStyles(() => ({
    subItem,

    activeSubItem: {
        ...subItem,
        color: "#2e475f !important",
        opacity: "1.0 !important",
        backgroundColor: "#F2F4F5 !important",
        },
    }));

export default function SubSectionButton({selectedItem, itemName, onSelect, messageId}) {
    const classes = useStyles();

    return (
        <Button
            className={selectedItem === itemName ? classes.activeSubItem : classes.subItem}
            onClick={() => onSelect(itemName)}>
            <FormattedMessage id={messageId} />
        </Button>
    );
}
