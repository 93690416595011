import { createX5Machine, deleteX5Machine, getX5Machines, updateX5Machine } from "@/api/x5-api";
import { createX6Machine, deleteX6Machine, getX6Machines, updateX6Machine } from "@/api/x6-api";
import { createX7Machine, deleteX7Machine, getX7Machines, updateX7Machine } from "@/api/x7-api";
import { machineTypes, routeStrings } from "../../../constants";
import useAxios from "@/api/useAxios";
import useCurrentUser from "@/hooks/use-current-user.js";

export default function useMachines(token) {
	const EmApi = useAxios(routeStrings.EmApi, token);
	const X4Api = useAxios(routeStrings.X4Api, token);
	const ZebraApi = useAxios(routeStrings.ZebraApi, token);
	const FusionApi = useAxios(routeStrings.FusionApi, token);
	const Scan2PackApi = useAxios("/scan2PackMachineApi/api/v1/machines", token);
	const currentUser = useCurrentUser();

	const get = async (setCallBack, errorCallBack) => {
		let errorCount = 0;
		let successCount = 0;
		let machineList = [];

		const errorHandler = (msg, err) => {
			console.error(msg, err);
			errorCount++;
		};

		const appendItems = (list, machineType) => {
			successCount++;

			if (!list.length) return;

			list = appendFlapsdown(list);
			const newItemsWithType = list.map((item) => ({ ...item, machineType }));
			machineList = [...machineList, ...newItemsWithType];
		};

		const appendFlapsdown = (list) => {
			let withFlapsdown = [];
			for (let index = 0; index < list.length; index++) {
				withFlapsdown.push({
					...list[index],
					flapsdown: list[index].settings?.hasFlapsDownModule ?? false,
				});
			}
			return withFlapsdown;
		};

		let tasks = [
			EmApi.get(
				(newItems) => appendItems(newItems, machineTypes.Em),
				(err) => errorHandler("EM", err),
			),
			"ff-pack-net-scan-2-pack" in currentUser
				? Scan2PackApi.get(
						(newItems) => appendItems(newItems, machineTypes.Scan2Pack),
						(err) => errorHandler("Scan2Pack", err),
					)
				: null,
			ZebraApi.get(
				(newItems) => appendItems(newItems, machineTypes.ZebraPrinter),
				(err) => errorHandler("ZebraPrinter", err),
			),
			"ff-pack-net-fusion-machine" in currentUser
				? FusionApi.getWithUrl(
						"v1/machines",
						(newItems) => appendItems(newItems, machineTypes.Fusion),
						(err) => errorHandler("Fusion", err),
					)
				: null,
			getX5Machines().then(
				(list) => appendItems(list, machineTypes.X5),
				(err) => errorHandler(machineTypes.X5, err),
			),
			"ff-x6-allow-machine" in currentUser
				? getX6Machines().then(
						(list) => appendItems(list, machineTypes.X6),
						(err) => errorHandler(machineTypes.X6, err),
					)
				: null,
			"ff-x7-machines" in currentUser
				? getX7Machines().then(
						(list) => appendItems(list, machineTypes.X7),
						(err) => errorHandler("X7", err),
					)
				: null,
			X4Api.get(
				(newItems) => appendItems(newItems, machineTypes.X4),
				(err) => errorHandler("X4", err),
			),
			"ff-pack-net-manual-cartons" in currentUser
				? appendItems(
						[
							{
								id: -1,
								alias: "Packing Stations",
								description: "Packing Stations",
							},
						],
						machineTypes.PackingStation,
					)
				: null,
		];

		await Promise.allSettled(tasks);

		if (errorCount > 0 && successCount === 0) {
			const newErr = new Error("Couldn't load any machine");
			if (errorCallBack) errorCallBack(newErr);
			else throw newErr;
		} else {
			machineList.sort((a, b) => (a.alias > b.alias ? 1 : -1));
			if (setCallBack) {
				setCallBack(machineList);
			}
		}

		return machineList;
	};

	const add = async (machine) => {
		switch (machine.machineType) {
			case machineTypes.X7:
				await createX7Machine(machine);
				break;
			case machineTypes.X5:
				await createX5Machine(machine);
				break;
			case machineTypes.X6:
				await createX6Machine(machine);
				break;
			case machineTypes.Em:
				await EmApi.add(machine);
				break;
			case machineTypes.Scan2Pack:
				await Scan2PackApi.add(machine);
				break;
			case machineTypes.X4:
				await X4Api.add(machine);
				break;
			case machineTypes.ZebraPrinter:
				await ZebraApi.add(machine);
				break;
			case machineTypes.Fusion:
				await FusionApi.addWithUrl("v1/machines", machine);
				break;
			default:
				throw new Error(`'add' not setup in use-machines.js for machine type ${machine.machineType}`);
		}
	};

	const update = async (machine) => {
		switch (machine.machineType) {
			case machineTypes.X5:
				await updateX5Machine(machine);
				break;
			case machineTypes.X6:
				await updateX6Machine(machine);
				break;
			case machineTypes.X7:
				await updateX7Machine(machine);
				break;
			case machineTypes.Em:
				await EmApi.updateWithUrl(`${machine.id}/configuration`, machine);
				break;
			case machineTypes.Scan2Pack:
				await Scan2PackApi.updateWithUrl(`${machine.id}/configuration`, machine);
				break;
			case machineTypes.X4:
				await X4Api.updateWithId(machine);
				break;
			case machineTypes.ZebraPrinter:
				await ZebraApi.updateWithId(machine);
				break;
			case machineTypes.Fusion:
				await FusionApi.updateWithUrl(`v1/machines/${machine.id}`, machine);
				break;
			default:
				throw new Error(`'update' not setup in use-machines.js for machine type ${machine.machineType}`);
		}
	};

	const remove = async (machine) => {
		switch (machine.machineType) {
			case machineTypes.X5:
				await deleteX5Machine(machine.id);
				break;
			case machineTypes.X6:
				await deleteX6Machine(machine.id);
				break;
			case machineTypes.X7:
				await deleteX7Machine(machine.id);
				break;
			case machineTypes.Em:
				await EmApi.removeWithUrl(machine.id);
				break;
			case machineTypes.Scan2Pack:
				await Scan2PackApi.removeWithUrl(machine.id);
				break;
			case machineTypes.X4:
				await X4Api.remove(machine.id);
				break;
			case machineTypes.ZebraPrinter:
				await ZebraApi.remove(machine.id);
				break;
			case machineTypes.Fusion:
				await FusionApi.removeWithUrl(`v1/machines/${machine.id}`);
				break;
			default:
				throw new Error(`'remove' not setup in use-machines.js for machine type ${machine.machineType}`);
		}
	};

	return { get, update, add, remove };
}
