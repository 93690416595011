import React, { createContext, useState, useEffect } from "react";

import { createUpdateCart, getCartsByTenant, deleteCart } from "@/api/carts-api";

const emptyCart = {
	name: "",
	description: "",
	ip: "",
	port: 0,
};

const initialState = {
	isLoading: true,
	carts: [],
	removeCart: () => {},
	saveCart: () => {},
};

const CartPageContext = createContext(initialState);

export function CartPageProvider({ children }) {
	const [carts, setCarts] = useState(initialState.carts);
	const [isLoading, setIsLoading] = useState(initialState.isLoading);
	const [editCart, setEditCart] = useState(emptyCart);

	useEffect(() => {
		onLoad();
	}, []);

	async function saveCart(cartToSave) {
		await createUpdateCart(cartToSave);
	}

	async function removeCart(mainId) {
		return await deleteCart(mainId);
	}

	async function onLoad() {
		setIsLoading(true);
		const results = await Promise.allSettled([fetchCarts()]);
		if (results[0].status === "rejected") {
			setCarts([]);
		}
		setIsLoading(false);
	}

	async function fetchCarts() {
		try {
			const carts = await getCartsByTenant();
			setCarts(carts);
		} catch (err) {
			//TODO add error message.
			//addErrorMessage(`Error loading list of carts: ${err.message}`);
		}
	}

	return (
		<CartPageContext.Provider
			value={{
				isLoading,
				carts,
				editCart,
				setCarts,
				setEditCart,
				removeCart,
				saveCart,
				reload: onLoad,
			}}
		>
			{children}
		</CartPageContext.Provider>
	);
}

export default CartPageContext;
