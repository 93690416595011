import { SnackbarContent } from "notistack";
import { forwardRef } from "react";
import { Message, MessageContent, Icon, List } from "semantic-ui-react";
import { createUseStyles, useTheme } from "react-jss";

const useStyles = createUseStyles((theme) => ({
	messageList: {
		display: "flex !important",
	},
	message: {
		borderColor: `${theme.colors.primary}`,
		color: `${theme.colors.primary}`,
		padding: "12px 16px !important",
	},
}));

const InfoToast = forwardRef((props, ref) => {
	const { message, actionComponent } = props;
	const theme = useTheme();
	const classes = useStyles(theme);

	return (
		<SnackbarContent ref={ref} style={{ justifyContent: "flex-end" }}>
			<Message compact className={classes.message}>
				<List horizontal className={classes.messageList}>
					<List.Item>
						<Icon name="info circle" />
					</List.Item>
					<List.Item>
						<MessageContent>{message}</MessageContent>
					</List.Item>
					{actionComponent && <List.Item>{actionComponent}</List.Item>}
				</List>
			</Message>
		</SnackbarContent>
	);
});

export default InfoToast;
