export default {
    " to save": " to save",
    "+ Label": "+ Label",
    "12 Hours": "12 Hours",
    "24 Hours": "24 Hours",
    "2D": "2D",
    "3D": "3D",
    "A carton that began production but was not completed": "A carton that began production but was not completed",
    "A carton that was produced successfully": "A carton that was produced successfully",
    "A to Z": "A to Z",
    "A unique title is required to save": "A unique title is required to save",
    "ADD ITEM": "ADD ITEM",
    "AMQP Import": "AMQP Import",
    "API Key": "API Key",
    "About": "About",
    "Acceleration": "Acceleration",
    "Access Attempts": "Access Attempts",
    "Access Carton Production": "Access Carton Production",
    "Access Configuration Audit": "Access Configuration Audit",
    "Access Dashboard": "Access Dashboard",
    "Access Machine Control": "Access Machine Control",
    "Access Machine Errors and Availability": "Access Machine Errors & Availability",
    "Access Per Second": "Access Per Second",
    "Access Procurement": "Access Procurement",
    "Access Raw Data": "Access Raw Data",
    "Access Service Mode Page": "Access Service Mode Page",
    "Access Trim": "Access Trim",
    "Access zFold Usage": "Access z-Fold Usage",
    "Action to Take": "Action to Take",
    "Actions": "Actions",
    "Activate": "Activate",
    "Active": "Active",
    "Activity": "Activity",
    "Add": "Add",
    "Add Action": "Add Action",
    "Add Carton Mapping": "Add Carton Mapping",
    "Add Custom Attribute": "Add Custom Attribute",
    "Add HTTP Header": "Add HTTP Header",
    "Add Header": "Add Header",
    "Add Headers Manually": "Add Headers Manually",
    "Add Item": "Add Item",
    "Add Label": "Add Label",
    "Add Label Mapping": "Add Label Mapping",
    "Add Long Head": "Add Long Head",
    "Add New": "Add New",
    "Add Print": "Add Print",
    "Add Print Variable": "Add Print Variable",
    "Add Rule": "Add Rule",
    "Add Solution with Print": "Add Solution with Print",
    "Add Title": "Add Title",
    "Add Track": "Add Track",
    "Add Widget": "Add Widget",
    "Add X Value": "Add X Value",
    "Add Your Headers": "Add Your Headers",
    "Add an item to produce": "Add an item to produce",
    "Add your first item": "Add your first item",
    "Add your first rule": "Add your first rule",
    "AddCampaignPrintImageToCarton": "Add Campaign Print Image to Carton",
    "AddCampaignPrintImageToCartonDescription": "Sets a print image for the carton.",
    "AddCarton": "Add Carton",
    "AddCartonDescription": "Adds a new carton. Each carton needs to be configured individually.",
    "AddCartonV2": "Add Carton",
    "AddCartonV2Description": "Adds a new carton. Each carton needs to be configured individually.",
    "AddLabelToCarton": "Add Label To Carton",
    "AddLabelToCartonDescription": "Adds a label to a carton. Each label needs to be configured individually.",
    "AddNew": "Add New",
    "AddOption": "Add Option",
    "AddPrintImage": "Add Print Image",
    "AddPrintImageFromCampaign": "Add Print Image from Campaign",
    "AddStandaloneLabel": "Add Standalone Label",
    "AddStandaloneLabelDescription": "Add a standalone label to the job.",
    "Additional Options": "Additional Options",
    "Administrator Permissions": "Administrator Permissions",
    "Advanced": "Advanced",
    "Alerts": "Alerts",
    "Alias": "Alias",
    "Alias already exists Please choose a unique alias": "Alias already exists. Please choose a unique alias",
    "All": "All",
    "All Machines Selected": "All Machines Selected",
    "All unsaved work will be lost": "All unsaved work will be lost",
    "Allow distribution of work across machine groups": "Allow distribution of work across machine groups",
    "AllowedValues": "Allowed Values",
    "AlphaNumeric": "Text",
    "Always": "Always",
    "AlwaysRun": "Always Run Rule",
    "AlwaysRunHelp": "When checked, this rule will be applied to all jobs.",
    "An X7 can be the only machine configured in a Machine Group": "An X7 can be the only machine configured in a Machine Group",
    "An email has been sent": "An email has been sent to reset your password.",
    "An unexpected error occurred in the component": "An unexpected error occurred in the component",
    "An unexpected error occurred while processing your request Please try again": "An unexpected error occurred while processing your request. Please try again.",
    "And": "And",
    "Any": "Any",
    "Any Orientation": "Any Orientation",
    "Api Key Header Name": "Api Key Header Name",
    "Api Key is required": "Api Key is required",
    "Apply": "Apply",
    "Apply label on one side": "Apply label on one side",
    "Apply same label on two sides": "Apply same label on two sides",
    "Apply to All Remaining": "Apply to All Remaining",
    "April": "April",
    "Are you sure about having an empty list of headers": "Are you sure about having an empty list of headers?",
    "Are you sure you want to export": "Are you sure you want to export?",
    "Area Per Box sq in": "Area Per Box (sq. in.)",
    "Area Per Box sq mm": "Area Per Box (sq. mm.)",
    "Area Per box": "Area Per Box",
    "Article ID": "Article ID",
    "Article Name": "Article Name",
    "Asset Identifier:": "Asset Identifier:",
    "Assign Corrugates": "Assign Corrugates",
    "Assign Machines": "Assign Machines",
    "Assigned to:": "Assigned to:",
    "At least one message type must be selected": "At least one message type must be selected",
    "Attribute Capture Flow": "Attribute Capture Flow",
    "Attribute Name": "Attribute Name",
    "Attributes": "Attributes",
    "Attributes:": "Attributes:",
    "August": "August",
    "Australia": "Australia",
    "Authentication": "Authentication",
    "Authentication Type": "Authentication Type",
    "Auto Generated by PackNet": "Auto Generated by PackNet",
    "Auto Gluer 4": "Auto Gluer 4",
    "AutoEnableMachineGroupTooltip": "When enabled, the Machine Group will automatically be put in play when it comes online",
    "Automatically Tab Between Fields With USB Scanner": "Automatically Tab Between Fields With USB Scanner",
    "Availability": "Availability",
    "Availability Score": "Availability Score",
    "Availability Status": "Availability Status",
    "Availability by Machine": "Availability by Machine",
    "Available": "Available",
    "Available Cartons: {cartonCount}": "Available Cartons: {cartonCount}",
    "Available Jobs": "Available Jobs",
    "Available Machines": "Available Machines",
    "Available Orders In {pgAlias}": "Available Orders In {pgAlias}",
    "Average Available Items": "Average Available Items",
    "Average Errors Per Hour by Machine": "Avg. Errors Per Hour by Machine",
    "Average Linger Time": "Average Linger Time",
    "Average Production Time": "Average Production Time",
    "Average Production Time This Week": "Average Production Time This Week",
    "Average Production Time Today": "Average Production Time Today",
    "Average Production Time in seconds": "Avg. Production Time (sec.)",
    "Average Selection Time": "Average Selection Time",
    "Average Waste Percentage": "Average Waste Percentage",
    "Average Waste Percentage By Machine": "Average Waste Percentage By Machine",
    "Average Waste Percentage By Machine This Week": "Average Waste Percentage By Machine This Week",
    "Average Waste Percentage By Machine Today": "Average Waste Percentage By Machine Today",
    "Average Waste Percentage By z-Fold": "Average Waste Percentage By z-Fold",
    "Average Waste Percentage By z-Fold This Week": "Average Waste Percentage By z-Fold This Week",
    "Average Waste Percentage By z-Fold Today": "Average Waste Percentage By z-Fold Today",
    "Average Waste Percentage This Week": "Average Waste Percentage This Week",
    "Average Waste Percentage Today": "Average Waste Percentage Today",
    "Average Waste Percentage Today By Machine": "Average Waste Percentage Today By Machine",
    "Average Waste Percentage Today By z-Fold": "Average Waste Percentage Today By z-Fold",
    "Average Waste This Week": "Average Waste This Week",
    "Average Waste Today": "Average Waste Today",
    "Back": "Back",
    "Back to Carton Production Overview": "Back to Carton Production Overview",
    "Back to Dashboard": "Back to Dashboard",
    "Back to Errors and Availability Overview": "Back to Errors & Availability Overview",
    "Back to Orders": "Back to Orders",
    "Back to Trim Overview": "Back to Trim Overview",
    "Back to search": "Back to search",
    "Back to z-Fold Usage Overview": "Back to z-Fold Usage Overview",
    "Back to zFold Usage Overview": "Back to z-Fold Usage Overview",
    "Bale calculations are estimated": "*Bale calculations are estimated",
    "Bales": "Bales",
    "Barcode": "Barcode",
    "Barcode Order": "Barcode Order",
    "Barcode Parsing Workflow": "Barcode Parsing Workflow",
    "Barcode Setup": "Barcode Setup",
    "Barcode example": "Barcode example",
    "Barcode ingestion error": "Barcode ingestion error",
    "BarcodeIncorrectFormat": "Barcode Error. Expected format: {format}",
    "Basic": "Basic",
    "BasicAuth": "Basic",
    "Batch Already Producing": "Batch Already Producing",
    "BatchDeleteSavedJobsConfirmationMessage": "Saved jobs will be permanently deleted. This action cannot be undone.",
    "Blocked": "Blocked",
    "Box Count": "Box Count",
    "Box First": "Box First",
    "Box Height": "Box Height",
    "Box Height in": "Box Height (in.)",
    "Box Height mm": "Box Height (mm.)",
    "Box Last": "Box Last",
    "Box Length": "Box Length",
    "Box Length in": "Box Length (in.)",
    "Box Length mm": "Box Length (mm.)",
    "Box Production": "Box Production",
    "Box Production Details": "Box Production Details",
    "Box Production by Site": "Box Production by Site",
    "Box Width": "Box Width",
    "Box Width in": "Box Width (in.)",
    "Box Width mm": "Box Width (mm.)",
    "BoxFirst": "Box First",
    "BoxLast": "Box Last",
    "Boxes": "{count} boxes",
    "Brake": "Brake",
    "Brand": "Brand",
    "Brand is required": "Brand is required",
    "Brand:": "Brand:",
    "Broken": "Broken",
    "Broken Boxes by User": "Broken Boxes by User",
    "Bulk Select": "Bulk Select",
    "Bulk Submit": "Bulk Submit",
    "By Carton Property Group Ratio": "By Carton Property Group Ratio",
    "By Carton Property Group Status": "By Carton Property Group Status",
    "By Classification Status": "By Classification Status",
    "By Create By Date": "By Create By Date",
    "By Group Id Date Time": "By Group Id Date Time",
    "By Import Date": "By Import Date",
    "By Loaded Materials": "By Loaded Materials",
    "By Machine Group Exclusivity": "By Machine Group Exclusivity",
    "By Optimal Production": "By Optimal Production",
    "By Optimal Production Exclusivity": "By Optimal Production Exclusivity",
    "By Pack Station": "By Pack Station",
    "By Priority": "By Priority",
    "By Priority Ascending": "By Priority Ascending",
    "By Priority Descending": "By Priority Descending",
    "By Released For Production": "By Released For Production",
    "By Released For Production Date": "By Released For Production Date",
    "By Stopped Carton Property Group": "By Stopped Carton Property Group",
    "By Stopped Classification": "By Stopped Classification",
    "By Stopped Pick Zone": "By Stopped Pick Zone",
    "CANCEL": "CANCEL",
    "Calibrate": "Calibrate",
    "Calibrating": "Calibrating",
    "Campaign Details": "Campaign Details",
    "Campaign End": "Campaign End",
    "Campaign Name": "Campaign Name",
    "Campaign Start": "Campaign Start",
    "Can the product contain?": "Can the product contain?",
    "Can the product nest?": "Can the product nest?",
    "Cancel": "Cancel",
    "Cancel Production": "Cancel Production",
    "Cancel Production After 3 Failed Attempts": "Cancel Production After 3 Failed Attempts",
    "Cancel Text": "Cancel Text",
    "Canceled": "Canceled",
    "Cannot contain special characters": "Cannot contain special characters",
    "Cannot have the same z-Fold loaded on more than one track": "Cannot have the same z-Fold loaded on more than one track",
    "Card is based on your Planned Production schedule": "*Card is based on your Planned Production schedule",
    "Card is based only on a 24hr time period": "*Card is based only on a 24hr time period",
    "Carton": "Carton",
    "Carton Area": "Carton Area",
    "Carton Area sq in": "Carton Area (sq. in.)",
    "Carton Area sq mm": "Carton Area (sq. mm.)",
    "Carton Content": "Carton Content",
    "Carton Count": "Carton Count",
    "Carton Count By Carton Property Group": "Carton Count By Carton Property Group",
    "Carton Count By Machine": "Carton Count By Machine",
    "Carton Count By Machine This Week": "Carton Count By Machine This Week",
    "Carton Count By Machine Today": "Carton Count By Machine Today",
    "Carton Count By User": "Carton Count By User",
    "Carton Count By z-Fold": "Carton Count By z-Fold",
    "Carton Count By z-Fold This Week": "Carton Count By z-Fold This Week",
    "Carton Count By z-Fold Today": "Carton Count By z-Fold Today",
    "Carton Creation": "Carton Creation",
    "Carton Design": "Carton Design",
    "Carton Designs": "Carton Designs",
    "Carton Labels:": "Carton Labels:",
    "Carton Production": "Carton Production",
    "Carton Production Details": "Carton Production Details",
    "Carton Production Over Time Per Machine": "Carton Production Over Time Per Machine",
    "Carton Production Overview": "Carton Production Overview",
    "Carton Production by Site": "Carton Production by Site",
    "Carton Property Group History:": "Carton Property Group History:",
    "Carton Property Group Production Ratio": "Carton Property Group Production Ratio",
    "Carton Property Groups": "Carton Property Groups",
    "Carton Qty": "Carton Qty",
    "Carton Title": "Carton Title",
    "Carton:": "Carton:",
    "CartonAttached": "Carton Attached",
    "CartonInformationFields": "Carton Fields",
    "CartonItemsNotFound": "Carton items not found",
    "CartonMustHaveTwoOrMoreDimensionsGreaterThanZero": "Carton must have two or more dimensions greater than zero",
    "Cartons": "Cartons",
    "Cartons Affected": "Cartons Affected",
    "Cartons Made": "Cartons Made",
    "Cartons Produced": "Cartons Produced",
    "Cartons Produced by Machine": "Cartons Produced by Machine",
    "Cartons Produced by z-Fold": "Cartons Produced by z-Fold",
    "Cartons Produced by zFold": "Cartons Produced by z-Fold",
    "Cartons:": "Cartons:",
    "CartonsProduced": "Cartons Produced",
    "Carts": "Carts",
    "Cavity": "Cavity",
    "Change": "Change",
    "Change Corrugate": "Change z-Fold",
    "Change Design": "Change Design",
    "Change Loaded Corrugates On Machine": "Change Loaded Corrugates On Machine",
    "Change Machine Group operator screen": "Change Machine Group operator screen",
    "Change Machine Groups": "Change Machine Groups",
    "Change Production Group": "Change Production Group",
    "Change Time Frame": "Change Time Frame",
    "Change carton design": "Change carton design",
    "Change machine type?": "Change machine type?",
    "Change your site": "Change your site",
    "Change z-Fold": "Change z-Fold",
    "Changes you made will not be included in the export": "Changes you made will not be included in the export.",
    "Changing Corrugate": "Changing Corrugate",
    "Changing Production Group": "Changing Production Group",
    "ChangingCorrugate": "Changing Corrugate",
    "Character Count": "Character Count",
    "Character Limit": "Character Limit (leave blank for no limit)",
    "Check All": "Check All",
    "Choose Design": "Choose Design",
    "Choose the image you have prepared for your carton": "Choose the image you have prepared for your carton",
    "Classification History:": "Classification History:",
    "Classifications": "Classifications",
    "Clean Cut": "Clean Cut",
    "Clean Cut Length": "Clean Cut Length",
    "Clean imported files from source": "Clean imported files from source",
    "Clear": "Clear",
    "Clear Headers": "Clear Headers",
    "Clear Pipeline": "Clear Pipeline",
    "ClearCartonValue": "Clear Carton Value",
    "ClearCartonValueDescription": "Clear the value of a carton field.",
    "ClearJobValue": "Clear Job Value",
    "ClearJobValueDescription": "Clear the value of a job attribute.",
    "ClearLabelValue": "Clear Label Value",
    "ClearLabelValueDescription": "Clear the value of a label field.",
    "ClearList": "Clear List",
    "ClearListDescription": "Are you sure you want to clear this list?",
    "ClearValue": "Clear Value",
    "ClearValueDescription": "Clear the value of a header.",
    "Click the": "Click the",
    "Client Id": "Client Id",
    "Client Secret": "Client Secret",
    "Close": "Close",
    "Closed": "Closed",
    "Cloud Device Identifier": "Cloud Device Identifier",
    "Cloud Device Identifier:": "Cloud Device Identifier:",
    "Collect Data": "Collect Data",
    "Command": "Command",
    "Comment": "Comment",
    "Comment Indicator": "Comment Indicator",
    "Company": "Company",
    "Complete": "Complete",
    "Completed": "Completed",
    "Completed Carton Count By z-Fold This Week": "Completed Carton Count By z-Fold This Week",
    "Completed Carton Count By z-Fold Today": "Completed Carton Count By z-Fold Today",
    "ComponentUnsavedMessage": "Are you sure you want to leave this page?",
    "ComponentUnsavedMessage2": "Changes you made will not be saved.",
    "Condition": "Condition",
    "Configuration": "Configuration",
    "Configuration Audit": "Configuration Audit",
    "Configure": "Configure",
    "Configure Dashboard": "Configure Dashboard",
    "Configure your dashboard by adding widgets with the 'Configure' button": "Configure your dashboard by adding widgets with the 'Configure' button",
    "Configured Machines": "Configured Machines",
    "Configured Width": "Configured Width",
    "Confirm Password": "Confirm Password",
    "Connect Delay": "Connect Delay",
    "Connection Status": "Connection Status",
    "Consumption": "Consumption",
    "Consumption By z-Fold": "Consumption By z-Fold",
    "Consumption By z-Fold This Week": "Consumption By z-Fold This Week",
    "Consumption By z-Fold Today": "Consumption By z-Fold Today",
    "Content": "Content",
    "Contents": "Contents",
    "Continue": "Continue",
    "ContinueSession": "Continue Working",
    "Control and monitor Machine groups by production group": "Control and monitor Machine groups by production group",
    "ConvertDateTimeToEpoch": "Convert DateTime to Epoch",
    "ConvertToLocalTime": "Convert Date",
    "Converted": "Converted",
    "Corrugate Usage": "Corrugate Usage",
    "CorrugateNotWideEnough": "z-Fold Not Wide Enough",
    "CorrugateTooWideForMachine": "z-Fold Too Wide For Machine",
    "CouldNotCoerceToNumber": "Error converting a field to a number",
    "CouldNotEvaluateValueExpression": "Error evaluating the value expression of condition",
    "Count": "Count",
    "Crease": "Crease",
    "Crease Compensation": "Crease Compensation",
    "Crease Delay Off": "Crease Delay Off",
    "Crease Delay On": "Crease Delay On",
    "Crease Ring": "Crease Ring",
    "Create": "Create",
    "Create Action": "Create Action",
    "Create Job": "Create Job",
    "Create Jobs": "Create Jobs",
    "Create Jobs from Barcode": "Create Jobs from Barcode",
    "Create New Job": "Create New Job",
    "Create Packaging Solution": "Create Packaging Solution",
    "Create Single": "Create Single",
    "Create Single Job": "Create Single Job",
    "Create from Barcode": "Create from Barcode",
    "CreateCart": "Create Cart",
    "CreateCustomAttribute": "Create Custom Attribute",
    "Cross Head": "Cross Head",
    "Cross Head Tool Width": "Cross Head Tool Width",
    "CrossHead": "CrossHead",
    "Csv failed to upload, try again": "Csv failed to upload, try again",
    "Cube": "Cube",
    "Cube carton contents": "Cube carton contents",
    "CubeAddPaddingToEveryContainer": "Add Carton Padding",
    "CubeAddPaddingToEveryContainerDescription": "“Add Carton Padding” will increase the size of your overall carton by your increment of choice",
    "CubeAddPaddingToEveryItem": "Add Item Padding",
    "CubeAddPaddingToEveryItemDescription": "“Add Item Padding” will add space to your item(s) dimensions for padding",
    "CubeAddPercentagePaddingToEveryContainer": "Add Carton Padding (%)",
    "CubeAddPercentagePaddingToEveryContainerDescription": "“Add Carton Padding (%)” will increase the size of your overall carton by your percentage increment of choice",
    "CubeAddRule": "Add Rule",
    "CubeAddRuleSubtext": "Pick a rule for your pipeline",
    "CubeComingSoon": " Coming Soon",
    "CubeCutoffTime": "Cutoff Time (ms)",
    "CubeDescription": "Cube the contents, select the best volume, then the best surface",
    "CubeErrorParsingJSON": "Error parsing JSON data for file",
    "CubeExample": "Example",
    "CubeExampleText": "Ex. ",
    "CubeFile": "File",
    "CubeHeightIncrement": "Height Incr.",
    "CubeInputCartonDimensions": "Input carton dimensions",
    "CubeIs": "Is",
    "CubeLengthIncrement": "Length Incr.",
    "CubeMaxCartons": "Max Cartons Per Order",
    "CubeMaxCartonsTooltip": "Order is rejected if Cartons exceed this value, 0 means no limit",
    "CubeMaxHeight": "Max Height",
    "CubeMaxLength": "Max Length",
    "CubeMaxWeight": "Max Weight",
    "CubeMaxWeightTooltip": "Sum of items’ weight cannot exceed this value (per box). Dynamic box list only. 0 means no limit",
    "CubeMaxWidth": "Max Width",
    "CubeMinHeight": "Min Height",
    "CubeMinLength": "Min Length",
    "CubeMinWidth": "Min Width",
    "CubeOrderResultsBy": "Order results by",
    "CubePadding": "Padding",
    "CubePaddingPercentage": "Padding (%)",
    "CubeRules": "Rules",
    "CubeServiceDegraded": "Cube service is degraded",
    "CubeSetup": "Setup",
    "CubeShrinkwrap": "Shrink wrap",
    "CubeShrinkwrapTooltip": "Enables less waste and time by increasing increment value",
    "CubeSolutionsReturned": "Solutions Returned",
    "CubeSplitByContainerMaxWeight": "Split By Max Weight",
    "CubeSplitOnBooleanDescription": "“Split by item attribute” places items in their own carton if attribute selected is true. ie: when ‘Fragile’ is ‘true’",
    "CubeUnableToFitAllItems": "Cube unable to fit all items",
    "CubeUploadBoxList": "Upload box list",
    "CubeUploadJSONFormat": "Upload box list: (JSON file)",
    "CubeUseDynamic": "Use dynamic box list",
    "CubeWhen": "When",
    "CubeWidthIncrement": "Width Incr.",
    "Cubing": "Cubing",
    "Culture": "Culture",
    "Curate": "Curate",
    "Current # in Queue": "Current # in Queue",
    "Current # in Staged": "Current # in Staged",
    "Current Machine Group": "Current Machine Group",
    "Current Production Status": "Current Production Status",
    "Current Status": "Current Status",
    "Current ZFold Status": "Current z-Fold Status",
    "CurrentValue": "Current Value",
    "Custom": "Custom",
    "Custom Job From Article": "Custom Job From Article",
    "Customer ID": "Customer ID",
    "Customer Specific": "Customer Specific",
    "Cut": "Cut",
    "Cut Compensation": "Cut Compensation",
    "Cut Delay Off": "Cut Delay Off",
    "Cut Delay On": "Cut Delay On",
    "Cycle CrossHead Tools": "Cycle CrossHead Tools",
    "Cycle Time In Seconds": "Cycle Time In Seconds",
    "Cycle Tools": "Cycle Tools",
    "Cycle Tracks": "Cycle Track Tools",
    "DELETE": "DELETE",
    "Dashboard": "Dashboard",
    "Data Capture": "Data Capture",
    "Data Capture Flow": "Data Capture Flow",
    "Data Capture Form": "Data Capture Form",
    "Data Capture Wizard": "Data Capture Wizard",
    "Data Mapping": "Data Mapping",
    "Data imported successfully": "Data imported successfully",
    "DataMapper": "Data Mapper",
    "Date": "Date",
    "Date Range": "Date Range",
    "DateLong": "Long Date",
    "DateShort": "Short Date",
    "DateTime": "DateTime",
    "DateTimeLong": "Long DateTime",
    "DateTimeShort": "Short DateTime",
    "Days": "Days",
    "Days to Live": "Days to Live",
    "Days to Live must be between 1 and 31": "Days to Live must be between 1 and 31",
    "Days to Live must be between 1 and 90": "Days to Live must be between 1 and 90",
    "Deactivate": "Deactivate",
    "December": "December",
    "DecimalPrecision": "Decimal Precision",
    "Default": "Default",
    "Default Value": "Default Value",
    "Delete": "Delete",
    "Delete Box Last Cartons": "Delete Box Last Cartons",
    "Delete Carton": "Delete Carton",
    "Delete Carton Design": "Delete Carton Design",
    "Delete Image": "Delete Image",
    "Delete Key": "Delete Key",
    "Delete Label Template": "Delete Label Template",
    "Delete Print Image": "Delete Print Image",
    "Delete Role": "Delete Role",
    "Delete Selected Jobs": "Delete Selected Jobs",
    "Delete User": "Delete User",
    "Delete carton property group": "Delete carton property group",
    "Delete classification": "Delete classification",
    "Delete machine": "Delete machine {alias}?",
    "Delete machine group": "Delete machine group",
    "Delete my account": "Delete my account",
    "Delete production group": "Delete production group",
    "Delete z-fold": "Delete z-fold",
    "Delete zone": "Delete zone",
    "Delete {count} Saved Jobs": "Delete {count} Saved Jobs?",
    "DeleteCart": "Delete Cart {cartId}?",
    "DeleteCartDescription": "This change cannot be undone.",
    "DeleteCartonDesign": "Do you want to delete design '{designId}'?",
    "DeleteJobConfirmation": "Do you want to delete '{jobName}?' All items in this job will be deleted",
    "DeleteProduct": "Delete Product {productId}?",
    "DeleteProductDescription": "This change cannot be undone",
    "DeleteThisRule": "Delete this rule?",
    "Deleting Carton Design": "Deleting Carton Design",
    "Deleting Saved Job": "Deleting Saved Job",
    "DeletingDesign": "Delete Design {designId}?",
    "Delimiter": "Delimiter",
    "Dependency": "Dependency",
    "Deprecated Logic": "Deprecated Logic",
    "Description": "Description",
    "Deselect All": "Deselect All",
    "Deselect all Bale Widths": "Deselect all Bale Widths",
    "Design": "Design",
    "Design ID": "Design ID",
    "Design Name": "Design Name",
    "DesignExists": "Design ID “{designId}” Already Exists",
    "Details": "Details",
    "Die Cut Cartons": "Die Cut Cartons",
    "Dim": "Dim",
    "Dim1": "Dimension 1",
    "Dim2": "Dimension 2",
    "Dim3": "Dimension 3",
    "DimAddAProductQuestion": "Would you like to add the product to your database?",
    "DimAddNewProduct": "Add New Product",
    "DimAddProduct": "Add Product",
    "DimDate": "Date",
    "DimDistoOffsetPlusUnit": "Disto Offset ({unit})",
    "DimDistoOffsetWarning": "Disto Offset ({offset}) will be applied",
    "DimEditing": "Editing...",
    "DimEnterDistoOffset": "Enter Disto Offset",
    "DimEnterProductId": "Enter {idType}",
    "DimEnterProductIdEmpty": "Enter {idType} To Add Your First Product",
    "DimFileName": "File Name",
    "DimFindAProduct": "Find a product in your database",
    "DimFindOrAddAProduct": "Find or add a product to your database",
    "DimHeightWithOneNest": "Height with one nest",
    "DimImportProductsToCreateHistory": "Import Products to Create History",
    "DimLengthWithOneNest": "Length with one nest",
    "DimMaxNumberOfProductsNest": "Max number of nested products",
    "DimNestingDims": "Nesting Dimensions",
    "DimNewImport": "New Import",
    "DimNoImportHistoryFound": "No Import History Found",
    "DimNoResults": "No Results Found",
    "DimNoResultsDetails": "We Can't find any products matching your search",
    "DimOffsetFlow": "Disto Configurations",
    "DimProductDetails": "Product Details",
    "DimProductDoesNotExist": "Product {id} Does Not Exist",
    "DimProductQuantity": "Product Quantity",
    "DimReferenceImage": "Reference Image",
    "DimReturnToProductsPage": "< Back To Products",
    "DimVoidFillNotes": "Note: Dimensions must be entered in cubic areas. No curved or sphere shapes can be measured.",
    "DimWidthWithOneNest": "Width with one nest",
    "Dimensioning": "Dimensioning",
    "DimensioningDescription": "Look up product dimensions based on product ID (UPC, SKU, ISBN, etc)",
    "Dimensions": "Dimensions",
    "Disable": "Disable",
    "Disable ColorWise": "Disable ColorWise",
    "Disabled": "Disabled",
    "Displays all estimated jobs by z-Fold": "Displays all estimated jobs by z-Fold",
    "Displays all open orders in your current machine group": "Displays all open orders in your current machine group",
    "Displays carton property groups": "Displays carton property groups",
    "Displays classifications": "Displays classifications",
    "Displays current z-Fold status": "Displays current z-Fold status",
    "Displays machine groups": "Displays machine groups",
    "Displays machine status": "Displays machine status",
    "Displays pick zones": "Displays pick zones",
    "Displays production groups": "Displays production groups",
    "Displays the Average Production Time this week for created items": "Displays the Average Production Time this week for created items",
    "Displays the Average Waste Percentage this week for created items": "Displays the Average Waste Percentage this week for created items",
    "Displays the Average Waste Percentage this week for created items by machine": "Displays the Average Waste Percentage this week for created items by machine",
    "Displays the Average Waste Percentage this week for created items by z-Fold": "Displays the Average Waste Percentage this week for created items by z-Fold",
    "Displays the Average Waste Percentage today for created items by machine": "Displays the Average Waste Percentage today for created items by machine",
    "Displays the Average Waste Percentage today for created items by z-Fold": "Displays the Average Waste Percentage today for created items by z-Fold",
    "Displays the average production time for created items": "Displays the average production time for created items",
    "Displays the average production time for created items today": "Displays the average production time for created items today",
    "Displays the average waste percentage for created items": "Displays the average waste percentage for created items",
    "Displays the average waste percentage for created items by machine": "Displays the average waste percentage for created items by machine",
    "Displays the average waste percentage for created items by machine this week": "Displays the average waste percentage for created items by machine this week",
    "Displays the average waste percentage for created items by z-Fold": "Displays the average waste percentage for created items by z-Fold",
    "Displays the average waste percentage for created items by z-Fold this week": "Displays the average waste percentage for created items by z-Fold this week",
    "Displays the average waste percentage for created items this week": "Displays the average waste percentage for created items this week",
    "Displays the average waste percentage for created items today": "Displays the average waste percentage for created items today",
    "Displays the number of cartons created by machine this week": "Displays the number of cartons created by machine this week",
    "Displays the number of cartons created by z-Fold this week": "Displays the number of cartons created by z-Fold this week",
    "Displays the number of created cartons by carton property group within production groups": "Displays the number of created cartons by carton property group within production groups",
    "Displays the number of created cartons by machine": "Displays the number of created cartons by machine",
    "Displays the number of created cartons by machine today": "Displays the number of created cartons by machine today",
    "Displays the number of created cartons by user": "Displays the number of created cartons by user",
    "Displays the number of created cartons by z-Fold": "Displays the number of created cartons by z-Fold",
    "Displays the number of created cartons by z-Fold today": "Displays the number of created cartons by z-Fold today",
    "Displays the number of created producible items": "Displays the number of created producible items",
    "Displays the number of created producible items this week": "Displays the number of created producible items this week",
    "Displays the number of created producible items today": "Displays the number of created producible items today",
    "Displays the number of failed producible items": "Displays the number of failed producible items",
    "Displays the number of failed producible items this week": "Displays the number of failed producible items this week",
    "Displays the number of failed producible items today": "Displays the number of failed producible items today",
    "Displays the number of imported producible items": "Displays the number of imported producible items",
    "Displays the number of imported producible items this week": "Displays the number of imported producible items this week",
    "Displays the number of imported producible items today": "Displays the number of imported producible items today",
    "Displays the number of open cartons in the system": "Displays the number of open cartons in the system",
    "Displays the number of rotated producible items": "Displays the number of rotated producible items",
    "Displays the number of rotated producible items this week": "Displays the number of rotated producible items this week",
    "Displays the number of rotated producible items today": "Displays the number of rotated producible items today",
    "Displays the z-Fold consumption by z-Fold this week": "Displays the z-Fold consumption by z-Fold this week",
    "Displays the z-Fold consumption by z-Fold today": "Displays the z-Fold consumption by z-Fold today",
    "Displays the z-Fold consumption for created items by z-Fold": "Displays the z-Fold consumption for created items by z-Fold",
    "DistoInput": "Disto Input",
    "Distribute Orders Across Multiple Machine Groups": "Distribute Orders Across Multiple Machine Groups",
    "Distribute Orders By Default": "Distribute Orders By Default",
    "Do not produce": "Do not produce",
    "Do you want to replace it with this new design?": "Do you want to replace it with this new design?",
    "Does your barcode have more than one value?": "Does your barcode have more than one value?",
    "Does your file have headers?": "Does your file have headers?",
    "Done": "Done",
    "Download": "Download",
    "DownloadCount": "Download (X{downloadCount})",
    "DownloadRawAuditResults": "Download Raw Audit Results",
    "Drop file here": "Drop file here",
    "Duplicate": "Duplicate",
    "DuplicateProductId": "{productId} is a duplicate item",
    "Duration in Minutes": "Duration in Minutes",
    "EAN": "EAN",
    "EM": "EM",
    "EMCCInfo": "Timers can be set up in the Tracks section of Physical Machine Settings",
    "EMCCInfoHeader": "Cross Conveyor Timer",
    "EMCCWarning": "Must enable cross conveyor on machine page",
    "EMHasPacktags": "Has Packtags",
    "EMPS1TimerLabel": "Packstation 1 Timer (seconds)",
    "EMPS2TimerLabel": "Packstation 2 Timer (seconds)",
    "EMSettingsNote": "*For updated settings to take effect, power cycle the machine after save",
    "EMSettingsWarning": "Machine production status must be idle to save changes",
    "Edit": "Edit",
    "Edit Carton Design": "Edit Carton Design",
    "Edit Carton Property Group": "Edit Carton Property Group",
    "Edit Classification": "Edit Classification",
    "Edit Count": "Edit Count",
    "Edit Event Notification": "Edit Event Notification",
    "Edit Label Template": "Edit Label Template",
    "Edit Machine": "Edit Machine",
    "Edit Machine Group": "Edit Machine Group",
    "Edit Packaging Solution": "Edit Packaging Solution",
    "Edit Physical Machine Settings": "Edit Physical Machine Settings",
    "Edit Pick Zone": "Edit Pick Zone",
    "Edit Print Image": "Edit Print Image",
    "Edit Production Group": "Edit Production Group",
    "Edit Role": "Edit Role",
    "Edit User": "Edit User",
    "Edit z-Fold": "Edit z-Fold",
    "EditCart": "Edit Cart",
    "Editing Product": "Editing Product",
    "Em": "EM",
    "Em Error 1": "E-Stop/Doors Are Open",
    "Em Error 12": "Invalid Instruction List",
    "Em Error 13": "Machine Error",
    "Em Error 14": "Change z-Fold",
    "Em Error 15": "Low Air Pressure",
    "Em Error 17": "Breached Outfeed Light Barrier",
    "Em Error 18": "Crosshead Reference Mismatch",
    "Em Error 19": "Longhead Position Out Of Tolerance",
    "Em Error 2": "Roller Axis Error",
    "Em Error 20": "Crosshead Position Out Of Tolerance",
    "Em Error 21": "Position Latch Error",
    "Em Error 22": "Detected Multple PackNet Connections",
    "Em Error 23": "Breached Infeed Light Barrier",
    "Em Error 3": "Tool Axis Error",
    "Em Error 4": "PackNet Connection Error",
    "Em Error 5": "Reload z-Fold",
    "Em Error 5-1": "Reload z-Fold on Track 1",
    "Em Error 5-2": "Reload z-Fold on Track 2",
    "Em Error 5-3": "Reload z-Fold on Track 3",
    "Em Error 5-4": "Reload z-Fold on Track 4",
    "Em Error 6": "Paper Jam",
    "Em Error 8": "Incorrect Number Of Longheads",
    "Em Error 9": "Invalid Longhead Position",
    "Em Error AirPressureLow": "AirPressureLow",
    "Em Error AlreadyConnectedError": "AlreadyConnectedError",
    "Em Error ChangeCorrugate": "ChangeCorrugate",
    "Em Error CrossHeadPositionOutsideOfTolerance": "CrossHeadPositionOutsideOfTolerance",
    "Em Error EmergencyStop": "Emergency Stop Activated",
    "Em Error InFeedLightBarrierBroken": "InFeedLightBarrierBroken",
    "Em Error InstructionList": "InstructionList",
    "Em Error LongHeadPosition": "LongHeadPosition",
    "Em Error LongHeadPositionOutsideOfTolerance": "LongHeadPositionOutsideOfTolerance",
    "Em Error LongHeadQuantity": "Invalid long head quantity",
    "Em Error MachineError": "MachineError",
    "Em Error OutFeedLightBarrierBroken": "OutFeedLightBarrierBroken",
    "Em Error OutOfCorrugate": "Out of material",
    "Em Error PacksizePackagingMachineError4": "Machine Needs To Be Reset",
    "Em Error PaperJam": "Check for material jam",
    "Em Error PositionLatchError": "PositionLatchError",
    "Em Error ReferenceQuantityMismatch": "ReferenceQuantityMismatch",
    "Em Error RollAxisError": "RollAxisError",
    "Em Error ToolAxisError": "ToolAxisError",
    "Email": "Email",
    "Email Address": "Email Address",
    "Email Address Placeholder": "Enter your email address...",
    "Email must be valid": "Email must be valid",
    "Emergency Stop": "Emergency Stop",
    "Enable": "Enable",
    "Enable Box Last Queue": "Enable Box Last Queue",
    "Enable External Printer": "Enable External Printer",
    "Enable Integrated Printer": "Enable Integrated Printer",
    "Enabled Feature Flags": "Enabled Feature Flags",
    "End Date": "End Date",
    "Enter Barcode": "Enter Barcode",
    "Enter Label": "Enter Label",
    "Enter Password": "Enter password",
    "Enter Question Text": "Enter Question Text",
    "Enter Service Mode": "Enter Service Mode",
    "Enter Text": "Enter Text",
    "Enter Text Here": "Enter Text Here",
    "Enter dimensions for your carton": "Enter dimensions for your carton",
    "Enter number value": "Enter number value",
    "Enter product information in the form fields on the right Click save when finished": "Enter product information in the form fields on the right. Click 'save' when finished.",
    "Enter value": "Enter value",
    "EnterProductId": "Enter {idType}",
    "EnterProductIdEmpty": "Enter {idType} To Add Your First Product",
    "Error": "Error",
    "Error Count": "Error Count",
    "Error Name": "Error Name",
    "Error importing data, status code {status}": "Error importing data, status code {status}",
    "Error when downloading packaging design(s): ": "Error when downloading packaging design(s): ",
    "Error: Please contact support to report this issue": "Error: Please contact support to report this issue",
    "ErrorSolvingCondition": "Error solving condition",
    "Errors": "Errors",
    "Errors Found": "Errors Found",
    "Errors Overview": "Errors Overview",
    "Errors and Availability": "Errors & Availability",
    "Errors and Availability Overview": "Errors & Availability Overview",
    "Estimated Carton Count By z-Fold (Box First)": "Estimated Carton Count By z-Fold (Box First)",
    "Estimated Carton Count By z-Fold (Orders)": "Estimated Carton Count By z-Fold (Orders)",
    "Estimated Consumed Bale Counts": "Estimated Consumed Bale Counts",
    "Estimated Corrugate Usage By z-Fold": "Estimated Corrugate Usage By z-Fold",
    "Estimated Remaining Bale Counts": "Estimated Remaining Bale Counts",
    "European Union": "European Union",
    "Event Notification Sent Through IoT": "Events will be sent through the IoT device registered. Please contact your Packsize representative if you would like to enable or update the IoT devices registered with Event Notifications.",
    "Event Notifications": "Event Notifications",
    "Everyday": "Everyday",
    "ExceptionType": "Exception Type",
    "Exchange Name": "Exchange Name",
    "Exchange Name is required": "Exchange Name is required",
    "Expedite": "Expedite",
    "Expedited Cartons: {total}": "Expedited Cartons: {total}",
    "Export": "Export",
    "Export Data": "Export Data",
    "Export Without Saving": "Export Without Saving",
    "Expression": "Expression",
    "External File": "External File",
    "External File Path or Fiery Job Id": "External File Path or Fiery Job Id",
    "External Print": "External Print",
    "External System": "External System",
    "ExtractValue": "Extract Value",
    "FailJob": "Fail Job",
    "FailJobOnCartonMatch": "Fail Job on Carton Match",
    "FailJobOnLabelMatch": "Fail Job on Label Match",
    "Failed": "Failed",
    "Failed Cartons": "Failed Cartons",
    "Failed Production Options:": "Failed Production Options:",
    "Failed To Produce Next": "Failed To Produce Next",
    "Failed To Produce Now": "Failed To Produce Now",
    "Failed To Remove Job": "Failed To Remove Job",
    "Failed To Remove Jobs": "Failed To Remove Jobs",
    "Failed To Trigger Job": "Failed To Trigger Job",
    "Failed to reproduce a job from the batch": "Failed to reproduce a job from the batch",
    "Failed to save": "Failed to save",
    "Failed to save Carton Property Group": "Failed to save Carton Property Group",
    "Failed to save Carton Property Group: An item already exists with the name {name}": "Failed to save Carton Property Group: An item already exists with the name {name}",
    "Failed to save Classification": "Failed to save Classification",
    "Failed to save Classification: An item already exists with the name {name} or number {number}": "Failed to save Classification: An item already exists with the name {name} or number {number}",
    "Failed to save Configuration": "Failed to save Configuration",
    "Failed to save Label Template": "Failed to save Label Template",
    "Failed to save Label Template: An item already exists with the name {name}": "Failed to save Label Template: An item already exists with the name {name}",
    "Failed to save Machine Group: A selected machine already belongs to another Machine Group": "Failed to save Machine Group: A selected machine already belongs to another Machine Group",
    "Failed to save Machine Group: An item already exists with the name {name}": "Failed to save Machine Group: An item already exists with the name {name}",
    "Failed to save Pick Zone": "Failed to save Pick Zone",
    "Failed to save Pick Zone: An item already exists with the name {name}": "Failed to save Pick Zone: An item already exists with the name {name}",
    "Failed to save Production Group": "Failed to save Production Group",
    "Failed to save Production Group: A selected machine group already belongs to another Production Group": "Failed to save Production Group: A selected machine group already belongs to another Production Group",
    "Failed to save Production Group: An item already exists with the name {name}": "Failed to save Production Group: An item already exists with the name {name}",
    "Failed to save Role": "Failed to save Role",
    "Failed to save Role: An item already exists with the name {name}": "Failed to save Role: An item already exists with the name {name}",
    "Failed to save User": "Failed to save User",
    "Failed to save User: An item already exists with the Username {name}": "Failed to save User: An item already exists with the Username {name}",
    "Failed to save z-Fold: An item already exists with the name {name}": "Failed to save z-Fold: An item already exists with the name {name}",
    "Failed to send to queue": "Failed to send packaging solution to queue",
    "False": "False",
    "Favorites": "Favorites",
    "February": "February",
    "Feed Roller": "Feed Roller",
    "Feed Roller Forward": "Feed Roller Forward",
    "FetchDependencyError": "Failed to fetch dependency",
    "Field": "Field",
    "Field Verified: {fieldName} with value {fieldValue}": "Field Verified: {fieldName} with value {fieldValue}",
    "Field.CartonInformation.Barcode": "Barcode",
    "Field.CartonInformation.Brand": "Brand",
    "Field.CartonInformation.CartonRotation": "Rotation",
    "Field.CartonInformation.FlapsDown": "Flaps Down",
    "Field.CartonInformation.Height": "Height",
    "Field.CartonInformation.Length": "Length",
    "Field.CartonInformation.Style": "Design Id",
    "Field.CartonInformation.Thickness": "Flute",
    "Field.CartonInformation.Width": "Width",
    "Field.JobInformation.Alias": "Job Title",
    "Field.JobInformation.CartonPropertyGroups": "Carton Property Group",
    "Field.JobInformation.Classification": "Classification",
    "Field.JobInformation.Command": "Command",
    "Field.JobInformation.CreateByDate": "Create By Date",
    "Field.JobInformation.GroupId": "Group ID",
    "Field.JobInformation.JobQuantity": "Quantity",
    "Field.JobInformation.LicensePlateNumber": "License Plate Number",
    "Field.JobInformation.Machine": "Machine ID",
    "Field.JobInformation.MachineGroup": "Machine Group",
    "Field.JobInformation.PackStation": "PackStation",
    "Field.JobInformation.PickZone": "PickZone",
    "Field.JobInformation.PrioritizeProductionBy": "Prioritize Production By",
    "Field.JobInformation.Priority": "Priority",
    "Field.JobInformation.ProductionGroup": "Production Group",
    "Field.JobInformation.SavedJobAlias": "Saved Job",
    "Field.LabelInformation.LabelType": "Label Type",
    "Field.LabelInformation.Priority": "Label Priority",
    "Field.LabelInformation.Style": "Label Template",
    "Field.LabelInformation.VerificationCode": "Label Verification Code",
    "FieldName": "Field Name",
    "File Access": "File Access",
    "File Extension": "File Extension",
    "File Import": "File Import",
    "File Name": "File Name",
    "Filter": "Filter",
    "Filter By": "Filter By",
    "FilterByStoppedCartonPropertyGroup": "Filter By Stopped Carton Property Group",
    "FilterByStoppedClassification": "Filter By Stopped Classification",
    "FilterByStoppedPickZone": "Filter By Stopped PickZone",
    "Filtering items based on loaded materials resulted in {remaining} remaining items": "Filtering items based on loaded materials resulted in {remaining} remaining items",
    "Filtering items based on what can be produced optimally on the machine resulted in {remaining} remaining items": "Filtering items based on what can be produced optimally on the machine resulted in {remaining} remaining items",
    "Filtering items reserved for other machine groups resulted in {remaining} remaining items": "Filtering items reserved for other machine groups resulted in {remaining} remaining items",
    "Filtering items reserved for other machines resulted in {remaining} remaining items": "Filtering items reserved for other machines resulted in {remaining} remaining items",
    "Filtering items reserved for other production groups resulted in {remaining} remaining items": "Filtering items reserved for other production groups resulted in {remaining} remaining items",
    "Filtering items that are released for production resulted in {remaining} remaining items": "Filtering items that are released for production resulted in {remaining} remaining items",
    "Filtering items that belong to batches that have already started resulted in {remaining} remaining items": "Filtering items that belong to batches that have already started resulted in {remaining} remaining items",
    "Filtering items that can be produced optimally resulted in {remaining} remaining items": "Filtering items that can be produced optimally resulted in {remaining} remaining items",
    "Filtering items that can not be produced on this machine group resulted in {remaining} remaining items": "Filtering items that can not be produced on this machine group resulted in {remaining} remaining items",
    "Filtering items that match the pack station resulted in {remaining} remaining items": "Filtering items that match the pack station resulted in {remaining} remaining items",
    "Filtering items that match the scanned identifier resulted in {remaining} remaining items": "Filtering items that match the scanned identifier resulted in {remaining} remaining items",
    "Filtering items to be produced during certains days resulted in {remaining} remaining items": "Filtering items to be produced during certains days resulted in {remaining} remaining items",
    "Filtering items with {stepName} resulted in {remaining} remaining items": "Filtering items with {stepName} resulted in {remaining} remaining items",
    "Filters": "Filters",
    "FinalValidation": "Final Validation",
    "FindOrAddAProduct": "Find or add a product to your database",
    "Finished Jobs": "Finished Jobs",
    "FinishedJobs": "Finished Jobs",
    "First Name": "First Name",
    "First carton": "First carton",
    "First label": "First label",
    "First matching carton": "First matching carton",
    "First matching label": "First matching label",
    "Flaps Down": "Flaps Down",
    "Flaps Down Band Used": "Flaps Down Band Used",
    "Flip": "Flip",
    "Flip Horizontal": "Flip Horizontal",
    "Flip Vertical": "Flip Vertical",
    "Flow Type": "Flow Type",
    "Flute": "Flute",
    "Flute is required": "Flute is required",
    "For All": "For All",
    "Format": "Format",
    "Friday": "Friday",
    "Friday Abbrev": "FRI",
    "From": "From",
    "From External Source": "From External Source",
    "Fusion": "Fusion",
    "Fusion Action AirPressureLow": "Verify pressure gauge has a reading of 6-10 bars, check for leakages from any of the fittings or tubings.",
    "Fusion Action AlreadyConnectedError": "Check that the machine is on and connected to the same network as the operator panel.",
    "Fusion Action ChangeCorrugate": "Load a new bale of z-fold. If z-Fold is already loaded, ensure the side steerings are flush against z-Fold edges.",
    "Fusion Action ConnectionError": "Check that the machine is on and connected to the same network as the operator panel.",
    "Fusion Action CorrugateMismatch": "Messured track width is {track}, corrugate width is {corrugate}",
    "Fusion Action EmergencyStop": "Turn emergency stop buttons clockwise to deactivate e-stop.",
    "Fusion Action InstructionList": "Machine cannot follow instructions in the design file. Send a new carton with a different design.",
    "Fusion Action LongHeadPosition": "Long head is not in the expected position. Make sure the long head belt is not damaged and slides freely.",
    "Fusion Action LongHeadPositionOutsideOfTolerance": "Long head is not in the expected position. Make sure the long head belt is not damaged and slides freely.",
    "Fusion Action LongHeadQuantity": "Make sure that the crosshead can move end to end, and the proximity sensor on it can detect each long head pin",
    "Fusion Action MachineError": "If this error persists, please contact support.",
    "Fusion Action OutOfCorrugate": "Track {track}: Load a new bale of z-fold. If z-Fold is already loaded, ensure the side steerings are flush against z-Fold edges.",
    "Fusion Action PaperJam": "Remove any z-Fold jammed between the roller and the cut/crease tools. Reload if there are no obvious jams.",
    "Fusion Action ReferenceQuantityMismatch": "Make sure that the crosshead can move end to end, and the proximity sensor on it can detect each long head pin",
    "Fusion Action RollAxisError": "Inspect the roller to figure out why it is being stalled.",
    "Fusion Action ToolAxisError": "Inspect all cut/crease tools to figure out if something is preventing tools from moving. Error Code: {code}",
    "Fusion Error AirPressureLow": "Low Air Pressure",
    "Fusion Error AlreadyConnectedError": "Already Connected",
    "Fusion Error ChangeCorrugate": "Change z-Fold",
    "Fusion Error ConnectionError": "No Connection",
    "Fusion Error CorrugateMismatch": "Corrugate Mismatch",
    "Fusion Error EmergencyStop": "E-Stop Activated",
    "Fusion Error InstructionList": "Design File Bug",
    "Fusion Error LongHeadPosition": "Invalid Long head Position",
    "Fusion Error LongHeadPositionOutsideOfTolerance": "Invalid Long head Position",
    "Fusion Error LongHeadQuantity": "Long head Quantity Mismatch",
    "Fusion Error MachineError": "Machine Error",
    "Fusion Error OutOfCorrugate": "Out of z-Fold",
    "Fusion Error PaperJam": "z-Fold Jam",
    "Fusion Error ReferenceQuantityMismatch": "Long head Quantity Mismatch",
    "Fusion Error RollAxisError": "Roll Axis Stalled",
    "Fusion Error ToolAxisError": "Tool Axis Stalled",
    "GTIN": "GTIN",
    "GTIN_12": "GTIN-12",
    "GTIN_13": "GTIN-13",
    "GTIN_14": "GTIN-14",
    "GTIN_8": "GTIN-8",
    "Gear Ratio": "Gear Ratio",
    "General": "General",
    "General Productivity": "General Productivity",
    "Generate": "Generate",
    "Generate API Key": "Generate API Key",
    "Global": "Global",
    "Go Back": "Go Back",
    "Grand Total": "Grand Total",
    "Graph View": "Graph View",
    "GreaterThan": "Greater Than",
    "GreaterThanOrEqual": "Greater Than Or Equal",
    "HTTP Headers": "HTTP Headers",
    "Hardcoded ZPL": "Hardcoded ZPL",
    "Has Carton Print": "Has Carton Print",
    "Header": "Header",
    "Headers": "Headers",
    "Height": "Height",
    "Height:": "Height:",
    "Here is your new API key": "Here is your new API key.",
    "HiddenFromSearch": "HiddenFromSearch",
    "Hide Advanced Settings": "Hide Advanced Settings",
    "History": "History",
    "Hmac": "HMAC",
    "Home": "Home",
    "Homing Position": "Homing Position",
    "Hours": "Hours",
    "How long PackNet will continue to retry sending the message to you": "How long PackNet will continue to retry sending the message to you",
    "How will you use your scanning device?": "How will you use your scanning device?",
    "ID": "ID",
    "IP": "IP",
    "IP Address": "IP Address",
    "IP is required": "IP is required",
    "IPWrongFormat": "IP address is in the wrong format",
    "ISBN": "ISBN",
    "ISBN_10": "ISBN-10",
    "ISBN_13": "ISBN-13",
    "Idle": "Idle",
    "Ignore": "Ignore",
    "IgnoreDuplicateWork": "Ignore Duplicate Work",
    "IgnoreDuplicateWorkNoMatches": "Ignore new work when the LPN matches {dropDown} from the last 30 days",
    "Ignored Headers": "Ignored Headers",
    "Image": "Image",
    "Image File Name": "Image File Name",
    "Image failed to upload": "Image failed to upload",
    "Image is uploading": "Image is uploading",
    "Import": "Import",
    "Import Configuration": "Import Configuration",
    "Import Configuration from 4_3 File": "Import Configuration from 4.3 File",
    "Import Configuration from File": "Import Configuration from File",
    "Import Configuration from History": "Import Configuration from History",
    "Import Failed": "Import Failed",
    "Import Fusion Settings": "Import Fusion Settings",
    "Import Headers": "Import Headers",
    "Import Pipeline": "Import Pipeline",
    "Import Pipeline Version History": "Import Pipeline Version History",
    "Import Pipelines - Data Mapping Service": "Import Pipelines - Data Mapping Service",
    "Import Pipelines - File Drop Service": "Import Pipelines - File Drop Service",
    "Import Pipelines - Import Api": "Import Pipelines - Import Api",
    "Import Pipelines - Import Configuration Api": "Import Pipelines - Import Configuration Api",
    "Import Pipelines - Network Scanner Service": "Import Pipelines - Network Scanner Service",
    "Import Pipelines - Rule Engine Service": "Import Pipelines - Rule Engine Service",
    "Import Pipelines - Scan To Create Api": "Import Pipelines - Scan To Create Api",
    "Import Pipelines use headers to allow customers to import information process it through rules and finally create a packaging solution": "Import Pipelines use headers to allow customers to import information, process it through rules and finally create a packaging solution.",
    "Import Selection": "Import Selection",
    "Import from 4_3": "Import from 4.3",
    "Import from File": "Import from File",
    "Import from History": "Import from History",
    "ImportIgnored": "Ignored",
    "ImportProducts": "Import Products",
    "Imported": "Imported",
    "Importing": "Importing",
    "Importing Data": "Importing Data",
    "Importing of item completed": "Importing of item completed",
    "Importing of item started": "Importing of item started",
    "In Progress": "In Progress",
    "Inches": "Inches",
    "Information": "Information",
    "Initializing": "Initializing",
    "Ink Templates": "Ink Templates",
    "Input Your Actual Remaining Bale Counts": "Input Your Actual Remaining Bale Counts",
    "Inputs": "Inputs",
    "Integration": "Integration",
    "Integration Type": "Integration Type",
    "Invalid Credentials": "Your username or password is incorrect",
    "Invalid token": "Invalid token, please try resetting your password again.",
    "InvalidPgId": "An invalid production group was assigned",
    "InvalidValue": "Invalid value",
    "InvalidValue {propertyName}": "Invalid value for {propertyName}",
    "Invariant": "Invariant",
    "Item failed and will not be produced": "Item failed and will not be produced",
    "Item is a duplicate of existing work and will not be produced": "Item is a duplicate of existing work and will not be produced",
    "Item was removed and marked to ignore from search functions": "Item was removed and marked to ignore from search functions",
    "Item was removed from queue and will not be produced": "Item was removed from queue and will not be produced",
    "Item was reproduced by a user": "Item was reproduced by a user",
    "Item was retracted from the machine due to a hardware related error The item was made available for reproduction": "Item was retracted from the machine due to a hardware related error The item was made available for reproduction",
    "Item was selected for production by machine group {machineGroupAlias}": "Item was selected for production by machine group {machineGroupAlias}",
    "Item was successfully produced on machine {machineAlias}": "Item was successfully produced on machine {machineAlias}",
    "Item was successfully staged for production": "Item was successfully staged for production",
    "ItemNumber": "Item Number",
    "Items Effected": "Items Effected",
    "Items per page": "Items per page",
    "Items were sorted by create date resulting in carton {firstCarton} as the top item": "Items were sorted by create date resulting in carton {firstCarton} as the top item",
    "Items were sorted by import date (ascending) resulting in carton {firstCarton} as the top item": "Items were sorted by import date (ascending) resulting in carton {firstCarton} as the top item",
    "Items were sorted by priority resulting in carton {firstCarton} as the top item": "Items were sorted by priority resulting in carton {firstCarton} as the top item",
    "IterationCount": "{current} of {total}",
    "January": "January",
    "Job": "Job",
    "Job Details": "Job Details",
    "Job History": "Job History",
    "Job Production": "Job Production",
    "Job Quantity": "Job Quantity",
    "Job Queue": "Queue",
    "Job Title": "Title",
    "JobFailedByUserViaFailJobAction": "Job Marked Failed by Fail Job Rule Action",
    "JobInformationFields": "Job Fields",
    "JobTitle": "Job Title",
    "JobTitlePrompt": "Scan Job Title",
    "Jobs": "Jobs",
    "Jobs History": "Jobs History",
    "Join Production Group": "Join Production Group",
    "JoinStrings": "Join Strings",
    "July": "July",
    "June": "June",
    "KVM": "KVM",
    "Knife Activation Delay": "Knife Activation Delay",
    "Knife Deactivation Delay": "Knife Deactivation Delay",
    "LEAVE PAGE": "LEAVE PAGE",
    "LONG HEAD {longheadNumber}": "LONG HEAD {longheadNumber}",
    "LPN": "LPN",
    "LPN trigger value releases matching batches": "LPN trigger value releases matching batches",
    "LPN trigger value releases one job in a batch": "LPN trigger value releases one job in a batch",
    "Label": "Label",
    "Label (Optional)": "Label (Optional)",
    "Label Optional": "Label (Optional)",
    "Label Priority": "Label Priority",
    "Label Qty": "Label Qty",
    "Label Sizes": "Label Sizes",
    "Label Template": "Label Template",
    "Label Template Name": "Label Template Name",
    "Label Templates": "Label Templates",
    "Label Templates History:": "Label Templates History:",
    "LabelInformationFields": "Label Fields",
    "Labels": "Labels",
    "Language": "Language",
    "Last": "Last",
    "Last Cartons: {total}": "Last Cartons: {total}",
    "Last Name": "Last Name",
    "Last Reported": "Last Reported",
    "Last30Days": "Last 30 Days",
    "Last7Days": "Last 7 Days",
    "Latch": "Latch",
    "Latch Minimum Distance": "Latch Minimum Distance",
    "Leave": "Leave",
    "Leave Create Packaging Solution?": "Leave Create Packaging Solution?",
    "Leave Edit Packaging Solution?": "Leave Edit Packaging Solution?",
    "Leave Machine Group": "Leave Machine Group",
    "Leave Page": "Leave Page",
    "Leave Scan Import?": "Leave Scan Import?",
    "Leave Service Mode": "Leave Service Mode",
    "Leave unsaved data confirmation": "If you leave before saving, your changes will be lost. Are you sure?",
    "Leave without saving?": "Leave without saving?",
    "Left Side To Tool": "Left Side to Tool",
    "Left Side To Waste Separator": "Left Side To Waste Separator",
    "Left Side to Sensor Pin": "Left Side to Sensor Pin",
    "Legacy": "Legacy",
    "Length": "Length",
    "Length:": "Length:",
    "LessThan": "Less Than",
    "LessThanOrEqual": "Less Than Or Equal",
    "LicensePlateNumber": "License Plate Number",
    "LicensePlateNumberPrompt": "Scan License Plate Number",
    "Licensing Client Connected": "Licensing Client Connected",
    "Licensing Client Disconnected": "Licensing Client Disconnected",
    "Licensing Server Connected": "Licensing Server Connected",
    "Licensing Server Disconnected": "Licensing Server Disconnected",
    "Light Barrier Broken": "Error",
    "Link label to Carton": "Link label to Carton",
    "Load": "Load",
    "Loaded": "Loaded",
    "Loaded On": "Loaded On",
    "Loaded z-Fold": "Loaded z-Fold",
    "Loading": "Loading",
    "Location": "Location",
    "Location:": "Location:",
    "Logging In": "Logging In",
    "Logging Out": "Logging Out",
    "LoggingIn": "Logging in",
    "Login": "Login",
    "Login Error": "Login Error",
    "Login Failure: Configuration": "SSO Configuration error, please notify your IT admin to resolve this issue",
    "LoginError": "Login Error",
    "Logout": "Logout",
    "Long": "Long",
    "Long Head Tool Width": "Long Head Tool Width",
    "Long Head Type": "Long Head Type",
    "Long Heads": "Long Heads",
    "LongHead Width": "LongHead Width",
    "LongHead {longheadNumber}": "LongHead {longheadNumber}",
    "LongHeads": "LongHeads",
    "Longhead Sensor to Tool": "Longhead Sensor to Tool",
    "Lowering Offset": "Lowering Offset",
    "M1": "M1",
    "MG:": "MG:",
    "MPN": "MPN",
    "MQTT": "MQTT",
    "Machine": "Machine",
    "Machine Accessories": "Machine Accessories",
    "Machine Control": "Machine Control",
    "Machine Error": "Machine Error",
    "Machine Error Occurred": "Machine Error Occurred",
    "Machine Group": "Machine Group",
    "Machine Group Changing Corrugate": "Paused",
    "Machine Group History:": "Machine Group History:",
    "Machine Group Status": "Machine Group Status",
    "Machine Group:": "Machine Group:",
    "Machine Group: {machineGroupName}": "Machine Group: {machineGroupName}",
    "Machine Groups": "Machine Groups",
    "Machine History": "Machine History",
    "Machine History:": "Machine History:",
    "Machine Initializing": "Machine Initializing",
    "Machine Name": "Machine Name",
    "Machine Offline": "Machine Offline",
    "Machine Paused": "Machine Paused",
    "Machine Production Completed": "Machine Production Completed",
    "Machine Selected": "{number} Machine Selected",
    "Machine Service": "Machine Service",
    "Machine Software Updates": "Machine Software Updates",
    "Machine Status": "Machine Status",
    "Machine Status Changed": "Machine Status Changed",
    "Machine Status by Hour by {machineName}": "Machine Status by Hour - {machineName}",
    "Machine Type": "Machine Type",
    "Machine Types:": "Machine Types:",
    "Machine Utilization": "Machine Utilization",
    "Machine does not have a service mode": "Machine does not have a service mode",
    "Machine group is in error mode": "Machine group is in error mode",
    "Machine group is initializing": "Machine group is initializing",
    "Machine group is not currently online, verify the machines are online": "Machine group is not currently online, verify the machines are online",
    "Machine group is online but paused, press play to start receiving jobs": "Machine group is online but paused, press play to start receiving jobs",
    "Machine group was found in database cache in {time}ms": "Machine group was found in database cache in {time}ms",
    "Machine group:": "Machine group:",
    "Machine is in Service Mode": "Machine is in Service Mode",
    "Machine is in error mode, reset the machine and try again": "Machine is in error mode, reset the machine and try again",
    "Machine is in service mode": "Machine is in service mode",
    "Machine is in service mode, changing z-Fold is not possible at this time": "Machine is in service mode, changing z-Fold is not possible at this time",
    "Machine is initializing, please wait": "Machine is initializing, please wait",
    "Machine is online, press pause to change z-Fold": "Machine is online, press pause to change z-Fold",
    "Machine must be paused to manually control": "Machine must be paused to manually control",
    "Machine was found in database cache in {time}ms": "Machine was found in database cache in {time}ms",
    "Machine(s)": "Machine(s)",
    "Machine: {machine}": "Machine: {machine}",
    "MachineChangingCorrugate": "Changing Corrugate",
    "MachineEmergencyStop": "Emergency Stop",
    "MachineErrorOccurred": "Machine Error Occurred",
    "MachineGroup": "Machine Group",
    "MachineGroupOffline": "Offline",
    "MachineOffline": "Offline",
    "MachineService": "Machine Service Mode",
    "MachineStatusChanged": "Machine Status Changed",
    "Machines": "Machines",
    "Machines Out Of Material": "Machines Out Of Material",
    "Machines Selected": "{number} Machines Selected",
    "Machines are paused, ensure all machines are online and ready": "Machines are paused, ensure all machines are online and ready",
    "Machines of type: {types} cannot produce job due to missing label value(s)": "Machines of type: {types} cannot produce job due to missing label value(s)",
    "Machines of type: {types} cannot produce job due to missing verification code(s) for label(s)": "Machines of type: {types} cannot produce job due to missing verification code(s) for label(s)",
    "Machines of type: {types} cannot produce jobs with multiple cartons": "Machines of type: {types} cannot produce jobs with multiple cartons",
    "Machines:": "Machines:",
    "Maintenance": "Maintenance",
    "Make this my default design": "Make this my default design",
    "Manage Carton Property Groups": "Manage Carton Property Groups",
    "Manage Classifications": "Manage Classifications",
    "Manage Designs": "Manage Designs",
    "Manage Dimensioning Configuration": "Manage Dimensioning Configuration",
    "Manage Dimensioning Products": "Manage Dimensioning Products",
    "Manage Import Pipelines": "Manage Import Pipelines",
    "Manage Label Templates": "Manage Label Templates",
    "Manage Machine Groups": "Manage Machine Groups",
    "Manage Machines": "Manage Machines",
    "Manage Packaging Solutions": "Manage Packaging Solutions",
    "Manage Packstations": "Manage Packstations",
    "Manage Pick Zones": "Manage Pick Zones",
    "Manage Planned Production": "Manage Planned Production",
    "Manage Print Campaigns": "Manage Print Campaigns",
    "Manage Print Images": "Manage Print Images",
    "Manage Production Groups": "Manage Production Groups",
    "Manage Roles": "Manage Roles",
    "Manage Settings": "Manage Settings",
    "Manage Users": "Manage Users",
    "Manage z-Fold": "Manage z-Fold",
    "Manual Cartons": "Manual Cartons",
    "Manual Import": "Manual Import",
    "Manual Scan to Create": "Manual Scan to Create",
    "Manual Scan to Trigger": "Manual Scan to Trigger",
    "Manual scan to produce": "Manual scan to produce",
    "Manufacturer": "Manufacturer",
    "Mapped to: Multiple": "Mapped to: Multiple",
    "Mapped to: {field}": "Mapped to: {field}",
    "Mapping": "Mapping",
    "March": "March",
    "MatchingCarton": "Matching Carton",
    "MatchingLabel": "Matching Label",
    "Material:": "Material:",
    "MaterialUsage": "Material Usage",
    "Materials": "Materials",
    "Max Tile Count": "Max Tile Count",
    "Maximum Acceleration": "Maximum Acceleration",
    "Maximum Deceleration": "Maximum Deceleration",
    "Maximum Position": "Maximum Position",
    "Maximum Speed": "Maximum Speed",
    "May": "May",
    "Message Expires After (Seconds)": "Message Expires After (Seconds)",
    "Message Types": "Message Types",
    "Metrics": "Metrics",
    "Millimeters": "Millimeters",
    "Minimum Line Distance To z-Fold Edge": "Minimum Line Distance To z-Fold Edge",
    "Minimum Line Length": "Minimum Line Length",
    "Minimum Position": "Minimum Position",
    "Minimum length of 8": "Minimum length of 8",
    "Mins": "Mins",
    "Missing track settings settings is loaded from machine at startup": "Missing track settings, settings is loaded from machine at startup.",
    "Missing track width": "Missing track width",
    "MissingCartonDimensions": "Missing Carton Dimensions",
    "MissingDimensions": "Missing Dimensions",
    "MissingProductIdentifiers": "Missing Product Identifiers",
    "Model Number": "Model Number",
    "Modify Packaging Solution Before Producing": "Modify Packaging Solution Before Producing",
    "Monday": "Monday",
    "Monday Abbrev": "MON",
    "Monitored Folder Access": "Monitored Folder Access",
    "Monitored Folder Name": "Monitored Folder Name",
    "More Details": "More Details",
    "Move Machine Groups": "Move Machine Groups",
    "MoveToSingleScan": "Move to Single Scan?",
    "MoveToSingleScanDescription": "Unsent work will not be saved",
    "MoveToSingleScanDisto": "Move to Single Scan for Disto?",
    "Multiple": "Multiple",
    "Multiple Choice Label": "Multiple Choice Label",
    "Multiple Machines in machine group that can change corrugates": "Multiple Machines in machine group that can change corrugates",
    "Multiple Scan": "Multiple Scan",
    "Multiple Sites Selected": "Multiple Sites Selected",
    "Must be configured in import selection": "Must be configured in import selection",
    "Must contain lowercase characters": "Must contain lowercase characters",
    "Must contain numbers": "Must contain numbers",
    "Must contain symbols": "Must contain symbols",
    "Must contain uppercase characters": "Must contain uppercase characters",
    "My Materials": "My Materials",
    "NOTE Only cubic areas can be measured No curved or sphere shapes can be measured See examples to the right": "NOTE:Only cubic areas can be measured. No curved or sphere shapes can be measured. See examples to the right.",
    "Name": "Name",
    "Name Is Already Taken": "Name Is Already Taken",
    "Name Your Carton": "Name Your Carton",
    "Name Your Label": "Name Your Label",
    "Name is required": "Name is required",
    "Name must be unique": "Name must be unique",
    "NameNewRulePlaceholder": "Rule Name",
    "Nesting": "Nesting",
    "Network Scanner": "Network Scanner",
    "Never": "Never",
    "New Carton Designs": "New Carton Designs",
    "New Carton Property Group": "New Carton Property Group",
    "New Classification": "New Classification",
    "New Design Just Added": "New Design Just Added",
    "New Event Notification": "New Event Notification",
    "New Label Template": "New Label Template",
    "New Machine": "New Machine",
    "New Machine Group": "New Machine Group",
    "New Packaging Solution": "New Packaging Solution",
    "New Pick Zone": "New Pick Zone",
    "New Production Group": "New Production Group",
    "New Role": "New Role",
    "New User": "New User",
    "New name is required": "New name is required.",
    "New z-Fold": "New z-Fold",
    "NewCart": "New Cart",
    "NewCartMessage01": "It looks like you don't have any connected carts",
    "NewCartMessage02": "Select 'Add New' to get started",
    "NewValue": "New Value",
    "Next": "Next",
    "No": "No",
    "No Actions Found": "No Actions Found",
    "No Cartons Have Been Produced": "No Cartons Have Been Produced",
    "No Data": "No Data",
    "No Data Exists": "No Data Exists",
    "No Image Avaliable": "No Image Avaliable",
    "No IoT devices registered": "No IoT devices registered",
    "No Jobs Remaining to Remove": "No Jobs Remaining to Remove",
    "No Jobs Remaining to Trigger": "No Jobs Remaining to Trigger",
    "No Link Selected": "No Link Selected",
    "No Machines in machine group that can change corrugates": "No Machines in machine group that can change corrugates",
    "No OEE Data Available": "No OEE Data Available",
    "No Preference": "No Preference",
    "No Preview Available": "No Preview Available",
    "No Production Options Exist": "No Production Options Exist",
    "No Results": "No Results",
    "No audit history exists for this item": "No audit history exists for this item",
    "No data for this time frame": "No data for this time frame",
    "No headers declared": "No headers declared",
    "No import rules have been defined": "No import rules have been defined",
    "No jobs available to reproduce": "No jobs available to reproduce",
    "No machine groups assigned": "No machine groups assigned",
    "No preview available for external files": "No preview available for external files",
    "No roller on track TRACK": "No roller on track {track}",
    "No search results were found": "No search results were found",
    "No secure headers found": "No secure headers found",
    "No sites configured": "No sites configured",
    "No sites selected": "No sites selected",
    "No, it cannot contain other products": "No, it cannot contain other products",
    "No, the product cannot nest": "No, the product cannot nest",
    "NoBlankOptions": "You cannot have any blank option choices.",
    "NoCartonsDefined": "No carton defined for dimensioning action",
    "NoComponents": "This job has no components to produce",
    "NoDuplicateOptions": "All option choices must be unique.",
    "NoImportPipelineConfigurationExists": "No import pipeline configuration exists",
    "NoMachinesConfiguredToProduceDesign": "Machine is not configured to produce this design",
    "NoPgOrCpg": "No production group or carton property groups were assigned",
    "NoProductionOptions": "No production options could be calculated for a component",
    "NoWorkSelected": "NoWorkSelected",
    "NoZplValueSupplied": "No ZPL value supplied for label",
    "Non Optimal": "Non Optimal",
    "Non-optimal": "Non-optimal",
    "None": "None",
    "Normal": "Normal",
    "North America": "North America",
    "Not Assigned To Production Group": "Not Assigned To Production Group",
    "Not Available": "Not Available",
    "Not Loaded": "Not Loaded",
    "Not Optimal": "Not Optimal",
    "NotANumber": "{value} is not a number",
    "NotFound": "Not found",
    "NotFoundDescription": "There is no match for {productId}",
    "NotFoundScanCode": "Not found: {scanCode}",
    "Note Sort in order of greatest importance": "Note: Sort in order of greatest importance.",
    "Note This printer is configured in the machine PLC": "Note This printer is configured in the machine PLC",
    "Note This printer type requires configuration in the machine PLC": "Note: This printer type requires configuration in the machine PLC",
    "Notes": "Notes",
    "Nothing Selected": "Nothing Selected",
    "Notifications about cartons - imported, completed, etc": "Notifications about cartons - imported, completed, etc",
    "Notifications about changes in a machine group's status - Production Enabled (true or false) and Status": "Notifications about changes in a machine group's status - Production Enabled (true or false) and Status",
    "Notifications when packaging solutions are imported": "Notifications when packaging solutions are imported",
    "Notifications when packaging solutions are produced": "Notifications when packaging solutions are produced",
    "Notifications when packaging solutions are retracted": "Notifications when packaging solutions are retracted",
    "Notifications when packaging solutions are selected": "Notifications when packaging solutions are selected",
    "Notifications when packaging solutions are staged": "Notifications when packaging solutions are staged",
    "Notifications when packaging solutions fail to be imported": "Notifications when packaging solutions fail to be imported",
    "Notifications when packaging solutions fail to be staged": "Notifications when packaging solutions fail to be staged",
    "November": "November",
    "Number": "Number",
    "Number of tracks": "Number of tracks",
    "NumberEqual": "Equals",
    "NumberEqualLabel": "Equals (number)",
    "NumberNotEqual": "Does Not Equal",
    "NumberNotEqualLabel": "Does Not Equal (number)",
    "NumberOfBoxes": "Number of boxes to be produced:",
    "Numeric": "Number",
    "OAuth": "OAuth",
    "OEE": "OEE",
    "OEE Over Time": "OEE Over Time",
    "OK": "OK",
    "October": "October",
    "Of": "Of",
    "Off": "Off",
    "Offline": "Offline",
    "Offset Tolerance": "Offset Tolerance",
    "Offset can't be larger than maximum position on track TRACK": "Offset can't be larger than maximum position on track {track}",
    "Offset can't be lower than minimum position": "Offset can't be lower than minimum longhead position",
    "Offset can't be lower than minimum position on track TRACK": "Offset can't be lower than minimum position on track {track}",
    "Offset is outside the machine boundaries": "Offset is outside the machine boundaries",
    "Offset must be a number": "Offset must be a number",
    "Okay": "Okay",
    "Oldest Item": "Oldest Item",
    "On": "On",
    "On It's Back": "On It's Back",
    "On It's Side": "On It's Side",
    "On Its Back": "On It's Back",
    "On Its Side": "On It's Side",
    "OnItsBack": "On It's Back",
    "OnItsSide": "On It's Side",
    "One to Many": "One to Many",
    "Online": "Online",
    "Only PDF files are valid for upload": "Only PDF files are valid for upload",
    "OnlyFailedProductionOptions": "All production options are invalid for a component",
    "Oops We can't find anything": "Oops, We can't find anything.",
    "Open": "Open",
    "Open Cartons": "Open Cartons",
    "Open Orders": "Open Orders",
    "Open or produced work": "Open or produced work",
    "Open work": "Open work",
    "Operator": "Operator",
    "Operator Efficiency": "Operator Efficiency",
    "Operator Permissions": "Operator Permissions",
    "Optimal": "Optimal",
    "Optimal Production": "Optimal Production",
    "Optimal Rotation": "Optimal Rotation",
    "Optimal:": "Optimal:",
    "Option": "Option {num}",
    "Optional Settings": "Optional Settings",
    "Or": "Or",
    "Order": "Order",
    "Orders": "Orders",
    "Orders Raw Data": "Orders Raw Data",
    "Orientation": "Orientation",
    "Orientations:": "Orientations:",
    "Other Time Zones": "Other Time Zones",
    "Out of Corrugate Position": "Out of Corrugate Position",
    "OutOfLabels": "Out Of Labels",
    "Outcome": "Outcome",
    "Outfeed Length": "Outfeed Length",
    "Outside Planned Production Window": "Outside Planned Production Window",
    "Over Time": "Over Time",
    "Overall Equipment Effectiveness": "Overall Equipment Effectiveness",
    "Overlapped Schedules": "Selection overlaps with other Planned Production schedules",
    "Overview": "Overview",
    "PG:": "PG:",
    "PLC Model": "PLC Model",
    "PLC Version:": "PLC Version:",
    "Pack Station: {packStation}": "Pack Station: {packStation}",
    "Pack Stations": "Pack Stations",
    "PackNet Field": "PackNet Field",
    "PackStation": "Pack Station",
    "PackTags": "PackTags",
    "Package Title": "Package Title",
    "Packaging Creation": "Packaging Creation",
    "Packaging Design": "Packaging Design",
    "Packaging Design Id": "Packaging Design ID",
    "Packaging Solution": "Packaging Solution",
    "Packaging Solution - Import Failed Status": "Packaging Solution - Import Failed Status",
    "Packaging Solution - Imported Status": "Packaging Solution - Imported Status",
    "Packaging Solution - Produced Status": "Packaging Solution - Produced Status",
    "Packaging Solution - Retracted Status": "Packaging Solution - Retracted Status",
    "Packaging Solution - Selected Status": "Packaging Solution - Selected Status",
    "Packaging Solution - Staged Status": "Packaging Solution - Staged Status",
    "Packaging Solution - Staging Failed Status": "Packaging Solution - Staging Failed Status",
    "Packaging Solution Status": "Packaging Solution Status",
    "PackagingMachineOutOfCorrugate": "Packaging Machine Out Of Corrugate",
    "PackagingSolutionContainsNoCartonsOrLabels": "Packaging Solution Contains No Cartons Or Labels",
    "Packing Station": "Packing Station",
    "Packing Stations": "Packing Stations",
    "PackingStation": "Packing Station",
    "Packsize": "Packsize",
    "Packsize Now!": "Packsize Now!",
    "PacksizePackagingAddressLookupError": "Address Lookup Error",
    "PacksizePackagingCartonDetectedInRollersTracks1and3": "Carton Detected In Rollers Tracks1and3",
    "PacksizePackagingCartonDetectedInRollersTracks2and4": "Carton Detected In Rollers Tracks2and4",
    "PacksizePackagingCartonDroppedInsideMachineTracks1and3": "Carton Dropped Inside Machine Tracks1and3",
    "PacksizePackagingCartonDroppedInsideMachineTracks2and4": "Carton Dropped Inside Machine Tracks2and4",
    "PacksizePackagingCartonNotDetectedWhenGrabberInsertsTracks1And3": "Carton Not Detected When Grabber Inserts Tracks1 And3",
    "PacksizePackagingCartonNotDetectedWhenGrabberInsertsTracks2And4": "Carton Not Detected When Grabber Inserts Tracks2 And4",
    "PacksizePackagingCorrugateLoadedOnUnusedTrack": "Corrugate Loaded On Unused Track",
    "PacksizePackagingGrabberCalibrationFailureError": "Calibration Failure Error",
    "PacksizePackagingGrabberInvalidTorqueDetected": "Invalid Torque Detected",
    "PacksizePackagingMachineErrorAirLeakage": "Air Leakage",
    "PacksizePackagingMachineErrorAirPressureLow": "Air Pressure Low",
    "PacksizePackagingMachineErrorApplicatorNotInRunMode": "Applicator Not In Run Mode",
    "PacksizePackagingMachineErrorApplicatorNotReady": "Applicator Not Ready",
    "PacksizePackagingMachineErrorAxisFailedToHomeInTime": "Axis Failed To Home In Time",
    "PacksizePackagingMachineErrorBoxLimitExceeded": "Box Limit Exceeded",
    "PacksizePackagingMachineErrorBoxPickedUpLabelNotApplied": "Box Picked Up Label Not Applied",
    "PacksizePackagingMachineErrorBoxSpinner": "Box Spinner",
    "PacksizePackagingMachineErrorBoxSpinnerAdjustGlueGun": "Box Spinner Adjust Glue Gun",
    "PacksizePackagingMachineErrorBoxSpinnerAdjustWall": "Box Spinner Adjust Wall",
    "PacksizePackagingMachineErrorBoxSpinnerAxisNotInSync": "Box Spinner Axis Not In Sync",
    "PacksizePackagingMachineErrorBoxSpinnerBoxFellOff": "Box Spinner Box Fell Off",
    "PacksizePackagingMachineErrorBoxSpinnerFormatLength": "Box Spinner Format Length",
    "PacksizePackagingMachineErrorBoxSpinnerFormatWidth": "Box Spinner Format Width",
    "PacksizePackagingMachineErrorBoxSpinnerGlueUnitError": "Box Spinner Glue Unit Error",
    "PacksizePackagingMachineErrorBoxSpinnerLengtAxisFault": "Box Spinner Lengt Axis Fault",
    "PacksizePackagingMachineErrorBoxSpinnerLowAirPressure": "Box Spinner Low Air Pressure",
    "PacksizePackagingMachineErrorBoxSpinnerOutFeed": "Box Spinner Out Feed",
    "PacksizePackagingMachineErrorBoxSpinnerOutFeedLost": "Box Spinner Out Feed Lost",
    "PacksizePackagingMachineErrorBoxSpinnerPressSupport": "Box Spinner Press Support",
    "PacksizePackagingMachineErrorBoxSpinnerTrigSensorCovered": "Box Spinner Trig Sensor Covered",
    "PacksizePackagingMachineErrorBoxSpinnerUAxis": "Box Spinner UAxis",
    "PacksizePackagingMachineErrorBoxSpinnerVacuumGripperMotion": "Box Spinner Vacuum Gripper Motion",
    "PacksizePackagingMachineErrorBoxSpinnerVirtualAxisError": "Box Spinner Virtual Axis Error",
    "PacksizePackagingMachineErrorBoxSpinnerWallLift": "Box Spinner Wall Lift",
    "PacksizePackagingMachineErrorBoxSpinnerWidthAxisFault": "Box Spinner Width Axis Fault",
    "PacksizePackagingMachineErrorBoxSpinnerXAxis": "Box Spinner XAxis",
    "PacksizePackagingMachineErrorBoxSpinnerYAxis": "Box Spinner YAxis",
    "PacksizePackagingMachineErrorBoxSpinnerZAxis": "Box Spinner ZAxis",
    "PacksizePackagingMachineErrorCam": "Cam",
    "PacksizePackagingMachineErrorCannotProduceBox": "Cannot Produce Box",
    "PacksizePackagingMachineErrorCantExecuteInstruction": "Cant Execute Instruction",
    "PacksizePackagingMachineErrorCantReceiveNewInstructionEntry": "Cant Receive New Instruction Entry",
    "PacksizePackagingMachineErrorCantReceiveNewInstructionList": "Cant Receive New Instruction List",
    "PacksizePackagingMachineErrorCartonNotDetectedAtPusher": "Carton Not Detected At Pusher",
    "PacksizePackagingMachineErrorChangeCorrugateLeftFailed": "Change Corrugate Left Failed",
    "PacksizePackagingMachineErrorChangeCorrugateRightFailed": "Change Corrugate Right Failed",
    "PacksizePackagingMachineErrorChanger": "Changer",
    "PacksizePackagingMachineErrorChangerCasetteLeftServoError": "Changer Casette Left Servo Error",
    "PacksizePackagingMachineErrorChangerCasetteOrElevatorPositionDiffError": "Changer Casette Or Elevator Position Diff Error",
    "PacksizePackagingMachineErrorChangerCasetteRightServoError": "Changer Casette Right Servo Error",
    "PacksizePackagingMachineErrorChangerCorrugatedNotReleasedFromMachine": "Changer Corrugated Not Released From Machine",
    "PacksizePackagingMachineErrorChangerElevatorLeftServoError": "Changer Elevator Left Servo Error",
    "PacksizePackagingMachineErrorChangerElevatorRightServoError": "Changer Elevator Right Servo Error",
    "PacksizePackagingMachineErrorChangerLagErrorTrack2": "Changer Lag Error Track2",
    "PacksizePackagingMachineErrorChangerLagErrorTrack3": "Changer Lag Error Track3",
    "PacksizePackagingMachineErrorChangerLagErrorTrack4": "Changer Lag Error Track4",
    "PacksizePackagingMachineErrorChangerNotPossibleToStartElevatorCalibration": "Changer Not Possible To Start Elevator Calibration",
    "PacksizePackagingMachineErrorChangerServoErrorLift": "Changer Servo Error Lift",
    "PacksizePackagingMachineErrorChangerServoErrorTrack1": "Changer Servo Error Track1",
    "PacksizePackagingMachineErrorChangerServoErrorTrack2": "Changer Servo Error Track2",
    "PacksizePackagingMachineErrorChangerServoErrorTrack3": "Changer Servo Error Track3",
    "PacksizePackagingMachineErrorChangerServoErrorTrack4": "Changer Servo Error Track4",
    "PacksizePackagingMachineErrorChangerTestError": "Changer Test Error",
    "PacksizePackagingMachineErrorChangerTimeout": "Changer Timeout",
    "PacksizePackagingMachineErrorChangingCorrugateFailedAtLeftSide": "Changing Corrugate Failed At Left Side",
    "PacksizePackagingMachineErrorChangingCorrugateFailedAtRightSide": "Changing Corrugate Failed At Right Side",
    "PacksizePackagingMachineErrorCleanCutChanger": "Clean Cut Changer",
    "PacksizePackagingMachineErrorCleancutTimeout": "Cleancut Timeout",
    "PacksizePackagingMachineErrorCommunicationError": "Communication Error",
    "PacksizePackagingMachineErrorConnectionProblem": "Connection Problem",
    "PacksizePackagingMachineErrorConveyorStopped": "Conveyor Stopped",
    "PacksizePackagingMachineErrorCorrugateDetectedAtBoxOffload": "Corrugate Detected At Box Offload",
    "PacksizePackagingMachineErrorCorrugateDetectedAtOutfeedOfConverter": "Corrugate Detected At Outfeed Of Converter",
    "PacksizePackagingMachineErrorCorrugateDetectedInMultipleTracks": "Corrugate Detected In Multiple Tracks",
    "PacksizePackagingMachineErrorCorrugateDidNotUnload": "Corrugate Did Not Unload",
    "PacksizePackagingMachineErrorCorrugateFailedToLoad": "Corrugate Failed To Load",
    "PacksizePackagingMachineErrorCorrugateGuideQuantity": "Corrugate Guide Quantity",
    "PacksizePackagingMachineErrorCorrugateMismatch": "Corrugate Mismatch",
    "PacksizePackagingMachineErrorCorrugateNotDetectedAtBoxOffload": "Corrugate Not Detected At Box Offload",
    "PacksizePackagingMachineErrorCorrugateNotDetectedAtOutfeedOfConverter": "Corrugate Not Detected At Outfeed Of Converter",
    "PacksizePackagingMachineErrorCouldNotCompleteFeederOperation": "Could Not Complete Feeder Operation",
    "PacksizePackagingMachineErrorCrossHeadPositionOutsideOfTolerance": "Cross Head Position Outside Of Tolerance",
    "PacksizePackagingMachineErrorCrossheadHomeBlockNotDetectedAfterHardStopFound": "Crosshead Home Block Not Detected After Hard Stop Found",
    "PacksizePackagingMachineErrorCrossheadServoError": "Crosshead Servo Error",
    "PacksizePackagingMachineErrorCutCrease": "Cut Crease",
    "PacksizePackagingMachineErrorCutCreaseClearCorrugateSensor": "Cut Crease Clear Corrugate Sensor",
    "PacksizePackagingMachineErrorCutCreaseCorrugateNotRetracted": "Cut Crease Corrugate Not Retracted",
    "PacksizePackagingMachineErrorCutCreaseServoErrorToolAxis": "Cut Crease Servo Error Tool Axis",
    "PacksizePackagingMachineErrorCutCreaseTestError": "Cut Crease Test Error",
    "PacksizePackagingMachineErrorCutSmallerThanKnife": "Cut Smaller Than Knife",
    "PacksizePackagingMachineErrorDigitalOutputShortOrOverloadDetected": "Digital Output Short Or Overload Detected",
    "PacksizePackagingMachineErrorEepromChecksum": "Eeprom Checksum",
    "PacksizePackagingMachineErrorEepromToolheadChecksum": "Eeprom Toolhead Checksum",
    "PacksizePackagingMachineErrorEmergencyStopActive": "Emergency Stop Active",
    "PacksizePackagingMachineErrorEncoderBatteryFault": "Encoder Battery Fault",
    "PacksizePackagingMachineErrorEncoderError": "Encoder Error",
    "PacksizePackagingMachineErrorErrorPackNetTestError": "Error Pack Net Test Error",
    "PacksizePackagingMachineErrorEstopActivated": "Estop Activated",
    "PacksizePackagingMachineErrorEtherCatError": "Ether Cat Error",
    "PacksizePackagingMachineErrorEthercatBusMissing": "Ethercat Bus Missing",
    "PacksizePackagingMachineErrorEthercatBusNotRunning": "Ethercat Bus Not Running",
    "PacksizePackagingMachineErrorExternalStopSignal": "External Stop Signal",
    "PacksizePackagingMachineErrorFailedToExtendError": "Failed To Extend Error",
    "PacksizePackagingMachineErrorFailedToRetractError": "Failed To Retract Error",
    "PacksizePackagingMachineErrorFaultyInstructionEntry": "Faulty Instruction Entry",
    "PacksizePackagingMachineErrorFeeder": "Feeder",
    "PacksizePackagingMachineErrorFeederServoError": "Feeder Servo Error",
    "PacksizePackagingMachineErrorFeederTestError": "Feeder Test Error",
    "PacksizePackagingMachineErrorFeedoutTimeout": "Feedout Timeout",
    "PacksizePackagingMachineErrorFlapcut": "Flapcut",
    "PacksizePackagingMachineErrorFlapcutServoErrorAdjustFlapcut": "Flapcut Servo Error Adjust Flapcut",
    "PacksizePackagingMachineErrorFlapcutServoErrorTool": "Flapcut Servo Error Tool",
    "PacksizePackagingMachineErrorFlapcutTestError": "Flapcut Test Error",
    "PacksizePackagingMachineErrorFlapsDownAirPressureLoss": "Flaps Down Air Pressure Loss",
    "PacksizePackagingMachineErrorFlapsDownBandHomeNotCompletedSuccessfully": "Flaps Down Band Home Not Completed Successfully",
    "PacksizePackagingMachineErrorFlapsDownBander": "Flaps Down Bander",
    "PacksizePackagingMachineErrorFlapsDownBanderArm1Extend": "Flaps Down Bander Arm1 Extend",
    "PacksizePackagingMachineErrorFlapsDownBanderArm1Retract": "Flaps Down Bander Arm1 Retract",
    "PacksizePackagingMachineErrorFlapsDownBanderArm2Extend": "Flaps Down Bander Arm2 Extend",
    "PacksizePackagingMachineErrorFlapsDownBanderArm2Retract": "Flaps Down Bander Arm2 Retract",
    "PacksizePackagingMachineErrorFlapsDownBanderError": "Flaps Down Bander Error",
    "PacksizePackagingMachineErrorFlapsDownBanderNotInRunMode": "Flaps Down Bander Not In Run Mode",
    "PacksizePackagingMachineErrorFlapsDownBanderZ": "Flaps Down Bander Z",
    "PacksizePackagingMachineErrorFlapsDownBandingLiftMotorAFaulted": "Flaps Down Banding Lift Motor AFaulted",
    "PacksizePackagingMachineErrorFlapsDownBandingLiftMotorANotRef": "Flaps Down Banding Lift Motor ANot Ref",
    "PacksizePackagingMachineErrorFlapsDownBandingLiftMotorBFaulted": "Flaps Down Banding Lift Motor BFaulted",
    "PacksizePackagingMachineErrorFlapsDownBandingLiftMotorBNotRef": "Flaps Down Banding Lift Motor BNot Ref",
    "PacksizePackagingMachineErrorFlapsDownBoxDataNotReceived": "Flaps Down Box Data Not Received",
    "PacksizePackagingMachineErrorFlapsDownBoxNotClearedFromFlapsDown": "Flaps Down Box Not Cleared From Flaps Down",
    "PacksizePackagingMachineErrorFlapsDownBoxNotPresentAtInfeed": "Flaps Down Box Not Present At Infeed",
    "PacksizePackagingMachineErrorFlapsDownCommWithX5Lost": "Flaps Down Comm With X5 Lost",
    "PacksizePackagingMachineErrorFlapsDownConveyorTimeout": "Flaps Down Conveyor Timeout",
    "PacksizePackagingMachineErrorFlapsDownDisableAllNotCompletedSuccessfullyDuringServiceModeCompleting": "Flaps Down Disable All Not Completed Successfully During Service Mode Completing",
    "PacksizePackagingMachineErrorFlapsDownDisableAllNotCompletedSuccessfullyDuringServiceModeStopping": "Flaps Down Disable All Not Completed Successfully During Service Mode Stopping",
    "PacksizePackagingMachineErrorFlapsDownEnableAllNotCompletedSuccessfullyDuringOperationMode": "Flaps Down Enable All Not Completed Successfully During Operation Mode",
    "PacksizePackagingMachineErrorFlapsDownEnableAllNotCompletedSuccessfullyDuringServiceModeStarting": "Flaps Down Enable All Not Completed Successfully During Service Mode Starting",
    "PacksizePackagingMachineErrorFlapsDownFlapsX": "Flaps Down Flaps X",
    "PacksizePackagingMachineErrorFlapsDownFlapsXAxisDriveFaulted": "Flaps Down Flaps XAxis Drive Faulted",
    "PacksizePackagingMachineErrorFlapsDownFlapsXAxisNotRef": "Flaps Down Flaps XAxis Not Ref",
    "PacksizePackagingMachineErrorFlapsDownFlapsY": "Flaps Down Flaps Y",
    "PacksizePackagingMachineErrorFlapsDownFlapsYAxisDriveFaulted": "Flaps Down Flaps YAxis Drive Faulted",
    "PacksizePackagingMachineErrorFlapsDownFlapsYAxisNotRef": "Flaps Down Flaps YAxis Not Ref",
    "PacksizePackagingMachineErrorFlapsDownFolderExtend": "Flaps Down Folder Extend",
    "PacksizePackagingMachineErrorFlapsDownFolderRetract": "Flaps Down Folder Retract",
    "PacksizePackagingMachineErrorFlapsDownGantryX": "Flaps Down Gantry X",
    "PacksizePackagingMachineErrorFlapsDownGantryY": "Flaps Down Gantry Y",
    "PacksizePackagingMachineErrorFlapsDownGantryZ": "Flaps Down Gantry Z",
    "PacksizePackagingMachineErrorFlapsDownHomeCompleteButBanderAxesNotInSync": "Flaps Down Home Complete But Bander Axes Not In Sync",
    "PacksizePackagingMachineErrorFlapsDownHomingFail": "Flaps Down Homing Fail",
    "PacksizePackagingMachineErrorFlapsDownJamAtConveyor1": "Flaps Down Jam At Conveyor1",
    "PacksizePackagingMachineErrorFlapsDownJamAtConveyor2": "Flaps Down Jam At Conveyor2",
    "PacksizePackagingMachineErrorFlapsDownJamAtConveyor3": "Flaps Down Jam At Conveyor3",
    "PacksizePackagingMachineErrorFlapsDownJamAtConveyor4": "Flaps Down Jam At Conveyor4",
    "PacksizePackagingMachineErrorFlapsDownJamInZone1": "Flaps Down Jam In Zone1",
    "PacksizePackagingMachineErrorFlapsDownJamInZone2": "Flaps Down Jam In Zone2",
    "PacksizePackagingMachineErrorFlapsDownJamInZone3": "Flaps Down Jam In Zone3",
    "PacksizePackagingMachineErrorFlapsDownJamInZone4": "Flaps Down Jam In Zone4",
    "PacksizePackagingMachineErrorFlapsDownJamOnHandOff": "Flaps Down Jam On Hand Off",
    "PacksizePackagingMachineErrorFlapsDownLabelApply": "Flaps Down Label Apply",
    "PacksizePackagingMachineErrorFlapsDownLabelApplyNotReady": "Flaps Down Label Apply Not Ready",
    "PacksizePackagingMachineErrorFlapsDownLabelApplyTimeout": "Flaps Down Label Apply Timeout",
    "PacksizePackagingMachineErrorFlapsDownLabelOnPrintPad": "Flaps Down Label On Print Pad",
    "PacksizePackagingMachineErrorFlapsDownLabelerInError": "Flaps Down Labeler In Error",
    "PacksizePackagingMachineErrorFlapsDownLabelsOut": "Flaps Down Labels Out",
    "PacksizePackagingMachineErrorFlapsDownMajorXAxisDriveFaulted": "Flaps Down Major XAxis Drive Faulted",
    "PacksizePackagingMachineErrorFlapsDownMajorXAxisNotRef": "Flaps Down Major XAxis Not Ref",
    "PacksizePackagingMachineErrorFlapsDownMajorYAxisDriveFaulted": "Flaps Down Major YAxis Drive Faulted",
    "PacksizePackagingMachineErrorFlapsDownMajorYAxisNotRef": "Flaps Down Major YAxis Not Ref",
    "PacksizePackagingMachineErrorFlapsDownMajorZAxisDriveFaulted": "Flaps Down Major ZAxis Drive Faulted",
    "PacksizePackagingMachineErrorFlapsDownMajorZAxisNotRef": "Flaps Down Major ZAxis Not Ref",
    "PacksizePackagingMachineErrorFlapsDownNoReadOnScanner": "Flaps Down No Read On Scanner",
    "PacksizePackagingMachineErrorFlapsDownPhotoEye2FalseTriggerTrackingLost": "Flaps Down Photo Eye2 False Trigger Tracking Lost",
    "PacksizePackagingMachineErrorFlapsDownPhotoEye3Or4FalseTriggerTrackingLost": "Flaps Down Photo Eye3 Or4 False Trigger Tracking Lost",
    "PacksizePackagingMachineErrorFlapsDownPhotoEye6Or7FalseTriggerTrackingLost": "Flaps Down Photo Eye6 Or7 False Trigger Tracking Lost",
    "PacksizePackagingMachineErrorFlapsDownPrinterQueueNotEmpty": "Flaps Down Printer Queue Not Empty",
    "PacksizePackagingMachineErrorFlapsDownPrinterTCPConnectionTimeout": "Flaps Down Printer TC PConnection Timeout",
    "PacksizePackagingMachineErrorFlapsDownPrinterTCPError": "Flaps Down Printer TC PError",
    "PacksizePackagingMachineErrorFlapsDownRibbonOut": "Flaps Down Ribbon Out",
    "PacksizePackagingMachineErrorFlapsDownScanner": "Flaps Down Scanner",
    "PacksizePackagingMachineErrorFlapsDownScannerFailedVerification": "Flaps Down Scanner Failed Verification",
    "PacksizePackagingMachineErrorFlapsDownTestError": "Flaps Down Test Error",
    "PacksizePackagingMachineErrorFlapsDownZone1CommsLost": "Flaps Down Zone1 Comms Lost",
    "PacksizePackagingMachineErrorFlapsDownZone2CommsLost": "Flaps Down Zone2 Comms Lost",
    "PacksizePackagingMachineErrorFlapsDownZone3CommsLost": "Flaps Down Zone3 Comms Lost",
    "PacksizePackagingMachineErrorFlapsDownZone4CommsLost": "Flaps Down Zone4 Comms Lost",
    "PacksizePackagingMachineErrorFrontLeftDoorOpen": "Front Left Door Open",
    "PacksizePackagingMachineErrorFrontRightDoorOpen": "Front Right Door Open",
    "PacksizePackagingMachineErrorGantry": "Gantry",
    "PacksizePackagingMachineErrorGeneralDriveError": "General Drive Error",
    "PacksizePackagingMachineErrorGeneralDriveWarningError": "General Drive Warning Error",
    "PacksizePackagingMachineErrorGluer": "Gluer",
    "PacksizePackagingMachineErrorGrabberAssemblyFailed": "Grabber Assembly Failed",
    "PacksizePackagingMachineErrorHighTemperature": "High Temperature",
    "PacksizePackagingMachineErrorHoldingBrakeError": "Holding Brake Error",
    "PacksizePackagingMachineErrorHomingAxisFailed": "Homing Axis Failed",
    "PacksizePackagingMachineErrorHomingChangerLeft": "Homing Changer Left",
    "PacksizePackagingMachineErrorHomingChangerRight": "Homing Changer Right",
    "PacksizePackagingMachineErrorHomingConfigurationMissing": "Homing Configuration Missing",
    "PacksizePackagingMachineErrorHomingUnitFailed": "Homing Unit Failed",
    "PacksizePackagingMachineErrorHomingUnitFailedToComplete": "Homing Unit Failed To Complete",
    "PacksizePackagingMachineErrorHomingUnitFailedToStart": "Homing Unit Failed To Start",
    "PacksizePackagingMachineErrorInfeedTimeout": "Infeed Timeout",
    "PacksizePackagingMachineErrorInfiniteLoopError": "Infinite Loop Error",
    "PacksizePackagingMachineErrorInitializationError": "Initialization Error",
    "PacksizePackagingMachineErrorInitiatingAxisFailed": "Initiating Axis Failed",
    "PacksizePackagingMachineErrorInitiatingUnitFailed": "Initiating Unit Failed",
    "PacksizePackagingMachineErrorInitiatingUnitFailedToComplete": "Initiating Unit Failed To Complete",
    "PacksizePackagingMachineErrorInitiatingUnitFailedToStart": "Initiating Unit Failed To Start",
    "PacksizePackagingMachineErrorInputParametersInvalidError": "Input Parameters Invalid Error",
    "PacksizePackagingMachineErrorInstructionList": "Instruction List",
    "PacksizePackagingMachineErrorInstructionListIndexOutOfBounds": "Instruction List Index Out Of Bounds",
    "PacksizePackagingMachineErrorInternalError": "Internal Error",
    "PacksizePackagingMachineErrorInvalidMeasurement": "Invalid Measurement",
    "PacksizePackagingMachineErrorIoModule": "I/O Module",
    "PacksizePackagingMachineErrorIoModuleError": "I/O Module Error",
    "PacksizePackagingMachineErrorLabel": "Label",
    "PacksizePackagingMachineErrorLabelLostOverCorrugate": "Label Lost Over Corrugate",
    "PacksizePackagingMachineErrorLabelLostWhileMoving": "Label Lost While Moving",
    "PacksizePackagingMachineErrorLabelNotDetected": "Label Not Detected",
    "PacksizePackagingMachineErrorLabelNotSent": "Label Not Sent",
    "PacksizePackagingMachineErrorLabelOnTampAfterBlow": "Label On Tamp After Blow",
    "PacksizePackagingMachineErrorLabelSync": "Label Sync",
    "PacksizePackagingMachineErrorLagError": "Lag Error",
    "PacksizePackagingMachineErrorLift": "Lift",
    "PacksizePackagingMachineErrorLifterFailedToExtend": "Lifter Failed To Extend",
    "PacksizePackagingMachineErrorLifterFailedToRetract": "Lifter Failed To Retract",
    "PacksizePackagingMachineErrorLongHeadPosition": "Long Head Position",
    "PacksizePackagingMachineErrorLongHeadPositionOutsideOfTolerance": "Long Head Position Outside Of Tolerance",
    "PacksizePackagingMachineErrorLongHeadQuantity": "Long Head Quantity",
    "PacksizePackagingMachineErrorLostCommunication": "Lost Communication",
    "PacksizePackagingMachineErrorLowVoltageFuses": "Low Voltage Fuses",
    "PacksizePackagingMachineErrorLowVoltagePowerSupply": "Low Voltage Power Supply",
    "PacksizePackagingMachineErrorM1GenericError": "M1 Generic Error",
    "PacksizePackagingMachineErrorMachineResetPrinterNotReadyError": "Machine Reset Printer Not Ready Error",
    "PacksizePackagingMachineErrorMagnetLossKnife": "Magnet Loss Knife",
    "PacksizePackagingMachineErrorMagnetLossRing": "Magnet Loss Ring",
    "PacksizePackagingMachineErrorMagnetSpi": "Magnet Spi",
    "PacksizePackagingMachineErrorMainVoltageError": "Main Voltage Error",
    "PacksizePackagingMachineErrorMotorEncoderFault": "Motor Encoder Fault",
    "PacksizePackagingMachineErrorMotorOverTemperatureError": "Motor Over Temperature Error",
    "PacksizePackagingMachineErrorMovablePressureRollerOutOfPosition": "Movable Pressure Roller Out Of Position",
    "PacksizePackagingMachineErrorNarrowToolOperations": "Narrow Tool Operations",
    "PacksizePackagingMachineErrorNoAlarm": "No Alarm",
    "PacksizePackagingMachineErrorNoBoxAtScanner": "No Box At Scanner",
    "PacksizePackagingMachineErrorNoDetectedCorrugateInRequestedTrack": "No Detected Corrugate In Requested Track",
    "PacksizePackagingMachineErrorNoUsbDeviceFound": "No Usb Device Found",
    "PacksizePackagingMachineErrorOutOfRibbon": "Out Of Ribbon",
    "PacksizePackagingMachineErrorOverloadOnDriveError": "Overload On Drive Error",
    "PacksizePackagingMachineErrorOverloadOnMotorError": "Overload On Motor Error",
    "PacksizePackagingMachineErrorPackNet": "Pack Net",
    "PacksizePackagingMachineErrorPackNetMultipleInstancesDetected": "Pack Net Multiple Instances Detected",
    "PacksizePackagingMachineErrorPackNetNoValidCorrugateData": "Pack Net No Valid Corrugate Data",
    "PacksizePackagingMachineErrorPackagingWidthIsGreaterThanCorrugateWidth": "Packaging Width Is Greater Than Corrugate Width",
    "PacksizePackagingMachineErrorPaperJam": "Paper Jam",
    "PacksizePackagingMachineErrorParameterNonexisting": "Parameter Nonexisting",
    "PacksizePackagingMachineErrorParameterNotReadable": "Parameter Not Readable",
    "PacksizePackagingMachineErrorParameterNotWritable": "Parameter Not Writable",
    "PacksizePackagingMachineErrorParameterOutOfBounds": "Parameter Out Of Bounds",
    "PacksizePackagingMachineErrorParameterWriteFailure": "Parameter Write Failure",
    "PacksizePackagingMachineErrorPerforationWhileReversing": "Perforation While Reversing",
    "PacksizePackagingMachineErrorPositionLatchError": "Position Latch Error",
    "PacksizePackagingMachineErrorPositioningToolsOnAxisFailed": "Positioning Tools On Axis Failed",
    "PacksizePackagingMachineErrorPositiveOrNegativeLimitSwitchReachedError": "Positive Or Negative Limit Switch Reached Error",
    "PacksizePackagingMachineErrorPrintCommandSendNoEnd": "Print Command Send No End",
    "PacksizePackagingMachineErrorPrinter": "Printer",
    "PacksizePackagingMachineErrorPrinterBarcodeNotReadable": "Printer Barcode Not Readable",
    "PacksizePackagingMachineErrorPrinterBarcodeScanFailed": "Printer Barcode Scan Failed",
    "PacksizePackagingMachineErrorPrinterBarcodeScanTimeout": "Printer Barcode Scan Timeout",
    "PacksizePackagingMachineErrorPrinterBarcodeVerificationFail": "Printer Barcode Verification Fail",
    "PacksizePackagingMachineErrorPrinterBoxPusherFailedToExtend": "Printer Box Pusher Failed To Extend",
    "PacksizePackagingMachineErrorPrinterCommunicationError": "Printer Communication Error",
    "PacksizePackagingMachineErrorPrinterDidNotCompletePrint": "Printer Did Not Complete Print",
    "PacksizePackagingMachineErrorPrinterError": "Printer Error",
    "PacksizePackagingMachineErrorPrinterLabelApplyTimeout": "Printer Label Apply Timeout",
    "PacksizePackagingMachineErrorPrinterLabelOnTamp": "Printer Label On Tamp",
    "PacksizePackagingMachineErrorPrinterOutOfLabels": "Printer Out Of Labels",
    "PacksizePackagingMachineErrorPrinterOutOfRibbon": "Printer Out Of Ribbon",
    "PacksizePackagingMachineErrorPrinterQueueNotEmpty": "Printer Queue Not Empty",
    "PacksizePackagingMachineErrorPrinterQueueTimeout": "Printer Queue Timeout",
    "PacksizePackagingMachineErrorPrinterSwungOpenError": "Printer Swung Open Error",
    "PacksizePackagingMachineErrorPrinterTcp": "Printer Tcp",
    "PacksizePackagingMachineErrorPrinterTimeoutNoLabel": "Printer Timeout No Label",
    "PacksizePackagingMachineErrorPrinterUnexpectedBox": "Printer Unexpected Box",
    "PacksizePackagingMachineErrorProtocolVersionNotSupported": "Protocol Version Not Supported",
    "PacksizePackagingMachineErrorPusherFailedToRetract": "Pusher Failed To Retract",
    "PacksizePackagingMachineErrorPusherSensorFailure": "Pusher Sensor Failure",
    "PacksizePackagingMachineErrorRearDoorOpen": "Rear Door Open",
    "PacksizePackagingMachineErrorRecipe": "Recipe",
    "PacksizePackagingMachineErrorRecipeNoBoxReleased": "Recipe No Box Released",
    "PacksizePackagingMachineErrorRecipeNoCleanCut": "Recipe No Clean Cut",
    "PacksizePackagingMachineErrorRecipeNoCrossHead": "Recipe No Cross Head",
    "PacksizePackagingMachineErrorRecipeNoCrossHeadOnTheFly": "Recipe No Cross Head On The Fly",
    "PacksizePackagingMachineErrorRecipeNoFeedOut": "Recipe No Feed Out",
    "PacksizePackagingMachineErrorRecipeNoFeeder": "Recipe No Feeder",
    "PacksizePackagingMachineErrorReferenceQuantityMissmatch": "Reference Quantity Missmatch",
    "PacksizePackagingMachineErrorRollAxis": "Roll Axis",
    "PacksizePackagingMachineErrorSDOReadFailure": "S DO Read Failure",
    "PacksizePackagingMachineErrorSDOReadTimeout": "S DO Read Timeout",
    "PacksizePackagingMachineErrorSafetyRelatedError": "Safety Related Error",
    "PacksizePackagingMachineErrorSafetyRelayNotEnabled": "Safety Relay Not Enabled",
    "PacksizePackagingMachineErrorSensorEndOfBaleActive": "Sensor End Of Bale Active",
    "PacksizePackagingMachineErrorSensorFailOrCartonJam": "Sensor Fail Or Carton Jam",
    "PacksizePackagingMachineErrorSensorFailure": "Sensor Failure",
    "PacksizePackagingMachineErrorServoPowerSwitchedOff": "Servo Power Switched Off",
    "PacksizePackagingMachineErrorSideTrimLeftServoError": "Side Trim Left Servo Error",
    "PacksizePackagingMachineErrorSidetrimCrease": "Sidetrim Crease",
    "PacksizePackagingMachineErrorSidetrimCreaseServoErrorAdjustCrease": "Sidetrim Crease Servo Error Adjust Crease",
    "PacksizePackagingMachineErrorSidetrimCreaseServoErrorAdjustSideTrim": "Sidetrim Crease Servo Error Adjust Side Trim",
    "PacksizePackagingMachineErrorSidetrimCreaseServoErrorFeeder": "Sidetrim Crease Servo Error Feeder",
    "PacksizePackagingMachineErrorSidetrimCreaseTestError": "Sidetrim Crease Test Error",
    "PacksizePackagingMachineErrorSidetrimRightServoError": "Sidetrim Right Servo Error",
    "PacksizePackagingMachineErrorSimulation": "Simulation",
    "PacksizePackagingMachineErrorSlaveFailedToReceive": "Slave Failed To Receive",
    "PacksizePackagingMachineErrorSlaveLostCommunication": "Slave Lost Communication",
    "PacksizePackagingMachineErrorSlavePowerFault": "Slave Power Fault",
    "PacksizePackagingMachineErrorSlaveReplyParsing": "Slave Reply Parsing",
    "PacksizePackagingMachineErrorSlaveRequestTimeout": "Slave Request Timeout",
    "PacksizePackagingMachineErrorSlipCompensationError": "Slip Compensation Error",
    "PacksizePackagingMachineErrorStateChangeTimeout": "State Change Timeout",
    "PacksizePackagingMachineErrorStepMotorFlag": "Step Motor Flag",
    "PacksizePackagingMachineErrorStepMotorPositionDrift": "Step Motor Position Drift",
    "PacksizePackagingMachineErrorStepMotorPositionStall": "Step Motor Position Stall",
    "PacksizePackagingMachineErrorTestError": "Test Error",
    "PacksizePackagingMachineErrorThicknessSensorLeftError": "Thickness Sensor Left Error",
    "PacksizePackagingMachineErrorThicknessSensorRightError": "Thickness Sensor Right Error",
    "PacksizePackagingMachineErrorTimeout": "Timeout",
    "PacksizePackagingMachineErrorTooManyRecipeSections": "Too Many Recipe Sections",
    "PacksizePackagingMachineErrorTooManyRecipeSteps": "Too Many Recipe Steps",
    "PacksizePackagingMachineErrorToolAKnifeStuckDown": "Tool AKnife Stuck Down",
    "PacksizePackagingMachineErrorToolAKnifeStuckUp": "Tool AKnife Stuck Up",
    "PacksizePackagingMachineErrorToolAxis": "Tool Axis",
    "PacksizePackagingMachineErrorToolBRingStuckDown": "Tool BRing Stuck Down",
    "PacksizePackagingMachineErrorToolBRingStuckUp": "Tool BRing Stuck Up",
    "PacksizePackagingMachineErrorToolHeadFwUpgradeFailed": "Tool Head Fw Upgrade Failed",
    "PacksizePackagingMachineErrorToolHeadNotFound": "Tool Head Not Found",
    "PacksizePackagingMachineErrorTrackNotActive": "Track Not Active",
    "PacksizePackagingMachineErrorTrackNumberMismatch": "Track Number Mismatch",
    "PacksizePackagingMachineErrorTrackOffset": "Track Offset",
    "PacksizePackagingMachineErrorTrackQuantity": "Track Quantity",
    "PacksizePackagingMachineErrorTypeConfigurationMissing": "Type Configuration Missing",
    "PacksizePackagingMachineErrorUnableToCreateRecipe": "Unable To Create Recipe",
    "PacksizePackagingMachineErrorUnderVoltage": "Under Voltage",
    "PacksizePackagingMachineErrorUnitDidNotConfirmRecipe": "Unit Did Not Confirm Recipe",
    "PacksizePackagingMachineErrorUnitDidNotStartRecipe": "Unit Did Not Start Recipe",
    "PacksizePackagingMachineErrorUnitNotReadyToRun": "Unit Not Ready To Run",
    "PacksizePackagingMachineErrorUnitReplacedOrReconfigured": "Unit Replaced Or Reconfigured",
    "PacksizePackagingMachineErrorUnknown": "Unknown",
    "PacksizePackagingMachineErrorUnknownInstruction": "Unknown Instruction",
    "PacksizePackagingMachineErrorUserAbortedOperation": "User Aborted Operation",
    "PacksizePackagingMachineErrorVoltageSagError": "Voltage Sag Error",
    "PacksizePackagingMachineErrorWrongMode": "Wrong Mode",
    "PacksizePackagingMachineErrorWrongReplyCode": "Wrong Reply Code",
    "PacksizePackagingMachineErrorWrongReplyLength": "Wrong Reply Length",
    "PacksizePackagingMachineErrorWrongSensorEndOfBaleActive": "Wrong Sensor End Of Bale Active",
    "PacksizePackagingMachineErrorWrongState": "Wrong State",
    "PacksizePackagingMachineErrorX5": "X5",
    "PacksizePackagingMachineInManualModeError": "In Manual Mode Error",
    "PacksizePackagingPrinterIsDisabled": "Printer Is Disabled",
    "PadString": "Pad String Left",
    "Padding Character": "Padding Character",
    "Padding Length": "Padding Length",
    "Page {currentPage} of {totalPages} ({totalRecords} items)": "Page {currentPage} of {totalPages} ({totalRecords} items)",
    "Password": "Password",
    "Password Rules:": "Password Rules:",
    "Password confirmation is required": "Password confirmation is required",
    "Password is required": "Password is required",
    "Password not strong enough": "Password not strong enough",
    "Passwords must match": "Passwords must match",
    "Pause Machine": "Pause Machine",
    "Pause Machine Between Orders": "Pause Machine Between Orders",
    "Pause between jobs": "Pause between jobs",
    "Pause current job to produce": "Pause current job to produce",
    "PausePlay Machine Group With Foot Pedal": "Pause/Play Machine Group With Foot Pedal",
    "Paused": "Paused",
    "Pausing": "Pausing",
    "Percentage": "Percentage",
    "Percentage Usage by z-Fold": "Percentage Usage by z-Fold",
    "Percentage Usage by zFold": "Percentage Usage by z-Fold",
    "Performance": "Performance",
    "Performance:": "Performance:",
    "Phone Number": "Phone Number",
    "Phone Number is Invalid: required format +12125551212": "Phone Number is Invalid: required format +12125551212",
    "Physical Machine Settings": "Physical Machine Settings",
    "Physical Machine Settings File": "Physical Machine Settings File",
    "Pick Zone: {pickZone}": "Pick Zone: {pickZone}",
    "Pick Zones": "Pick Zones",
    "Pick Zones History:": "Pick Zones History:",
    "Ping Federate": "Ping Federate",
    "Pipeline": "Pipeline",
    "Pipeline Configuration": "Pipeline Configuration",
    "Pipeline {pipelineName} with {availableCount} available items to select from:": "Pipeline {pipelineName} with {availableCount} available items to select from:",
    "Pipelines": "Pipelines",
    "Placeholder Error": "Placeholder Error",
    "Placement:": "Placement:",
    "Planned Production": "Planned Production",
    "Please Enter Your Email Below": "Please enter your email below, and we'll send you a link to reset your password.",
    "Please contact your administrator for more information": "Please contact your administrator for more information",
    "Please create a different name": "Please create a different name",
    "Please deselect the image before changing carton dimensions": "Please deselect the image before changing carton dimensions",
    "Please return to the login page to log back in": "Please return to the login page to log back in.",
    "Please select a valid corrugate for track(s)": "Please select a valid corrugate for track(s)",
    "Please select an image smaller than 100 MB": "Please select an image smaller than 100 MB",
    "Please select another date range": "Please select another date range",
    "Please try again": "Please try again.",
    "Port": "Port",
    "PositiveNumeric": "Positive Number",
    "Power Tools": "Power Tools",
    "Powered By": "Powered by Packsize (R)",
    "Press Play on the Status Bar to Produce": "Press Play on the Status Bar to Produce",
    "Pressure Roller": "Pressure Roller",
    "Preview": "Preview",
    "Preview Print Image": "Preview Print Image",
    "Previous": "Previous",
    "Previously Reported Bale Counts": "Previously Reported Bale Counts",
    "Primary Key": "Primary Key",
    "Print": "Print",
    "Print Campaigns": "Print Campaigns",
    "Print Design": "Print Design",
    "Print Files": "Print Files",
    "Print Image Alias": "Print Image Alias",
    "Print Image Name": "Print Image Name",
    "Print Images": "Print Images",
    "Print Templates": "Print Templates",
    "Print Variable": "Print Variable",
    "Print Variable List": "Print Variable List",
    "Print Variables": "Print Variables",
    "Printer Type": "Printer Type",
    "Priority": "Priority",
    "Priority Printer": "Priority Printer",
    "Proceed to change z-Fold": "Proceed to change z-Fold",
    "ProcessBarcodeExtraProperties": "Barcode contains the following extra data: {extraProperties}",
    "ProcessBarcodeMissingProperties": "Barcode is missing the following properties: {missingProperties}",
    "Produce": "Produce",
    "Produce Custom Jobs": "Produce Custom Jobs",
    "Produce From Article": "Produce From Article",
    "Produce Item": "Produce Item",
    "Produce Job": "Produce Job",
    "Produce Next": "Produce Next",
    "Produce Now": "Produce Now",
    "Produce a job from barcode content": "Produce a job from barcode content",
    "Produce after current job": "Produce after current job",
    "Produce failed": "Produce failed",
    "Produce now": "Produce now",
    "Produce one job in batch": "Produce one job in batch",
    "ProduceCurrentAndPause": "Producing Current Job Then Pausing",
    "Produced": "Produced",
    "Produced Optimally": "Produced Optimally",
    "Produced non-optimally": "Produced non-optimally",
    "Produced optimally": "Produced optimally",
    "Producible Items Created": "Producible Items Created",
    "Producible Items Created This Week": "Producible Items Created This Week",
    "Producible Items Created Today": "Producible Items Created Today",
    "Producible Items Failed": "Producible Items Failed",
    "Producible Items Failed This Week": "Producible Items Failed This Week",
    "Producible Items Failed Today": "Producible Items Failed Today",
    "Producible Items Imported": "Producible Items Imported",
    "Producible Items Imported This Week": "Producible Items Imported This Week",
    "Producible Items Imported Today": "Producible Items Imported Today",
    "Producible Items Rotated": "Producible Items Rotated",
    "Producible Items Rotated This Week": "Producible Items Rotated This Week",
    "Producible Items Rotated Today": "Producible Items Rotated Today",
    "Producing": "Producing",
    "Producing Next": "Producing Next",
    "Producing Now": "Producing Now",
    "Producing on {machineGroupName}": "Producing on {machineGroupName}",
    "Producing on {machineGroupName} ({count})": "Producing on {machineGroupName} ({count})",
    "Product Dimensions": "Product Dimensions",
    "Product Orientation": "Product Orientation",
    "ProductCountPage": "Showing {total} of {totalCount} results. Try refining your search.",
    "ProductCountPlural": "{total} products found",
    "ProductCountSingular": "{total} product found",
    "ProductIdentifier": "Product Identifier",
    "ProductNotFound": "1 product not found",
    "Production": "Production",
    "Production Control": "Production Control",
    "Production Enabled": "Production Enabled",
    "Production Group": "Production Group",
    "Production Group Control": "Production Group Control",
    "Production Group Controls": "Production Group Controls",
    "Production Group History:": "Production Group History:",
    "Production Group:": "Production Group:",
    "Production Group: {productionGroupName}": "Production Group: {productionGroupName}",
    "Production Groups": "Production Groups",
    "Production Mode": "Production Mode",
    "Production Options:": "Production Options:",
    "Production Status": "Production Status",
    "Production by Site": "Production by Site",
    "Production group has no configured z-Fold": "Production group has no configured z-Fold",
    "Production group is not assigned or has no configured z-Fold": "Production group is not assigned or has no configured z-Fold",
    "Production group was found in database cache in {time}ms": "Production group was found in database cache in {time}ms",
    "Production group:": "Production group:",
    "ProductionCompleted": "Completed",
    "ProductionError": "Error",
    "ProductionGroup": "Production Group",
    "ProductionIdle": "Idle",
    "ProductionInProgress": "In Progress",
    "ProductionMode": "Production Mode",
    "ProductionPaused": "Paused",
    "ProductionSettings": "Production Settings",
    "Products": "Products",
    "ProductsInCarton": "{count} products in Carton",
    "ProductsNotFound": "{count} products not found",
    "Profile": "Profile",
    "Prompt": "Prompt",
    "Provides detailed information about machine group work selection": "Provides detailed information about machine group work selection",
    "Provides information about work selection": "Provides information about work selection",
    "Provides metrics about work selection": "Provides metrics about work selection",
    "Pull Mode Machine Job ID: {pullModeMachineJobId}": "Pull Mode Machine Job ID: {pullModeMachineJobId}",
    "Put machine in ErrorPaused to change z-Fold": "Put machine in Error/Paused to change z-Fold",
    "Qty": "Qty",
    "Quality": "Quality",
    "Quality is required": "Quality is required",
    "Quantity": "Quantity",
    "Question Format": "Question Format",
    "Question Text": "Question Text",
    "Question Text is required": "Question Text is required",
    "Question format is required": "Question Format is required",
    "Queue Size": "Queue Size",
    "Queue Type": "Queue Type",
    "REPRODUCE": "REPRODUCE",
    "Ratio": "Ratio",
    "Raw Data": "Raw Data",
    "Ready to Receive Jobs": "Ready to Receive Jobs",
    "Ready to receive jobs": "Ready to receive jobs",
    "Ready to scan": "Ready to scan",
    "ReadyForMoreWork": "Ready For More Work",
    "ReadyToScan": "Ready to scan",
    "Recent": "Recent",
    "Recently Added": "Recently Added",
    "Recently Produced": "Recently Produced",
    "Redacted": "Redacted",
    "Release": "Release",
    "Release Brakes": "Release Brakes",
    "Release Carton With Foot Pedal": "Release Carton With Foot Pedal",
    "Release Carton With Trigger": "Release Carton With Trigger",
    "Release Notes": "Release Notes",
    "Release Work": "Release Work",
    "Reload": "Reload",
    "Reload Now": "Reload Now",
    "Remove": "Remove",
    "Remove All Jobs": "Remove All Jobs",
    "Remove Carton Mapping": "Remove Carton Mapping",
    "Remove Job from Search": "Remove Job from Search",
    "Remove Label Mapping": "Remove Label Mapping",
    "Remove Link": "Remove Link",
    "Remove Machine Group from Production Group": "Remove Machine Group from Production Group",
    "Remove Schedule": "Remove Schedule",
    "Remove Selected Jobs": "Remove Selected Jobs",
    "Remove files after day": "Remove files after day",
    "Remove imported files from source": "Remove imported files from source",
    "Remove {aliasToBeRemoved}": "Remove {aliasToBeRemoved}",
    "Remove {count} Job(s) from Search": "Remove {count} Job(s) from Search",
    "RemoveFromSearchConfirmationDetail": "{alias} will no longer be visible in PackNet.",
    "RemoveFromSearchConfirmationStaged": "If it has not already been sent to a machine, it will be canceled.",
    "RemoveFromSearchConfirmationTitle": "Delete Job?",
    "RemoveHeadersQuestion": "Remove Headers?",
    "Removed": "Removed",
    "Repeating Event:": "Repeating Event:",
    "Replace Design": "Replace Design",
    "Report": "Report",
    "Reporting": "Reporting",
    "Reporting Connected": "Reporting Connected",
    "Reporting Disconnected": "Reporting Disconnected",
    "Reporting ID": "Reporting ID",
    "Reporting Permissions": "Reporting Permissions",
    "Reproduce": "Reproduce",
    "Reproduced": "Reproduced",
    "Requests": "Requests",
    "Required": "Required",
    "RequiredCorrugateMissing": "A required z-Fold is not part of the assigned production group",
    "RequiredDimensions": "Required Dimensions",
    "RequiredFieldsNotSet": "Required fields not set",
    "RequiredMachineMissing": "A required machine is not part of the assigned production group",
    "Resend email": "Resend Email",
    "Reset Layout": "Reset Layout",
    "Reset Password": "Reset Password",
    "Resolution": "Resolution",
    "ResolvedFieldValue": "Resolved Field Value",
    "Restore": "Restore",
    "Resume Production": "Resume Production",
    "Retracted": "Retracted",
    "Retry Count": "Retry Count",
    "ReturnToLogin": "Return to Login Page",
    "Roles": "Roles",
    "Roll Backward": "Roll Backward",
    "Roll Forward": "Roll Forward",
    "Rollback": "Rollback",
    "Rotate": "Rotate",
    "Rotate Left": "Rotate Left",
    "Rotate Right": "Rotate Right",
    "RoundCartonValue": "Round Carton Value",
    "RoundJobValue": "Round Job Value",
    "Routing Key Pattern": "Routing Key Pattern",
    "Routing Key Pattern is required": "Routing Key Pattern is required",
    "Rubber Roller": "Rubber Roller",
    "RuleEditor": "Rule Editor",
    "RuleName": "Rule Name",
    "RuleNamePlaceholder": "ex. Add Carton",
    "Rules": "Rules",
    "Rules Rewrite": "Rules Rewrite",
    "Run": "Run",
    "Run Manually": "Run Manually",
    "Run on Packaging Creation": "Run on Packaging Creation",
    "Run this rule": "Run this rule",
    "SAVE": "SAVE",
    "SEE QUEUE": "SEE QUEUE",
    "SKU": "SKU",
    "SP00": "SP00",
    "SSO Login Failed": "SSO Login Failed",
    "Saturday": "Saturday",
    "Saturday Abbrev": "SAT",
    "Save": "Save",
    "Save And Close": "Save And Close",
    "Save Failed": "Save Failed",
    "Save Job": "Save Job",
    "Save Rule": "Save Rule",
    "Save as default": "Save as Default",
    "Save packaging solutions to make work easy": "Save jobs to make work easy",
    "SaveRule": "Save Rule",
    "Saved": "Saved",
    "Saved Job Alias": "Saved Job Alias",
    "Saved Jobs": "Packaging Creation",
    "Saved Packaging Solutions": "Saved Packaging Solutions",
    "Saved Successfully": "Saved Successfully",
    "Saving": "Saving",
    "Scaling": "Scaling",
    "Scan": "Scan",
    "Scan Failed": "Scan Failed",
    "Scan Import": "Scan Import",
    "Scan Succeeded": "Scan Succeeded",
    "Scan or enter a barcode": "Scan or enter a barcode",
    "Scan to Produce": "Scan to Produce",
    "Scan to Produce Pipeline": "Scan to Produce Pipeline",
    "Scan to create": "Scan to create",
    "Scan to trigger (all)": "Scan to trigger (all)",
    "Scan to trigger (single)": "Scan to trigger (single)",
    "Scan2Pack": "Scan2Pack",
    "ScanToProduceCaptureFlow": "Scan To Produce Capture Flow",
    "Scanned": "Scanned",
    "Scanned Identifier: {scannedIdentifier}": "Scanned Identifier: {scannedIdentifier}",
    "Scanned Products": "Scanned Products",
    "ScanningDevice": "Scanning Device",
    "Scheduled downtime": "Scheduled downtime",
    "Search": "Search",
    "Search Carton Designs": "Search Carton Designs",
    "Search Packaging Solutions": "Search Packaging Solutions",
    "Search User Notifications": "Search User Notifications",
    "Search header": "Search header",
    "Search keyword or name": "Search keyword or name",
    "Search through user notifications, filtered by date range, notification type, or notification severity": "Search through user notifications, filtered by date range, notification type, or notification severity.",
    "Search...": "Search...",
    "SearchDimensioningProducts": "Search {idType} (3 character min)",
    "SearchSavedJobs": "Search {jobCount} total saved jobs",
    "Searching": "Searching...",
    "Secondary Key": "Secondary Key",
    "Seconds Between Retries": "Seconds Between Retries",
    "Secret": "Secret",
    "Secret is required": "Secret is required",
    "Secure Print": "Secure Print",
    "SecureLabelSending": "Secure Label Storing",
    "SecurePrint.CustomerManaged": "Customer Managed Secure Print",
    "SecurePrint.CustomerManagedDescription": "PackNet will send print variable inputs (like customer id, etc) to the Packsize IoT device, and call a customer API from the IoT device. The customer API is responsible for printing. Label contents will never be seen by Packsize, enabling the most secure label printing process.",
    "SecurePrint.ExternalPacksizeManaged": "External Packsize Managed Secure Print",
    "SecurePrint.ExternalPacksizeManagedDescription": "PackNet will send print variable inputs (like customer id, etc) to the Packsize IoT device, and call a customer API from the IoT device to receive back the label contents to print. The IoT device will then queue the label contents to the printer. Throughout the process, the label content is secure, and will not leave the IoT device beyond the printing process.",
    "SecurePrint.InternalPacksizeManaged": "Internal Packsize Managed Secure Print",
    "SecurePrint.InternalPacksizeManagedDescription": "When you import label information using PackNet, our system follows strict rules to ensure sensitive data is removed and sent securely to the print engine. We store your label data safely and separately from other customers, with a defined time limit. When it's time to print your labels, our system will send them directly to the printer. Rest assured that your information is protected throughout the process.",
    "See Details": "See Details",
    "Select": "Select",
    "Select 'Create Job' to begin": "Select 'Create Job' to begin",
    "Select A Template": "Select A Template",
    "Select All": "Select All",
    "Select Brand": "Select Brand",
    "Select Carton": "Select Carton",
    "Select Configure to get started": "Select 'Configure' to get started",
    "Select Customer Header": "Select Customer Header",
    "Select Design": "Select Design",
    "Select Design to See Preview": "Select Design to See Preview",
    "Select Field": "Select Field",
    "Select File": "Select File",
    "Select Flow Type": "Select Flow Type",
    "Select Flute": "Select Flute",
    "Select Image": "Select Image",
    "Select Label Printer": "Select Label Printer",
    "Select Label Template": "Select Label Template",
    "Select Label Type": "Select Label Type",
    "Select Location": "Select Location",
    "Select Machine": "Select Machine",
    "Select Machine Group": "Select Machine Group",
    "Select Machine Type": "Select Machine Type",
    "Select Machines": "Select Machines",
    "Select One": "Select One",
    "Select One elipses": "Select One...",
    "Select Only One Site": "Select Only One Site",
    "Select PLC Model": "Select PLC Model",
    "Select PackNet Field": "Select PackNet Field",
    "Select Print Image": "Select Print Image",
    "Select Printer": "Select Printer",
    "Select Site(s) 5 MAX": "Select Site(s) 5 MAX",
    "Select Verification Code": "Select Verification Code",
    "Select Your Site": "Select Your Site",
    "Select a Machine": "Select a Machine",
    "Select a Sorting Policy": "Select a Sorting Policy",
    "Select all machines": "Select all machines",
    "Select an attribute or type a hardcoded priority": "Select an attribute or type a hardcoded priority (e.g.): 2",
    "Select another date range": "Select another date range",
    "Select drop downs to change z-Fold": "Select drop downs to change z-Fold",
    "Select one or more type of notification you would like to receive": "Select one or more type of notification you would like to receive.",
    "Select only one day to view this graph": "Select only one day to view this graph",
    "Select question format": "Select question format",
    "Select up to 1 location": "Select up to 1 location",
    "Select up to 5 locations": "Select up to 5 locations",
    "Select which machines you would like to add to the machine group": "Select which machine(s) you would like to add to the machine group",
    "Select which services this key will be used for": "Select which services this key will be used for:",
    "SelectAnAction": "Select An Action",
    "SelectAnAttribute": "Select an attribute",
    "SelectAnOption": "Select An Option",
    "SelectOperator": "Select Operator",
    "SelectUpload": "Select 'Upload File' to manually load carton designs.",
    "SelectValue": "Select Value",
    "Selected": "Selected",
    "Selected item ''{alias}''": "Selected item ''{alias}''",
    "Selected z-Fold": "Selected z-Fold",
    "Selected zFold": "Selected z-Fold",
    "Selection Analytics": "Selection Analytics",
    "Selection History": "Selection History",
    "Selection Pipeline": "Selection Pipeline",
    "Selection Type": "Selection Type",
    "Self Report": "Self Report",
    "Send": "Send",
    "Send Test Print": "Send Test Print",
    "Send through IoT": "Send through IoT",
    "Send through IoT device": "Send through IoT device",
    "Send to Production Group": "Send to Production Group",
    "Send to Queue": "Send to Queue",
    "Sending": "Sending",
    "Sent to Machine": "Sent to Machine",
    "Sent to Machine Group": "Sent to Machine Group",
    "Sent to Queue": "Sent to Queue",
    "September": "September",
    "Server Settings": "Settings",
    "ServerError": "Server error",
    "Service": "Service",
    "Service Mode": "Service",
    "Service Status": "Service Status",
    "ServiceDegraded": "Machine Service Degraded",
    "ServiceDependencyFailures": "Service Dependency Failures",
    "Services": "Services",
    "Session expired": "Your session timed out. Please log in to continue.",
    "Session expiring soon": "Session expiring soon",
    "Set Value": "Set Value",
    "Set Value for locale": "Set Value for locale",
    "Set delimiter": "Set delimiter",
    "Set on Machine PLC": "Set on Machine PLC",
    "Set starting inventory levels": "Set starting inventory levels",
    "Set to Local Time": "Set to Local Time",
    "Set up tracks and configure machine": "Set up tracks and configure machine.",
    "SetCartonValue": "Set Carton Value",
    "SetCartonValueDescription": "Modify a carton field.",
    "SetJobValue": "Set Job Value",
    "SetJobValueDescription": "Modify a job attribute.",
    "SetLabelPosition": "Set Label Position",
    "SetLabelValue": "Set Label Value",
    "SetLabelValueDescription": "Modify a label field.",
    "SetValue": "Set Value",
    "SetValueDescription": "Modify the current job using the existing headers and values.",
    "Setting up your dashboard is easy": "Setting up your dashboard is easy.",
    "Settings": "Settings",
    "Settings successfully imported": "Settings successfully imported.",
    "SetupRequired": "Setup required",
    "Short": "Short",
    "Show Advanced Settings": "Show Advanced Settings",
    "Show All": "Show All",
    "Show Hide Columns": "Show/Hide Columns",
    "Show Trigger Interface On Machine Production Screen": "Show Trigger Interface On Machine Production Screen",
    "Shows jobs that are blocked, failed, or not optimal": "Shows jobs that are blocked, failed, or not optimal",
    "Shows machines that are out of material": "Shows machines that are out of material",
    "Side Steering Tolerance": "Side Steering Tolerance",
    "Signals": "Signals",
    "Signed in as": "Signed in as",
    "Simulators": "Simulators",
    "Single": "Single",
    "Single Scan": "Single Scan",
    "Site": "Site",
    "Site Availability": "Site Availability",
    "Site Availability Score": "Site Availability Score",
    "Site Availability Status": "Site Availability Status",
    "Site ID": "Site ID",
    "Site Time Zone": "Site Time Zone",
    "Sites": "Sites",
    "Size:": "Size:",
    "Software License Agreement": "Software License Agreement",
    "Software Update Available": "Software Update Available",
    "Solution Title": "Solution Title",
    "Sort By": "Sort By",
    "SortByCartonPropertyGroup": "Sort By Carton Property Group",
    "SortByCartonPropertyGroupRatio": "Sort By Carton Property Group Ratio",
    "SortByClassification": "Sort By Classification",
    "SortByDateAttribute": "Sort By Date Attribute",
    "SortByMachineGroupExclusivity": "Sort By Machine Group Exclusivity",
    "SortByMachineOptimalProductionExclusivity": "Sort By Machine Optimal Production Exclusivity",
    "SortByOptimalProduction": "Sort By Optimal Production",
    "SortByReleasedForProductionDateAscending": "Sort By Released For Production Date (ascending)",
    "SortCartonDimensions": "Sort Carton Dimensions",
    "Sorting items with {stepName} resulted in carton {firstCarton} as the top item": "Sorting items with {stepName} resulted in carton {firstCarton} as the top item",
    "Source": "Source",
    "Speed": "Speed",
    "SplitStringByCharacterCount": "Split String By Character Count",
    "SplitStringByDelimiter": "Split String By Delimiter",
    "Square Feet": "Square Feet",
    "Square Meters": "Square Meters",
    "Sso Sign In Failure": "There was an error with your sign in attempt. Please try again.",
    "Stage": "Stage",
    "Staged": "Staged",
    "Standalone": "Standalone",
    "Standalone Labels:": "Standalone Labels:",
    "Standard Scanner": "Standard Scanner",
    "Standing Up": "Standing Up",
    "StandingUp": "Standing Up",
    "Start Date": "Start Date",
    "Start by selecting a new site location": "Start by selecting a new site location",
    "StartMachine": "Start Machine",
    "StartMachineContinueProduction": "Start machine to continue production",
    "Started": "Started",
    "Starting Position": "Starting Position",
    "Status": "Status",
    "StatusCode": "Status Code",
    "Stop": "Stop",
    "StopMachine": "Stop Machine",
    "StopMachineChangeZFold": "Stop machine to change z-Fold",
    "Stopped": "Stopped",
    "Stopped Cartons: {total}": "Stopped Cartons: {total}",
    "Style:": "Style:",
    "Subscribed To": "Subscribed To",
    "Subscription test passed": "Subscription test passed",
    "Subscription {name} History": "Subscription {name} History",
    "Succeeded": "Successful",
    "Successful Production Options:": "Successful Production Options:",
    "Successfully Removed Job": "Successfully Removed Job",
    "Successfully Removed Jobs": "Successfully Removed Jobs",
    "Successfully Sent to Queue": "Successfully Sent to Queue",
    "Successfully Triggered Job": "Successfully Triggered Job",
    "Successfully deleted {count} solutions": "Successfully deleted {count} solutions",
    "Suggested Re-Order Counts": "Suggested Re-Order Counts",
    "Sum of Selected": "Sum of Selected",
    "Sunday": "Sunday",
    "Sunday Abbrev": "SUN",
    "Supplier": "Supplier",
    "SupplierNumber": "Supplier Number",
    "Support": "Support",
    "Surge": "Surge",
    "Surge Count": "Surge Count",
    "SwapValues": "Swap Values",
    "SwapValuesDescription": "Swap existing header value with another header value.",
    "System": "System",
    "SystemError": "System Error",
    "Table View": "Table View",
    "Target": "Target",
    "Template": "Template",
    "Template Name": "Template Name",
    "Test": "Test",
    "Test Results": "Test Results",
    "TestSecurePrintConfigurationInvalidApiKey": "Invalid configuration, missing or invalid API key",
    "TestSecurePrintConfigurationNotReachable": "Invalid configuration, server responded with status {statusCode}",
    "TestSecurePrintConfigurationSuccess": "Secure print configuration tested successfully",
    "TextContains": "Contains",
    "TextContainsInsensitive": "Contains (case insensitive)",
    "TextEqual": "Equals",
    "TextEqualInsensitive": "Equals (case insensitive)",
    "TextEqualLabel": "Equals (string)",
    "TextNotContains": "Does Not Contain",
    "TextNotContainsInsensitive": "Does Not Contain (case insensitive)",
    "TextNotEqual": "Does Not Equal",
    "TextNotEqualInsensitive": "Does Not Equal (case insensitive)",
    "TextNotEqualLabel": "Does Not Equal (string)",
    "The image to upload must be smaller than 100 MB": "The image to upload must be smaller than 100 MB",
    "The import rule ''{step}'' failed with reason ''{reason}''": "The import rule ''{step}'' failed with reason ''{reason}''",
    "The import rule ''{step}'' requires header(s) ''{headers}'' that were not configured": "The import rule ''{step}'' requires header(s) ''{headers}'' that were not configured",
    "The import source {source} encountered an error": "The import source {source} encountered an error",
    "The {types} machine(s) are not configured to produce this design type": "The {types} machine(s) are not configured to produce this design type",
    "The {types} machine(s) can not produce because no available corrugate is wide enough": "The {types} machine(s) can not produce because no available corrugate is wide enough",
    "The {types} machine(s) can not produce because the z-Fold {material} is too narrow to be loaded on the machine": "The {types} machine(s) can not produce because the z-Fold {material} is too narrow to be loaded on the machine",
    "The {types} machine(s) can not produce because the z-Fold {material} is too wide": "The {types} machine(s) can not produce because the z-Fold {material} is too wide",
    "The {types} machine(s) can not produce on z-Fold {material} because carton production would result in too little waste and cause machine jams": "The {types} machine(s) can not produce on z-Fold {material} because carton production would result in too little waste and cause machine jams",
    "The {types} machine(s) can not produce on z-Fold {material} because carton production would result in too much waste": "The {types} machine(s) can not produce on z-Fold {material} because carton production would result in too much waste",
    "The {types} machine(s) can not produce this item in the {orientations} orientations": "The {types} machine(s) can not produce this item in the {orientations} orientations",
    "The {types} machine(s) {machines} can produce this item on z-Fold {material} with tile count {tileCount}": "The {types} machine(s) {machines} can produce this item on z-Fold {material} with tile count {tileCount}",
    "The {types} service(s) were not available to stage this item": "The {types} service(s) were not available to stage this item",
    "The {type} machine(s) {machines} can not produce this design {design}": "The {type} machine(s) {machines} can not produce this design {design}",
    "The {type} machine(s) {machines} can not produce with a tile count of {tileCount}": "The {type} machine(s) {machines} can not produce with a tile count of {tileCount}",
    "The {type} machine(s) {machines} cannot produce because Secure Print is not supported": "The {type} machine(s) {machines} cannot produce because Secure Print is not supported",
    "The {type} machine(s) {machines} cannot produce because a barcode was not provided": "The {type} machine(s) {machines} cannot produce because a barcode was not provided",
    "The {type} machine(s) {machines} cannot produce because more than one label was provided": "The {type} machine(s) {machines} cannot produce because more than one label was provided",
    "The {type} machine(s) {machines} cannot produce because no label was provided": "The {type} machine(s) {machines} cannot produce because no label was provided",
    "The {type} machine(s) {machines} cannot produce because no label was provided for the TaLU printer": "The {type} machine(s) {machines} cannot produce because no label was provided for the TaLU printer",
    "The {type} machine(s) {machines} cannot produce because the printer is disabled": "The {type} machine(s) {machines} cannot produce because the printer is disabled",
    "The {type} machine(s) {machines} cannot produce because {failureReason}": "The {type} machine(s) {machines} cannot produce because {failureReason}",
    "There are no records to display": "There are no records to display",
    "There are no stored images that match your carton dimensions": "There are no stored images that match your carton dimensions",
    "Thickness": "Thickness",
    "Thickness:": "Thickness:",
    "This Week's Metrics": "This Week's Metrics",
    "This change can not be undone": "This change can not be undone.",
    "This image is too large to be displayed": "This image is too large to be displayed",
    "This is not the card you are looking for": "This is not the card you are looking for",
    "This is the only time this will be displayed, so be sure to copy it.": "This is the only time this will be displayed, so be sure to copy it.",
    "This page is running an old version of PackNet. Refresh your page to run the latest version.": "This page is running an old version of PackNet. Refresh your page to run the latest version.",
    "This printer is configured in the machine PLC": "This printer is configured in the machine PLC",
    "This report displays data for only one site at a time Use the drop down menu to select the desired site": "This report displays data for only one site at a time. Use the drop down menu to select the desired site.",
    "Throughput": "Throughput",
    "Thursday": "Thursday",
    "Thursday Abbrev": "THU",
    "Tile Across Batches": "Tile Across Batches",
    "Tile Count:": "Tile Count:",
    "Tile Within Batches": "Tile Within Batches",
    "Tiled": "Tiled",
    "Tiling": "Tiling",
    "Time": "Time",
    "Time Zone": "Time Zone",
    "Time to look up dependancies {time}ms": "Time to look up dependancies {time}ms",
    "Time to process: {time}": "Time to process: {time}",
    "TimeLong": "Long Time",
    "TimeShort": "Short Time",
    "Timeline": "Timeline",
    "Title": "Title",
    "Title already exists": "Title already exists",
    "To change z-Fold pause the machine": "To change z-Fold pause the machine",
    "To change zFold": "To change z-Fold",
    "To view the full history, visit {link}": "To view the full history, visit {link}",
    "Today": "Today",
    "Today's Metrics": "Today's Metrics",
    "Token URL": "Token URL",
    "Token URL is required": "Token URL is required",
    "Token URL must start with http:// or https://": "Token URL must start with http:// or https://",
    "TokenProvider": "Token Provider",
    "Tool To Sensor Offset": "Tool to Sensor Offset",
    "Top 6 Errors": "Top 6 Errors",
    "Top Errors by Machine": "Top Errors by Machine",
    "Torque": "Torque",
    "Total": "Total",
    "Total Cartons: {total}": "Total Cartons: {total}",
    "Total Errors": "Total Errors",
    "Total Items Created": "Total Items Created",
    "Total Items Created This Week": "Total Items Created This Week",
    "Total Items Created Today": "Total Items Created Today",
    "Total Items Failed": "Total Items Failed",
    "Total Items Failed This Week": "Total Items Failed This Week",
    "Total Items Failed Today": "Total Items Failed Today",
    "Total Items Imported": "Total Items Imported",
    "Total Items Imported This Week": "Total Items Imported This Week",
    "Total Items Imported Today": "Total Items Imported Today",
    "Total Items Rotated": "Total Items Rotated",
    "Total Items Rotated This Week": "Total Items Rotated This Week",
    "Total Items Rotated Today": "Total Items Rotated Today",
    "Total Jobs To Submit": "Total Jobs To Submit",
    "Total Open Cartons": "Total Open Cartons",
    "Total Trim": "Total Trim",
    "Total Trim sq ft": "Total Trim (sq. ft.)",
    "Total Usage": "Total Usage",
    "Total Usage by Site": "Total Usage by Site",
    "Total Usage by z-Fold": "Total Usage by z-Fold",
    "Total Usage by zFold": "Total Usage by z-Fold",
    "Total Z-Fold Area": "Total z-Fold Area",
    "Total Z-Fold Used": "Total z-Fold Used",
    "Total time to fetch {count} items from database {time}ms": "Total time to fetch {count} items from database {time}ms",
    "Total time to select item {time}ms": "Total time to select item {time}ms",
    "Total zFold Area": "Total z-Fold Area",
    "Total zFold Area sq in": "Total z-Fold Area (sq. in.)",
    "Total zFold Area sq mm": "Total z-Fold Area (sq. mm.)",
    "Total zFold Used": "Total z-Fold Used",
    "Track": "Track",
    "Track Activate Delay": "Track Activate Delay",
    "Track Activation Delay": "Track Activation Delay",
    "Track FIRSTTRACK can't be loaded since that roller is used on track SECONDTRACK": "Track {firstTrack} can't be loaded since that roller is used on track {secondTrack}",
    "Track FIRSTTRACK overlaps track SECONDTRACK": "Track {firstTrack} overlaps track {secondTrack}",
    "Track Offset": "Track Offset",
    "Track Sensor to Knife": "Track Sensor to Knife",
    "Track Sensor to Tool": "Track Sensor to Tool",
    "Track settings missing": "Track settings missing",
    "Track {trackNumber}": "Track {trackNumber}",
    "Tracks": "Tracks",
    "Tracks 1 & 2 exceed maximum width for machine": "Tracks 1 & 2 exceed maximum width for machine",
    "Tracks 3 & 4 exceed maximum width for machine": "Tracks 3 & 4 exceed maximum width for machine",
    "TranslateData": "Translate Data",
    "TranslateDataDescription": "Convert Base64 field to ZPL format (plain text)",
    "Trigger": "Trigger",
    "Trigger Field": "Trigger Field",
    "Trigger Guide": "Trigger Guide",
    "Trigger ID Location": "Trigger ID Location",
    "Trigger Value": "Trigger Value",
    "Trim": "Trim",
    "Trim (%)": "Trim (%)",
    "Trim Area": "Trim Area",
    "Trim Area sq in": "Trim Area (sq. in.)",
    "Trim Area sq mm": "Trim Area (sq. mm.)",
    "Trim Overview": "Trim Overview",
    "Trim Width:": "Trim Width:",
    "Trim by Bale Width (%)": "Trim by Bale Width (%)",
    "Trim by Bale Width in percentage": "Trim by Bale Width (%)",
    "Trim by Machine (%)": "Trim by Machine (%)",
    "Trim by Machine Over Time (%)": "Trim by Machine Over Time (%)",
    "Trim by Machine Over Time in percentage": "Trim by Machine Over Time (%)",
    "Trim by Machine in percentage": "Trim by Machine (%)",
    "Trim by Site (%)": "Trim by Site (%)",
    "Trim by Site in percentage": "Trim by Site (%)",
    "Trim in percentage": "Trim (%)",
    "Trim in percentage by Machine Over Time": "Trim (%) by Machine Over Time",
    "Trim percentage": "Trim %",
    "True": "True",
    "True/False": "True/False",
    "Tuesday": "Tuesday",
    "Tuesday Abbrev": "TUE",
    "Type": "Type",
    "Type a value or choose an attribute": "Type a value or choose an attribute",
    "Type or select value": "Type or select value",
    "UPC": "UPC",
    "UPLOAD": "UPLOAD",
    "UPLOADING": "UPLOADING",
    "URL": "URL",
    "URL is required": "URL is required",
    "URL must start with http:// or https://": "URL must start with http:// or https://",
    "Unable to communicate with the server": "Unable to communicate with the server",
    "Unable to test configuration try again": "Unable to test configuration, try again",
    "UnableToResolveLocationProperties": "Unable to resolve location properties. Please supply a Machine, Machine Group or Production Group.",
    "UnableToResolveNumberExpression": "Error evaluating number expression",
    "Unexpected Error": "Unexpected Error",
    "UnexpectedErrorExecutingRuleAction": "Unexpected error executing rule action",
    "Unique Identifier": "Unique Identifier",
    "Unique title is required to save": "Unique title is required to save",
    "Unit of Measure": "Unit of Measure",
    "Universal": "Universal",
    "Unknown": "Unknown",
    "Unknown actionCode:": "Unknown actionCode:",
    "Unmapped": "Unmapped",
    "UnpackedItemIds": "Unpacked Item Identifiers",
    "Unsaved Changes": "Unsaved Changes",
    "Unsupported": "Unsupported",
    "Untitled": "Untitled",
    "Update Count": "Update Count",
    "Update Module Twin": "Update Module Twin",
    "Update the Design Name for Design ID: ": "Update the Design Name for Design ID: ",
    "UpdateCartonDesign": "Update Carton Design",
    "Updated Date": "Last updated {updatedDate, date, ::yyyyMMdd}",
    "Updated Date Never": "Not set up",
    "Upgrade": "Upgrade",
    "Upgrade Legacy Condition": "Upgrade Legacy Condition",
    "Upgrade to rich condition": "Please upgrade to use the new format. The current version will work but cannot be changed.",
    "Upload": "Upload",
    "Upload File": "Upload File",
    "Upload Files": "Upload Files",
    "Upload Jobs from File": "Upload Jobs from File",
    "Upload from File": "Upload from File",
    "Uri is required": "Uri is required",
    "Uri must start with amqp:// or amqps://": "Uri must start with amqp:// or amqps://",
    "Uri must start with http:// or https://": "Uri must start with http:// or https://",
    "Usage": "Usage",
    "Usage by Machine": "Usage by Machine",
    "Use Cross Conveyor": "Use Cross Conveyor",
    "Use Form": "Use Form",
    "Use Machine Group": "Use Machine Group",
    "Use This Design": "Use This Design",
    "Use Wizard": "Use Wizard",
    "Use my email address as my username": "Use my email address as my username.",
    "User": "User",
    "User Error": "User Error",
    "User Logout Expiration": "User Logout Expiration",
    "UserError": "User Error",
    "UserName": "Username",
    "Username": "Username",
    "Username is required": "Username is required",
    "Users": "Users",
    "VMI": "VMI",
    "Value": "Value",
    "Value is required": "Value is required",
    "Value:": "Value:",
    "ValueExpression": "Value Expression",
    "Vendor Managed Inventory": "Vendor Managed Inventory",
    "Verification Code": "Verification Code",
    "Verify Login Information": "Verify Login Information",
    "VerifyLogin": "Verify your user name and password and try again",
    "Version {version}": "Version {version}",
    "Version {version} of the PackNet software is now available. The changes will take effect when the page is reloaded.": "Version {version} of the PackNet software is now available. The changes will take effect when the page is reloaded.",
    "View Audit": "View Audit",
    "VoidFill": "Void Fill",
    "WaitForTrigger": "Wait For Trigger",
    "Waiting To Produce": "Waiting To Produce",
    "Waiting to trigger": "Waiting to trigger",
    "WaitingForTrigger": "Waiting For Trigger",
    "Warnings": "Warnings",
    "Waste Percentage": "Waste Percentage",
    "Waste Separator Activation Offset": "Waste Separator Activation Offset",
    "Waste Separator Deactivation Offset": "Waste Separator Deactivation Offset",
    "Waste Separator Width": "Waste Separator Width",
    "We are working on fixing the problem": "We are working on fixing the problem",
    "We had a problem loading your information": "We had a problem loading your information",
    "Webhook": "Webhook",
    "Wednesday": "Wednesday",
    "Wednesday Abbrev": "WED",
    "Wednesdays": "Wednesdays",
    "Weekdays": "Weekdays",
    "Weight": "Weight",
    "Were here to help For support or spare parts contact us using the information below": "We're here to help. For support or spare parts, contact us using the information below.",
    "What type of scanning device will be used?": "What type of scanning device will be used?",
    "What z-Fold will be used": "What z-Fold will be used",
    "When": "When",
    "Where your job will go": "Where your job will go",
    "Width": "Width",
    "Width is required": "Width is required",
    "Width must be greater than 0": "Width must be greater than 0",
    "Width:": "Width:",
    "Within Planned Production Window": "Within Planned Production Window",
    "Work to Produce": "Work to Produce",
    "Workflow Path": "Workflow Path",
    "Would you like to leave the operator screen for this machine group?": "Would you like to leave the operator screen for this machine group?",
    "Would you like to load the operator screen for a different machine group?": "Would you like to load the operator screen for a different machine group?",
    "Would you like to remove this machine group from the current production group This can negatively impact the way jobs are produced": "Would you like to remove this machine group from the current production group? This can negatively impact the way jobs are produced.",
    "Would you load the operator screen for a different machine group?": "Would you load the operator screen for a different machine group?",
    "X Position": "X Position",
    "X1": "X1",
    "X4": "X4",
    "X4 Error 1000": "General drive error.",
    "X4 Error 1001": "Error during initialization phase.",
    "X4 Error 1002": "Input parameters invalid.",
    "X4 Error 1003": "Motor over temperature.",
    "X4 Error 1004": "Lag Error. Check for paper Jams or mechanical failures.",
    "X4 Error 1005": "Positive or Negative Limit Switch Reached.",
    "X4 Error 1006": "Voltage sag on the drive enable input.",
    "X4 Error 1007": "Error on the encoder happened.",
    "X4 Error 1008": "Main Voltage Problem: DC bus voltage drop, over voltage, or over current.",
    "X4 Error 1009": "Safety Related Error.",
    "X4 Error 1010": "Problem with holding brake.",
    "X4 Error 1011": "Communication (powerlink) breakdown, cable unplugged or defective.",
    "X4 Error 1012": "Overload on drive.",
    "X4 Error 1013": "Overload on motor",
    "X4 Error 1014": "General drive warning.",
    "X4 Error 2000": "Too Few longheads found during longhead search.",
    "X4 Error 2001": "Too Few longheads found during longhead search.",
    "X4 Error 2002": "Longhead was not found at the expected positon.",
    "X4 Error 2003": "Longhead was lost in transition to new postion.",
    "X4 Error 2004": "Battery encoder fault detected and cleared.",
    "X4 Error 2005": "Changer lift failed to extend or sensor did not activate at end of stroke.",
    "X4 Error 2006": "Changer lift failed to extend or sensor did not activate at end of stroke.",
    "X4 Error 2007": "Changer lift sensor failure.",
    "X4 Error 2008": "Set longhead position is below the minimum allowed value.",
    "X4 Error 2009": "Set longhead position is above the maximum allowed value.",
    "X4 Error 2010": "Set longhead positions would result in a physical overlap of longheads.",
    "X4 Error 2011": "Gantry must be at start position in order to start gantry sequence.",
    "X4 Error 2012": "Glue is not ready wait for gluer to heat up.",
    "X4 Error 2013": "Applicator not in Run Mode: Check Weber Applicator console for error message.",
    "X4 Error 2014": "CrossHeadHome Block not detected after hard stop found.",
    "X4 Error 2015": "No detected corrugate in requested track(s)",
    "X4 Error 2016": "Invalid longhead count entered.",
    "X4 Error 2017": "Corrugate failed to load in track.",
    "X4 Error 2018": "Corrugate failed to load in track.",
    "X4 Error 2019": "Corrugate did not unload.",
    "X4 Error 2020": "Emergency stop button pressed.",
    "X4 Error 2021": "Air Pressure Lost.",
    "X4 Error 2022": "Gantry cannot start if gluer is not at Safe Position.",
    "X4 Error 2023": "Active recipe file not found or an active recipe file is not set. Default parameters are being used.",
    "X4 Error 2024": "USB memory not found.",
    "X4 Error 2025": "Simulation code is running on a real PLC.",
    "X4 Error 2026": "Check Service Application for more inforamtion.",
    "X4 Error 2027": "Corrugate detected in multiple tracks. Reload corrugate while in Change Corrugate Postion.",
    "X4 Error 2028": "Changer lift movement attempted with corrugate loaded in the throat. check for debris under sensors. Reload corrugates.",
    "X4 Error 2029": "Invalid measurement z-fold Detection Sensor",
    "X4 Error 2030": "Grabber Assembly failed to exted or sensor did not activate at end of stroke.",
    "X4 Error 2031": "Grabber Assembly failed to exted or sensor did not activate at end of stroke.",
    "X4 Error 2032": "Grabber assembly failed to rotate toward converter or sensor did not activate at end of stroke.",
    "X4 Error 2033": "Grabber assembly failed to rotate away from converter or sensor did not activate at end of stroke",
    "X4 Error 2034": "Grabber Assembly's side clamp failed to open or sensor did not activate at end of stroke.",
    "X4 Error 2035": "Grabber Assembly's side clamp failed to close or sensor did not activate at end of stroke.",
    "X4 Error 2036": "Grabber Assembly's gripper clamp failed to open or sensor did not activate at end of stroke.",
    "X4 Error 2037": "Grabber Assembly's gripper clamb failed to close",
    "X4 Error 2038": "Axis failed to HOME in time. Check all couplers are tightned correctly",
    "X4 Error 2039": "Print command sent but print end signal never received. Restart production. If error persists then ensure Weber Applicator is configured correctly.",
    "X4 Error 2040": "Invalid label position.",
    "X4 Error 2041": "Label was NOT sent to printer. Waited for 45 seconds. Slow print server or printer is not configured correctly in PackNet.",
    "X4 Error 2042": "Label Sync Error - More labels were sent to printer than required for this carton. Restart production to re-sync labels.",
    "X4 Error 2043": "Printer TCP communication error. See Service Application for more info.",
    "X4 Error 2044": "Carton finished out feed but label was never applied.",
    "X4 Error 2045": "Glue melter fault. (Ensure melter is powered on.)",
    "X4 Error 2046": "Check Service Application for more information.",
    "X4 Error 2047": "Machine reset but printer did not become ready.",
    "X4 Error 2048": "Check Service Application for more information.",
    "X4 Error 2049": "Infinite loop in longhead check detected",
    "X4 Error 2050": "Y Cam does not reach glue position.",
    "X4 Error 2051": "Y Cam overshoots glue position.",
    "X4 Error 2052": "Z Cam does not reach glue position.",
    "X4 Error 2053": "Rotation Cam does not reach glue position",
    "X4 Error 2054": "Error calculatign cam from points.",
    "X4 Error 2055": "Error saving cam object.",
    "X4 Error 2056": "Error downloading cam to drive.",
    "X4 Error 2057": "MANUAL_MODE - Machine is in Manual Mode. Must be in Auto Mode to initialize.",
    "X4 Error 2058": "Check Service Application for more information",
    "X4 Error 2059": "PackNet Instruction List corrupted.",
    "X4 Error 2060": "Corrugate detected at outfeed of converter. Ensure Crosshead Kife is cutting box cleanly",
    "X4 Error 2061": "Corrugate NOT detected at outfeed of converter. Check for corrugate jam and feed sensors",
    "X4 Error 2062": "Corrugate detected at offload roller.",
    "X4 Error 2063": "Corrugate NOT detected at offload roller",
    "X4 Error 2064": "AXIS_AXES_NOT_HOMED - An axis or multiple axes are not homed.",
    "X4 Error 2065": "Packaging width (H+W) is greater than corrugate width.",
    "X4 Error 2066": "Box limits exceeded.",
    "X4 Error 2067": "GRABBER_CAL_FAIL - Failure while running Auto Calibration routine for Grabber. Converter Fold Plate not found within Grabber's stroke. check mechanical alignment. Manual homing will be necessary.",
    "X4 Error 2068": "GRABBER_TORQUE_INVALID - Invalid torque detected for Grabber axis. Excessive torque detected while holding Grabber Stationary. Possible interference at end of travel. Rehoming required.",
    "X4 Error 2069": "Address lookup error.",
    "X4 Error 2070": "Faild to extend/open.",
    "X4 Error 2071": "Failed to retract/close.",
    "X4 Error 2072": "Sensor Failure.",
    "X4 Error 2073": "Printer is out of Labels.",
    "X4 Error 2074": "Printer is out of ribbon.",
    "X4 Error 2075": "Printer Error - Ensure Zebra Printer is not paused. Check Applicator and Printer consoles for additional error messages.",
    "X4 Error 2076": "Printer's queue is not empty. Queue must be empty before creating next carton.",
    "X4 Error 2077": "PRINTER_DISABLED - Printer is disabled. Printer must be enabled to make cartons requiring a label.",
    "X4 Error 2078": "Emergency stop button pressed.",
    "X4 Error 2079": "Unknown - Check Service Application for more information",
    "X4 Error 2080": "IO module is not OK.",
    "X4 Error 2081": "Digital output short or overload detected. Check Service Application for more information.",
    "X4 Error 2082": "OFFLOAD_TRANSFER - Corrugate not detected at the beginning of the offload roller movement. (Track 1&3 Side)",
    "X4 Error 2083": "OFFLOAD_TRANSFER- Corrugate not detected at the beginning of the offload roller movement. (Track 2&4 Side)",
    "X4 Error 2084": "Carton not ejected. Carton may have dropped inside the machine. (Track 1&3 Side)",
    "X4 Error 2085": "Rear E-Stop button side activated. (Track 1&3 Side)",
    "X4 Error 2086": "Rear E-Stop button side activated. (Track 2&4 Side)",
    "X4 Error 2087": "Label Apply Error - Label not detected on tamp pad after it was printed.",
    "X4 Error 2088": "Label Apply Error - Label lost while moving to apply postion.",
    "X4 Error 2089": "Label Apply Error - Label lost while tamp pad was over the corrugate waiting for blow command.",
    "X4 Error 2090": "Label Apply Error - Label still detected on tamp pad after the blow command finished.",
    "X4 Error 2091": "Carton not ejected. Carton may have dropped inside the machine. (Track 2&4 Side)",
    "X4 Error 2092": "Changer rear access door open.",
    "X4 Error 2093": "Front sliding door open. Close door. (Track 2&4 Side)",
    "X4 Error 2094": "Front sliding door open. Close door. (Track 1&3 Side)",
    "X4 Error 2095": "E-Stop print head open. Close print head.",
    "X4 Error 2096": "Carton not ejected. Carton is detected in the rollers. (Track 1&3 Side)",
    "X4 Error 2097": "Carton not ejected. Carton is detected in the rollers. (Track 2&4 Side)",
    "X4 Error 2098": "CORRUGATE_LOADED_UNUSED_TRACK - Corrugate loaded on an unused track. Make sure PackNet configuration matches the corrugates loaded.",
    "X4 Error 2099": "Test Alarm",
    "X4 Error 3000": "Changer Roller: General Drive Error",
    "X4 Error 3001": "Changer Roller: Error during initialization phase",
    "X4 Error 3002": "Changer Roller: Input parameters invalid",
    "X4 Error 3003": "Changer Roller: Motor over temperature",
    "X4 Error 3004": "Changer Roller: Lag Error Check for paper Jams or mechanical failures.",
    "X4 Error 3005": "Changer Roller: Positive or Negative Limit Switch Reached",
    "X4 Error 3006": "Changer Roller: Voltage sag on the drive enable input",
    "X4 Error 3007": "Changer Roller: Error on the encoder happened (signal amplitude,..)",
    "X4 Error 3008": "Changer Roller: Main Voltage Problem: DC bus voltage drop, over voltage, or over current",
    "X4 Error 3009": "Changer Roller: Safety Related Error",
    "X4 Error 3010": "Changer Roller: Problem with holding bake",
    "X4 Error 3011": "Changer Roller: Communication (powerlink) breakdown, cable unplugged or defective",
    "X4 Error 3012": "Changer Roller: Overload on drive",
    "X4 Error 3013": "Changer Roller: Overload on Motor",
    "X4 Error 3014": "Changer Roller: General Drive Warning",
    "X4 Error 3100": "Cross Head Axis: General Drive Error",
    "X4 Error 3101": "Cross Head Axis: Error during initialization phase",
    "X4 Error 3102": "Cross Head Axis: Input parameters invalid",
    "X4 Error 3103": "Cross Head Axis: Motor over temperature",
    "X4 Error 3104": "Cross Head Axis: Lag Error Check for paper Jams or mechanical failures.",
    "X4 Error 3105": "Cross Head Axis: Positive or Negative Limit Switch Reached",
    "X4 Error 3106": "Cross Head Axis: Voltage sag on the drive enable input",
    "X4 Error 3107": "Cross Head Axis: Error on the encoder happened (signal amplitude,..)",
    "X4 Error 3108": "Cross Head Axis: Main Voltage Problem: DC bus voltage drop, over voltage, or over current",
    "X4 Error 3109": "Cross Head Axis: Safety Related Error",
    "X4 Error 3110": "Cross Head Axis: Problem with holding bake",
    "X4 Error 3111": "Cross Head Axis: Communication (powerlink) breakdown, cable unplugged or defective",
    "X4 Error 3112": "Cross Head Axis: Overload on drive",
    "X4 Error 3113": "Cross Head Axis: Overload on Motor",
    "X4 Error 3114": "Cross Head Axis: General Drive Warning",
    "X4 Error 3200": "Converter Rollers: General Drive Error",
    "X4 Error 3201": "Converter Rollers: Error during initialization phase",
    "X4 Error 3202": "Converter Rollers: Input parameters invalid",
    "X4 Error 3203": "Converter Rollers: Motor over temperature",
    "X4 Error 3204": "Converter Rollers: Lag Error Check for paper Jams or mechanical failures.",
    "X4 Error 3205": "Converter Rollers: Positive or Negative Limit Switch Reached",
    "X4 Error 3206": "Converter Rollers: Voltage sag on the drive enable input",
    "X4 Error 3207": "Converter Rollers: Error on the encoder happened (signal amplitude,..)",
    "X4 Error 3208": "Converter Rollers: Main Voltage Problem: DC bus voltage drop, over voltage, or over current",
    "X4 Error 3209": "Converter Rollers: Safety Related Error",
    "X4 Error 3210": "Converter Rollers: Problem with holding bake",
    "X4 Error 3211": "Converter Rollers: Communication (powerlink) breakdown, cable unplugged or defective",
    "X4 Error 3212": "Converter Rollers: Overload on drive",
    "X4 Error 3213": "Converter Rollers: Overload on Motor",
    "X4 Error 3214": "Converter Rollers: General Drive Warning",
    "X4 Error 3300": "Print/Label Apply Axis: General Drive Error",
    "X4 Error 3301": "Print/Label Apply Axis: Error during initialization phase",
    "X4 Error 3302": "Print/Label Apply Axis: Input parameters invalid",
    "X4 Error 3303": "Print/Label Apply Axis: Motor over temperature",
    "X4 Error 3304": "Print/Label Apply Axis: Lag Error Check for paper Jams or mechanical failures.",
    "X4 Error 3305": "Print/Label Apply Axis: Positive or Negative Limit Switch Reached",
    "X4 Error 3306": "Print/Label Apply Axis: Voltage sag on the drive enable input",
    "X4 Error 3307": "Print/Label Apply Axis: Error on the encoder happened (signal amplitude,..)",
    "X4 Error 3308": "Print/Label Apply Axis: Main Voltage Problem: DC bus voltage drop, over voltage, or over current",
    "X4 Error 3309": "Print/Label Apply Axis: Safety Related Error",
    "X4 Error 3310": "Print/Label Apply Axis: Problem with holding bake",
    "X4 Error 3311": "Print/Label Apply Axis: Communication (powerlink) breakdown, cable unplugged or defective",
    "X4 Error 3312": "Print/Label Apply Axis: Overload on drive",
    "X4 Error 3313": "Print/Label Apply Axis: Overload on Motor",
    "X4 Error 3314": "Print/Label Apply Axis: General Drive Warning",
    "X4 Error 3400": "Y Gantry Axis: General Drive Error",
    "X4 Error 3401": "Y Gantry Axis: Error during initialization phase",
    "X4 Error 3402": "Y Gantry Axis: Input parameters invalid",
    "X4 Error 3403": "Y Gantry Axis: Motor over temperature",
    "X4 Error 3404": "Y Gantry Axis: Lag Error Check for paper Jams or mechanical failures.",
    "X4 Error 3405": "Y Gantry Axis: Positive or Negative Limit Switch Reached",
    "X4 Error 3406": "Y Gantry Axis: Voltage sag on the drive enable input",
    "X4 Error 3407": "Y Gantry Axis: Error on the encoder happened (signal amplitude,..)",
    "X4 Error 3408": "Y Gantry Axis: Main Voltage Problem: DC bus voltage drop, over voltage, or over current",
    "X4 Error 3409": "Y Gantry Axis: Safety Related Error",
    "X4 Error 3410": "Y Gantry Axis: Problem with holding bake",
    "X4 Error 3411": "Y Gantry Axis: Communication (powerlink) breakdown, cable unplugged or defective",
    "X4 Error 3412": "Y Gantry Axis: Overload on drive",
    "X4 Error 3413": "Y Gantry Axis: Overload on Motor",
    "X4 Error 3414": "Y Gantry Axis: General Drive Warning",
    "X4 Error 3500": "Z Gantry Axis: General Drive Error",
    "X4 Error 3501": "Z Gantry Axis: Error during initialization phase",
    "X4 Error 3502": "Z Gantry Axis: Input parameters invalid",
    "X4 Error 3503": "Z Gantry Axis: Motor over temperature",
    "X4 Error 3504": "Z Gantry Axis: Lag Error Check for paper Jams or mechanical failures.",
    "X4 Error 3505": "Z Gantry Axis: Positive or Negative Limit Switch Reached",
    "X4 Error 3506": "Z Gantry Axis: Voltage sag on the drive enable input",
    "X4 Error 3507": "Z Gantry Axis: Error on the encoder happened (signal amplitude,..)",
    "X4 Error 3508": "Z Gantry Axis: Main Voltage Problem: DC bus voltage drop, over voltage, or over current",
    "X4 Error 3509": "Z Gantry Axis: Safety Related Error",
    "X4 Error 3510": "Z Gantry Axis: Problem with holding bake",
    "X4 Error 3511": "Z Gantry Axis: Communication (powerlink) breakdown, cable unplugged or defective",
    "X4 Error 3512": "Z Gantry Axis: Overload on drive",
    "X4 Error 3513": "Z Gantry Axis: Overload on Motor",
    "X4 Error 3514": "Z Gantry Axis: General Drive Warning",
    "X4 Error 3600": "Rotation Gantry Axis: General Drive Error",
    "X4 Error 3601": "Rotation Gantry Axis: Error during initialization phase",
    "X4 Error 3602": "Rotation Gantry Axis: Input parameters invalid",
    "X4 Error 3603": "Rotation Gantry Axis: Motor over temperature",
    "X4 Error 3604": "Rotation Gantry Axis: Lag Error Check for paper Jams or mechanical failures.",
    "X4 Error 3605": "Rotation Gantry Axis: Positive or Negative Limit Switch Reached",
    "X4 Error 3606": "Rotation Gantry Axis: Voltage sag on the drive enable input",
    "X4 Error 3607": "Rotation Gantry Axis: Error on the encoder happened (signal amplitude,..)",
    "X4 Error 3608": "Rotation Gantry Axis: Main Voltage Problem: DC bus voltage drop, over voltage, or over current",
    "X4 Error 3609": "Rotation Gantry Axis: Safety Related Error",
    "X4 Error 3610": "Rotation Gantry Axis: Problem with holding bake",
    "X4 Error 3611": "Rotation Gantry Axis: Communication (powerlink) breakdown, cable unplugged or defective",
    "X4 Error 3612": "Rotation Gantry Axis: Overload on drive",
    "X4 Error 3613": "Rotation Gantry Axis: Overload on Motor",
    "X4 Error 3614": "Rotation Gantry Axis: General Drive Warning",
    "X4 Error 3700": "Glue Apply Axis: General Drive Error",
    "X4 Error 3701": "Glue Apply Axis: Error during initialization phase",
    "X4 Error 3702": "Glue Apply Axis: Input parameters invalid",
    "X4 Error 3703": "Glue Apply Axis: Motor over temperature",
    "X4 Error 3704": "Glue Apply Axis: Lag Error Check for paper Jams or mechanical failures.",
    "X4 Error 3705": "Glue Apply Axis: Positive or Negative Limit Switch Reached",
    "X4 Error 3706": "Glue Apply Axis: Voltage sag on the drive enable input",
    "X4 Error 3707": "Glue Apply Axis: Error on the encoder happened (signal amplitude,..)",
    "X4 Error 3708": "Glue Apply Axis: Main Voltage Problem: DC bus voltage drop, over voltage, or over current",
    "X4 Error 3709": "Glue Apply Axis: Safety Related Error",
    "X4 Error 3710": "Glue Apply Axis: Problem with holding bake",
    "X4 Error 3711": "Glue Apply Axis: Communication (powerlink) breakdown, cable unplugged or defective",
    "X4 Error 3712": "Glue Apply Axis: Overload on drive",
    "X4 Error 3713": "Glue Apply Axis: Overload on Motor",
    "X4 Error 3714": "Glue Apply Axis: General Drive Warning",
    "X4 Error 3800": "Grabber Axis: General Drive Error",
    "X4 Error 3801": "Grabber Axis: Error during initialization phase",
    "X4 Error 3802": "Grabber Axis: Input parameters invalid",
    "X4 Error 3803": "Grabber Axis: Motor over temperature",
    "X4 Error 3804": "Grabber Axis: Lag Error Check for paper Jams or mechanical failures.",
    "X4 Error 3805": "Grabber Axis: Positive or Negative Limit Switch Reached",
    "X4 Error 3806": "Grabber Axis: Voltage sag on the drive enable input",
    "X4 Error 3807": "Grabber Axis: Error on the encoder happened (signal amplitude,..)",
    "X4 Error 3808": "Grabber Axis: Main Voltage Problem: DC bus voltage drop, over voltage, or over current",
    "X4 Error 3809": "Grabber Axis: Safety Related Error",
    "X4 Error 3810": "Grabber Axis: Problem with holding bake",
    "X4 Error 3811": "Grabber Axis: Communication (powerlink) breakdown, cable unplugged or defective",
    "X4 Error 3812": "Grabber Axis: Overload on drive",
    "X4 Error 3813": "Grabber Axis: Overload on Motor",
    "X4 Error 3814": "Grabber Axis: General Drive Warning",
    "X4 Error 3900": "Off Load Roller: General Drive Error",
    "X4 Error 3901": "Off Load Roller: Error during initialization phase",
    "X4 Error 3902": "Off Load Roller: Input parameters invalid",
    "X4 Error 3903": "Off Load Roller: Motor over temperature",
    "X4 Error 3904": "Off Load Roller: Lag Error Check for paper Jams or mechanical failures.",
    "X4 Error 3905": "Off Load Roller: Positive or Negative Limit Switch Reached",
    "X4 Error 3906": "Off Load Roller: Voltage sag on the drive enable input",
    "X4 Error 3907": "Off Load Roller: Error on the encoder happened (signal amplitude,..)",
    "X4 Error 3908": "Off Load Roller: Main Voltage Problem: DC bus voltage drop, over voltage, or over current",
    "X4 Error 3909": "Off Load Roller: Safety Related Error",
    "X4 Error 3910": "Off Load Roller: Problem with holding bake",
    "X4 Error 3911": "Off Load Roller: Communication (powerlink) breakdown, cable unplugged or defective",
    "X4 Error 3912": "Off Load Roller: Overload on drive",
    "X4 Error 3913": "Off Load Roller: Overload on Motor",
    "X4 Error 3914": "Off Load Roller: General Drive Warning",
    "X4 Warning 0": "Glue Low. Refill Glue.",
    "X4 Warning 1": "Gantry Hard Home Grabber.",
    "X4 Warning 10": "Stacking Table Full. Clear Table. (Track 2&4 Side)",
    "X4 Warning 11": "Stacking Table Full. Clear Table.",
    "X4 Warning 12": "Downtime timer ON.",
    "X4 Warning 13": "Glue heater OFF.",
    "X4 Warning 14": "Unable to ping printer. Ensure printer is on and connected to network.",
    "X4 Warning 15": "Max parameter files",
    "X4 Warning 16": "Gluer is not ready.",
    "X4 Warning 2": "Printer labels low.",
    "X4 Warning 3": "Printer ribbon low.",
    "X4 Warning 4": "Printer data not cleared.",
    "X4 Warning 5": "Corrugate out Track 1.",
    "X4 Warning 6": "Corrugate out Track 2.",
    "X4 Warning 7": "Corrugate out Track 3.",
    "X4 Warning 8": "Corrugate out Track 4.",
    "X4 Warning 9": "Stacking Table Full. Clear Table. (Track 1&3 Side)",
    "X5": "X5",
    "X5 Action CheckAir": ". Check Air Sources:",
    "X5 Action CheckFollowing": ". Check The Following:",
    "X5 Action CheckLabelPrinter": ". Check Printer Panel:",
    "X5 Action CheckScanner": ". Check Scanner For Error:",
    "X5 Action CleanBoxFeeder": ". Clean The Box Feeder Area:",
    "X5 Action CleanConveyor": ". Clean The Conveyor Area:",
    "X5 Action CleanConveyorAndCheckLabelApplicator": ". Clean The Conveyor Area And Check Label Applicator:",
    "X5 Action CleanLabelArea": ". Clean The Label Area:",
    "X5 Action CleanRollers": ". Clean The Rollers Area:",
    "X5 Action CleanSpinner": ". Clean The Spinner Area:",
    "X5 Action ClearAndHome": ". Clear And Re-Home Machine:",
    "X5 Action DoFollowing": ". Do The Following:",
    "X5 Error -601": "Gluer Is Not Ready",
    "X5 Error 1": "E-Stop pressed or doors are open",
    "X5 Error 1 Action": ". Check The Following:",
    "X5 Error 1001": "Flaps Down Gantry X-Axis Error",
    "X5 Error 1001 Action": ". Clear And Re-Home Machine:",
    "X5 Error 1002": "Flaps Down Gantry Y-Axis Error",
    "X5 Error 1002 Action": ". Clear And Re-Home Machine:",
    "X5 Error 1003": "Flaps Down Gantry Z-Axis Error",
    "X5 Error 1003 Action": ". Clear And Re-Home Machine:",
    "X5 Error 1004": "Flap Folder X-Axis Error",
    "X5 Error 1004 Action": ". Clear And Re-Home Machine:",
    "X5 Error 1005": "Flap Folder Y-Axis Error",
    "X5 Error 1005 Action": ". Clear And Re-Home Machine:",
    "X5 Error 1006": "Bander Z-Axis Error",
    "X5 Error 1006 Action": ". Clear And Re-Home Machine:",
    "X5 Error 1007": "Bander Arm 1 Failed To Extend",
    "X5 Error 1007 Action": ". Clear And Re-Home Machine:",
    "X5 Error 1008": "Bander Arm 1 Failed To Retract",
    "X5 Error 1008 Action": ". Clear And Re-Home Machine:",
    "X5 Error 1009": "Bander Arm 2 Failed To Extend",
    "X5 Error 1009 Action": ". Clear And Re-Home Machine:",
    "X5 Error 101": "Track 1 Changer Servo Error",
    "X5 Error 1010": "Bander Arm 2 Failed To Retract",
    "X5 Error 1010 Action": ". Clear And Re-Home Machine:",
    "X5 Error 1010101201": "E-Stop pressed or doors are open",
    "X5 Error 1010101202": "Stopped by external signal",
    "X5 Error 1010101203": "State change timeout",
    "X5 Error 1010101204": "Loss of power",
    "X5 Error 1010101207": "Ethercat error",
    "X5 Error 1010101208": "Low air pressure",
    "X5 Error 1010101215": "Corrugate detected in machine during run-4-ever",
    "X5 Error 1010101220": "Test Error 1",
    "X5 Error 1010101221": "Test Error 2",
    "X5 Error 1010101222": "Test Error 3",
    "X5 Error 1010101223": "Test Error 4",
    "X5 Error 1010103001": "Cut Crease Incorrect tool parameters",
    "X5 Error 1010103002": "Cut Crease Trigger sensored active when arming feeder touch probe",
    "X5 Error 1010103003": "Cut Crease Failed to synchronize feeder at CamIn",
    "X5 Error 1010103004": "Cut Crease Remove corrugate from feeder trigger sensor",
    "X5 Error 1010103702": "Cut Crease Feeder Servo Error",
    "X5 Error 1010103703": "Cut Crease Tool Servo Error",
    "X5 Error 1010104002": "Box Spinner Trigger sensored active when arming touch probe",
    "X5 Error 1010104003": "Box Spinner Failed to synchronize at CamIn",
    "X5 Error 1010104004": "Box Spinner Remove corrugate from trigger sensor",
    "X5 Error 1010104201": "Box Spinner Fault occurred while homing",
    "X5 Error 1010104202": "Box Spinner Axis not in sync when folding started",
    "X5 Error 1010104203": "Box Spinner Outfeed lost the box while ejecting",
    "X5 Error 1010104204": "Box Spinner Box fell off outfeed as it was raised",
    "X5 Error 1010104205": "Box Spinner Remove corrugate from trigg sensor",
    "X5 Error 1010104206": "Box Spinner Cam calculation error",
    "X5 Error 1010104207": "Box Spinner Failed to reset motion valve",
    "X5 Error 1010104208": "Box Spinner Unexpected trigger at box spinner infeed",
    "X5 Error 1010104701": "Box Spinner Adjust Infeed Servo Error",
    "X5 Error 1010104702": "Box Spinner Glue Head Servo Error",
    "X5 Error 1010104703": "Box Spinner Outfeed Servo Error",
    "X5 Error 1010104704": "Box Spinner Adjust Wall Servo Error",
    "X5 Error 1010104705": "Box Spinner Wall Lift Servo Error",
    "X5 Error 1010104706": "Box Spinner Press Support Servo Error",
    "X5 Error 1010104707": "Box Spinner Width Servo Error",
    "X5 Error 1010104708": "Box Spinner Length Servo Error",
    "X5 Error 1010104709": "Box Spinner X-Axis Servo Error",
    "X5 Error 1010104710": "Box Spinner Y-Axis Servo Error",
    "X5 Error 1010104711": "Box Spinner Z-Axis Servo Error",
    "X5 Error 1010104712": "Box Spinner U-Axis Servo Error",
    "X5 Error 1010104713": "Box Spinner Feeder Belt Servo Error",
    "X5 Error 1010104714": "Box Spinner Outfeed Tilt Servo Error",
    "X5 Error 1010104715": "Box Spinner Cross Glue Gun Servo Error",
    "X5 Error 1010104716": "Box Spinner Virtual Robot Servo Error",
    "X5 Error 1010104717": "Box Spinner Virtual X-Axis Servo Error",
    "X5 Error 1010104718": "Box Spinner Virtual Outfeed Servo Error",
    "X5 Error 1010104719": "Box Spinner Logical Infeed Servo Error",
    "X5 Error 1010106201": "Glue Unit: Glue unit has internal error",
    "X5 Error 1010201201": "E-Stop pressed or doors are open",
    "X5 Error 1010201202": "Stopped by external signal",
    "X5 Error 1010201203": "State change timeout",
    "X5 Error 1010201204": "Loss of power",
    "X5 Error 1010201207": "Ethercat error",
    "X5 Error 1010201208": "Low air pressure",
    "X5 Error 1010201215": "Corrugate detected in machine during run-4-ever",
    "X5 Error 1010201220": "Test Error 1",
    "X5 Error 1010201221": "Test Error 2",
    "X5 Error 1010201222": "Test Error 3",
    "X5 Error 1010201223": "Test Error 4",
    "X5 Error 1010203003": "Failed to synchronize Side Trim at CamIn",
    "X5 Error 1010203201": "Side Trim Tool lift failure",
    "X5 Error 1010203702": "Side Trim  Servo Error",
    "X5 Error 1010203704": "Side Trim Servo Error, adjust side trim",
    "X5 Error 1010205202": "Label Printer Conveyor Stopped",
    "X5 Error 1010205202 Action": "Clean The Conveyor Area",
    "X5 Error 1010205204": "Label Printer timeout",
    "X5 Error 1010205208": "Failed to start label printer",
    "X5 Error 1010205209": "Label applicator is not in run mode",
    "X5 Error 1010205210": "Barcode Scan command timed out. Ensure Bar code reader is configured correctly",
    "X5 Error 1010205211": "Failed To Read Barcode",
    "X5 Error 1010205212": "Failed To Verify Barcode",
    "X5 Error 1010205213": "Unable To Connect To Label Printer",
    "X5 Error 1010205214": "Label Printer Is Out Of Labels",
    "X5 Error 1010205215": "Label Printer Is Out Of Ribbon",
    "X5 Error 1010205216": "Label Printer Error",
    "X5 Error 1010205217": "Label Printer Applicator Needs To Be Cleaned",
    "X5 Error 1010205218": "Label Printer Timed out waiting for label to arrive at printer",
    "X5 Error 1010205220": "Label Printer Unknown Barcode was Scanned on initialization",
    "X5 Error 1010205221": "Label Printer Timed Out Clearing Queue",
    "X5 Error 1010205222": "Label Printer Conveyor tracking error",
    "X5 Error 1010205700": "Label Printer Conveyor Servo Error",
    "X5 Error 1010301201": "E-Stop pressed or doors are open",
    "X5 Error 1010301202": "Stopped by external signal",
    "X5 Error 1010301203": "State change timeout",
    "X5 Error 1010301204": "Loss of power",
    "X5 Error 1010301207": "Ethercat error",
    "X5 Error 1010301208": "Low air pressure",
    "X5 Error 1010301215": "Corrugate detected in machine during run-4-ever",
    "X5 Error 1010301220": "Test Error 1",
    "X5 Error 1010301221": "Test Error 2",
    "X5 Error 1010301222": "Test Error 3",
    "X5 Error 1010301223": "Test Error 4",
    "X5 Error 1010303001": "Flap Cut: Incorrect tool parameters",
    "X5 Error 1010303003": "Flap Cut: Failed to synchronize at CamIn",
    "X5 Error 1010303702": "Flap Cut Servo Error",
    "X5 Error 1010303703": "Flap Cut Tool Servo Error",
    "X5 Error 1010303705": "Flap Cut Servo Error, adjust Flap Cut",
    "X5 Error 1010402003": "Changer Failed to synchronize at CamIn",
    "X5 Error 1010402201": "Track 1 Changer lag error",
    "X5 Error 1010402202": "Track 2 Changer lag error",
    "X5 Error 1010402203": "Track 3 Changer lag error",
    "X5 Error 1010402204": "Track 4 Changer lag error",
    "X5 Error 1010402701": "Track 1 Changer Servo Error",
    "X5 Error 1010402702": "Track 2 Changer Servo Error",
    "X5 Error 1010402703": "Track 3 Changer Servo Error",
    "X5 Error 1010402704": "Track 4 Changer Servo Error",
    "X5 Error 1010402705": "Changer Lift Servo Error",
    "X5 Error 1010402706": "Track 1 Changer Encoder Servo Error",
    "X5 Error 1010402707": "Track 2 Changer Encoder Servo Error",
    "X5 Error 1010402708": "Track 3 Changer Encoder Servo Error",
    "X5 Error 1010402709": "Track 4 Changer Encoder Servo Error",
    "X5 Error 1010403002": "Feeder belt trigger sensor is active when arming touch probe",
    "X5 Error 1010403003": "Feeder belt failed to synchronize at CamIn",
    "X5 Error 1010403004": "Feeder belt trigger sensor is blocked",
    "X5 Error 1010403701": "Feeder belt servo error",
    "X5 Error 1010503001": "Helix Cutter Incorrect tool parameters",
    "X5 Error 1010503003": "Helix Cutter Failed to synchronize at CamIn",
    "X5 Error 1010503700": "Helix Cutter Servo Error Tool",
    "X5 Error 1010603001": "Helix Crease Incorrect tool parameters",
    "X5 Error 1010603003": "Helix Crease Failed to synchronize at CamIn",
    "X5 Error 1010603700": "Helix Crease Tool Servo Error",
    "X5 Error 1010803301": "Cyan ink tank empty. Refill ink.",
    "X5 Error 1010803302": "Magenta ink tank empty. Refill ink.",
    "X5 Error 1010803303": "Yellow ink tank empty. Refill ink.",
    "X5 Error 1010803304": "Black ink tank empty. Refill ink.",
    "X5 Error 1010803305": "Orange ink tank empty. Refill ink.",
    "X5 Error 1010803306": "Violet ink tank empty. Refill ink.",
    "X5 Error 1010803307": "Clear z-Fold from press and restart.",
    "X5 Error 1010803308": "Press alarm active. Check press interface.",
    "X5 Error 1010803309": "Job failed to queue on press.",
    "X5 Error 1010803310": "EFIPrint job queue has timed out.",
    "X5 Error 1010803311": "Print queue clearing has timed out. Check press interface.",
    "X5 Error 1010803312": "Press failed to start. Check press interface.",
    "X5 Error 1010803313": "Press clean has timed out. Check press interface.",
    "X5 Error 1010803314": "Press bypass command has timed out. Check press interface.",
    "X5 Error 1010803315": "Press blowers failed to start. Check press interface.",
    "X5 Error 1010803316": "Press jam recovery has timed out. Check press interface.",
    "X5 Error 1010803318": "The requested Print Job could not be found on the Press",
    "X5 Error 1010803319": "Fiery Has Failed to Process the Print Job",
    "X5 Error 1010803320": "Print Job Was Deleted Out Of Fiery During Production",
    "X5 Error 1010803321": "Press is in local mode, production not possible",
    "X5 Error 1010803322": "State Change timeout, Press OPC communication has likely failed",
    "X5 Error 1010803323": "Unexpected stop by the press",
    "X5 Error 1010803324": "Press not in auto mode",
    "X5 Error 1010803325": "Press electronics not ready for printing",
    "X5 Error 1011": "Flap Folder Failed To Extend",
    "X5 Error 1011 Action": ". Clear And Re-Home Machine:",
    "X5 Error 1011003002": "Detect and Delete: Trigger sensored active when arming touch probe",
    "X5 Error 1011003003": "Detect and Delete: Failed to synchronize at CamIn",
    "X5 Error 1011003004": "Detect and Delete: Remove corrugate from trigger sensor",
    "X5 Error 1011003201": "Detect and Delete: Tool lift failure",
    "X5 Error 1011003701": "Detect and Delete: Feeder Servo Error",
    "X5 Error 1011003702": "Detect and Delete: Adjust Side Trim Servo Error",
    "X5 Error 1012": "Flap Folder Failed To Retract",
    "X5 Error 1012 Action": ". Clear And Re-Home Machine:",
    "X5 Error 1013": "Box Not Detected At Flaps Down Module",
    "X5 Error 1013 Action": ". Clear And Re-Home Machine:",
    "X5 Error 1014": "Flaps Down Conveyor 1 Is Jammed",
    "X5 Error 1014 Action": ". Clean The Conveyor Area:",
    "X5 Error 1015": "Flaps Down Conveyor 2 Is Jammed",
    "X5 Error 1015 Action": ". Clean The Conveyor Area:",
    "X5 Error 1016": "Flaps Down Conveyor 3 Is Jammed",
    "X5 Error 1016 Action": ". Clean The Conveyor Area:",
    "X5 Error 1017": "Flaps Down Conveyor 4 Is Jammed",
    "X5 Error 1017 Action": ". Clean The Conveyor Area:",
    "X5 Error 1018": "Need To Remove Boxes From Machine",
    "X5 Error 1019": "Label Printer Is Out Of Labels",
    "X5 Error 102": "Track 2 Changer Servo Error",
    "X5 Error 1020": "Label Printer Is Out Of Ribbon",
    "X5 Error 1020403002": "Feeder belt 2 trigger sensor is active when arming touch probe",
    "X5 Error 1020403003": "Feeder belt 2 failed to synchronize at CamIn",
    "X5 Error 1020403004": "Feeder belt 2 trigger sensor is blocked",
    "X5 Error 1020403701": "Feeder belt 2 servo error",
    "X5 Error 1021": "Label Printer Application Error",
    "X5 Error 1021 Action": ". Clean The Conveyor Area And Check Label Applicator:",
    "X5 Error 1022": "Label Printer Is Not Ready",
    "X5 Error 1022 Action": ". Check Printer Panel:",
    "X5 Error 1023": "Label Printer Applicator Needs To Be Cleaned",
    "X5 Error 1024": "Unable To Connect To Label Printer",
    "X5 Error 1024 Action": ". Check Printer Panel:",
    "X5 Error 1025": "Label Printer Communication Error",
    "X5 Error 1025 Action": ". Check Printer Panel:",
    "X5 Error 1026": "Label Printer Queue Is Not Empty",
    "X5 Error 1026 Action": ". Check Printer Panel:",
    "X5 Error 1027": "Label Printer Timed Out Applying Label",
    "X5 Error 1027 Action": ". Clean The Conveyor Area And Check Label Applicator:",
    "X5 Error 1028": "Failed To Verify Barcode",
    "X5 Error 1029": "Failed To Read Barcode",
    "X5 Error 103": "Track 3 Changer Servo Error",
    "X5 Error 1030": "Flaps Down Air Pressure Is Low",
    "X5 Error 1030 Action": ". Check Air Sources:",
    "X5 Error 1031": "Bander Is Not In Run Mode",
    "X5 Error 1032": "Scanner Error",
    "X5 Error 1032 Action": ". Check Scanner For Error:",
    "X5 Error 1033": "Conveyor Timed Out",
    "X5 Error 1034": "Bander Error",
    "X5 Error 1034 Action": ". Check Scanner For Error:",
    "X5 Error 1035": "Flaps Down Conveyors 1 & 2 Do Not Have Power",
    "X5 Error 1036": "Flaps Down Conveyors 3 & 4 Do Not Have Power",
    "X5 Error 1037": "Label Printer Did Not Receive Label Data",
    "X5 Error 104": "Track 4 Changer Servo Error",
    "X5 Error 105": "Changer Lift Servo Error",
    "X5 Error 106": "Track 1 Changer Lag Error",
    "X5 Error 107": "Track 2 Changer Lag Error",
    "X5 Error 108": "Track 3 Changer Lag Error",
    "X5 Error 109": "Track 4 Changer Lag Error",
    "X5 Error 1101": "Press alarm active. Check press interface.",
    "X5 Error 1102": "Job failed to queue on press.",
    "X5 Error 1103": "Job queue has timed out.",
    "X5 Error 1104": "Print queue clearing has timed out. Check press interface.",
    "X5 Error 1105": "Press failed to start. Check press interface.",
    "X5 Error 1106": "Press clean has timed out. Check press interface.",
    "X5 Error 1107": "Press bypass command has timed out. Check press interface.",
    "X5 Error 1108": "Clear z-Fold from press and restart.",
    "X5 Error 1109": "Cyan ink tank empty. Refill ink.",
    "X5 Error 1110": "Magenta ink tank empty. Refill ink.",
    "X5 Error 1111": "Yellow ink tank empty. Refill ink.",
    "X5 Error 1112": "Black ink tank empty. Refill ink.",
    "X5 Error 1113": "Orange ink tank empty. Refill ink.",
    "X5 Error 1114": "Violet ink tank empty. Refill ink.",
    "X5 Error 1115": "Press blowers failed to start. Check press interface.",
    "X5 Error 1116": "Press jam recovery has timed out. Check press interface.",
    "X5 Error 120": "Changer Error: Test Error",
    "X5 Error 1314": "X5 Error 1314",
    "X5 Error 2": "Stopped By External Signal",
    "X5 Error 201": "Cut And Crease Feeder Servo Axis Error",
    "X5 Error 202": "Cut And Crease Tool Servo Axis Error",
    "X5 Error 203": "Cut And Crease Trigger Sensor Is Blocked",
    "X5 Error 203 Action": ". Clean The Rollers Area:",
    "X5 Error 204": "Corrugate Not Retracted From Cut And Crease Area",
    "X5 Error 220": "Cut And Crease Error: Test Error",
    "X5 Error 3": "State Change Timed Out",
    "X5 Error 301": "Side Trim And Crease Feeder Servo Axis Error",
    "X5 Error 302": "Side Trim Servo Needs Adjustment",
    "X5 Error 303": "Crease Servo Needs Adjustment",
    "X5 Error 304": "Machine error: Side Trim Crease Servo Error Adjust Crease",
    "X5 Error 320": "Side Trim And Crease: Test Error",
    "X5 Error 4": "Low Voltage: Power Supply",
    "X5 Error 401": "Flap Cut Feeder Servo Error",
    "X5 Error 402": "Flap Cut Tool Servo Error",
    "X5 Error 403": "Flap Cut Servo Needs Adjustment",
    "X5 Error 404": "Flap Cut Servo Adjustment Not Referenced",
    "X5 Error 420": "Flap Cut Error: Test Error",
    "X5 Error 5": "Low Voltage: Fuses",
    "X5 Error 501": "Feeder Servo Axis Error",
    "X5 Error 502": "Feeder Trigger Sensor Is Blocked",
    "X5 Error 502 Action": ". Clean The Box Feeder Area:",
    "X5 Error 520": "Feeder Error: Test Error",
    "X5 Error 6": "Servo Power Is Switched Off",
    "X5 Error 601": "Box Spinner Infeed Servo Error",
    "X5 Error 602": "Box Spinner Glue Gun Servo Error",
    "X5 Error 603": "Box Spinner Outfeed Servo Error",
    "X5 Error 604": "Box Spinner Wall Servo Error",
    "X5 Error 605": "Box Spinner Press Support Servo Error",
    "X5 Error 606": "Box Spinner Width Servo Error",
    "X5 Error 607": "Box Spinner Length Servo Error",
    "X5 Error 608": "Box Spinner X-Axis Servo Error",
    "X5 Error 609": "Box Spinner Y-Axis Servo Error",
    "X5 Error 610": "Box Spinner Z-Axis Servo Error",
    "X5 Error 611": "Box Spinner Rotation(U-Axis) Servo Error",
    "X5 Error 612": "Box Spinner Wall Lift Servo Error",
    "X5 Error 613": "Box Spinner Area Needs To Be Cleaned",
    "X5 Error 614": "Box Spinner Area Needs To Be Cleaned",
    "X5 Error 615": "Box Spinner Virtual Spinner Axis Error",
    "X5 Error 616": "Box Spinner Area Needs To Be Cleaned",
    "X5 Error 617": "Gluer Error",
    "X5 Error 618": "Box Spinner Trigger Sensor Was Blocked During Format Change",
    "X5 Error 619": "Box Spinner Air Pressure Is Low",
    "X5 Error 620": "Box Spinner Error: Test Error",
    "X5 Error 621": "Box Spinner Homing Width Axis Fault",
    "X5 Error 622": "Box Spinner Homing Length Axis Fault",
    "X5 Error 623": "Box Spinner Vacuum Gripper Motion Valve Error",
    "X5 Error 624": "Box Spinner Is Not Ready For Next Corrugate",
    "X5 Error 625": "Box Spinner Outfeed Servo Tilt Error",
    "X5 Error 626": "Box Spinner Failed To Reset Servo Drive",
    "X5 Error 627": "Box Spinner Infeed Servo Error",
    "X5 Error 628": "Box Spinner Trigger Sensor Is Blocked",
    "X5 Error 629": "Glue Pump Is Switched Off",
    "X5 Error 7": "EtherCat Error",
    "X5 Error 700": "Machine stopped. Clean the conveyor area:",
    "X5 Error 701": "Box Pusher Failed To Extend",
    "X5 Error 701 Action": ". Clean The Conveyor Area:",
    "X5 Error 702": "Box Pusher Failed To Retract",
    "X5 Error 702 Action": ". Clean The Conveyor Area:",
    "X5 Error 703": "A Box Pusher Sensor Failed. Both Are Active At Same Time",
    "X5 Error 703 Action": ". Clean The Conveyor Area:",
    "X5 Error 704": "Box Lifter Failed To Extend",
    "X5 Error 704 Action": ". Clean The Conveyor Area:",
    "X5 Error 705": "Box Lifter Failed To Retract",
    "X5 Error 705 Action": ". Clean The Conveyor Area:",
    "X5 Error 706": "Box Not Detected At Pusher",
    "X5 Error 706 Action": ". Clean The Spinner Area:",
    "X5 Error 707": "Box Not Detected On Conveyor",
    "X5 Error 707 Action": ". Clean The Conveyor Area:",
    "X5 Error 708": "Box Not Detected At Scanner",
    "X5 Error 709": "Label Printer Is Out Of Labels",
    "X5 Error 709 Action": ". Do The Following:",
    "X5 Error 710": "Label Printer Is Out Of Ribbon",
    "X5 Error 710 Action": ". Do The Following:",
    "X5 Error 711": "Label Printer Needs To Be Started",
    "X5 Error 712": "Label Printer Is Not Ready",
    "X5 Error 712 Action": ". Check Printer Panel:",
    "X5 Error 713": "Label Printer Did Not Receive Label Data",
    "X5 Error 713 Action": ". Check Printer Panel:",
    "X5 Error 714": "Failed To Scan Barcode",
    "X5 Error 715": "Unable To Connect To Label Printer",
    "X5 Error 716": "Label Printer Communication Error",
    "X5 Error 717": "Conveyor Area Needs To Be Cleaned",
    "X5 Error 718": "Label Printer Conveyor Stopped",
    "X5 Error 718 Action": ". Clean The Conveyor Area:",
    "X5 Error 719": "Label Printer Timed Out Clearing Queue",
    "X5 Error 719 Action": ". Clean The Label Area:",
    "X5 Error 720": "Label Printer Timed Out Applying Label",
    "X5 Error 720 Action": ". Clean The Label Area:",
    "X5 Error 721": "Label Printer Applicator Needs To Be Cleaned",
    "X5 Error 722": "Scanner Timed Out Scanning Box",
    "X5 Error 723": "Failed To Verify Barcode",
    "X5 Error 724": "Failed To Read Barcode",
    "X5 Error 725": "Printer Box Pusher Failed To Extend",
    "X5 Error 725 Action": ". Clean The Conveyor Area:",
    "X5 Error 726": "Printer Box Side Pusher Failed To Retract",
    "X5 Error 726 Action": ". Clean The Conveyor Area:",
    "X5 Error 727": "Printer Box Side Pusher Failed To Extend",
    "X5 Error 727 Action": ". Clean The Conveyor Area:",
    "X5 Error 8": "Flaps Down Light Curtain Triggered",
    "X5 Error 801": "Invalid Corrugate Data From PackNet",
    "X5 Error 802": "Detected Multiple PackNet Instances",
    "X5 Error 820": "PackNet Error: Test Error",
    "X5 Error 903": "Machine stopped. Remove all boxes and re-home",
    "X5 Error 904": "Machine stopped. Remove all boxes and re-home",
    "X5 Error 905": "Machine stopped. Remove all boxes and re-home",
    "X5 Error 906": "Machine stopped. Remove all boxes and re-home",
    "X5 Error 907": "Machine stopped. Remove all boxes and re-home",
    "X5 Error 908": "Machine stopped. Remove all boxes and re-home",
    "X5 Error 909": "Machine stopped. Remove all boxes and re-home",
    "X5 Error 910": "Machine stopped. Remove all boxes and re-home",
    "X5 Error 911": "Machine stopped. Remove all boxes and re-home",
    "X5 Error 912": "Machine stopped. Remove all boxes and re-home",
    "X5 Error 913": "Machine error: Check the following:",
    "X5 Error 914": "Machine stopped. Remove all boxes and re-home",
    "X5 Error 915": "Machine stopped. Remove all boxes and re-home",
    "X5 Error 916": "Machine stopped. Remove all boxes and re-home",
    "X5 Error 917": "Machine stopped. Remove all boxes and re-home",
    "X5 Error 918": "Machine stopped. Remove all boxes and re-home",
    "X5 Error 919": "Machine stopped. Remove all boxes and re-home",
    "X5 Error 920": "Machine stopped. Remove all boxes and re-home",
    "X5 Error 921": "Machine stopped. Remove all boxes and re-home",
    "X5 Error 922": "Machine stopped. Remove all boxes and re-home",
    "X5 Error 923": "Machine error: Check the following:",
    "X5 Error 924": "Machine error: Check the following:",
    "X5 Error 925": "Machine error: Check the following:",
    "X5 Error 926": "Machine error: Check the following:",
    "X5 Error 927": "Machine error: Check the following:",
    "X5 Error 928": "Label printer stopped. Check printer panel",
    "X5 Error 929": "Air pressure low. Check air sources:",
    "X5 Error 930": "Bander stopped. Clear area and re-home",
    "X5 Error 934": "Flaps Down has lost communication with machine",
    "X5 Error 935": "Machine error: Check the following:",
    "X5 Error 936": "Machine error: Check the following:",
    "X5 Error 937": "Machine error: Check the following:",
    "X5 Error 938": "Machine error: Check the following:",
    "X5 Error 939": "Machine error: Check the following:",
    "X5 Error 940": "Machine error: Check the following:",
    "X5 Error 941": "Machine error: Check the following:",
    "X5 Error 942": "Machine error: Check the following:",
    "X5 Error 943": "Machine error: Check the following:",
    "X5 Error 944": "Machine error: Check the following:",
    "X5 Error 999": "Flaps Down Test Error",
    "X5 Machine Api": "X5 Machine Api",
    "X5 Warning 1": "Paused by external signal",
    "X5 Warning 1-0": "Paused by external signal",
    "X5 Warning 1-1": "Paused by external E-stop signal",
    "X5 Warning 1-2": "Paused by trim conveyor stopped signal",
    "X5 Warning 1-3": "Paused by conveyance stopped signal",
    "X5 Warning 1-4": "Paused by downstream conveyor full signal",
    "X5 Warning 1001": "Label Printer Is Low On Labels",
    "X5 Warning 1002": "Scanner Did Not Read Barcode",
    "X5 Warning 1003": "Bander Reel Is Low",
    "X5 Warning 101": "Reload z-Fold On Track 1",
    "X5 Warning 1010101201": "Paused by external signal",
    "X5 Warning 1010101202": "Internal Waste Conveyor Error",
    "X5 Warning 1010101203": "External Waste Conveyor Error",
    "X5 Warning 1010101204": "Paused by downstream conveyor full signal",
    "X5 Warning 1010101205": "Waste Conveyor Blockage",
    "X5 Warning 1010101206": "Printer waste conveyor error",
    "X5 Warning 1010101207": "Secondary Internal Waste Conveyor Error",
    "X5 Warning 1010101210": "More than two splices detected in the last 20 boxes",
    "X5 Warning 1010106201": "Gluer is not ready",
    "X5 Warning 1010106202": "Low on Glue",
    "X5 Warning 1010106203": "Glue Guns Are Disabled",
    "X5 Warning 1010106204": "Gluer Error",
    "X5 Warning 1010106205": "The glue unit is turned off",
    "X5 Warning 1010201201": "Paused by external signal",
    "X5 Warning 1010201202": "Internal Waste Conveyor Error",
    "X5 Warning 1010201203": "External Waste Conveyor Error",
    "X5 Warning 1010201204": "Paused by downstream conveyor full signal",
    "X5 Warning 1010201205": "Waste Conveyor Blockage",
    "X5 Warning 1010201206": "Printer waste conveyor error",
    "X5 Warning 1010201207": "Secondary Internal Waste Conveyor Error",
    "X5 Warning 1010201210": "More than two splices detected in the last 20 boxes",
    "X5 Warning 1010205201": "Label Printer Is Low On Labels",
    "X5 Warning 1010301201": "Paused by external signal",
    "X5 Warning 1010301202": "Internal Waste Conveyor Error",
    "X5 Warning 1010301203": "External Waste Conveyor Error",
    "X5 Warning 1010301204": "Paused by downstream conveyor full signal",
    "X5 Warning 1010301205": "Waste Conveyor Blockage",
    "X5 Warning 1010301206": "Printer waste conveyor error",
    "X5 Warning 1010301207": "Secondary Internal Waste Conveyor Error",
    "X5 Warning 1010301210": "More than two splices detected in the last 20 boxes",
    "X5 Warning 1010402201": "Reload z-Fold On Track 1",
    "X5 Warning 1010402202": "Reload z-Fold On Track 2",
    "X5 Warning 1010402203": "Reload z-Fold On Track 3",
    "X5 Warning 1010402204": "Reload z-Fold On Track 4",
    "X5 Warning 1010402205": "Load z-Fold On Track 1",
    "X5 Warning 1010402206": "Load z-Fold On Track 2",
    "X5 Warning 1010402207": "Load z-Fold On Track 3",
    "X5 Warning 1010402208": "Load z-Fold On Track 4",
    "X5 Warning 1010803301": "E-Stop triggered on Press",
    "X5 Warning 1010803302": "Cyan ink level low. Refill ink.",
    "X5 Warning 1010803303": "Magenta ink level low. Refill ink.",
    "X5 Warning 1010803304": "Yellow ink level low. Refill ink.",
    "X5 Warning 1010803305": "Black ink level low. Refill ink.",
    "X5 Warning 1010803306": "Orange ink level low. Refill ink.",
    "X5 Warning 1010803307": "Violet ink level low. Refill ink.",
    "X5 Warning 102": "Reload z-Fold On Track 2",
    "X5 Warning 103": "Reload z-Fold On Track 3",
    "X5 Warning 104": "Reload z-Fold On Track 4",
    "X5 Warning 105": "Load z-Fold On Track 1",
    "X5 Warning 106": "Load z-Fold On Track 2",
    "X5 Warning 107": "Load z-Fold On Track 3",
    "X5 Warning 108": "Load z-Fold On Track 4",
    "X5 Warning 1101": "E-Stop triggered on Press",
    "X5 Warning 1101 Action": ". Check the following.",
    "X5 Warning 1102": "Cyan ink level low. Refill ink.",
    "X5 Warning 1103": "Magenta ink level low. Refill ink.",
    "X5 Warning 1104": "Yellow ink level low. Refill ink.",
    "X5 Warning 1105": "Black ink level low. Refill ink.",
    "X5 Warning 1106": "Orange ink level low. Refill ink.",
    "X5 Warning 1107": "Violet ink level low. Refill ink.",
    "X5 Warning 1108": "Press performing cleaning cycle.",
    "X5 Warning 120": "Changer: Test Warning",
    "X5 Warning 2": "Internal Waste Conveyor Error",
    "X5 Warning 220": "Cut And Crease: Test Warning",
    "X5 Warning 3": "External Waste Conveyor Error",
    "X5 Warning 320": "Side Trim And Crease: Test Warning",
    "X5 Warning 4": "Jam Downstream",
    "X5 Warning 420": "Flap Cut: Test Warning",
    "X5 Warning 5": "Waste Conveyor Is Blocked",
    "X5 Warning 520": "Feeder: Test Warning",
    "X5 Warning 601": "Gluer Is Not Ready",
    "X5 Warning 602": "Gluer Error",
    "X5 Warning 603": "Low On Glue",
    "X5 Warning 603 Action": ". Do The Following:",
    "X5 Warning 604": "Glue Guns Are Disabled",
    "X5 Warning 620": "Box Spinner: Test Warning",
    "X5 Warning 624": "Box Spinner Is Not Ready For Corrugate",
    "X5 Warning 701": "Label Printer Is Waiting For Label Data",
    "X5 Warning 702": "Label Printer Took Longer Than Expected To Receive Label Data",
    "X5 Warning 710": "Label Printer Is Low On Labels",
    "X5 Warning 710 Action": ". Do The Following:",
    "X5 Warning 720": "Label Printer: Test Warning",
    "X5 Warning 820": "PackNet: Test Warning",
    "X5 with Printer": "X5 with Printer",
    "X6": "X6",
    "X6 Error 1010106201": "Glue unit has internal error",
    "X6 Error 1010203003": "Side trim failed to synchronize at CamIn",
    "X6 Error 1010203004": "Side trim trigger sensor is blocked",
    "X6 Error 1010203201": "Side trim tool lift failure",
    "X6 Error 1010203701": "Side trim feeder servo error",
    "X6 Error 1010203702": "Side trim servo error",
    "X6 Error 1010204002": "Box folder trigger sensor is active when arming touch probe",
    "X6 Error 1010204003": "Box folder failed to synchronize at CamIn",
    "X6 Error 1010204004": "Box folder trigger sensor is blocked",
    "X6 Error 1010204201": "Box folder CAM calculation error",
    "X6 Error 1010204701": "Box folder side feeder belt servo error",
    "X6 Error 1010204702": "Box folder side servo width adjustment error",
    "X6 Error 1010204703": "Box folder left side brush servo error",
    "X6 Error 1010204704": "Box folder right side brush servo error",
    "X6 Error 1010204705": "Box folder front servo width adjustment error",
    "X6 Error 1010204706": "Box folder front left feeding belt servo error",
    "X6 Error 1010204707": "Box folder front right feeding belt servo error",
    "X6 Error 1010204708": "Box folder front left servo error",
    "X6 Error 1010204709": "Box folder front right servo error",
    "X6 Error 1010204710": "Box folder front tracking error",
    "X6 Error 1010204711": "Box folder back servo width adjustment error",
    "X6 Error 1010204712": "Box folder back left feeding belt servo error",
    "X6 Error 1010204713": "Box folder back right feeding belt servo error",
    "X6 Error 1010204714": "Box folder back left servo error",
    "X6 Error 1010204715": "Box folder back right servo error",
    "X6 Error 1010204716": "Box folder back left support servo error",
    "X6 Error 1010204717": "Box folder back right support servo error",
    "X6 Error 1010303001": "Flap cut 1 has incorrect tool parameters",
    "X6 Error 1010303003": "Flap cut 1 failed to synchronize at CamIn",
    "X6 Error 1010303004": "Flap cut 1 trigger sensor is blocked",
    "X6 Error 1010303702": "Flap cut 1 feeder servo error",
    "X6 Error 1010303703": "Flap cut 1 tool servo error",
    "X6 Error 1010303705": "Flap cut 1 servo flap cut adjustment error",
    "X6 Error 1010401004": "Trigger sensor is blocked",
    "X6 Error 1010401005": "Missing trigger event",
    "X6 Error 1010401006": "Trigger event occurred too early",
    "X6 Error 1010401201": "E-Stop pressed or doors are open",
    "X6 Error 1010401202": "Stopped by external signal",
    "X6 Error 1010401203": "State change timeout",
    "X6 Error 1010401204": "Loss of power",
    "X6 Error 1010401207": "Ethercat error",
    "X6 Error 1010401208": "Low air pressure",
    "X6 Error 1010401215": "z-Fold detached in machine during local production run-4-ever",
    "X6 Error 1010401220": "Test error 1",
    "X6 Error 1010401221": "Test error 2",
    "X6 Error 1010401222": "Test error 3",
    "X6 Error 1010401223": "Test error 4",
    "X6 Error 1010402003": "Changer failed to synchronize at CamIn",
    "X6 Error 1010402004": "Changer trigger sensor is blocked",
    "X6 Error 1010402201": "Changer track 1 lag error",
    "X6 Error 1010402202": "Changer track 2 lag error",
    "X6 Error 1010402203": "Changer track 3 lag error",
    "X6 Error 1010402204": "Changer track 4 lag error",
    "X6 Error 1010402701": "Changer track 1 servo error",
    "X6 Error 1010402702": "Changer track 2 servo error",
    "X6 Error 1010402703": "Changer track 3 servo error",
    "X6 Error 1010402704": "Changer track 4 servo error",
    "X6 Error 1010402705": "Changer lift servo error",
    "X6 Error 1010402706": "Changer track 1 servo encoder error",
    "X6 Error 1010402707": "Changer track 2 servo encoder error",
    "X6 Error 1010402708": "Changer track 3 servo encoder error",
    "X6 Error 1010402709": "Changer track 4 servo encoder error",
    "X6 Error 1010403002": "Feeder belt trigger sensor is active when arming touch probe",
    "X6 Error 1010403003": "Feeder belt failed to synchronize at CamIn",
    "X6 Error 1010403004": "Feeder belt trigger sensor is blocked",
    "X6 Error 1010403701": "Feeder belt servo error",
    "X6 Error 1010405004": "Print and apply trigger sensor is blocked",
    "X6 Error 1010405202": "Print and apply area must be cleaned before starting production",
    "X6 Error 1010405204": "Print and apply timed out applying label",
    "X6 Error 1010405208": "Print and apply failed to start",
    "X6 Error 1010405209": "Print and apply label applicator is not in run mode",
    "X6 Error 1010405210": "Print and apply barcode scan timed out",
    "X6 Error 1010405211": "Print and apply barcode is not readable",
    "X6 Error 1010405212": "Print and apply barcode failed verification",
    "X6 Error 1010405213": "Print and apply failed to connect to label printer",
    "X6 Error 1010405214": "Print and apply is out of labels",
    "X6 Error 1010405215": "Print and apply is out of ribbon",
    "X6 Error 1010405216": "Print and apply label printer error",
    "X6 Error 1010405217": "Print and apply has unexpected label on tamp pad",
    "X6 Error 1010405218": "Print and apply timed out waiting for label data",
    "X6 Error 1010405220": "Print and apply scanned unknown barcode during initialization",
    "X6 Error 1010405221": "Print and apply timed out clearing print queue",
    "X6 Error 1010405222": "Print and apply conveyor tracking error",
    "X6 Error 1010405700": "Print and apply conveyor servo error",
    "X6 Error 1010503001": "Helix cutter has incorrect tool parameters",
    "X6 Error 1010503003": "Helix cutter failed to synchronize at CamIn",
    "X6 Error 1010503004": "Helix cutter trigger sensor is blocked",
    "X6 Error 1010503700": "Helix cutter tool servo error",
    "X6 Error 1010603001": "Helix crease 1 has incorrect tool parameters",
    "X6 Error 1010603003": "Helix crease 1 failed to synchronize at CamIn",
    "X6 Error 1010603004": "Helix crease 1 trigger sensor is blocked",
    "X6 Error 1010603700": "Helix crease 1 tool servo error",
    "X6 Error 1010703003": "Length crease 1 failed to synchronize at CamIn",
    "X6 Error 1010703004": "Length crease 1 trigger sensor is blocked",
    "X6 Error 1010703701": "Length crease 1 feeder servo error",
    "X6 Error 1010703702": "Length crease 1 servo length adjustment error",
    "X6 Error 1020303001": "Flap cut 2 has incorrect tool parameters",
    "X6 Error 1020303003": "Flap cut 2 failed to synchronize at CamIn",
    "X6 Error 1020303004": "Flap cut 2 trigger sensor is blocked",
    "X6 Error 1020303702": "Flap cut 2 feeder servo error",
    "X6 Error 1020303703": "Flap cut 2 tool servo error",
    "X6 Error 1020303705": "Flap cut 2 servo flap cut adjustment error",
    "X6 Error 1020603001": "Helix crease 2 has incorrect tool parameters",
    "X6 Error 1020603003": "Helix crease 2 failed to synchronize at CamIn",
    "X6 Error 1020603004": "Helix crease 2 trigger sensor is blocked",
    "X6 Error 1020603700": "Helix crease 2 tool servo error",
    "X6 Error 1020703003": "Length crease 2 failed to synchronize at CamIn",
    "X6 Error 1020703004": "Length crease 2 trigger sensor is blocked",
    "X6 Error 1020703701": "Length crease 2 feeder servo error",
    "X6 Error 1020703702": "Length crease 2 servo length adjustment error",
    "X6 Warning 1010106201": "Glue unit is not ready for production",
    "X6 Warning 1010106202": "Glue level is low",
    "X6 Warning 1010106203": "Glue guns are manually disabled",
    "X6 Warning 1010106204": "Glue feeder error",
    "X6 Warning 1010106205": "Glue unit is turned off",
    "X6 Warning 1010401201": "Suspended by external signal",
    "X6 Warning 1010401202": "Internal waste conveyor error",
    "X6 Warning 1010401203": "External waste conveyor error",
    "X6 Warning 1010401204": "Downstream conveyor blocked",
    "X6 Warning 1010401205": "Waste conveyor blocked",
    "X6 Warning 1010401210": "More than two splices detected in the last 20 boxes",
    "X6 Warning 1010402201": "Changer track 1 is out of z-Fold",
    "X6 Warning 1010402202": "Changer track 2 is out of z-Fold",
    "X6 Warning 1010402203": "Changer track 3 is out of z-Fold",
    "X6 Warning 1010402204": "Changer track 4 is out of z-Fold",
    "X6 Warning 1010402205": "Changer track 1 is not loaded",
    "X6 Warning 1010402206": "Changer track 2 is not loaded",
    "X6 Warning 1010402207": "Changer track 3 is not laoded",
    "X6 Warning 1010402208": "Changer track 4 is not loaded",
    "X6 Warning 1010405201": "Print and apply labels are low",
    "X7": "X7",
    "X7 Action CorrugateMismatch": "Select a valid z-Fold that matches configured z-Fold width on X7",
    "X7 Action DefaultError": "Check HMI",
    "X7 Action DefaultWarning": "Check HMI",
    "X7 Action DoorUnsecuredError": "Check HMI",
    "X7 Action DoorUnsecuredWarning": "Check HMI",
    "X7 Action DownstreamConveyorStoppedError": "Check downstream conveyor",
    "X7 Action DownstreamConveyorStoppedWarning": "Check downstream conveyor",
    "X7 Action EStopTriggered": "Check HMI",
    "X7 Action GlueLevelLow": "Refill glue",
    "X7 Action GlueUnitError": "Check HMI",
    "X7 Action GlueUnitOff": "Turn glue unit on",
    "X7 Action GluerNotReady": "Wait for unit to warm up",
    "X7 Action LabelUnitError": "Check HMI",
    "X7 Action LabelUnitLowLabels": "Check HMI, refill and restart",
    "X7 Action LabelUnitLowRibbon": "Check HMI, refill and restart",
    "X7 Action LabelUnitNoLabels": "Check HMI, refill and restart unit",
    "X7 Action LabelUnitNoRibbon": "Check HMI, refill and restart unit",
    "X7 Action LabelUnitOff": "Turn label unit on",
    "X7 Action NoPadLabel": "Check HMI",
    "X7 Action PackNetConnectionError": "Contact customer support",
    "X7 Action ReloadZFoldTrack1": "Check HMI",
    "X7 Action ReloadZFoldTrack2": "Reload z-Fold or check HMI",
    "X7 Action WaitingOnPackNet": "Check HMI and operator panel",
    "X7 Error CorrugateMismatch": "Selected z-Fold Is Invalid",
    "X7 Error DefaultError": "Machine Error Detected",
    "X7 Error DoorUnsecuredError": "Door Unsecured",
    "X7 Error DownstreamConveyorStoppedError": "Downstream Conveyor Stopped",
    "X7 Error EStopTriggered": "E-Stop Triggered",
    "X7 Error GlueUnitError": "Glue Unit Error",
    "X7 Error GlueUnitOff": "Glue Unit Powered Off",
    "X7 Error GluerNotReady": "Gluer Not Ready",
    "X7 Error LabelUnitError": "Label Unit Error",
    "X7 Error LabelUnitNoLabels": "Label Unit Out of Labels",
    "X7 Error LabelUnitNoRibbon": "Label Unit Out of Ribbon",
    "X7 Error LabelUnitOff": "Label Unit Powered Off",
    "X7 Error NoPadLabel": "No Label Detected on Pad",
    "X7 Error PackNetConnectionError": "Packnet Connection Error",
    "X7 Error ReloadZFoldTrack1": "Reload z-Fold on Track 1",
    "X7 Error ReloadZFoldTrack2": "Reload z-Fold on Track 2",
    "X7 Warning DefaultWarning": "Machine Warning Detected",
    "X7 Warning DoorUnsecuredWarning": "Door Unsecured",
    "X7 Warning DownstreamConveyorStoppedWarning": "Downstream Conveyor Stopped",
    "X7 Warning GlueLevelLow": "Glue Level Low",
    "X7 Warning LabelUnitLowLabels": "Label Unit Low on Labels",
    "X7 Warning LabelUnitLowRibbon": "Label Unit Low on Ribbon",
    "X7 Warning WaitingOnPackNet": "Waiting on Packnet",
    "Y Position": "Y Position",
    "Yes": "Yes",
    "Yes, it can contain other products": "Yes, it can contain other products",
    "Yes, the product can nest": "Yes, the product can nest",
    "YesNo": "Yes/No",
    "Yesterday": "Yesterday",
    "You are being logged out of PackNet": "You are being logged out of PackNet",
    "You do not have any data yet": "You don't have any data yet",
    "You don't have any data yet": "You don't have any data yet",
    "You don’t have any data yet": "You don’t have any data yet",
    "You will need to set up scan to create in the Importing Data page": "* You will need to set up scan to create, in the Importing Data page.",
    "Your API Key": "Your API Key",
    "Your Sites": "Your Sites",
    "Your session will expire in": "Your session will expire in {countdown}",
    "Youve been logged out of PackNet": "You've been logged out of PackNet.",
    "Z-Fold Width": "Z-Fold Width",
    "ZPL": "ZPL",
    "ZPL (Plain Text)": "ZPL (Plain Text)",
    "ZPL from Attribute": "ZPL from Attribute",
    "Zebra Printer": "Zebra Printer",
    "ZebraPrinter": "Zebra Printer",
    "ZebraPrinter Error 1": "Printer Error: Out Of Labels",
    "ZebraPrinter Error 2": "Printer Error: Out Of Ribbon",
    "ZebraPrinter Error 3": "Printer Error: Printer Head Open",
    "ZebraPrinter Error 4": "Printer Error: Unexpected Label On Printer",
    "ZebraPrinter Error 5": "Printer Error: Unexpected Label In Printer Queue",
    "ZebraPrinter Error HeadOpen": "Printer Error: Printer Head Open",
    "ZebraPrinter Error OutOfLabels": "Printer Error: Out Of Labels",
    "ZebraPrinter Error UnexpectedLabelInPrinterQueue": "Printer Error: Unexpected Label In Printer Queue",
    "ZebraPrinter Error UnexpectedLabelOnPrinter": "Printer Error: Unexpected Label On Printer",
    "and {count} more": "and {count} more",
    "availability": "Availability",
    "carton": "carton",
    "cartons": "cartons",
    "copy": "copy",
    "current version": "current version",
    "deleteKey": "This will permanently delete {keyName}. This is a permanent change that cannot be undone.",
    "deleteKeyConfirm": "Please type delete to confirm",
    "hr": "hr",
    "idealRunRate": "Ideal Run Rate",
    "in": "in.",
    "label": "label",
    "less than a second": "less than a second",
    "mins": "mins",
    "on machine": "on machine",
    "or": "or",
    "or contact customer support": "or contact customer support",
    "percent Used": "% Used",
    "performance": "Performance",
    "plannedProductionTime": "Planned Production Time",
    "print": "print",
    "quality": "Quality",
    "runTime": "Run Time",
    "singleLetterFriday": "F",
    "singleLetterMonday": "M",
    "singleLetterSaturday": "S",
    "singleLetterSunday": "S",
    "singleLetterThursday": "T",
    "singleLetterTuesday": "T",
    "singleLetterWednesday": "W",
    "skip": "Skip",
    "sq ft": "sq. ft.",
    "sq in": "sq. in.",
    "sq meters": "sq. m.",
    "status": "status",
    "successfulCount": "Sucessful Count",
    "take": "take",
    "to": "to",
    "to Production Group": "to Production Group",
    "to select a column": "to select a column",
    "total corrugated": "total corrugated",
    "total trim": "total trim",
    "totalCount": "Total Count",
    "try using a different alias": "try using a different alias",
    "unplannedDowntime": "Unplanned Downtime",
    "unsuccessfulCount": "Broken Count",
    "weightedIdealCycleTime": "Weighted Ideal Cycle Time",
    "x4": "X4",
    "z-Fold": "z-Fold",
    "z-Fold Brand": "z-Fold Brand",
    "z-Fold Flute": "z-Fold Flute",
    "z-Fold History:": "z-Fold History:",
    "z-Fold Name": "z-Fold Name",
    "z-Fold Not Loaded": "z-Fold Not Loaded",
    "z-Fold Quality": "z-Fold Quality",
    "z-Fold Status": "z-Fold Status",
    "z-Fold Usage": "z-Fold Usage",
    "z-Fold Usage Over Time": "z-Fold Usage Over Time",
    "z-Fold Usage Overview": "z-Fold Usage Overview",
    "z-Fold Used Length": "Length of z-Fold Used",
    "z-Fold Used Width": "Width of z-Fold Used",
    "z-Fold Width": "z-Fold Width",
    "z-Fold Width Tolerance": "z-Fold Width Tolerance",
    "z-Folds": "z-Folds",
    "zFold Brand": "z-Fold Brand",
    "zFold Flute": "z-Fold Flute",
    "zFold Name": "z-Fold Name",
    "zFold Usage": "z-Fold Usage",
    "zFold Usage Over Time": "z-Fold Usage Over Time",
    "zFold Usage Overview": "z-Fold Usage Overview",
    "zFold Used Length": "Length of z-Fold Used",
    "zFold Used Length in": "Length of z-Fold Used (in.)",
    "zFold Used Length mm": "Length of z-Fold Used (mm.)",
    "zFold Used Width": "Width of z-Fold Used",
    "zFold Used Width in": "Width of z-Fold Used (in.)",
    "zFold Used Width mm": "Width of z-Fold Used (mm.)",
    "zFold Width": "z-Fold Width",
    "zFold Width in": "z-Fold Width (in.)",
    "zFold Width mm": "z-Fold Width (mm.)",
    "{alias} sent to queue": "{alias} sent to queue",
    "{count} Jobs": "{count} Jobs",
    "{count} of {totalCount}": "{count} of {totalCount}",
    "{date} {machineGroupAlias} Selected ''{alias}''": "{date} {machineGroupAlias} Selected ''{alias}''",
    "{days}d {hours}h {minutes}m": "{days}d {hours}h {minutes}m",
    "{days}d {hours}h {minutes}m {seconds}s": "{days}d {hours}h {minutes}m {seconds}s",
    "{header} already exists as a header or computed field": "''{header}'' already exists as a header or computed field",
    "{machineName} Current Planned Production Status:": "{machineName} Current Planned Production Status:",
    "{packagingSolutionAlias} Audit Trail": "{packagingSolutionAlias} Audit Trail",
    "{page} of {pages}": "{page} of {pages}"
};
