export function addDays(date, days) {
	var result = new Date(date);
	result.setDate(result.getDate() + days);
	return result;
}

export const dateToUtcEpoch = (date) => {
	const msEpoch = date.getTime();
	const epoch = Math.round(msEpoch / 1000);
	return epoch;
};

export const datesToUtcEpoch = (dates) => {
	if (dates[1]) {
		return [dateToUtcEpoch(dates[0]), dateToUtcEpoch(dates[1])];
	} else {
		const newDate = new Date(dates[0].getTime());
		newDate.setHours(23);
		newDate.setMinutes(59);
		newDate.setSeconds(59);
		const end = dateToUtcEpoch(newDate);
		const start = dateToUtcEpoch(addDays(newDate, -1));
		return [start, end];
	}
};
