import axios from "axios";
import { readLocalStorage } from "@/api/local-storage";

let cancelToken;

export async function getPlannedProductionDataForSite() {
	const token = readLocalStorage("BEARER");
	if (typeof cancelToken != typeof undefined) {
		cancelToken.cancel("Operation canceled due to new request.");
	}

	cancelToken = axios.CancelToken.source();

	const url = "/PlannedProductionApi/api/v1/productionSchedule";

	const response = await axios.get(url, {
		headers: { Authorization: token },
		cancelToken: cancelToken.token,
	});
	return response.data;
}
