import { FormattedMessage } from "react-intl";
import { ReactComponent as NoDataImage } from "@/views/reporting/img/no-site.svg";
import React from "react";
import { createUseStyles } from "react-jss";

const useStyles = createUseStyles(() => ({
	noData: {
		display: "flex !important",
		flexDirection: "column !important",
		alignItems: "center !important",
		justifyContent: "center",
		flex: "1 !important",
		backgroundColor: "white !important",
		padding: "20px",
		borderRadius: "8px",
		height: "100%",
		gap: "16px",
		width: "98%",
	},
	largeNoDataText: {
		fontFamily: "Stolzl-Medium",
		color: "#353430",
		fontSize: "24px",
	},
}));

function NoData() {
	const classes = useStyles();

	return (
		<div className={classes.noData}>
			<NoDataImage />
			<span className={classes.largeNoDataText}>
				<FormattedMessage id="No data for this time frame" />
			</span>
			<span>
				<FormattedMessage id="Select another date range" />
			</span>
		</div>
	);
}

export default NoData;
