export function getAvailabilityDisplayData(status) {
	switch (status) {
		// Returns [order, color, displayStatus, availability]
		case "Online":
		case "MachineOnline":
			return [1, "#417664", "Online", true];
		case "Paused":
		case "MachinePaused":
			return [2, "#fccfbd", "Paused", true];
		case "Error":
		case "MachineError":
			return [3, "#f5804a", "Error", false];
		case "Service":
		case "MachineService":
			return [4, "#fcd778", "Service", false];
		case "Initializing":
		case "MachineInitializing":
			return [5, "#b7cae7", "Initializing", false];
		case "Offline":
		case "MachineOffline":
			return [6, "#818280", "Offline", false];
		case "Changing Corrugate":
		case "MachineChangingCorrugate":
		case "ChangingCorrugate":
			return [7, "#916421", "Changing Corrugate", false];
		case "Emergency Stop":
		case "MachineEmergencyStop":
			return [8, "#8a332e", "Emergency Stop", false];
		case "PackagingMachineOutOfCorrugate":
		case "Packaging Machine Out of Corrugate":
		case "MachineInOutOfCorrugate":
		case "Out of Corrugate":
			return [9, "#cfa97b", "Out of Corrugate", true];
		case "Out of Labels":
			return [9, "#cfa97b", "Out of Labels", true];
		case "Maintenance":
		case "MachineMaintenance":
			return [10, "#6cc6ad", "Maintenance", false];
		case "LightBarrierBroken":
		case "Light Barrier Broken":
			return [11, "#d2d893", "Light Barrier Broken", false];
		case "MachineCalibrating":
			return [12, "#6b779b", "Calibrating", false];
		default:
			return [13, "#0000ff", "Unknown", false];
	}
}
