import { Button, Header, Modal, Segment } from "semantic-ui-react";
import React, { useEffect, useState } from "react";
import { createUseStyles, useTheme } from "react-jss";

import { FormattedMessage } from "react-intl";
import ListContext from "@/components/list-context";

const useStyles = createUseStyles((theme) => ({
	header: {
		border: "0 !important",
		alignItems: "center",
		padding: "24px 32px !important",
		margin: "0 !important",
		backgroundColor: `${theme.colors.light} !important`,
		borderRadius: "0px !important",
		minHeight: "96px",
	},
	headerText: {
		color: `${theme.colors.text} !important`,
		fontWeight: "normal !important",
		fontSize: "40px !important",
		margin: "0 !important",
		lineHeight: "36px !important",
		letterSpacing: "-.5px",
	},
	headerButton: {
		margin: "0 !important",
	},
	modal: {
		"& > :first-child": {
			padding: "0px !important",
		},
	},
	listView: {
		maxHeight: "calc(100vh - 224px)",
		height: "calc(100vh - 224px)",
		overflowY: "auto",
	},
}));

const ListView = ({ title, Table, AddItem, size }) => {
	const theme = useTheme();
	const classes = useStyles({ theme });
	const [modalOpen, setModalOpen] = useState(false);
	const [list, setList] = useState([]);
	const [edit, setEdit] = useState({});
	const [loading, setLoading] = useState(true);
	const [failed, setFailed] = useState(false);
	const contextValue = {
		list,
		setList,
		edit,
		setEdit,
		loading,
		setLoading,
		failed,
		setFailed,
	};

	useEffect(() => {
		if (edit.id) setModalOpen(true);
	}, [edit]);

	return (
		<ListContext.Provider value={contextValue}>
			<Segment clearing className={classes.header}>
				<Header floated="left" className={classes.headerText}>
					{title}
				</Header>
				<Header size="large" floated="right" className={classes.headerButton}>
					{AddItem !== undefined && (
						<Modal
							size={size ?? "large"}
							centered={false}
							closeOnEscape={false}
							closeOnDimmerClick={false}
							open={modalOpen}
							onClose={() => {
								setEdit({});
								setModalOpen(false);
							}}
							className={classes.modal}
							trigger={
								<Button
									primary
									disabled={loading || failed ? true : false}
									onClick={() => setModalOpen(true)}
									data-cy="list-view-add-new-button"
								>
									<span>
										<FormattedMessage id="Add New" />
									</span>
								</Button>
							}
						>
							<Modal.Content>
								<AddItem
									closeModal={() => {
										setEdit({});
										setModalOpen(false);
									}}
								/>
							</Modal.Content>
						</Modal>
					)}
				</Header>
			</Segment>
			<div className={classes.listView} data-cy="list-view">
				<Table />
			</div>
		</ListContext.Provider>
	);
};

export default ListView;
