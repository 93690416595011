import { Icon, Menu } from "semantic-ui-react";
import { Link, useLocation } from "react-router-dom";
import React, { useState } from "react";
import { createUseStyles, useTheme } from "react-jss";

import useOnLoadedEffect from "@/components/use-on-loaded-effect";

const useStyles = createUseStyles({
	menu: {
		display: "inline-flex !important",
		"max-width": "calc(100% - 25px)",
		"border-bottom": "0px !important",
		margin: "0px 0px -2px 0px!important",
		padding: "0px 0px 2px 0px !important",
		overflow: "auto",
		"&::-webkit-scrollbar": {
			display: "none",
		},
	},
	rightButton: {
		background: "white !important",
		width: "20px",
		float: "right",
		display: "contents !important",
	},
	leftButton: {
		width: "40px",
		float: "left",
		display: "contents !important",
		"padding-left": "10px",
	},
	white: {
		height: "60px",
		"border-color": "#ffffff !important",
		color: "#ffffff !important",
		"text-transform": "uppercase !important",
		"letter-spacing": "1.5px",
	},
	grey: {
		height: "64px",
		color: "#a2adb3 !important",
		zIndex: "9",
	},
	blue: {
		height: "64px",
		color: "#353430 !important",
		zIndex: "9",
	},
});

export const filterAuthorizedAndLicensedTabs = (tabs, currentUser) => {
	const licensed = tabs.filter((t) => {
		if (!("licensed" in t))
			return true; // no licensed property so default to true
		else if (!currentUser) return false;
		else if (t.licensed === true)
			return true; // support hard coding the bool values
		else if (t.licensed === false)
			return false; // support hard coding the bool values
		else return currentUser["ff-" + t.licensed] === "True"; //check the user object for the feature flag
	});

	const authorizedTabs = licensed.filter((t) => {
		if (!("authorization" in t))
			return true; // no authorization property so default to true
		else if (!currentUser) return false;
		else if (t.authorization === true)
			return true; // support hard coding the bool values
		else if (t.authorization === false)
			return false; // support hard coding the bool values
		else if (Array.isArray(t.authorization)) {
			// support one of any ex {authorization: ["this", "that"]}
			const lowerAuthorization = t.authorization.map((a) => a.toLocaleLowerCase());
			return currentUser.role.some((r) => lowerAuthorization.indexOf(r) >= 0);
		} else return currentUser.role.includes(t.authorization.toLocaleLowerCase()); // support {authorization: "that"}
	});
	return authorizedTabs;
};

const endOfPath = (part, pathname) => {
	var parts = pathname
		.toLowerCase()
		.split("/")
		.filter((p) => p !== "");
	return parts.at(-1) === part.toLowerCase() || parts.at(-1) === part.toLowerCase() + "#";
};

export const isActive = (item, pathname) => {
	const isPartOfPath = endOfPath(item.link, pathname);
	if (isPartOfPath) {
		return true;
	}

	if (item.defaultMenuItem === true && item.parentLink && endOfPath(item.parentLink, pathname)) {
		return true;
	}

	if (item.subPaths && pathname.includes(item.link)) {
		return item.subPaths.some((subpath) => {
			const regex = new RegExp(`/${item.link}/${subpath}[#]?$`, "i");
			return pathname.match(regex);
		});
	}

	return false;
};

const ScrollMenu = (props) => {
	const theme = useTheme();
	const classes = useStyles({ theme });
	const { pathname } = useLocation();
	const { list, scrollVisible, onActiveSet, initialState, currentUser } = props;
	const [active, setActive] = useState();

	const authorized = filterAuthorizedAndLicensedTabs(list, currentUser);

	useOnLoadedEffect(() => {
		if (!active && authorized[0] && !authorized[0].link) {
			if (initialState) setActive(initialState);
			else setActive(authorized[0]);
		}
	});

	if (!authorized) return <span></span>;

	const refs = authorized.reduce((acc, value) => {
		acc[value.text] = React.createRef();
		return acc;
	}, {});

	const handleClick = (item) => {
		refs[item].current.scrollIntoView({
			behavior: "smooth",
			block: "center",
			inline: "center",
		});
	};

	return (
		<React.Fragment>
			{scrollVisible && (
				<Icon
					name="angle left"
					size="large"
					className={classes.leftButton}
					onClick={() => handleClick(authorized[0].text)}
					data-cy="scroll-menu-left-button"
				/>
			)}
			<Menu pointing secondary className={classes.menu}>
				{authorized.map((item, i) => {
					if (item.link)
						return (
							<Menu.Item
								data-cy-link={item.link}
								key={i}
								as={Link}
								to={item.link}
								name={item.name}
								className={isActive(item, pathname) ? classes.blue : classes.grey}
								active={isActive(item, pathname)}
							>
								<span ref={refs[item.text]}>{item.text}</span>
							</Menu.Item>
						);
					return (
						<Menu.Item
							key={i}
							name={item.name}
							className={active?.text === item.text ? classes.blue : classes.grey}
							active={active?.text === item.text}
							onClick={() => {
								setActive(item);
								if (onActiveSet) onActiveSet(item);
							}}
						>
							<span ref={refs[item.text]}>{item.text}</span>
						</Menu.Item>
					);
				})}
			</Menu>
			{scrollVisible && (
				<Icon
					name="angle right"
					size="large"
					className={classes.rightButton}
					onClick={() => handleClick(authorized[authorized.length - 1].text)}
					data-cy="scroll-menu-right-button"
				/>
			)}
			{/* {active && active.component && <active.component />} */}
			{active && active.component && <React.Fragment>{active.component()}</React.Fragment>}
		</React.Fragment>
	);
};

export default ScrollMenu;
