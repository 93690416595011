import { FormattedMessage } from "react-intl";
import { ReactComponent as ChangeTimeImage } from "@/views/reporting/img/calendar-icon.svg";
import { createUseStyles } from "react-jss";

const useStyles = createUseStyles(() => ({
	container: {
		display: "flex !important",
		flexDirection: "column !important",
		alignItems: "center !important",
		justifyContent: "center",
		flex: "1 !important",
		backgroundColor: "white !important",
		padding: "20px",
		borderRadius: "8px",
		height: "100%",
		gap: "16px",
		width: "98%",
	},
	largeText: {
		fontFamily: "Stolzl-Medium",
		color: "#353430",
		fontSize: "24px",
	},
}));

function ChangeTimeFrame() {
	const classes = useStyles();

	return (
		<div className={classes.container}>
			<ChangeTimeImage />
			<span className={classes.largeText}>
				<FormattedMessage id="Change Time Frame" />
			</span>
			<span>
				<FormattedMessage id="Select only one day to view this graph" />
			</span>
		</div>
	);
}

export default ChangeTimeFrame;
