import axios from "axios";
import { readLocalStorage } from "../api/local-storage.js";

const packNetAxios = axios.create({});
packNetAxios.interceptors.request.use((config) => {
	config.headers.Authorization = readLocalStorage("BEARER");
	return config;
});

export default packNetAxios;
