import { useContext } from "react";
import { ProductionContext } from "@/contexts/production-context";
import { getMachineGroupStatusIcon } from "@/views/production/machine-group-control/machine-group-status-icon";
import { BodyMedium } from "@/components/typography";
import ScanInput from "@/views/production/orders/scan-input/scan-input";
import { OrdersContext } from "@/views/production/orders/orders";
import { useIsFeatureFlagEnabled } from "@/hooks/use-is-feature-flag-enabled.js";
import { Link } from "react-router-dom";
import { Icon } from "semantic-ui-react";
import Flex from "@/components/flex";
import styled, { useTheme } from "styled-components";
import { JobQueueContext } from "@/views/production/orders/job-queue-provider";
import MachineStatusMessage from "@/views/production/orders/machine-status-message/machine-status-message";

const StyledHeader = styled(Flex)`
	width: 100%;
	padding: 16px 24px;
	border-top-right-radius: 6px;
	border-top-left-radius: 6px;
	background-color: ${({ color, theme }) => color || theme.colors.white};
`;

export const getMachineStatusColor = ({ status, productionEnabled, theme }) => {
	switch (status.toLowerCase()) {
		case "initializing":
		case "service":
		case "machineservice":
		case "paused":
			return theme.colors.warningYellow;
		case "offline":
			return theme.colors.offlineGrey;
		case "error":
			return theme.colors.errorRed;
		case "online":
			if (!productionEnabled) return theme.colors.warningYellow;
		default:
			return theme.colors.white;
	}
};

function OrdersHeader() {
	const { currentMachineGroup } = useContext(ProductionContext);
	const { triggerJobByLPN } = useContext(OrdersContext);
	const { selectedBatches, batchesLoading } = useContext(JobQueueContext);
	const theme = useTheme();

	var jobsInProgress = !batchesLoading && selectedBatches && selectedBatches.length > 0;
	const productionEnabled = currentMachineGroup.productionEnabled;
	const showHistoryLink = useIsFeatureFlagEnabled("orders-page-history");

	return (
		<StyledHeader
			alignCenter
			justifyBetween
			color={getMachineStatusColor({
				status: currentMachineGroup.status,
				productionEnabled,
				theme,
			})}
		>
			<Flex alignCenter gap={8}>
				{jobsInProgress && (
					<>
						{!productionEnabled &&
							getMachineGroupStatusIcon({status: currentMachineGroup.status, productionEnabled, useColor: false})}

						<BodyMedium>
							<MachineStatusMessage />
						</BodyMedium>
					</>
				)}
			</Flex>

			<Flex gap={16} alignCenter>
				<ScanInput triggerJobByLPN={(currentMachineGroup, lpn) => triggerJobByLPN(currentMachineGroup, lpn)} />
				{showHistoryLink && (
					<Link to="history" style={{ marginLeft: "auto" }}>
						<Icon name="history" />
						History
					</Link>
				)}
			</Flex>
		</StyledHeader>
	);
}

export default OrdersHeader;
