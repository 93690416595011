import { useMemo } from "react";
import { isFeatureFlagEnabled } from "@/utils/auth.js";
import useCurrentUser from "@/hooks/use-current-user.js";

export const FeatureFlags = {
	SecurePrint: "secure-print",
	Ingestion: {
		LegacyUpgrade: "ingestion-legacy-4-3-upgrade",
		CanRunRulesAfterPackagingCreation: "ingestion-can-run-rules-after-packaging-creation",
	},
};

export const useIsFeatureFlagEnabled = (launchDarklyKey) => {
	const currentUser = useCurrentUser();

	return useMemo(() => isFeatureFlagEnabled(currentUser, launchDarklyKey), [currentUser, launchDarklyKey]);
};
