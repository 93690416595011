import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";

import ProgressBarList from "@/views/reporting/progress-bar-list";
import SwimLanePagination from "@/views/reporting/swim-lane-pagination";
import { getPaginationResult } from "@/views/reporting/functions/swimlanes";
import { getFilteredCorrugate } from "@/views/reporting/functions/corrugate";
import { sortByValue } from "@/views/reporting/functions/sort";

function TrimByBaleWidth({ reportData, dateRange, limit, color, isMetric }) {
	const [presentationData, setPresentationData] = useState([]);
	const intl = useIntl();
	const [pagination, setPagination] = useState({
		perPage: 6,
		page: 1,
	});
	const unitLabel = isMetric ? "sq meters" : "sq ft";

	function getDetails(bale) {
		if (limit === 1000) {
			return (
				bale.corrugateAlias +
				" - " +
				bale.totalZFoldArea.toLocaleString(undefined, {
					maximumFractionDigits: 2,
				}) +
				" " +
				intl.formatMessage({ id: unitLabel }) +
				" (" +
				intl.formatMessage({ id: "total corrugated" }) +
				") - " +
				bale.trimArea.toLocaleString(undefined, {
					maximumFractionDigits: 2,
				}) +
				" " +
				intl.formatMessage({ id: unitLabel }) +
				" (" +
				intl.formatMessage({ id: "total trim" }) +
				") - " +
				intl.formatMessage({ id: "Flute" }) +
				" " +
				bale.corrugateFlute +
				" - " +
				intl.formatMessage({ id: "Brand" }) +
				" " +
				bale.corrugateBrand
			);
		}
		return bale.corrugateAlias;
	}

	function mapData(data) {
		data.byBaleWidth = data.byBaleWidth.map((item) => {
			return {
				...item,
				percentage: item.totalZFoldArea > 0 ? (item.trimArea / item.totalZFoldArea) * 100 : 0,
			};
		});
		data.byBaleWidth = sortByValue(data.byBaleWidth, "percentage", "desc", "corrugateAlias", "asc");
		setPresentationData([
			{
				bales: data.byBaleWidth,
			},
		]);
	}

	useEffect(() => {
		mapData(reportData, dateRange);
	}, [reportData, dateRange]);

	const items = presentationData.map((item) => {
		return {
			items: getFilteredCorrugate(item.bales).map((bale) => {
				return {
					percent: bale.percentage,
					text: getDetails(bale),
					value: `${bale.percentage !== 0 ? bale.percentage.toFixed(1).toLocaleString() : 0}%`,
					popupText:
						bale.trimArea.toLocaleString(undefined, {
							maximumFractionDigits: 2,
						}) +
						" " +
						intl.formatMessage({ id: unitLabel }),
				};
			}),
		};
	});

	const paginationResult = getPaginationResult(items[0], pagination.perPage, pagination.page);
	return paginationResult.items.length ? (
		[
			<div key={`trim-by-bale-width-progress-bar-list-section`}>
				{limit === 1000 &&
					items.map((item, i) => {
						return (
							<ProgressBarList
								heading={item.heading}
								color={color}
								items={item.items}
								limit={1000}
								key={`trim-by-bale-width-progress-bar-list-${i}`}
							/>
						);
					})}
				{limit < 1000 && (
					<ProgressBarList
						heading={paginationResult.heading}
						color={color}
						items={paginationResult.items}
						limit={paginationResult.items.length}
					/>
				)}
			</div>,
			<SwimLanePagination
				pagination={pagination}
				setPagination={setPagination}
				paginationResult={paginationResult}
				limit={limit}
				key={`trim-by-bale-width-swim-lane-pagination`}
			/>,
		]
	) : (
		<div></div>
	);
}

export default TrimByBaleWidth;
