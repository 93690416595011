import axios from "axios";
import { readLocalStorage } from "./local-storage";

export async function createCustomAttribute(data) {
	const token = readLocalStorage("BEARER");

	const response = await axios.post("/DimensionApi/api/v1/customattributes", data, {
		headers: {
			Authorization: token,
			"Content-Type": "application/json",
		},
	});
	return response.data;
}
