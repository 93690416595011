import { FormattedMessage } from "react-intl";
import React from "react";
import { createUseStyles } from "react-jss";
import { Icon } from "semantic-ui-react";

const useStyles = createUseStyles(() => ({
	noData: {
		display: "flex !important",
		flexDirection: "column !important",
		alignItems: "center !important",
		justifyContent: "center",
		flex: "1 !important",
		backgroundColor: "white !important",
		padding: "20px",
		borderRadius: "8px",
		height: "100%",
		gap: "16px",
		width: "98%",
	},
	largeNoDataText: {
		fontFamily: "Stolzl-Medium",
		color: "#353430",
		fontSize: "24px",
	},
}));

function UnexpectedError() {
	const classes = useStyles();

	return (
		<div className={classes.noData}>
			<Icon name="warning circle" size="huge" />
			<span className={classes.largeNoDataText}>
				<FormattedMessage id="Unexpected Error" />
			</span>
			<span>
				<FormattedMessage id="We are working on fixing the problem" />
			</span>
		</div>
	);
}

export default UnexpectedError;
