import { Button, Card, Header, Segment } from "semantic-ui-react";
import React, { useContext } from "react";
import { createUseStyles, useTheme } from "react-jss";

import { FormattedMessage } from "react-intl";
import { Fragment } from "react";
import { ProductionContext } from "@/contexts/production-context";
import { ProductionGroupContext } from "@/contexts/production-group/production-group-context";
import { sortObjectsAscending } from "@/utils/sort";

const useStyles = createUseStyles((theme) => ({
	cards: {},
	header: {
		border: "0 !important",
		alignItems: "center",
		padding: "24px 32px !important",
		margin: "0 !important",
		backgroundColor: "#f2f4f5 !important",
		borderRadius: "0px !important",
	},
	headerText: {
		color: "#353430 !important",
		fontWeight: "normal !important",
		fontSize: "40px !important",
		margin: "0 !important",
		lineHeight: "36px !important",
		letterSpacing: "-.5px",
	},
	headerContent: {
		display: "flex",
		alignItems: "center",
	},
	useGroupButton: {
		marginBottom: "6px !important",
	},
}));

const SelectProductionGroup = () => {
	const theme = useTheme();
	const classes = useStyles({ theme });
	const { productionGroups, currentMachineGroup } = useContext(ProductionContext);
	const { changeProductionGroup } = useContext(ProductionGroupContext);

	const selectPg = async (productionGroup) => {
		await changeProductionGroup(currentMachineGroup, productionGroup);
	};
	return (
		<Fragment>
			<Segment clearing className={classes.header}>
				<Header floated="left" className={classes.headerText}>
					<FormattedMessage
						id="Select production group for machine group {alias}"
						defaultMessage="Select production group for machine group {alias}"
						values={{ alias: currentMachineGroup.alias }}
					/>
				</Header>
			</Segment>
			<Segment basic className={classes.cards}>
				<Card.Group centered>
					{sortObjectsAscending(productionGroups, "alias").map((pg) => (
						<Card key={pg.id} onClick={() => selectPg(pg)}>
							<Card.Content>
								<Card.Header>{pg.alias}</Card.Header>
								<Card.Description textAlign="center">
									<Button primary className={classes.useGroupButton}>
										<FormattedMessage id="Join Production Group" />
									</Button>
								</Card.Description>
							</Card.Content>
						</Card>
					))}
				</Card.Group>
			</Segment>
		</Fragment>
	);
};

export default SelectProductionGroup;
