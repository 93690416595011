import React, { Fragment } from "react";
import { createUseStyles, useTheme } from "react-jss";

import { Select } from "semantic-ui-react";
import { useIntl } from "react-intl";

const orientations = ["Any", "StandingUp", "OnItsSide", "OnItsBack"];

const useStyles = createUseStyles((theme) => ({
	...theme.configurationDialog,
	prompt: {
		paddingBottom: "8px",
	},
	mainInput: {
		width: "400px !important",
	},
}));

const DimOrientation = ({ product, setProduct }) => {
	const intl = useIntl();
	const theme = useTheme();
	const classes = useStyles({ theme });

	return (
		<Fragment>
			<div className={classes.prompt}>{intl.formatMessage({ id: "Product Orientation" })}</div>
			<Select
				className={classes.mainInput}
				placeholder={intl.formatMessage({ id: "Select One" })}
				value={product.orientation ?? "Any"}
				onChange={(e, { value }) => setProduct({ ...product, orientation: value })}
				options={orientations.map((orientation) => {
					return {
						key: orientation,
						value: orientation,
						text: intl.formatMessage({ id: orientation }),
					};
				})}
			/>
		</Fragment>
	);
};

export default DimOrientation;
