import { Button, Table } from "semantic-ui-react";
import React, { Fragment, useContext, useEffect, useState } from "react";
import { createUseStyles, useTheme } from "react-jss";

import FailedRetry from "@/components/failed-retry";
import { FormattedMessage } from "react-intl";
import ListContext from "@/components/list-context";
import Loading from "@/components/loading";
import { readLocalStorage } from "@/api/local-storage";
import useAxios from "@/api/useAxios";
import useMachines from "../machines/use-machines";

const useStyles = createUseStyles((theme) => ({
	...theme.configurationDialog.listView,
	spacer: {
		width: "32px !important",
	},
	tableRow: {
		minHeight: "60px !important",
		height: "60px !important",
	},
}));

const PlcManagement = () => {
	const theme = useTheme();
	const classes = useStyles({ theme });
	const token = readLocalStorage("BEARER");
	const { list, setList, loading, setLoading, failed, setFailed } = useContext(ListContext);
	const { get, updateWithUrl } = useAxios("/PlcManagementApi/api/v1/updates", token);
	const Machines = useMachines(token);
	const [machineList, setMachineList] = useState([]);

	const load = () => {
		setFailed(false);
		setLoading(true);
		setList([]);
		Machines.get(setMachineList, (_) => {
			setLoading(false);
			setFailed(true);
		});
		get(
			(data) => {
				setList(data);
				setLoading(false);
			},
			(_) => {
				setLoading(false);
				setFailed(true);
			}
		);
	};

	useEffect(() => {
		if (list.length === 0 || machineList.length === 0) return;

		var updated = false;
		list.map((l) => {
			const machine = machineList.find((m) => m.id === l.machineId);
			if (l.machineAlias !== machine?.alias) updated = true;
			l.machineAlias = machine?.alias;
		});

		if (updated) setList([...list]);
	}, [list, machineList]);

	useEffect(load, []);

	const approveUpdate = (update) => {
		console.info(update);
		updateWithUrl(`${update.id}/status/approved`, {}, () => load());
	};

	return (
		<Fragment>
			{loading ? (
				<Loading />
			) : failed ? (
				<FailedRetry retry={load} />
			) : (
				<>
					<Table basic="very" className={classes.table}>
						<Table.Header>
							<Table.Row>
								<Table.HeaderCell>
									<FormattedMessage id="Machine" />
								</Table.HeaderCell>
								<Table.HeaderCell>
									<FormattedMessage id="Machine Type" />
								</Table.HeaderCell>
								<Table.HeaderCell>
									<FormattedMessage id="Version" />
								</Table.HeaderCell>
								<Table.HeaderCell>
									<FormattedMessage id="Configuration" />
								</Table.HeaderCell>
								<Table.HeaderCell>
									<FormattedMessage id="Status" />
								</Table.HeaderCell>
								<Table.HeaderCell>
									<FormattedMessage id="Cloud Device Identifier" />
								</Table.HeaderCell>
								<Table.HeaderCell width={2}></Table.HeaderCell>
							</Table.Row>
						</Table.Header>

						<Table.Body>
							{list.map((item) => (
								<Table.Row key={item.id} className={classes.tableRow}>
									<Table.Cell>{item.machineAlias}</Table.Cell>
									<Table.Cell>{item.machineType}</Table.Cell>
									<Table.Cell>{item.plcVersion}</Table.Cell>
									<Table.Cell>{item.plcHardwareConfiguration}</Table.Cell>
									<Table.Cell>{item.updateStatus}</Table.Cell>
									<Table.Cell>{item.iotEdgeDeviceId}</Table.Cell>
									<Table.Cell textAlign="right">
										{item.updateStatus === "Identified" && (
											<Button primary onClick={() => approveUpdate(item)}>
												<FormattedMessage id="Apply Update Now" />
											</Button>
										)}
									</Table.Cell>
								</Table.Row>
							))}
						</Table.Body>
					</Table>
				</>
			)}
		</Fragment>
	);
};

export default PlcManagement;
