import { Button, Icon, Input } from "semantic-ui-react";
import { createUseStyles, useTheme } from "react-jss";

const useStyles = createUseStyles((theme) => ({
	numContainer: {
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		margin: "0 !important",
		padding: "0 !important",
	},
	upDnButton: {
		background: "none !important",
		backgroundColor: "white !important",
		color: `${theme.colors.primary}`,
		border: `1px solid ${theme.colors.border1} !important`,
	},

	textbox: {
		"& input": {
			width: "5.5rem !important",
			textAlign: "center !important",
			margin: `0 4.5px !important`,
		},
	},
}));

const NumericUpDown = (props) => {
	const theme = useTheme();
	const classes = useStyles({ theme });

	const validateAndSet = (num) => {
		const parsed = parseInt(num, 10);
		if (isNaN(num) || isNaN(parsed)) {
			return;
		}
		if (props.minValue !== null && parsed < props.minValue) {
			return;
		}
		if (props.maxValue !== null && parsed > props.maxValue) {
			return;
		}
		if (props.setValue) props.setValue(parsed);
	};

	const isMin = props.minValue && props.number <= props.minValue;
	const isMax = props.maxValue && props.number >= props.maxValue;

	return (
		<div className={classes.numContainer} onBlur={props.hasChanged}>
			<Button
				icon
				className={classes.upDnButton}
				disabled={isMin}
				onClick={() => {
					validateAndSet(props.number - 1);
				}}
				size={props.size}
				data-cy="nud-minus-button"
				data-testid="nud-minus-button"
			>
				<Icon name="minus" disabled={isMin} color="blue" size={props.size} />
			</Button>
			<Input
				className={`${classes.textbox} ${props.className ?? ""}`}
				placeholder="1"
				value={props.number}
				onChange={(_, { value }) => {
					validateAndSet(value);
				}}
				size={props.size}
				data-cy="nud-text"
			/>
			<Button
				icon
				className={classes.upDnButton}
				disabled={isMax}
				onClick={() => {
					validateAndSet(props.number + 1);
				}}
				size={props.size}
				data-cy="nud-plus-button"
				data-testid="nud-plus-button"
			>
				<Icon name="plus" disabled={isMax} color="blue" size={props.size} />
			</Button>
		</div>
	);
};

export default NumericUpDown;
