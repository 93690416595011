import Flex from "@/components/flex";
import { JobQueueContext } from "@/views/production/orders/job-queue-provider";
import { ProductionContext } from "@/contexts/production-context";
import { useContext } from "react";
import Card from "@/components/card";
import { Loader } from "semantic-ui-react";
import styled, { useTheme } from "styled-components";
import { Header2 } from "@/components/typography";
import InProductionOrder from "./in-production-order";
import OrdersHeader, { getMachineStatusColor } from "@/views/production/orders/orders-header";
import { getMachineGroupStatusIcon } from "@/views/production/machine-group-control/machine-group-status-icon";
import MachineStatusMessage from "@/views/production/orders/machine-status-message/machine-status-message";

const StyledCard = styled(Card)`
	&&& {
		display: flex;
		align-items: center;
		flex-direction: column;
		height: 288px;
		flex-shrink: 0;
		background-color: ${({ color, theme }) => color || theme.colors.white};
	}
`;

const BatchContainer = styled(Flex)`
	width: 100%;
	height: 100%;
	padding: 16px 24px;
`;

function InProductionOrdersCard() {
	const { selectedBatches, batchesLoading } = useContext(JobQueueContext);
	const { currentMachineGroup } = useContext(ProductionContext);
	const theme = useTheme();

	var jobsInProgress = !batchesLoading && selectedBatches && selectedBatches.length > 0;

	return (
		<StyledCard
			padding={false}
			color={
				!jobsInProgress
					? getMachineStatusColor({
							status: currentMachineGroup.status,
							productionEnabled: currentMachineGroup.productionEnabled,
							theme,
						})
					: null
			}
		>
			<OrdersHeader />
			{batchesLoading && <Loader active inline="centered" data-testid="loader" />}
			{!jobsInProgress && (
				<Flex style={{ height: "100%" }} alignCenter justifyCenter column gap={16}>
					{getMachineGroupStatusIcon({status: currentMachineGroup.status, productionEnabled: currentMachineGroup.productionEnabled, useColor: false, size: "big"})}
					<Header2>
						<MachineStatusMessage />
					</Header2>
				</Flex>
			)}
			{jobsInProgress && (
				<BatchContainer column justifyBetween>
					{selectedBatches.slice(0, 2).map((batch) => (
						<InProductionOrder selectedBatch={batch} key={batch.batchId} />
					))}
				</BatchContainer>
			)}
		</StyledCard>
	);
}

export default InProductionOrdersCard;
