import ListView from "@/components/list-view";
import New from "./new-pick-zone";
import React from "react";
import Table from "./pick-zones";
import { useIntl } from "react-intl";

function PickZones() {
	const intl = useIntl();
	const title = intl.formatMessage({ id: "Pick Zones" });

	return <ListView title={title} Table={Table} AddItem={New} size="small" />;
}

export default PickZones;
