import { isToday } from "date-fns";

export function isGuid(value) {
	if (!value) return false;

	const matches = value.match(
		//eslint-disable-next-line
		/^(\{){0,1}[0-9a-fA-F]{8}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{12}(\}){0,1}$/gi
	);
	if (!matches) return false;
	return matches.length > 0;
}

export function incrementDateRange(value) {
	if (value.id !== "Custom") return value;
	// const startDate = value.start.endsWith("Z") ? value.start : (value.start += "Z");
	const endDate = value.end.endsWith("Z") ? value.end : (value.end += "Z");
	const dateRange = { ...value };
	const date = Date.parse(endDate);
	let newEndDate = new Date(date);

	if (isToday(newEndDate)) return value;

	newEndDate.setDate(newEndDate.getDate() + 1);
	newEndDate.setMinutes(newEndDate.getMinutes() + newEndDate.getTimezoneOffset());
	dateRange.end = midnightDate(newEndDate);
	return dateRange;
}

export function midnightDate(date) {
	return `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, "0")}-${date
		.getDate()
		.toString()
		.padStart(2, "0")}T00:00:00Z`;
}

export function ensureSiteName(data, customerId) {
	for (const row of data) {
		if (!row.siteName || isGuid(row.siteName)) {
			row.siteName = customerId;
		}
	}
}

export function padZeroIfNeeded(value) {
	if (value < 10) {
		return `0${value}`;
	}
	return value;
}

export function toLocalFormat(date) {
	let month = padZeroIfNeeded(date.getMonth() + 1);
	let day = padZeroIfNeeded(date.getDate());
	let hours = padZeroIfNeeded(date.getHours());
	let minutes = padZeroIfNeeded(date.getMinutes());
	let seconds = padZeroIfNeeded(date.getSeconds());
	return `${date.getFullYear()}-${month}-${day}T${hours}:${minutes}:${seconds}`;
}
