import { allAdministratorPermissions, allOperatorPermissions, allReportingPermissions } from "./new-role";

export const allPermissions = (currentUser) =>
	allAdministratorPermissions
		.concat(allOperatorPermissions)
		.concat(allReportingPermissions)
		.filter((m) => m.licensed == null || currentUser["ff-" + m.licensed] === "True")
		.map((m) => m.name.toLocaleLowerCase());

export const hasAnyReportingPermission = (userRoles) => {
	if (!userRoles) return false;
	const keys = allReportingPermissions.map((k) => k.name.toLowerCase());
	return userRoles.map((r) => r.toLowerCase()).some((r) => keys.indexOf(r) >= 0);
};

export const hasAnyAdminPermission = (userRoles) => {
	if (!userRoles) return false;
	const keys = allAdministratorPermissions.map((k) => k.name.toLowerCase());
	return userRoles.map((r) => r.toLowerCase()).some((r) => keys.indexOf(r) >= 0);
};
