import React from "react";
import ReportingHeader from "../reporting-header";
import { Loader } from "semantic-ui-react";
import { FormattedMessage } from "react-intl";
import { createUseStyles } from "react-jss";
import NoData from "../no-data";
import Row from "./row";

const useStyles = createUseStyles(() => ({
	mainContainer: {
		background: "#f2f4f5",
		display: "flex",
		flexDirection: "column",
		margin: 0,
		paddingLeft: "34px",
		height: "calc(100vh - 128px)",
	},
	header: {
		paddingRight: "34px",
	},
	contentContainer: {
		paddingRight: "10px",
		overflow: "auto",
	},
	container: {
		display: "flex",
		justifyContent: "space-between",
		padding: "9px",
	},
	spacingContainer: {
		display: "flex",
		gap: "0 18px",
		flexDirection: "row",
		flexWrap: "wrap",
	},
}));

function ExperienceContainer({
	currentUser,
	header,
	backMessage,
	dateOptions,
	setDateOptions,
	internalNavigation,
	setInternalNavigation,
	machines,
	setMachines,
	noData,
	isLoading,
	children,
}) {
	const classes = useStyles();

	return (
		<div className={classes.mainContainer}>
			<div className={classes.header}>
				<ReportingHeader
					currentUser={currentUser}
					header={header}
					backMessage={backMessage}
					dateOptions={dateOptions}
					setDateOptions={setDateOptions}
					internalNavigation={internalNavigation}
					setInternalNavigation={setInternalNavigation}
					machines={machines}
					setMachines={setMachines}
				/>
			</div>
			{!isLoading && noData && (
				<Row bottom>
					<NoData />
				</Row>
			)}
			{isLoading && (
				<Loader active>
					<FormattedMessage id="Loading" />
				</Loader>
			)}
			{!noData && !isLoading && (
				<div className={classes.contentContainer}>
					<div className={classes.spacingContainer}>{children}</div>
				</div>
			)}
		</div>
	);
}

export default ExperienceContainer;
