import { Dropdown } from "semantic-ui-react";

const X4Dropdown = ({
	allowChange,
	track,
	selectedMachine,
	dropDownBox,
	allowTrackToBeChanged,
	getValidCorrugatesForTrack,
	trackErrors,
	selectedCorrugateChanged,
}) => {
	const icon = allowChange ? "dropdown" : "dont";
	const displayRed =
		(trackErrors.casset1 && (track.trackNumber === 1 || track.trackNumber === 2)) ||
		(trackErrors.casset2 && (track.trackNumber === 3 || track.trackNumber === 4)) ||
		trackErrors.corrugateTrackErrors?.find((t) => t === track.trackNumber);

	return (
		<Dropdown
			key={`${selectedMachine.alias}-${track.trackNumber}`}
			selection
			selectOnNavigation={false}
			value={track.loadedCorrugate.id}
			className={`${dropDownBox}`}
			disabled={allowTrackToBeChanged(selectedMachine, track) === false}
			icon={icon}
			options={getValidCorrugatesForTrack(selectedMachine, track)}
			placeholder={track.alias}
			style={displayRed ? { color: "red", border: "2px solid rgb(253 4 4 / 50%)", ".dt": "" } : {}}
			onChange={(e, { value }) => selectedCorrugateChanged(value, track, selectedMachine)}
		/>
	);
};

export default X4Dropdown;
