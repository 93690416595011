import { FormattedMessage, useIntl } from "react-intl";
import React, { Fragment, useContext, useEffect, useState } from "react";
import { createUseStyles, useTheme } from "react-jss";

import ConfirmModal from "../../../components/confirm-modal";
import DeleteButton from "@/components/delete-button";
import EditButton from "@/components/edit-button";
import FailedRetry from "@/components/failed-retry";
import ListContext from "@/components/list-context";
import Loading from "@/components/loading";
import { Table } from "semantic-ui-react";
import { readLocalStorage } from "@/api/local-storage";
import useAxios from "@/api/useAxios";

const useStyles = createUseStyles((theme) => ({
	...theme.configurationDialog.listView,
	spacer: {
		width: "32px !important",
	},
}));

const Roles = () => {
	const intl = useIntl();
	const theme = useTheme();
	const classes = useStyles({ theme });
	const token = readLocalStorage("BEARER");
	const { list, setList, setEdit, loading, setLoading, failed, setFailed } = useContext(ListContext);
	const { get, remove } = useAxios("/IdentityApi/api/v1/roles", token);
	const [isDeleteModalOpened, setIsDeleteModalOpened] = useState(false);
	const [itemToDelete, setItemToDelete] = useState({});

	const load = () => {
		setFailed(false);
		setLoading(true);
		setList([]);
		get(
			(data) => {
				setList(data);
				setLoading(false);
			},
			(_) => {
				setLoading(false);
				setFailed(true);
			}
		);
	};

	useEffect(load, []);

	const removeItem = async (item) => {
		setItemToDelete(item);
		setIsDeleteModalOpened(true);
	};

	return (
		<Fragment>
			<ConfirmModal
				open={isDeleteModalOpened}
				onClose={async (response) => {
					if (response) {
						remove(itemToDelete.id, setList);
					}
					setIsDeleteModalOpened(false);
					setItemToDelete({});
				}}
				titleText={intl.formatMessage(
					{
						id: "Delete Role",
					},
					{
						alias: itemToDelete && itemToDelete.alias ? itemToDelete.alias : "",
					}
				)}
				detailText="This change can not be undone"
				confirmText="Delete"
				cancelText="Cancel"
			/>
			{loading ? (
				<Loading />
			) : failed ? (
				<FailedRetry retry={load} />
			) : (
				<Table basic="very" className={classes.table}>
					<Table.Header>
						<Table.Row>
							<Table.HeaderCell>
								<FormattedMessage id="Name" />
							</Table.HeaderCell>
							<Table.HeaderCell textAlign="center" collapsing>
								<FormattedMessage id="Edit" />
							</Table.HeaderCell>
							<Table.HeaderCell className={classes.spacer}></Table.HeaderCell>
							<Table.HeaderCell textAlign="right" collapsing>
								<FormattedMessage id="Delete" />
							</Table.HeaderCell>
						</Table.Row>
					</Table.Header>

					<Table.Body>
						{list.map((item) => (
							<Table.Row key={item.id}>
								<Table.Cell>{item.name}</Table.Cell>
								<Table.Cell textAlign="center" collapsing className={classes.actionButtons}>
									<EditButton onClick={() => setEdit(item)} />
								</Table.Cell>
								<Table.Cell className={classes.spacer}></Table.Cell>
								<Table.Cell textAlign="right" collapsing className={classes.actionButtons}>
									<DeleteButton onClick={() => removeItem(item)} />
								</Table.Cell>
							</Table.Row>
						))}
					</Table.Body>
				</Table>
			)}
		</Fragment>
	);
};

export default Roles;
