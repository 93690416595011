import React, { useEffect, useState } from "react";
import Chart from "react-apexcharts";
import { useIntl } from "react-intl";

function TotalPercentage({ reportData, dateRange, color }) {
	const intl = useIntl();
	const [presentationData, setPresentationData] = useState({});

	function mapData(data) {
		setPresentationData({ percentage: data.totalPercentage });
	}

	useEffect(() => {
		mapData(reportData, dateRange);
	}, [reportData, dateRange]);

	return presentationData && !isNaN(presentationData.percentage) ? (
		<div>
			<Chart
				options={{
					plotOptions: {
						radialBar: {
							inverseOrder: true,
							offsetY: -40,
							hollow: {
								size: "60%",
							},
							track: {
								background: "#e3e7e9",
							},
							dataLabels: {
								showOn: "always",
								value: {
									color: "#353430",
									width: "114px",
									height: "62px",
									flexGrow: "0",
									fontFamily: "Stolzl",
									fontSize: "51px",
									fontWeight: "bold",
									fontStretch: "normal",
									fontStyle: "normal",
									lineHeight: "normal",
									letterSpacing: "normal",
									textAlign: "center",
									offsetY: -10,
									show: true,
								},
								name: {
									formatter: (val) => `${val.toUpperCase()}`,
									offsetY: 40,
									show: true,
									fontFamily: "Stolzl",
									fontSize: "13px",
									fontWeight: "bold",
									fontStretch: "normal",
									fontStyle: "normal",
									lineHeight: "normal",
									letterSpacing: "2px",
									textAlign: "center",
									color: "#353430",
									textTransform: "uppercase",
								},
							},
						},
					},
					stroke: {
						lineCap: "round",
						colors: ["red"],
					},
					fill: {
						colors: [color],
						opacity: 1,
						type: "solid",
					},
					labels: [intl.formatMessage({ id: "Total Trim" })],
					states: {
						hover: {
							filter: {
								type: "none",
							},
						},
						active: {
							filter: {
								type: "none",
							},
						},
					},
				}}
				series={[presentationData?.percentage !== 0 ? presentationData?.percentage.toFixed(1).toLocaleString() : 0]}
				type="radialBar"
				height={350}
			/>
		</div>
	) : (
		<div></div>
	);
}

export default TotalPercentage;
