import { Form, Grid, Header } from "semantic-ui-react";
import { FormattedMessage } from "react-intl";
import { createUseStyles, useTheme } from "react-jss";

import BgImage from "@/assets/images/loginBgImage.png";
import React from "react";
import LoginLeftPanel from "./login-left-panel";

const useStyles = createUseStyles((theme) => ({
	container: {
		backgroundColor: "#2e475f",
		width: "100%",
		height: "100vh",
		margin: "0 !important",
		"& div.two": { padding: "0 !important" },
	},
	rightSide: {
		backgroundImage: `url(${BgImage})`,
		backgroundRepeat: "no-repeat",
		backgroundSize: "cover",
		display: "flex !important",
		justifyContent: "center",
		alignItems: "center",
	},
	fullHeight: { height: "100%" },
	form: {
		width: "491px",
		backgroundColor: "#f2f4f5",
		borderRadius: "6px",
	},
	centerForm: {
		padding: "40px 54px",
		fontFamily: "Stolzl-Book",
	},
	formFields: {
		padding: "40px",
	},
	formHeader: {
		backgroundColor: "#ffffff",
		textAlign: "center",
		padding: "40px !important",
		borderRadius: "6px",
		fontFamily: "Stolzl-Bold",
	},
	message: {
		display: "flex",
		justifyContent: "space-between",
		alignItems: "center",
	},
	dropdown: {
		"& i": { float: "right" },
	},
}));

const LogOut = () => {
	const theme = useTheme();
	const classes = useStyles({ theme });

	return (
		<Grid centered className={classes.container}>
			<Grid.Row columns={2}>
				<Grid.Column width={4}>
					<LoginLeftPanel />
				</Grid.Column>
				<Grid.Column width={12} className={classes.rightSide}>
					<Form method="post" className={classes.form}>
						<Header className={classes.formHeader} as="h1">
							<FormattedMessage id="Logging Out" />
						</Header>
						<div className={classes.centerForm}>
							<br />
							<FormattedMessage id="You are being logged out of PackNet" />
						</div>
					</Form>
				</Grid.Column>
			</Grid.Row>
		</Grid>
	);
};

export default LogOut;
