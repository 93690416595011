import { useAppInsightsContext } from "@microsoft/applicationinsights-react-js";
import { useMemo } from "react";
import { getApplicationInsights } from "@/contexts/app-insights.jsx";

export const getPackNetAppInsights = () => {
	const appInsights = getApplicationInsights();

	return {
		trackEvent: (event, customProperties) => {
			if (appInsights) {
				appInsights.trackEvent(event, customProperties);
			} else {
				console.info("AppInsights track event", event, customProperties);
			}
		},
		trackException: (exception, customProperties) => {
			if (appInsights) {
				appInsights.trackException(exception, customProperties);
			} else {
				console.error("AppInsights track exception", exception, customProperties);
			}
		},
		trackMetric: (metric, customProperties) => {
			if (appInsights) {
				appInsights.trackMetric(metric, customProperties);
			} else {
				console.info("AppInsights track metric", metric, customProperties);
			}
		},
	};
};

const usePackNetAppInsightsContext = () => {
	const appInsights = useAppInsightsContext();

	return useMemo(getPackNetAppInsights, [appInsights]);
};

export default usePackNetAppInsightsContext;
