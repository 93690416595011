import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { createUseStyles, useTheme } from "react-jss";
import { Menu, Input } from "semantic-ui-react";

import { ReactComponent as Back } from "@/assets/images/caret-back.svg";

const useStyles = createUseStyles((theme) => ({
	container: {
		height: "65px",
		borderRadius: "0 !important",
		backgroundColor: `${theme.colors.light} !important`,
	},
	fullWidth: {
		flex: "1 !important",
	},
	leftAction: {
		"&:before": {
			width: "0 !important",
		},
	},
	backIcon: {
		height: 18,
		width: 12,
	},
}));

const SearchNav = ({ term, setTerm, searchHandlers }) => {
	const theme = useTheme();
	const classes = useStyles({ theme });

	const handleSearch = async (evt) => {
		if (evt.charCode !== 13 || term.length < 3) {
			return;
		}

		await searchHandlers.search(term);
	};

	return (
		<Menu fixed="top" className={classes.container}>
			<Menu.Item className={classes.leftAction}>
				<Link to="/cart/scan">
					<Back className={classes.backIcon} />
				</Link>
			</Menu.Item>
			<Menu.Menu className={classes.fullWidth}>
				<Menu.Item className={classes.fullWidth}>
					<Input
						value={term}
						onChange={(evt) => setTerm(evt.target.value)}
						icon="search"
						placeholder="Search..."
						onKeyPress={handleSearch}
					/>
				</Menu.Item>
			</Menu.Menu>
		</Menu>
	);
};

SearchNav.propTypes = {
	// the provided search term
	term: PropTypes.string,

	searchHandlers: PropTypes.shape({
		search: PropTypes.func,
	}),
};

export { SearchNav as default };
