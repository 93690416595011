import React, { useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { createUseStyles } from "react-jss";
import Chart from "react-apexcharts";
import CheckboxButton from "@/views/reporting/checkbox-button";
import { timeLabelFormatter, getDateIndex } from "@/views/reporting/functions/over-time";
import { incrementDateRange } from "@/views/reporting/functions";
import { sortByOrder } from "@/views/reporting/functions/sort";

const useStyles = createUseStyles((theme) => ({
	container: {
		display: "flex",
		flexDirection: "row",
		"& > div:first-child": {
			flexBasis: "70%",
		},
		"& > div:last-child": {
			flexBasis: "30%",
		},
	},
	errorContainer: {
		display: "flex",
		marginBottom: "32px",
	},
	header: {
		fontFamily: "Stolzl",
		fontSize: "15px",
		fontWeight: "bold",
		fontStretch: "normal",
		fontStyle: "normal",
		lineHeight: "normal",
		letterSpacing: "normal",
		textAlign: "left",
		marginBottom: "20px",
		color: "black",
	},
	sideMenuBody: {
		display: "flex",
		flexDirection: "column",
		gap: "1%",
		height: "343px",
		overflow: "auto",
		"&::-webkit-scrollbar-track": {
			backgroundColor: "white",
		},
	},
	sideMenuHeader: {
		display: "flex",
		flexDirection: "column",
		flexFlow: "column wrap",
	},
	checkbox: {
		fontFamily: "Stolzl",
		fontSize: "15px",
		fontWeight: "normal",
		fontStretch: "normal",
		fontStyle: "normal",
		lineHeight: "normal",
		letterSpacing: "normal",
		textAlign: "left",
	},
}));

function OEEOverTimeGraph({ reportData, dateRange, order }) {
	const classes = useStyles();
	const [aggregatedData, setAggregatedData] = useState([]);
	const [siteBrandTypes, setSiteBrandTypes] = useState([]);
	const intl = useIntl();
	const labelColors = "#7c7b79";

	function toggle(tenantId, metric) {
		const site = siteBrandTypes.find((s) => s.tenantId === tenantId);
		const key = site.metrics.find((c) => c.metric === metric);
		key.checked = !key.checked;
		setSiteBrandTypes([...siteBrandTypes]);
	}

	function getOptions(allData, sitesSelection) {
		if (!allData || !allData.length) return { series: [] };

		const flatMetrics = sitesSelection.flatMap((x) => x.metrics);
		const presentationData = allData.filter(
			(d) => flatMetrics.find((s) => s.tenantId === d.tenantId && s.metric === d.metric).checked,
		);

		if (!presentationData || !presentationData.length) return { series: [] };

		const colors = presentationData.map((a) => a.color);
		const series = presentationData.map((a) => ({
			name: intl.formatMessage({ id: a.metric }),
			data: a.data.map((d) => (d.score * 100).toLocaleString(undefined, { maximumFractionDigits: 1 })),
		}));

		const indexDate = getDateIndex(incrementDateRange(dateRange));
		let labelText = indexDate;

		const allDates = presentationData[0].data.map((item) => {
			return timeLabelFormatter(item, indexDate, 0);
		});
		const xaxisCategories = presentationData[0].data.map((item, i) => {
			return timeLabelFormatter(item, indexDate, i);
		});

		return {
			chart: {
				toolbar: {
					show: false,
					tools: {
						zoom: false,
					},
				},
				type: "line",
				stacked: false,
			},
			dataLabels: {
				enabled: false,
			},
			colors: colors,
			series: series,
			stroke: {
				curve: "smooth",
				width: [1, 1],
			},
			markers: {
				size: 0,
			},
			plotOptions: {
				bar: {
					columnWidth: "20%",
				},
			},
			xaxis: {
				labels: {
					style: {
						colors: labelColors,
						fontSize: "16px",
						fontFamily: "Stolzl-Book",
					},
				},
				title: {
					text: intl.formatMessage({ id: labelText }),
					style: {
						color: "#353430",
						fontFamily: "Stolzl",
						fontSize: "15px",
						fontWeight: "normal",
					},
					offsetY: 0,
				},
				categories: xaxisCategories,
			},
			yaxis: [
				{
					axisTicks: {
						show: false,
					},
					axisBorder: {
						show: false,
						color: "#e3e7e9",
					},
					min: 0,
					max: 100,
					stepSize: 10,
					labels: {
						style: {
							colors: labelColors,
							fontSize: "16px",
							fontFamily: "Stolzl-Book",
							margin: "10px",
						},
						formatter: (value) => {
							return `${value}%`;
						},
						offsetX: 32,
					},
					title: {
						text: intl.formatMessage({ id: "Percentage" }),
						style: {
							color: "#353430",
							fontFamily: "Stolzl",
							fontSize: "15px",
							fontWeight: "normal",
						},
						offsetX: -20,
					},
				},
			],
			tooltip: {
				show: false,
				x: {
					formatter: (index) => {
						return allDates[index - 1];
					},
				},
			},
			legend: {
				show: false,
			},
			grid: {
				show: true,
				borderColor: "#e3e7e9",
				strokeDashArray: 0,
				position: "back",
				xaxis: {
					lines: {
						show: false,
					},
				},
				yaxis: {
					lines: {
						show: true,
					},
				},
				row: {
					colors: undefined,
					opacity: 0.5,
				},
				column: {
					colors: undefined,
					opacity: 0.5,
				},
				padding: {
					top: 0,
					right: 0,
					bottom: 0,
					left: 52,
				},
			},
		};
	}

	function toggleAll() {
		const allChecked = siteBrandTypes.flatMap((s) => s.metrics).every((i) => i.checked);
		siteBrandTypes.forEach((s) => s.metrics.forEach((m) => (m.checked = !allChecked)));
		setSiteBrandTypes([...siteBrandTypes]);
	}

	function mapData(data) {
		const aggregation = sortByOrder(
			data.map((item) => {
				let color = "";
				switch (item.metric) {
					case "Availability":
						color = "d04700";
						break;
					case "Quality":
						color = "7600bc";
						break;
					case "Performance":
						color = "004ecc";
						break;
					case "OEE":
						color = "006808";
						break;
					default:
						color = Math.floor(Math.random() * 16777215)
							.toString(16)
							.padStart(6, "0");
						break;
				}
				return {
					...item,
					color: `#${color}`,
				};
			}),
			"metric",
			order,
		);

		setAggregatedData(aggregation);

		const aggregationGroupBySites = aggregation.reduce((groups, item) => {
			const group = groups.find((i) => i.tenantId === item.tenantId);
			if (group) {
				group.metrics = [
					...group.metrics,
					{
						tenantId: item.tenantId,
						metric: item.metric,
						checked: true,
						color: item.color,
					},
				];
				return groups;
			}
			return [
				...groups,
				{
					tenantId: item.tenantId,
					metrics: [
						{
							tenantId: item.tenantId,
							metric: item.metric,
							checked: true,
							color: item.color,
						},
					],
				},
			];
		}, []);
		const checkboxValues = aggregationGroupBySites.map((item) => {
			return {
				...item,
				metrics: item.metrics,
			};
		});

		setSiteBrandTypes(checkboxValues);
	}

	useEffect(() => {
		mapData(reportData, dateRange);
	}, [reportData, dateRange]);

	const options = getOptions(aggregatedData, siteBrandTypes);

	const allChecked = siteBrandTypes.every((s) => s.metrics.every((m) => m.checked) === true);

	return siteBrandTypes.length ? (
		<div className={classes.container}>
			<Chart options={options} series={options.series} type="line" width="95%" height="353px" />
			<div>
				<div className={classes.sideMenuHeader}>
					{siteBrandTypes[0].metrics.length > 1 && (
						<CheckboxButton
							key={`cb-all`}
							color={"black"}
							checked={siteBrandTypes.flatMap((s) => s.metrics).every((i) => i.checked)}
							onChange={() => toggleAll()}
						>
							{allChecked ? <FormattedMessage id="Deselect All" /> : <FormattedMessage id="Select All" />}
						</CheckboxButton>
					)}
				</div>
				<div className={classes.sideMenuBody}>
					{siteBrandTypes.map((item, i) => {
						const metrics = item.metrics.filter((s) => s.metric);
						return (
							<div className={classes.siteGroup} key={`oee-over-time-graph-site-group-${i}`}>
								<div className={classes.header}>{item.siteName}</div>
								<div>
									{metrics.map((metric) => (
										<CheckboxButton
											key={`cb-${metric.siteId}-${metric.metric}`}
											color={metric.color}
											checked={metric.checked}
											className={classes.checkbox}
											onChange={() => toggle(item.tenantId, metric.metric)}
										>
											<FormattedMessage id={metric.metric} />
										</CheckboxButton>
									))}
								</div>
							</div>
						);
					})}
				</div>
			</div>
		</div>
	) : (
		<div className={classes.errorContainer}>
			<FormattedMessage id="No OEE Data Available" />
		</div>
	);
}

export default OEEOverTimeGraph;
