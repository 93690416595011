import {
	Card,
	CardContent,
	CardDescription,
	CardGroup,
	CardHeader,
	CardMeta,
	Divider,
	Header,
	Search,
} from "semantic-ui-react";
import React, { useContext, useEffect, useState } from "react";
import { createUseStyles, useTheme } from "react-jss";
import { useLocation, useNavigate } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import { readLocalStorage } from "@/api/local-storage";
import useAxios from "@/api/useAxios";
import FailedRetry from "@/components/failed-retry";
import Loading from "@/components/loading";
import { setSsoUser, UserContext } from "@/components/user-context";
import { search } from "@/utils/search";
import { useQueryClient } from "@tanstack/react-query";
import isEqual from "lodash.isequal";
import { publicSettingsQueryKey } from "@/contexts/public-settings-context";

const useStyles = createUseStyles((theme) => ({
	...theme.configurationDialog,
	page: {
		backgroundColor: "#2e475f",
		display: "flex",
		flexDirection: "column",
	},
	walmartLogo: {
		width: "132px",
		height: "32px",
		position: "absolute",
		left: "120px",
	},
	packsizeLogo: {
		width: "180px",
		"@media (max-width:750px)": {
			display: "none",
		},
	},
	mainTitleText: {
		textAlign: "center !important",
		fontFamily: "Stolzl !important",
		fontSize: "32px !important",
		fontStyle: "normal !important",
		fontWeight: "500 !important",
		lineHeight: "normal !important",
		margin: "0 !important",
	},
	title: {
		display: "flex",
		padding: "0 2rem",
		justifyContent: "space-between",
		textAlign: "center",
		lineHeight: "normal",
		"& > div": {
			width: "20%",
		},
		marginTop: "36px",
		marginBottom: "36px",
	},
	titleColumn: {
		minWidth: "150px",
		width: "300px !important",
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
	},
	container: {
		height: "calc(100vh - 128px)",
		margin: "64px",
		backgroundColor: "white",
		borderRadius: "5px",
		minWidth: "460px",
	},
	cards: {
		marginLeft: "94px",
		marginRight: "94px",
		marginTop: "44px",
		height: "calc(100vh - 300px)",
		maxHeight: "calc(100vh - 300px)",
		overflowY: "auto",
		overflowX: "hidden",
		padding: "5px",
	},
	card: {
		minHeight: "88px",
		borderRadius: "6px",
		boxShadow: "0 2px 4px 0 rgba(0, 0, 0, 0.2) !important",
		backgroundColor: "#f2f4f5 !important",
		textAlign: "center",
		minWidth: "245px",
	},
	selectedCard: {
		minHeight: "88px",
		borderRadius: "6px",
		boxShadow: "0 2px 4px 0 rgba(0, 0, 0, 0.2) !important",
		backgroundColor: "#f2f4f5 !important",
		border: "1px solid #7ED321 !important",
		textAlign: "center",
		minWidth: "245px",
	},
	nocards: {
		textAlign: "center",
	},
	noWrap: {
		maxWidth: "100%",
		wordWrap: "break-word",
		textOverflow: "ellipsis",
	},
	header: {
		fontSize: "20px",
		fontWeight: 500,
		paddingTop: "15px",
		paddingLeft: "35px",
		paddingRight: "35px",
		color: "#353430",
		textOverflow: "ellipsis",
		minWidth: "245px",
		whiteSpace: "nowrap",
		overflow: "hidden",
		minHeight: "40px",
	},
	content: {
		fontSize: "16px",
		border: "none !important",
		color: "#7c7b79",
	},
	version: {
		position: "absolute",
		bottom: "0px",
		color: "white",
		paddingLeft: "8px",
	},
	siteSearch: {
		margin: "0 !important",
		"& input": {
			borderRadius: "10px !important",
		},
		"& .ui.icon.input": {
			width: "100% !important",
		},

		"& .ui.icon.input > input": {
			paddingLeft: "3rem !important",
		},
		"& i": {
			marginLeft: "1rem !important",
		},
	},
	emptyDiv: {
		minHeight: "1em",
		minWidth: "2em",
	},
	logoContainer: {
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
	},
	backContainer: {
		"& :hover": {
			cursor: "pointer !important",
		},
		display: "flex",
		alignItems: "center !important",
		justifyContent: "start",
	},
	backIcon: {
		minHeight: "36px !important",
		minWidth: "36px !important",
		"& path": {
			fill: "#000 !important",
		},
	},
}));

const SiteSelect = ({ setCurrentUser }) => {
	const theme = useTheme();
	const { currentUser } = useContext(UserContext);
	const classes = useStyles({ theme });
	const token = readLocalStorage("BEARER");
	const { get } = useAxios("/IdentityApi/api/v1/sites", token);
	const TokenApi = useAxios("/IdentityApi/api/v1/oauth/token", token);
	const [allList, setAllList] = useState([]);
	const [list, setList] = useState([]);
	const [loading, setLoading] = useState(true);
	const [failed, setFailed] = useState(false);
	const [searchTerm, setSearchTerm] = useState("");
	const [showBackButton, setShowBackButton] = useState(false);
	const navigate = useNavigate();
	const routeData = useLocation();
	const queryClient = useQueryClient();

	const loginSite = (site) => {
		TokenApi.getWithUrl(
			`site/${site.tenantId}`,
			(success) => {
				queryClient.removeQueries({
					predicate: (query) => !isEqual(query.queryKey, publicSettingsQueryKey),
				});
				setSsoUser(success.access_token, setCurrentUser).then(() => navigate("/"));
			},
			(fail) => {
				console.error("fail", fail);
			},
		);
	};

	const onSearch = (e, { value }) => {
		if (value.length < 3) setList(allList);
		else setList(search(value, allList, ["company", "siteId", "salesForceId", "siteTimeZone"]));
		setSearchTerm(value);
	};
	const load = () => {
		if (routeData?.state?.pathName !== "login") setShowBackButton(true);
		setFailed(false);
		setLoading(true);
		setList([]);
		get(
			(data) => {
				setList(data);
				setAllList(data);
				setLoading(false);
			},
			(_) => {
				setLoading(false);
				setFailed(true);
			},
		);
	};
	useEffect(load, []);

	return (
		<span className={classes.page}>
			{loading ? (
				<div className={classes.container}>
					<Loading />
				</div>
			) : failed ? (
				<div className={classes.container}>
					<FailedRetry retry={load} />
				</div>
			) : (
				<div className={classes.container}>
					<div className={classes.title}>
						<div onClick={() => navigate(-1)} className={classes.titleColumn}>
							{showBackButton && (
								<div className={classes.backContainer}>
									<svg
										className={classes.backIcon}
										xmlns="http://www.w3.org/2000/svg"
										width="36"
										height="36"
										viewBox="0 0 36 36"
										fill="none"
									>
										<g clip-path="url(#clip0_218_1921)">
											<path
												d="M22.065 10.065C21.48 9.47997 20.535 9.47997 19.95 10.065L13.065 16.95C12.48 17.535 12.48 18.48 13.065 19.065L19.95 25.95C20.535 26.535 21.48 26.535 22.065 25.95C22.65 25.365 22.65 24.42 22.065 23.835L16.245 18L22.065 12.18C22.65 11.595 22.635 10.635 22.065 10.065Z"
												fill="#353430"
											/>
										</g>
										<defs>
											<clipPath id="clip0_218_1921">
												<rect width="36" height="36" fill="white" />
											</clipPath>
										</defs>
									</svg>
									<div>{currentUser.Company}</div>
								</div>
							)}
						</div>
						<Header className={`${classes.mainTitleText} ${classes.titleColumn}`}>
							<FormattedMessage id="Your Sites" />
						</Header>
						<Search
							input={{ icon: "search", iconPosition: "left" }}
							fluid
							value={searchTerm}
							onSearchChange={onSearch}
							showNoResults={false}
							className={`${classes.siteSearch} ${classes.titleColumn}`}
							placeholder={"Search for a site..."}
							autoFocus
						/>
					</div>
					<Divider />
					{list.length > 0 && (
						<div className={classes.cards}>
							<CardGroup centered>
								{list.map((l) => (
									<Card
										key={l.id}
										onClick={() => loginSite(l)}
										className={currentUser.tid === l.tenantId ? classes.selectedCard : classes.card}
									>
										<CardContent>
											<CardHeader>{l.company}</CardHeader>
											<CardMeta>{l.siteId}</CardMeta>
											<CardDescription className={classes.noWrap}>{l.salesForceId}</CardDescription>
											<CardDescription extra>{l.siteTimeZone}</CardDescription>
										</CardContent>
									</Card>
								))}
							</CardGroup>
						</div>
					)}
					{list.length === 0 && (
						<div className={classes.nocards}>
							<FormattedMessage id="No sites configured" />
						</div>
					)}
				</div>
			)}
		</span>
	);
};

export default SiteSelect;
