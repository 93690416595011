import React from "react";
import { Popup } from "semantic-ui-react";
import { createUseStyles } from "react-jss";

const useStyles = createUseStyles(() => ({
	bar: {
		borderRadius: "15px",
		height: "15px",
	},
	container: {
		borderRadius: "15px",
		height: "15px",
		backgroundColor: "#E6E6E6; !important",
		flexGrow: "1",
		zIndex: "2",
	},
	progress: {
		display: "flex",
		flexDirection: "row",
		gap: "16px",
		zIndex: "3",
		"& > span": {
			textAlign: "right",
			width: "70px",
			height: "23px",
			fontWeight: "bold",
			fontFamily: "Stolzl-Medium",
			fontSize: "19px",
			fontStretch: "normal",
		},
	},
}));

function ProgressBar({ percent, color, popupText }) {
	const classes = useStyles();
	const barStyle = {
		backgroundColor: color,
		width: `${percent}%`,
	};

	return (
		<div className={classes.progress}>
			<div className={classes.container}>
				{!popupText && (
					<div className={classes.bar} style={barStyle}>
						&nbsp;
					</div>
				)}
				{popupText && (
					<Popup
						content={popupText}
						trigger={
							<div className={classes.bar} style={barStyle}>
								&nbsp;
							</div>
						}
						inverted
						position="top center"
						hideOnScroll
					/>
				)}
			</div>
		</div>
	);
}

export default ProgressBar;
