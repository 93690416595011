import { createUseStyles } from "react-jss";
import { Button } from "semantic-ui-react";
import { Close } from "@styled-icons/material-rounded";

const useStyles = createUseStyles({
	button: {
		boxShadow: "none !important",
		margin: "0 !important",
		padding: "0 !important",
		backgroundColor: "transparent !important",
	},
});

const CloseButton = (props) => {
	const classes = useStyles();

	return (
		<Button
			data-component="CloseButton"
			className={`${classes.button} ${props.className}`}
			onClick={props.onClick}
			data-cy="close-button"
		>
			<Close color="#353430" size={36} data-element="Close" />
		</Button>
	);
};
export default CloseButton;
