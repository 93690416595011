import ListView from "@/components/list-view";
import New from "./new-user";
import React from "react";
import Table from "./users";
import { useIntl } from "react-intl";

function Users() {
	const intl = useIntl();
	const title = intl.formatMessage({ id: "Users" });

	return <ListView title={title} Table={Table} AddItem={New} size="small" />;
}

export default Users;
