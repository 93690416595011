import React from "react";
import {createUseStyles, useTheme} from "react-jss";

const useStyles = createUseStyles((theme) => ({
    leftBar: {
        background: "#2E475F",
        flex: "1",
        display: "flex",
        alignItems: "center !important",
        justifyContent: "start !important",
        "& svg": {
            height: " 100%",
            width: "80%"
        }
    },
    loginBox: {
        flex: "2 1 0",
        width: "100%",
        height: "100vh",
        display: "flex !important",
        alignItems: "center !important",
        justifyContent: "center !important",
    },
    loginContainer: {
        width: "100%",
        height: "100%",
        display: "flex",
    }

}));
const LoginSplashFrame = ({children}) => {
    const theme = useTheme();
    const classes = useStyles({theme});
    return (
        <div className={classes.loginContainer}>
            <div className={classes.leftBar}>
                <svg viewBox="0 0 387 467" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M84.9999 406.517L84.9999 132.549M84.9999 406.517L-147.258 341.872L-147.258 166.227M84.9999 406.517L137.838 391.808M84.9999 132.549L-147.258 67.2264M84.9999 132.549L15.7096 209.678L-215 144.355L-147.258 67.2264M84.9999 132.549L317.258 67.323M-147.258 67.2264L85.0001 2M317.258 67.323L385 144.453L154.194 209.775L84.9999 132.646M317.258 67.323L85.0001 2M317.258 166.323V260.969M84.9999 132.646V316.614M84.9999 132.646L85.0001 2M375.323 346.42V326.775C375.323 290.969 364.097 281.678 328.29 281.678L205.678 281.678C169.871 281.678 158.645 290.872 158.645 326.775V346.42H206.452V320L327.613 320L327.613 346.42H375.323ZM206.355 398.194L206.355 424.904L327.613 424.904V398.194H375.323V419.679C375.323 455.581 364.097 464.775 328.29 464.775L205.678 464.775C169.871 464.775 158.645 455.581 158.645 419.679L158.645 398.194H206.452H206.355ZM232.871 346.42L301.097 346.42V398.194L232.871 398.194V346.42Z"
                        stroke="#7E94A8" strokeWidth="4" strokeMiterlimit="10" strokeLinecap="round"
                        strokeLinejoin="round"/>
                </svg>
            </div>
            <div className={classes.loginBox}>
                {children}
            </div>
        </div>
    );
};
export default LoginSplashFrame;
