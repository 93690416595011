import React, { useCallback, useContext, useMemo } from "react";
import { readLocalStorage, removeLocalStorage, writeLocalStorage } from "@/api/local-storage";

import { allPermissions } from "@/views/configuration/roles/role-utils";
import axios from "axios";
import { isFeatureFlagEnabled } from "@/utils/auth";
import { jwtDecode } from "jwt-decode";
import { FullStory, isInitialized } from "@fullstory/browser";
import { getApplicationInsights } from "@/contexts/app-insights";

export const getUserSso = async (username) => {
	try {
		return await requestSso(username);
	} catch (err) {
		console.error("Error fetching sso url", err);
		return false;
	}
};

export const getUser = async (username, password) => {
	try {
		const token = await requestToken(username, password);
		return await processToken(token);
	} catch (err) {
		console.error("Error logging in to PackNet.", err);
		return false;
	}
};

export async function setSsoUser(encodedToken, setCurrentUser) {
	let loggedInUser = await processToken(encodedToken);
	let totallyUpdatedCurrentUser = await setCurrentUser(loggedInUser);
	//We do not know why the next line is in this context
	removeLocalStorage("currentMachineGroup");
	return totallyUpdatedCurrentUser;
}

export async function deleteSsoUser() {
	await fetch("/IdentityApi/api/v1/users/deleteself", {
		method: "DELETE",
		headers: {
			Authorization: localStorage.getItem("BEARER"),
		},
	});

	logout("Deleted SSO User");
}

async function refreshToken(token) {
	let newToken = undefined;

	try {
		const response = await axios.post(
			"/IdentityApi/api/v1/oauth/token/refresh",
			{},
			{ headers: { Authorization: token } },
		);

		newToken = response.data?.access_token;
	} catch (err) {
		console.error("Error fetching refresh token", err);
	}

	return newToken;
}

async function requestToken(username, password) {
	const resp = await axios.post("/IdentityApi/api/v1/oauth/token/acquire", { username: username, password: password });
	const data = resp.data;
	return data.access_token;
}

async function requestSso(username) {
	const resp = await axios.get(`/IdentityApi/api/v1/oauth/token/tenantSsoUrl/byUser/${username}`);
	const data = resp.data;
	return data;
}

export async function requestExpireTime(token) {
	try {
		if (!token) return undefined;
		const resp = await axios.get(`/IdentityApi/api/v1/oauth/token/expiresInSeconds`, {
			headers: { Authorization: token },
		});
		const data = resp.data;
		return data;
	} catch (err) {
		return undefined;
	}
}

export const refreshUser = async () => {
	let decodedToken = undefined;
	try {
		const localToken = readLocalStorage("BEARER");
		if (localToken == null || !localToken || !localToken.includes("BEARER")) {
			logout("Refreshing User but localToken was not found");
		} else {
			const encodedToken = await refreshToken(localToken);
			decodedToken = await processToken(encodedToken);
		}
	} catch (err) {
		logout("Exception refreshing user");
	}

	return decodedToken;
};

const setFullStoryIdentity = async (decodedToken) => {
	if (isInitialized()) {
		try {
			await FullStory("setIdentityAsync", {
				uid: decodedToken.id,
				properties: {
					userGuid: decodedToken.id,
					tenantId: decodedToken.Tenant,
					customerId: decodedToken.CustomerId,
					siteId: decodedToken.SiteId,
				},
			});
		} catch (e) {
			console.error("Error setting FullStory identity", e);
		}
	}
};

const setAppInsightsIdentity = (decodedToken) => {
	let applicationInsights = getApplicationInsights(); // may be null before it has been initialized
	applicationInsights?.setAuthenticatedUserContext(decodedToken.id, decodedToken.tid, true);
};

const deleteAppInsightsIdentity = () => {
	let applicationInsights = getApplicationInsights(); // may be null before it has been initialized
	applicationInsights?.clearAuthenticatedUserContext();
};

export const processToken = async (encodedToken) => {
	const decodedToken = jwtDecode(encodedToken);
	decodedToken.token = `BEARER ${encodedToken}`;
	writeLocalStorage("BEARER", decodedToken.token);
	await setFullStoryIdentity(decodedToken);
	setAppInsightsIdentity(decodedToken);

	return normalizeRoles(decodedToken);
};

export const logout = (logoutReason, redirect) => {
	console.info("User logged out:", logoutReason);
	deleteAppInsightsIdentity();
	removeLocalStorage("BEARER");
	removeLocalStorage("currentMachineGroup");
	removeLocalStorage("LOCALTOKENEXPIRATION");
	removeLocalStorage("dimension-products-search-term");
	removeLocalStorage("preAuth")
	window.location.href = redirect ?? "/PackNet/logged-out";
};

const normalizeRoles = (decodedToken) => {
	if (!decodedToken) return decodedToken;
	else if (!decodedToken.role) decodedToken.role = [];
	else if (typeof decodedToken.role === "string" || decodedToken.role instanceof String)
		decodedToken.role = [decodedToken.role.toLowerCase()];
	else decodedToken.role = decodedToken.role.map((r) => r.toLowerCase());

	if (decodedToken.role.includes("admin")) {
		const all = allPermissions(decodedToken);
		return { ...decodedToken, role: all };
	}
	return decodedToken;
};

export const getExpirationFromLocalStorage = () => {
	const localExpireEpoch = readLocalStorage("LOCALTOKENEXPIRATION");
	if (localExpireEpoch) {
		const localNow = new Date().getTime();
		const localExpire = parseInt(localExpireEpoch);
		const expiresInSeconds = (localExpire - localNow) / 1000;
		console.info("Remote api call to get expire time failed. using local expire time ", expiresInSeconds);
		return expiresInSeconds;
	}

	console.info(
		"Remote api call to get expire time failed. can not use local time because it is not stored, expiring token",
	);
	const expiresInSeconds = -1;
	return expiresInSeconds;
};

export const getTokenFromLocalStorage = async () => {
	const localToken = readLocalStorage("BEARER");
	if (localToken == null || !localToken || !localToken.includes("BEARER")) {
		return undefined;
	} else {
		const decodedToken = jwtDecode(localToken.replace("BEARER ", ""));
		decodedToken.token = localToken;

		await setFullStoryIdentity(decodedToken);
		setAppInsightsIdentity(decodedToken);

		const token = normalizeRoles(decodedToken);
		let expiresInSeconds = await requestExpireTime(localToken);
		if (!expiresInSeconds) {
			//api could have failed, check localstorage
			expiresInSeconds = getExpirationFromLocalStorage();
		}

		if (expiresInSeconds <= 0) {
			logout("User Context: Invalid token expiration");
			return undefined;
		}
		return token;
	}
};

export const UserContext = React.createContext({
	currentUser: {
		id: "",
		roles: [],
		role: [],
		token: getTokenFromLocalStorage(),
	},
	setCurrentUser: () => {},
	logout,
});

export const useLaunchDarkly = () => {
	const { currentUser } = useContext(UserContext);

	return useCallback((launchDarklyKey) => isFeatureFlagEnabled(currentUser, launchDarklyKey), [currentUser]);
};

export const useIsRoleEnabled = (role) => {
	const { currentUser } = useContext(UserContext);

	return useMemo(() => {
		if (currentUser?.role) return currentUser.role.includes(role.toLowerCase());
		return false;
	}, [currentUser?.role, role]);
};

export const useCanManageJobs = () => {
	const { currentUser } = useContext(UserContext);
	return useMemo(() => {
		if (currentUser?.role)
			return currentUser.role.includes("managepackagingsolutions") || currentUser.role.includes("managejobactions");
		return false;
	}, [currentUser?.role]);
};
