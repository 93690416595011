export const updateAllSolutionAttributeValuesToString = (solution) => {
	return {
		...solution,
		attributes: ensureStringValues(solution.attributes),
		labels: solution.labels.map((l) => ({ ...l, attributes: ensureStringValues(l.attributes) })),
		cartons: solution.cartons.map((c) => ({
			...c,
			media: {
				...c.media,
				attributes: ensureStringValues(c.media.attributes),
			},
			labels: c.labels.map((l) => ({ ...l, attributes: ensureStringValues(l.attributes) })),
		})),
	};
};

const ensureStringValues = (attributes) => {
	return Object.fromEntries(Object.entries(attributes).map(([k, v]) => [k, v?.toString()]));
};
