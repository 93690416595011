import { useState } from "react";
import { useIntl } from "react-intl";
import { useLocation } from "react-router-dom";
import { Segment, List } from "semantic-ui-react";
import { createUseStyles, useTheme } from "react-jss";

import Loading from "@/components/loading";
import ConfirmModal from "@/components/confirm-modal";
import SearchNav from "@/views/cart/scan/search-nav";
import useSearchData from "@/utils/use-product-search";
import SearchResultRow from "@/views/cart/scan/search-result-row";
import objectFromQuerystring from "@/utils/object-from-querystring";

const useStyles = createUseStyles((theme) => ({
	container: {
		backgroundColor: `${theme.colors.light} !important`,
		height: "calc(100vh - 65px)",
	},
}));

const SearchResults = () => {
	const intl = useIntl();
	const theme = useTheme();
	const location = useLocation();
	const classes = useStyles({ theme });

	const { term: originalTerm } = objectFromQuerystring(location.search);
	const [searchTerm, setSearchTerm] = useState(() => originalTerm);
	const { data, loading, showNotFound, handlers } = useSearchData(originalTerm);

	return (
		<div className={classes.container}>
			<SearchNav term={searchTerm} setTerm={setSearchTerm} searchHandlers={handlers} />

			{loading && <Loading />}

			{!loading && data && data.products.length > 0 && (
				<Segment attached>
					<p>
						{data.products.length} result{data.products.length > 1 ? "s" : null}:
					</p>
					<List selection divided verticalAlign="middle">
						{data.products.map((product) => (
							<SearchResultRow key={product.identifiers.upc} product={product} searchTerm={searchTerm} />
						))}
					</List>
				</Segment>
			)}

			<ConfirmModal
				open={showNotFound}
				onClose={handlers.closeNotFound}
				titleText={intl.formatMessage({ id: "NotFound" })}
				detailText={intl.formatMessage({ id: "NotFoundDescription" }, { productId: searchTerm })}
				confirmText={intl.formatMessage({ id: "Add New" })}
				cancelText={intl.formatMessage({ id: "Cancel" })}
			/>
		</div>
	);
};

export { SearchResults as default };
