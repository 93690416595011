export const productState = {
	NONE: 0,
	LOADING: 1,
	FOUND: 2,
	NOT_FOUND: 3,
	ERROR: 255,
};

export function isErrorState(state) {
	return state === productState.NOT_FOUND || state === productState.ERROR;
}
