import styled from "styled-components";
import { Dropdown as SemanticDropdown, Icon } from "semantic-ui-react";

const StyledDropdown = styled(SemanticDropdown)`
	&&& {
		display: flex;
		align-items: center;
		gap: 16px;
		border: 1px solid ${({ theme }) => theme.colors.border1};
		border-radius: 6px;
		justify-content: space-between;
		padding: 0.5rem 1rem;
	}
	background-color: ${({ theme, backgroundColor }) => backgroundColor || theme.colors.white};
`;

const StyledIcon = styled(Icon)`
	&&& {
		display: flex;
		align-items: center;
		margin: 0;
		width: auto;
	}
`;

function Dropdown({ children, ...props }) {
	return (
		<StyledDropdown icon={<StyledIcon name="angle down" />} {...props}>
			{children}
		</StyledDropdown>
	);
}

export default Dropdown;
