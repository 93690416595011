import React from "react";

export default function ProductId({ text, searchText }) {
	const css = {
		fontFamily: "Stolzl-Medium",
	};

	const n = text.toUpperCase();
	const q = searchText.toUpperCase();
	const x = n.indexOf(q);
	if (!q || x === -1) {
		return <span>{text}</span>; // bail early
	}
	const l = q.length;
	return (
		<span>
			{text.substr(0, x)}
			<span style={css}>{text.substr(x, l)}</span>
			{text.substr(x + l)}
		</span>
	);
}
