import { useContext } from "react";
import { ProductionContext } from "@/contexts/production-context";
import SelectMachineGroup from "@/views/production/job-queue/select-machine-group";
import SelectProductionGroup from "@/views/production/job-queue/select-production-group";

const RequireCurrentMachineGroup = ({ children }) => {
	const { currentMachineGroup, currentProductionGroup } = useContext(ProductionContext);

	if (!currentMachineGroup) return <SelectMachineGroup />;
	else if (!currentProductionGroup) return <SelectProductionGroup />;
	else return <>{children}</>;
};

export default RequireCurrentMachineGroup;
