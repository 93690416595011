import React, { useEffect, useState } from "react";

import { FormattedMessage, useIntl } from "react-intl";
import { createUseStyles, useTheme } from "react-jss";

const useStyles = createUseStyles((theme) => ({
	content: {
		display: "flex",
		flexDirection: "column",
		minHeight: "288px",
	},
	divider: {
		width: "100%",
		height: "1px",
		flexGrow: 0,
		marginBottom: "16px",
		backgroundColor: "#e3e7e9",
	},
	totalTrim: {
		marginBottom: "16px",
		display: "flex",
		flexDirection: "column",
		fontFamily: "Stolzl",
		fontStretch: "normal",
		fontStyle: "normal",
		lineHeight: "normal",
		letterSpacing: "normal",
		textAlign: "left",
		color: "#353430",
		"& > span:first-child": {
			fontSize: "37px",
			fontWeight: "bold",
			paddingBottom: "8px",
		},
		"& > span:last-child": {
			fontSize: "17px",
			fontWeight: "500",
			textTransform: "uppercase",
		},
	},
	totalZFoldArea: {
		display: "flex",
		flexDirection: "column",
		fontFamily: "Stolzl",
		fontStretch: "normal",
		fontStyle: "normal",
		letterSpacing: "normal",
		textAlign: "left",
		color: "#353430",
		fontWeight: "normal",
		"& > span:first-child": {
			fontSize: "37px",
			lineHeight: "normal",
			paddingBottom: "8px",
		},
		"& > span:last-child": {
			fontSize: "17px",
			lineHeight: "1.4",
			textTransform: "uppercase",
		},
	},
}));

function TotalTrim({ reportData, dateRange, isMetric }) {
	const intl = useIntl();
	const theme = useTheme();
	const classes = useStyles({ theme });
	const [presentationData, setPresentationData] = useState({});
	const unitLabel = isMetric ? "sq meters" : "sq ft";

	function mapData(data) {
		const aggregation = { totalZFoldArea: data.totalZFoldArea, trimArea: data.totalTrimArea };

		setPresentationData(aggregation);
	}

	useEffect(() => {
		mapData(reportData, dateRange);
	}, [reportData, dateRange]);

	return presentationData ? (
		<div className={classes.content}>
			<div className={classes.totalTrim}>
				<span>
					{presentationData.trimArea?.toLocaleString(undefined, {
						maximumFractionDigits: 0,
					})}{" "}
					{intl.formatMessage({ id: unitLabel })}
				</span>
				<span>
					<FormattedMessage id="Total Trim" />
				</span>
			</div>
			<div className={classes.divider} />
			<div className={classes.totalZFoldArea}>
				<span>
					{presentationData.totalZFoldArea?.toLocaleString(undefined, {
						maximumFractionDigits: 0,
					})}{" "}
					{intl.formatMessage({ id: unitLabel })}
				</span>
				<span>
					<FormattedMessage id="Total zFold Used" />
				</span>
			</div>
		</div>
	) : (
		<div></div>
	);
}

export default TotalTrim;
