import { FormattedMessage, FormattedNumber } from "react-intl";
import React, { useEffect, useState } from "react";
//import { useTheme } from "react-jss";
import { createSignalRContext } from "react-signalr";
import { Header, Form, Button, Grid, Statistic, Modal, Input, Icon, Segment } from "semantic-ui-react";
import DataTable from "react-data-table-component";

//import SelectMachineGroup from "../job-queue/select-machine-group";
import { readLocalStorage } from "@/api/local-storage";

import useAxios from "@/api/useAxios";

const columns = [
	{
		name: "Name",
		selector: (row) => row.alias,
		wrap: true,
		grow: 3,
	},
	{
		name: "Design",
		cell: (row) => {
			if (!row.cartons) return "";
			return row.cartons.length ? row.cartons[0].media.style : "";
		},
		center: true,
		grow: 0,
	},
	{
		name: "Dimensions",
		cell: (row) => {
			if (!row.cartons) return "";
			return row.cartons.length ? getDimensionString(row.cartons[0]) : "";
		},
		wrap: false,
		center: true,
	},
	{
		name: "Quantity",
		cell: (row) => (!row.cartons ? "" : row.cartons.length),
		center: true,
	},
];

const createProductionRule = () => {
	return {
		machineType: "Manual",
		materialId: "1bff53e4-fdcd-4aca-8ac3-3f697327b61b",
		optimal: true,
	};
};

const createJobs = (number = 1) => {
	let j = [];

	for (let i = 1; i <= number; i++) {
		j.push({
			id: i,
			alias: `alias-longitem-really-long-name-${i}`,
			licensePlateNumber: `lpn-${i}`,
			cartons: [
				{
					labels: [
						{
							media: {
								dimensions: {
									length: 6,
									width: 5,
								},
							},
						},
					],
					productionRules: createProductionRule(),
					media: {
						style: "RSC",
						dimensions: {
							length: 0.125 * i + 4,
							width: 0.45 * i + 3,
							height: 4,
						},
					},
				},
			],
			labels: [
				{
					media: {
						dimensions: {
							length: 6,
							width: 5,
						},
					},
				},
			],
			importDate: "ImportDate",
			createByDate: "CreateByDate",
		});
	}

	j[1].cartons.push({
		labels: [],
		productionRules: createProductionRule(),
		media: {
			style: "RSC",
			dimensions: {
				length: 6,
				width: 5,
				height: 5,
			},
		},
	});

	return j;
};

const JobList = ({ jobs, setSelectedJob }) => {
	return (
		<DataTable
			noHeader
			columns={columns}
			data={jobs}
			direction="auto"
			fixedHeader
			persistTableHead
			responsive
			subHeader
			subHeaderAlign="right"
			subHeaderWrap
			onRowClicked={(row, event) => {
				setSelectedJob(row);
			}}
			noDataComponent={<FormattedMessage id="No Packaging Solutions" />}
		/>
	);
};

const getDimensionString = (carton) => {
	return `${carton.media.dimensions.length} x ${carton.media.dimensions.width} x ${carton.media.dimensions.height}`;
};

const Carton = ({ carton, cartonCount, getMaterialAlias }) => {
	const [isPacked, setIsPacked] = useState(false);
	return (
		<>
			<Grid.Row columns={cartonCount > 1 ? 2 : 1}>
				<Grid.Column>
					<Header>
						{getMaterialAlias(carton.productionRules.materialId)}{" "}
						<Header.Subheader>{getDimensionString(carton)}</Header.Subheader>
					</Header>
				</Grid.Column>
				{cartonCount > 1 && (
					<Grid.Column>
						<Button fluid positive={isPacked} onClick={() => setIsPacked((prevState) => !prevState)}>
							Packed
						</Button>
					</Grid.Column>
				)}
			</Grid.Row>
			{carton.labels.map((label, i) => {
				return <Label label={label} labelCount={1} key={1} />;
			})}
		</>
	);
};

const Label = ({ label }) => {
	return (
		<Grid.Row columns={1}>
			<Grid.Column>
				<Header>
					Label{" "}
					<Header.Subheader>
						{label.media.dimensions.length}x{label.media.dimensions.width}
					</Header.Subheader>
				</Header>
			</Grid.Column>
		</Grid.Row>
	);
};
const SelectedJob = ({ solution, isOpen, setIsCanceled, setIsPacked, getMaterialAlias }) => {
	const cartonCount = solution.cartons.length;
	const labelCount = solution.labels.length;

	return (
		<>
			<Modal open={isOpen} size="tiny">
				<Modal.Header>
					{solution.alias} <Header.Subheader>{solution.licensePlateNumber}</Header.Subheader>
				</Modal.Header>
				<Modal.Content>
					<Grid fluid>
						{solution.cartons.map((carton, i) => {
							return <Carton carton={carton} cartonCount={cartonCount} getMaterialAlias={getMaterialAlias} key={i} />;
						})}
						{solution.labels.map((label, i) => {
							return <Label label={label} labelCount={labelCount} key={i} />;
						})}
					</Grid>
				</Modal.Content>
				<Modal.Actions>
					<Button.Group fluid>
						<Button basic negative onClick={() => setIsCanceled(true)}>
							<FormattedMessage id="Cancel" />
						</Button>
						<Button basic positive onClick={() => setIsPacked(true)}>
							Packed
						</Button>
					</Button.Group>
				</Modal.Actions>
			</Modal>
		</>
	);
};

// const ItemsInSystem = () => {
// 	return (
// 		<Statistic>
// 			<Statistic.Label>Items</Statistic.Label>
// 			<Statistic.Value>
// 				<FormattedNumber value={1000} />
// 			</Statistic.Value>
// 		</Statistic>
// 	);
// };

const ItemsInMachineGroup = ({ solutions }) => {
	const totalCartons = solutions.reduce((acc, cv) => acc + cv.cartons.length, 0);
	return (
		<Statistic>
			<Statistic.Label>Items in Machine Group</Statistic.Label>
			<Statistic.Value>
				<FormattedNumber value={totalCartons} />
			</Statistic.Value>
		</Statistic>
	);
};

const JobQueueSignalRContext = createSignalRContext();

const ManualCartonBuild = () => {
	//const theme = useTheme();
	//const classes = useStyles({ theme });
	const token = readLocalStorage("BEARER");
	const [jobs, setJobs] = useState(createJobs(10));
	const [completedJobs, setCompletedJobs] = useState([]);
	const [filterText, setFilterText] = useState("");
	const [selectedJob, setSelectedJob] = useState();
	const [isCanceled, setIsCanceled] = useState(false);
	const [isPacked, setIsPacked] = useState(false);
	const [interval, setIntervalState] = useState();
	const [refreshInterval, setRefreshInterval] = useState(300000);
	const [materials, setMaterials] = useState([]);
	const CartonsApi = useAxios("/corrugateApi/api/v1/cartons", token);

	// const PipelineAuditApi = useAxios("/PipelineAuditApi/api/v1/CurrentState", token);
	// const MachineGroupApi = useAxios("/MachineGroupApi/api/v1/MachineGroups", token);
	// const ProductionGroupApi = useAxios("/ProductionGroupApi/api/v1/ProductionGroups", token);

	JobQueueSignalRContext.useSignalREffect("OnVersion", (version) => {
		console.info("signalr JobQueue - ", version);
	});

	JobQueueSignalRContext.useSignalREffect("OnUnsubscribed", (message) => {
		clearInterval(interval);
	});

	JobQueueSignalRContext.useSignalREffect("OnProductionQueueUpdated", (tenantId, machineGroupId, selectedJobs) => {
		setJobs(selectedJobs);
	});

	const getMaterialAlias = (id) => {
		return materials.filter((m) => m.id === id)[0].alias;
	};

	const refresh = async () => {
		// 	if (
		// 		!currentMachineGroup ||
		// 		currentMachineGroup.status.toLowerCase() !== "online" ||
		// 		!currentMachineGroup.productionEnabled
		// 	)
		// 		return;
		// 	PipelineAuditApi.getWithUrl(
		// 		`PackagingSolutions/byStatus/InProgress/byMachineGroup/${currentMachineGroup.id}`,
		// 		(data) => {
		// 			setJobs(data);
		// 		},
		// 		(err) => console.error("Error fetching queue items", err)
		// 	);
	};

	useEffect(() => {
		const f = async () => await refresh();
		f();
		if (refreshInterval && refreshInterval > 0) {
			const i = setInterval(refresh, refreshInterval);
			setIntervalState(i);
			return () => clearInterval(i);
		}
	}, [refreshInterval]);

	// useEffect(() => {
	// 	if (
	// 		currentMachineGroup &&
	// 		currentMachineGroup.status.toLowerCase() === "online" &&
	// 		currentMachineGroup.productionEnabled &&
	// 		!interval
	// 	) {
	// 		refresh();
	// 	} else if (
	// 		!currentMachineGroup ||
	// 		currentMachineGroup.status.toLowerCase() !== "online" ||
	// 		(!currentMachineGroup.productionEnabled && interval)
	// 	) {
	// 		clearInterval(interval);
	// 	}
	// }, [currentMachineGroup]);

	// const toggleMachineGroupPlay = () => {
	// 	if (currentMachineGroup) {
	// 		MachineGroupApi.updateWithUrl(
	// 			`${currentMachineGroup.id}/enableProduction`,
	// 			{ enabled: !currentMachineGroup.productionEnabled },
	// 			() => {},
	// 			(err) => console.error("Error enabling/disabling production", err)
	// 		);
	// 	}
	// };

	useEffect(() => {
		setSelectedJob(null);
		setIsCanceled(false);
	}, [isCanceled]);

	useEffect(() => {
		if (selectedJob?.id) {
			setJobs(jobs.filter((j) => j.id !== selectedJob.id));
			setCompletedJobs([...completedJobs, selectedJob]);
		}
		setSelectedJob(null);
		setIsPacked(false);
	}, [isPacked]);

	useEffect(() => {
		CartonsApi.get((data) => {
			setMaterials(data);
		});
	}, []);

	const findAndSelectJob = (identifier) => {
		if (identifier) {
			var items = jobs.filter((job) => job.alias === identifier || job.licensePlateNumber === identifier);
			if (items.length === 1) {
				setSelectedJob(items[0]);
			}
		}
	};

	// if (!currentMachineGroup) return <SelectMachineGroup />;
	// else if (!currentProductionGroup) return <SelectProductionGroup />;
	return (
		<JobQueueSignalRContext.Provider
			connectEnabled={!!token}
			accessTokenFactory={() => token.replace("BEARER ", "")}
			dependencies={[token]}
			url={"/PipelineAuditApi/hubs/jobQueue"}
			onOpen={() => {
				JobQueueSignalRContext.invoke("Version");
			}}
			onBeforeClose={() => new Promise((resolve) => setTimeout(() => resolve(), 1000))}
			onError={(error) => {
				console.error(`JobQueueSignalRContext ${error}`);
				setRefreshInterval(3000); //Fallback for when websocket is not connected
			}}
			onClosed={() => {
				setRefreshInterval(3000); //Fallback for when websocket is not connected
			}}
		>
			<Header>
				<FormattedMessage id="Manual Carton Build" />
			</Header>
			<Grid columns={2} divided stretched>
				<Grid.Row>
					<Grid.Column>
						<Form onSubmit={() => findAndSelectJob(filterText)}>
							<Form.Field
								label="Find Id"
								control={Input}
								action={{
									icon: "search",
								}}
								value={filterText}
								onChange={(e) => setFilterText(e.target.value)}
							></Form.Field>
						</Form>
					</Grid.Column>
					<Grid.Column>
						<Segment basic>
							<Button icon="box" size="large" circular />
							<Button icon="print" size="large" circular />

							<Button.Group icon>
								<Button>
									<Icon name="play" toggle />
								</Button>
								<Button>
									<Icon name="pause" toggle />
								</Button>
							</Button.Group>
						</Segment>
					</Grid.Column>
				</Grid.Row>
				<Grid.Row verticalAlign="top">
					<Grid.Column>
						<Header>In Queue</Header>
						<ItemsInMachineGroup solutions={jobs} />
						<JobList jobs={jobs} setSelectedJob={setSelectedJob} />
					</Grid.Column>
					<Grid.Column>
						<Header>Completed</Header>
						<JobList jobs={completedJobs} setSelectedJob={() => {}} />
					</Grid.Column>
				</Grid.Row>
			</Grid>
			{selectedJob && (
				<SelectedJob
					solution={selectedJob}
					isOpen={selectedJob !== null}
					setIsCanceled={setIsCanceled}
					setIsPacked={setIsPacked}
					getMaterialAlias={getMaterialAlias}
				/>
			)}
		</JobQueueSignalRContext.Provider>
	);
};

export default ManualCartonBuild;
