import { Form, Radio } from "semantic-ui-react";
import React, { Fragment, useState } from "react";
import { createUseStyles, useTheme } from "react-jss";

import { useIntl } from "react-intl";

const useStyles = createUseStyles((theme) => ({
	...theme.configurationDialog,
	prompt: {
		paddingBottom: "16px",
	},
}));

const DimensioningBool = (props) => {
	const theme = useTheme();
	const classes = useStyles({ theme });
	const intl = useIntl();
	const [formValue, setFormValue] = useState(false);

	const positiveText = props.yesno ? "Yes" : "True";
	const negativeText = props.yesno ? "No" : "False";

	return (
		<Fragment>
			<Form.Field className={classes.prompt}>{props.prompt ? props.prompt : props.defaultPrompt}</Form.Field>
			<Form.Field>
				<Radio
					label={intl.formatMessage({ id: positiveText })}
					name="radioGroup"
					value="true"
					checked={formValue}
					onChange={(e, { value }) => setFormValue(value === "true")}
				/>
			</Form.Field>
			<Form.Field>
				<Radio
					label={intl.formatMessage({ id: negativeText })}
					name="radioGroup"
					value="false"
					checked={!formValue}
					onChange={(e, { value }) => setFormValue(value === "false")}
				/>
			</Form.Field>
		</Fragment>
	);
};

export default DimensioningBool;
