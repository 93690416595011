import axios from "axios";
import { readLocalStorage } from "@/api/local-storage";

let cancelToken;

export async function getTrimByPlannedProductionSchedulesForSite(schedules, sites, dateFilter) {
	const token = readLocalStorage("BEARER");
	if (typeof cancelToken != typeof undefined) {
		cancelToken.cancel("Operation canceled due to new request.");
	}

	cancelToken = axios.CancelToken.source();

	if (!sites.length || !dateFilter.id) {
		return [];
	}

	if (dateFilter && dateFilter.start) {
		const url = `/PacksizeReportingApi/api/v1/Trim/ByProductionSchedules?startDate=${dateFilter.start}&endDate=${dateFilter.end}&offsetInMinutes=${dateFilter.offset}`;

		const response = await axios.post(
			url,
			{ schedules: schedules, sites: sites },
			{
				headers: { Authorization: token },
				cancelToken: cancelToken.token,
			}
		);
		return response.data;
	} else {
		return [];
	}
}

export async function getTrim(sites, dateFilter) {
	const token = readLocalStorage("BEARER");

	if (typeof cancelToken != typeof undefined) {
		cancelToken.cancel("Operation canceled due to new request.");
	}

	cancelToken = axios.CancelToken.source();

	if (!sites.length || !dateFilter.id) {
		return [];
	}

	if (dateFilter && dateFilter.start) {
		const url = `/PacksizeReportingApi/api/v1/Trim?startDate=${dateFilter.start.replaceAll(
			":",
			"%3A"
		)}&endDate=${dateFilter.end.replaceAll(":", "%3A")}&offsetInMinutes=${dateFilter.offset}`;
		const response = await axios.post(url, sites, {
			headers: { Authorization: token },
			cancelToken: cancelToken.token,
		});
		return response.data;
	} else {
		return [];
	}
}

export async function getBucketTrimByPlannedProductionSchedulesForSite(schedules, sites, machinesSelected, dateFilter) {
	const token = readLocalStorage("BEARER");
	if (typeof cancelToken != typeof undefined) {
		cancelToken.cancel("Operation canceled due to new request.");
	}

	cancelToken = axios.CancelToken.source();

	if (!sites.length || !dateFilter.id) {
		return [];
	}

	if (dateFilter && dateFilter.start) {
		const url = `/PacksizeReportingApi/api/v1/Trim/TrimBuckets?startDate=${dateFilter.start}&endDate=${dateFilter.end}&offsetInMinutes=${dateFilter.offset}`;

		const response = await axios.post(
			url,
			{ schedules: schedules, sites: sites, machines: machinesSelected },
			{
				headers: { Authorization: token },
				cancelToken: cancelToken.token,
			}
		);
		return response.data;
	} else {
		return [];
	}
}