import { createContext } from "react";

const ListContext = createContext({
  list: [],
  setList: () => [],
  edit: {},
  setEdit: () => { },
  loading: true,
  setLoading: () => { },
  failed: false,
  setFailed: () => { },
});

export default ListContext;
