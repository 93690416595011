export const sortByValue = (items, primaryKey, primaryOrder = "desc", secondaryKey, secondaryOrder = "asc") => {
	if (!items.length) return items;

	return items.sort((a, b) => {
		const primaryComparison = compareValues(a[primaryKey], b[primaryKey], primaryOrder);

		if (primaryComparison !== 0) {
			return primaryComparison;
		} else if (secondaryKey) {
			return compareValues(a[secondaryKey], b[secondaryKey], secondaryOrder);
		} else {
			return 0;
		}
	});
};

const compareValues = (valueA, valueB, order) => {
	let comparison = 0;

	if (typeof valueA === "number" && typeof valueB === "number") {
		comparison = valueA - valueB;
	} else if (typeof valueA === "string" && typeof valueB === "string") {
		comparison = valueA.localeCompare(valueB, undefined, { numeric: true, sensitivity: "base" });
	} else if (valueA !== undefined && valueB !== undefined) {
		comparison = valueA > valueB ? 1 : -1;
	}

	if (order === "desc") {
		comparison *= -1;
	}

	return comparison;
};

export const sortByOrder = (items, key, order = []) => {
	if (!items.length) return items;

	return items.sort((a, b) => {
		if (order.length > 0) {
			const lowerCaseOrder = order.map((o) => o.toLowerCase());
			const indexA = lowerCaseOrder.indexOf(a[key].toLowerCase());
			const indexB = lowerCaseOrder.indexOf(b[key].toLowerCase());

			if (indexA === -1 && indexB === -1) return 0;
			if (indexA === -1) return 1;
			if (indexB === -1) return -1;

			return indexA - indexB;
		}
	});
};
