import { useQuery } from "@tanstack/react-query";
import { createContext } from "react";

export const BaseRoutesContext = createContext({
	audit: "/pipelineAuditApi/api",
	cartonPropertyGroup: "/cartonPropertyGroupApi/api",
	classification: "/classificationApi/api",
	cube: "/cubeApi/api",
	corrugate: "/corrugateApi/api",
	dim: "/dimensionApi/api",
	em: "/emMachineApi/api",
	eventNotification: "/eventNotificationApi/api",
	fusion: "/fusionapi/api",
	identity: "/identityApi/api",
	import: "/importApi/api",
	machineGroup: "/machineGroupApi/api",
	packagingDesign: "/packagingDesignApi/api",
	pickZone: "/pickZoneApi/api",
	plannedProduction: "/plannedProductionApi/api",
	printImage: "/printImageApi/api",
	printImageStaging: "/printImageStagingApi/api",
	productionGroup: "/productionGroupApi/api",
	reporting: "/packsizeReportingApi/api",
	reportingCollector: "/reportingCollector/api",
	rulesEngine: "/ruleEngineService/api",
	securePrint: "/securePrintApi/api",
	selection: "/selectionPipelineApi/api",
	x4: "/x4api/api",
	x5: "/x5api/api",
	x6: "/x6api/api",
	x7: "/x7api",
	zebra: "/zebraPrinterMachineApi/api",
});

export const baseRoutesQueryKey = ["baseRoutes"];

function BaseRoutesProvider({ children }) {
	const queryFn = async () => {
		const response = await fetch(`${window.location.origin}/PackNet/base-routes.json`);
		return await response.json();
	};

	const { data } = useQuery({
		queryKey: baseRoutesQueryKey,
		queryFn,
		refetchInterval: 10000,
	});

	return <BaseRoutesContext.Provider value={data}>{children}</BaseRoutesContext.Provider>;
}

export default BaseRoutesProvider;
