import axios from "axios";
import { readLocalStorage } from "./local-storage";

export async function postProduct(data) {
	const token = readLocalStorage("BEARER");

	const response = await axios.post("/DimensionApi/api/v1/Products", data, {
		headers: {
			Authorization: token,
			"Content-Type": "application/json",
		},
	});

	return response.data;
}

export async function putProduct(data, id) {
	const token = readLocalStorage("BEARER");

	const response = await axios.put(`/DimensionApi/api/v1/Products/${id}`, data, {
		headers: {
			Authorization: token,
			"Content-Type": "application/json",
		},
	});

	return response.data;
}

export async function importProducts(data) {
	const token = readLocalStorage("BEARER");

	const response = await axios.post(`/DimensionApi/api/v1/Import/`, data, {
		headers: {
			Authorization: token,
			"Content-Type": "application/json",
		},
	});

	return response.data;
}

export async function importProductsBackground(filename, data) {
	const token = readLocalStorage("BEARER");

	const response = await axios.post(`/DimensionApi/api/v1/Import/background/${filename}`, data, {
		headers: {
			Authorization: token,
			"Content-Type": "application/json",
		},
	});

	return response.data;
}

export async function getImportStatusHistory() {
	const token = readLocalStorage("BEARER");

	const response = await axios.get("/DimensionApi/api/v1/Import", {
		headers: {
			Authorization: token,
			"Content-Type": "application/json",
		},
	});

	return response.data;
}

export async function findProduct(id) {
	const token = readLocalStorage("BEARER");

	const response = await axios.get(`/DimensionApi/api/v1/Products/${id}`, {
		headers: {
			Authorization: token,
			"Content-Type": "application/json",
		},
	});

	return response.data;
}

export async function searchProduct(id) {
	const token = readLocalStorage("BEARER");

	const response = await axios.get(`/DimensionApi/api/v1/Products/Search/${id}?pageNumber=0&pageSize=500`, {
		headers: {
			Authorization: token,
			"Content-Type": "application/json",
		},
	});

	return response.data;
}

export async function totalProductsCount() {
	const token = readLocalStorage("BEARER");

	const response = await axios.get(`/DimensionApi/api/v1/Products/CountByTenant`, {
		headers: {
			Authorization: token,
			"Content-Type": "application/json",
		},
	});

	return response.data;
}

export async function deleteProduct(id) {
	const token = readLocalStorage("BEARER");

	const response = await axios.delete(`/DimensionApi/api/v1/Products/${id}`, {
		headers: {
			Authorization: token,
			"Content-Type": "application/json",
		},
	});

	return response;
}

export function flattenCustomAttributes(product, tenantConfig) {
	tenantConfig.attributeCaptureSteps.forEach((step) => {
		if (step.isCustomAttribute) {
			if (!product.customAttributes) {
				return;
			}
			product[step.name] = product.customAttributes[step.id];
		}
	});

	return product;
}

export function unflattenCustomAttributes(product, tenantConfig) {
	tenantConfig.attributeCaptureSteps.forEach((step) => {
		if (step.isCustomAttribute) {
			if (!product.customAttributes) {
				product.customAttributes = {};
			}
			product.customAttributes[step.id] = product[step.name];
		}
	});

	return product;
}
