import { Button, Header, Icon, Input, Segment } from "semantic-ui-react";
import { FormattedMessage, useIntl } from "react-intl";
import React, { useContext } from "react";
import { createUseStyles } from "react-jss";

import DataTable from "react-data-table-component";
import History from "@/views/production/job-history/job-history";
import { ProductionContext } from "@/contexts/production-context";
import useAxios from "@/api/useAxios";
import useScanInput from "./use-scan-input";
import { toggleMachineGroupPlay } from "@/views/production/machine-group-control/machine-group-control";
import { JobQueueContext } from "../orders/job-queue-provider";
import { UserContext } from "@/components/user-context";
import useToken from "@/hooks/use-token";

const useStyles = createUseStyles((theme) => ({
	header: {
		border: "0 !important",
		alignItems: "center",
		padding: "24px 32px !important",
		margin: "0 !important",
		backgroundColor: "#f2f4f5 !important",
		borderRadius: "0px !important",
	},
	headerText: {
		color: "#353430 !important",
		fontWeight: "normal !important",
		fontSize: "40px !important",
		margin: "0 !important",
		lineHeight: "36px !important",
		letterSpacing: "-.5px",
	},
	headerContent: {
		display: "flex",
		alignItems: "center",
	},
	scanInput: {
		"& input": {
			fontFamily: "Stolzl-Book !important",
			fontSize: "16px !important",
			minWidth: "340px",
		},
	},
	ready: {
		margin: "0px !important",
		padding: "100px !important",
		backgroundColor: theme.colors.lightBackground,
		height: "calc(100vh - 224px) !important",
	},
	bigButton: {
		margin: "0px !important",
		padding: "32px !important",
		background: "white !important",
	},
	checkBox: {
		fontSize: "6em !important",
	},
}));

const columns = [
	{
		name: "Name",
		selector: (row) => row.alias,
		sortable: true,
		maxWidth: "40vw",
		grow: 3,
	},
	{
		hide: "md",
		wrap: true,
		grow: 1,
	}, //Add some space between the columns
	{
		name: "Design",
		cell: (row) => {
			if (!row.cartons) return "";
			return row.cartons.length ? row.cartons[0].media.style : "";
		},
		maxWidth: "100px",
		center: true,
		grow: 0,
	},
	{
		name: "Length",
		cell: (row) => {
			if (!row.cartons) return "";
			return row.cartons.length ? row.cartons[0].media.dimensions.length : "";
		},
		maxWidth: "100px",
		center: true,
		grow: 0,
	},
	{
		name: "Width",
		cell: (row) => {
			if (!row.cartons) return "";
			return row.cartons.length ? row.cartons[0].media.dimensions.width : "";
		},
		maxWidth: "100px",
		center: true,
		grow: 0,
	},
	{
		name: "Height",
		cell: (row) => {
			if (!row.cartons) return "";
			return row.cartons.length ? row.cartons[0].media.dimensions.height : "";
		},
		maxWidth: "100px",
		center: true,
		grow: 0,
	},
	{
		name: "Quantity",
		cell: (row) => (!row.cartons ? "" : row.cartons.length),
		maxWidth: "100px",
		center: true,
		grow: 0,
	},
];

const JobQueue = () => {
	const intl = useIntl();
	const classes = useStyles();
	const token = useToken();

	const { currentUser } = useContext(UserContext);
	const { currentMachineGroup } = useContext(ProductionContext);

	const {
		shouldRender: shouldRenderScanInput,
		inputRef: scanInputRef,
		onKeyUp: onScanInputKeyUp,
		isLoading: isScanInputLoading,
	} = useScanInput({ currentMachineGroup, historyExpanded: false });

	const { selectedJobs } = useContext(JobQueueContext);

	const MachineGroupApi = useAxios("/MachineGroupApi/api/v1/MachineGroups", token);

	const customStyles = {
		rows: {
			style: {
				minHeight: "90px",
			},
		},
		cells: {
			style: {
				paddingLeft: "8px", // override the cell padding for data cells
				paddingRight: "8px",
			},
		},
	};

	return (
		<>
			<Segment clearing className={classes.header}>
				<Header floated="left" className={classes.headerText}>
					{intl.formatMessage({ id: "Job Queue" })}
				</Header>
				<Header size="large" floated="right" className={classes.headerContent}>
					{shouldRenderScanInput && (
						<Input
							icon={{ size: "tiny" }}
							loading={isScanInputLoading}
							disabled={isScanInputLoading}
							input={{ ref: scanInputRef, onKeyUp: onScanInputKeyUp }}
							className={classes.scanInput}
							data-cy="scan-input"
							placeholder={intl.formatMessage({ id: "Ready to scan" })}
						/>
					)}
					<History />
				</Header>
			</Segment>
			{(function () {
				const productionEnabled = currentMachineGroup.productionEnabled;
				switch (currentMachineGroup.status.toLowerCase()) {
					case "initializing":
						return (
							<Segment basic textAlign="center" className={classes.ready}>
								<Icon name="refresh" size="huge" color="grey" className={classes.checkBox} />
								<Header as="h2">
									<FormattedMessage id="Machine group is initializing" />
								</Header>
							</Segment>
						);
					case "service":
					case "machineservice":
						return (
							<Segment basic textAlign="center" className={classes.ready}>
								<Icon name="exclamation circle" size="huge" color="red" className={classes.checkBox} />
								<Header as="h2">
									<FormattedMessage id="Machine is in service mode" />
								</Header>
							</Segment>
						);
					case "error":
						return (
							<Segment basic textAlign="center" className={classes.ready}>
								<Icon name="exclamation circle" size="huge" color="red" className={classes.checkBox} />
								<Header as="h2">
									<FormattedMessage id="Machine group is in error mode" />
								</Header>
							</Segment>
						);
					case "online": {
						if (productionEnabled && selectedJobs.length > 0)
							return <DataTable noHeader columns={columns} data={selectedJobs} customStyles={customStyles} />;
						else if (productionEnabled && selectedJobs.length <= 0)
							return (
								<Segment basic textAlign="center" className={classes.ready}>
									<Icon name="check circle" size="huge" color="green" className={classes.checkBox} />
									<Header as="h2">
										<FormattedMessage id="Ready to receive jobs" />
									</Header>
								</Segment>
							);
						else if (!productionEnabled)
							return (
								<Segment basic textAlign="center" className={classes.ready}>
									<Button
										icon
										className={classes.bigButton}
										onClick={() => toggleMachineGroupPlay(currentMachineGroup, MachineGroupApi)}
									>
										<Icon name="play" size="huge" className={classes.checkBox} />
									</Button>
									<Header as="h2">
										<FormattedMessage id="Machine group is online but paused, press play to start receiving jobs" />
									</Header>
								</Segment>
							);
						break;
					}
					case "paused":
						return (
							<Segment basic textAlign="center" className={classes.ready}>
								<Icon name="pause" size="huge" color="yellow" className={classes.checkBox} />
								<Header as="h2">
									<FormattedMessage id="Machines are paused, ensure all machines are online and ready" />
								</Header>
							</Segment>
						);
					case "offline":
						return (
							<Segment basic textAlign="center" className={classes.ready}>
								<Icon
									data-cy="machine-offline-icon"
									name="close"
									size="huge"
									color="black"
									className={classes.checkBox}
								/>
								<Header as="h2">
									<FormattedMessage id="Machine group is not currently online, verify the machines are online" />
								</Header>
							</Segment>
						);
					default:
						return <div>State {currentMachineGroup.status.toLowerCase()} not handled</div>;
				}
			})()}
		</>
	);
};

export default JobQueue;
