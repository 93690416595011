import styled from "styled-components";

const ZeroMarginP = styled("p")`
	margin: 0;
`;

export const Title1 = styled("h1")`
	font-family: ${(props) => props.theme.fonts.medium};
	font-size: ${(props) => props.theme.fontSizes[48]};
	letter-spacing: 0;
	margin: 0;
`;

export const Title2 = styled("h2")`
	font-family: ${(props) => props.theme.fonts.medium};
	font-size: ${(props) => props.theme.fontSizes[40]};
	margin: 0;
`;

export const Header1 = styled("h3")`
	font-family: ${(props) => props.theme.fonts.medium};
	font-size: ${(props) => props.theme.fontSizes[32]};
	margin: 0;
`;

export const Header2 = styled("h4")`
	font-family: ${(props) => props.theme.fonts.medium};
	font-size: ${(props) => props.theme.fontSizes[24]};
	margin: 0;
`;

export const Header3 = styled("h5")`
	font-family: ${(props) => props.theme.fonts.medium};
	font-size: ${(props) => props.theme.fontSizes[16]};
	margin: 0;
`;

export const BodyLarge = styled(ZeroMarginP)`
	font-family: ${(props) => props.theme.fonts.small};
	font-size: ${(props) => props.theme.fontSizes[24]};
`;

export const BodyRegular = styled(ZeroMarginP)`
	font-family: ${(props) => props.theme.fonts.regular};
	font-size: ${(props) => props.theme.fontSizes[16]};
`;

export const BodyMedium = styled(ZeroMarginP)`
	font-family: ${(props) => props.theme.fonts.medium};
	font-size: ${(props) => props.theme.fontSizes[16]};
`;

export const BodyBook = styled(ZeroMarginP)`
	font-family: ${(props) => props.theme.fonts.small};
	font-size: ${(props) => props.theme.fontSizes[16]};
`;

export const SmallRegular = styled(ZeroMarginP)`
	font-family: ${(props) => props.theme.fonts.regular};
	font-size: ${(props) => props.theme.fontSizes[14]};
`;

export const SmallBook = styled(ZeroMarginP)`
	font-family: ${(props) => props.theme.fonts.small};
	font-size: ${(props) => props.theme.fontSizes[14]};
`;

export const SmallerRegular = styled(ZeroMarginP)`
	font-family: ${(props) => props.theme.fonts.regular};
	font-size: ${(props) => props.theme.fontSizes[12]};
`;

export const SmallerBook = styled(ZeroMarginP)`
	font-family: ${(props) => props.theme.fonts.small};
	font-size: ${(props) => props.theme.fontSizes[12]};
`;

export const Subtitle = styled.span`
	font-family: ${(props) => props.theme.fonts.small};
	font-size: ${(props) => props.theme.fontSizes[16]};
	line-height: 1.4285em;
	margin-bottom: 8px;
`;
