import styled from "styled-components";

function getJustifyContent(props) {
	if (props.justifyCenter) {
		return "center";
	}
	if (props.justifyAround) {
		return "space-around";
	}
	if (props.justifyBetween) {
		return "space-between";
	}
	if (props.justifyEvenly) {
		return "space-evenly";
	}
	if (props.justifyStart) {
		return "flex-start";
	}
	if (props.justifyEnd) {
		return "flex-end";
	}
	return null;
}

function getAlignItems(props) {
	if (props.alignCenter) {
		return "center";
	}
	if (props.alignStart) {
		return "flex-start";
	}
	if (props.alignEnd) {
		return "flex-end";
	}
	if (props.alignBaseline) {
		return "baseline";
	}
	return null;
}

const flexProps = [
	"alignCenter",
	"alignStart",
	"alignEnd",
	"alignBaseline",
	"justifyCenter",
	"justifyAround",
	"justifyBetween",
	"justifyEvenly",
	"justifyStart",
	"justifyEnd",
	"column",
	"gap",
	"grow",
	"row",
];

export const Flex = styled.div.withConfig({
	shouldForwardProp: (prop) => !flexProps.includes(prop),
})`
	display: flex;
	flex-direction: ${(props) => (props.column ? "column" : "row")};
	justify-content: ${(props) => getJustifyContent(props)};
	align-items: ${(props) => getAlignItems(props)};
	gap: ${(props) => (props.gap ? props.gap + "px" : null)};
	flex-grow: ${(props) => (props.grow ? 1 : null)};
`;

export default Flex;
