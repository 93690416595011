export const getJobContentsString = ({ cartonCountPerJob, labelCountPerJob, cartonDimension, intl }) => {
	const componentCounts = [];
	// some apis return capital carton dimensions, while others don't :(
	let lowercaseDimensions;
	if (cartonDimension !== null) {
		lowercaseDimensions = Object.fromEntries(Object.entries(cartonDimension).map(([k, v]) => [k.toLowerCase(), v]));
	}

	if (cartonCountPerJob === 1) {
		if (lowercaseDimensions) {
			componentCounts.push(
				`${lowercaseDimensions.length} x ${lowercaseDimensions.width} x ${lowercaseDimensions.height}`,
			);
		} else {
			componentCounts.push(`1 ${intl.formatMessage({ id: "Carton" })}`);
		}
	}
	if (cartonCountPerJob > 1) {
		componentCounts.push(`${cartonCountPerJob} ${intl.formatMessage({ id: "Cartons" })}`);
	}

	if (labelCountPerJob === 1) {
		componentCounts.push(`1 ${intl.formatMessage({ id: "Label" })}`);
	}
	if (labelCountPerJob > 1) {
		componentCounts.push(`${labelCountPerJob} ${intl.formatMessage({ id: "Labels" })}`);
	}

	return componentCounts.join(", ");
};
