import { useState, useMemo, useEffect } from "react";
import { useIntl } from "react-intl";

import { searchProduct } from "@/api/dim-product-api";

/**
 * useSearchData
 *
 * Custom hook for querying the API for products.
 * Example:
 *   const { data, loading, error } = useSearchData('searchTerm');
 *
 * @param {string} term
 * @returns {object}
 */
function useSearchData(term) {
	const intl = useIntl();
	const [data, setData] = useState(null);
	const [error, setError] = useState(null);
	const [loading, setLoading] = useState(true);
	const [showNotFound, setShowNotFound] = useState(false);
	const [showError, setShowError] = useState(false);

	useEffect(() => {
		internalSearch(term);
	}, []);

	async function internalSearch(term) {
		try {
			setData(null);
			setLoading(true);
			const result = await searchProduct(term);
			setData(result);
		} catch (error) {
			if (error.response && error.response.status === 404) {
				setShowNotFound(true);
				return;
			}
			setShowError(true);
			setError(intl.formatMessage({ id: "SystemError" }));
		} finally {
			setLoading(false);
		}
	}

	const handlers = useMemo(
		() => ({
			closeNotFound: () => {
				setShowNotFound(false);
			},
			closeError: () => {
				setShowError(false);
			},
			search: async (term) => {
				return await internalSearch(term);
			},
		}),
		[]
	);

	return {
		data,
		loading,
		error,
		showNotFound,
		showError,
		handlers,
	};
}

export { useSearchData as default };
