import axios from "axios";
import { readLocalStorage } from "./local-storage";

export async function getIdentifiers() {
	const token = readLocalStorage("BEARER");
	const response = await axios.get("/DimensionApi/api/v1/Configuration/identifiers", {
		headers: {
			Authorization: token,
			"Content-Type": "application/json",
		},
	});
	return response.data;
}

export async function getAllAttributeTypes() {
	const token = readLocalStorage("BEARER");
	const response = await axios.get("/DimensionApi/api/v1/Configuration/AttributeTypes", {
		headers: {
			Authorization: token,
			"Content-Type": "application/json",
		},
	});
	return response.data;
}

export async function getAllScanSteps() {
	const token = readLocalStorage("BEARER");
	const response = await axios.get("/DimensionApi/api/v1/Configuration/ScanSteps", {
		headers: {
			Authorization: token,
			"Content-Type": "application/json",
		},
	});
	return response.data;
}

export async function getCurrentConfiguration() {
	const token = readLocalStorage("BEARER");
	const response = await axios.get("/DimensionApi/api/v1/Configuration", {
		headers: {
			Authorization: token,
			"Content-Type": "application/json",
		},
	});
	return response.data;
}

export async function postTenantConfiguration(data) {
	const token = readLocalStorage("BEARER");

	const response = await axios.post("/DimensionApi/api/v1/Configuration", data, {
		headers: {
			Authorization: token,
			"Content-Type": "application/json",
		},
	});
	return response.data;
}
