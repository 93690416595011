import { SnackbarProvider } from "notistack";
import { createUseStyles } from "react-jss";
import SuccessToast from "./success-toast";
import ErrorToast from "./error-toast";
import InfoToast from "./info-toast";

const useStyles = createUseStyles({
	container: {
		top: 65,
	},
});

const ToastProvider = ({ children }) => {
	const classes = useStyles();

	return (
		<SnackbarProvider
			maxSnack={10}
			classes={{ containerAnchorOriginTopRight: classes.container }}
			anchorOrigin={{ vertical: "top", horizontal: "right" }}
			Components={{ customSuccess: SuccessToast, customError: ErrorToast, customInfo: InfoToast }}
		>
			{children}
		</SnackbarProvider>
	);
};

export default ToastProvider;
