import { Button, Header, Segment, Icon } from "semantic-ui-react";
import { FormattedMessage, useIntl } from "react-intl";
import React, { useContext, useState } from "react";
import { createUseStyles, useTheme } from "react-jss";

import DataTable from "react-data-table-component";
import { readLocalStorage } from "@/api/local-storage";
import useAxios from "@/api/useAxios";
import { JobQueueContext } from "@/views/production/orders/job-queue-provider";
import { useCanManageJobs } from "@/components/user-context";

const flyOutPosition = "85vw";
const jobHandleStyles = (theme) => ({
	"-webkit-transition-duration": "1s",
	cursor: "pointer",
	marginLeft: "40px",
	marginRight: "-45px",
	color: theme.colors.primary,
	backgroundColor: theme.colors.white,
	height: "48px",
	padding: "6px 16px",
	borderTopLeftRadius: "6px",
	borderBottomLeftRadius: "6px",
	fontSize: "16px",
	minWidth: "185px",
	boxShadow: "0px 3px 3px 0px #bcbdbd, 0px 0px 0px 1px #d4d4d5",
});
const jobContentStyles = (theme) => ({
	"-webkit-transition-duration": "1s",
	position: "absolute",
	top: "10px",
	right: `-${flyOutPosition}`,
	width: `${flyOutPosition}`,
	height: "calc(100vh - 138px)",
	marginLeft: "50px",
	borderTopLeftRadius: "6px",
	borderBottomLeftRadius: "6px",
	backgroundColor: theme.colors.white,
	zIndex: 9,
	boxShadow: "0px 3px 3px 0px #bcbdbd, 0px 0px 0px 1px #d4d4d5",
	overflowY: "auto",
});

const useStyles = createUseStyles((theme) => ({
	header: {
		border: "0 !important",
		alignItems: "center",
		padding: "24px 32px !important",
		margin: "0 !important",
		borderRadius: "0px !important",
	},
	headerText: {
		color: "#353430 !important",
		fontWeight: "normal !important",
		fontSize: "40px !important",
		margin: "0 !important",
		lineHeight: "36px !important",
		letterSpacing: "-.5px",
	},
	handle: {
		transform: "translate3d(0vw, 0, 0)",
		...jobHandleStyles(theme),
	},
	expanded: {
		transform: `translate3d(-${flyOutPosition}, 0, 0)`,
		...jobHandleStyles(theme),
		minWidth: "0px",
	},
	handleContent: {
		transform: "translate3d(0vw, 0, 0)",
		...jobContentStyles(theme),
	},
	expandedHistoryContent: {
		transform: `translate3d(-${flyOutPosition}, 0, 0)`,
		...jobContentStyles(theme),
	},
	reproduceButton: {
		boxShadow: "unset !important",
		color: theme.colors.primary,
	},
	jobName: {
		textOverflow: "ellipsis",
		whiteSpace: "nowrap",
		overflow: "hidden",
		padding: "24px !important",
		paddingBottom: "0px !important",
		maxWidth: "40vw",
	},
	jobStatus: {
		padding: "24px !important",
		paddingTop: "6px !important",
	},
	date: {
		color: "#a2adb3",
		marginRight: "24px !important",
		fontFamily: "Stolzl-Book !important",
		fontSize: "smaller",
	},
	completed: {
		color: "#7ed321",
	},
}));

const JobHistory = () => {
	const intl = useIntl();
	const theme = useTheme();
	const classes = useStyles({ theme });
	const token = readLocalStorage("BEARER");
	const [historyExpanded, setHistoryExpanded] = useState(false);

	const { jobHistory } = useContext(JobQueueContext);
	const Reproduce = useAxios("/PipelineAuditApi/api/v1/Reproduce/PackagingSolution", token);

	const userCanManageJobs = useCanManageJobs();

	const getAuditState = (row) => {
		if (row.auditType === "Produced")
			return (
				<span className={classes.completed}>
					<FormattedMessage id="Completed" />
				</span>
			);
		return <span>{row.auditType}</span>;
	};

	const getContent = (row) => {
		const cartonCount = row.data.Cartons.length;
		const labelCount = row.data.Labels.length;
		const documentsCount = row.data.Documents.length;
		const bagsCount = row.data.Bags.length;
		const totalLabelCount = labelCount + row.data.Cartons.map((c) => c.Labels).flat().length;
		const totalDocumentsCount = documentsCount + row.data.Cartons.map((c) => c.Documents).flat().length;

		const items = [];
		if (cartonCount)
			items.push(
				intl.formatMessage(
					{
						id: "{count} Cartons(s)",
						defaultMessage: "{count} Cartons(s)",
					},
					{ count: cartonCount },
				),
			);
		if (bagsCount)
			items.push(
				intl.formatMessage(
					{
						id: "{count} Bag(s)",
						defaultMessage: "{count} Bag(s)",
					},
					{ count: bagsCount },
				),
			);
		if (totalLabelCount)
			items.push(
				intl.formatMessage(
					{
						id: "{count} Label(s)",
						defaultMessage: "{count} Label(s)",
					},
					{ count: totalLabelCount },
				),
			);
		if (totalDocumentsCount)
			items.push(
				intl.formatMessage(
					{
						id: "{count} Document(s)",
						defaultMessage: "{count} Document(s)",
					},
					{ count: totalDocumentsCount },
				),
			);
		return <span>{items.join(", ")}</span>;
	};

	const columns = [
		{
			name: "Name",
			maxWidth: "40vw",
			cell: (row) => (
				<div>
					<Segment basic vertical className={classes.jobName}>
						{row.alias}
					</Segment>
					<Segment basic vertical className={classes.jobStatus}>
						<span className={classes.date}>{row.date.toLocaleString()}</span>
					</Segment>
				</div>
			),
			grow: 3,
		},
		{
			name: "Status",
			cell: (row) => getAuditState(row),
			maxWidth: "200px",
			cednter: true,
			grow: 1,
		},
		{
			hide: "md",
			wrap: true,
			grow: 1,
		}, //Add some space between the columns
		{
			name: "Content",
			cell: (row) => getContent(row),
			maxWidth: "200px",
			center: true,
			grow: 1,
		},
		{
			name: "Quantity",
			cell: (row) => (!row.data.Cartons ? "" : row.data.Cartons.length),
			maxWidth: "200px",
			center: true,
			grow: 1,
		},
		{
			name: "",
			omit: !userCanManageJobs,
			cell: (row) => {
				if (row.auditType === "Removed" || row.auditType === "Produced") {
					return (
						<Button
							basic
							className={classes.reproduceButton}
							onClick={() => {
								Reproduce.addWithUrl(row.key, {});
							}}
						>
							<FormattedMessage id="REPRODUCE" />
						</Button>
					);
				} else {
					return <></>;
				}
			},
			width: "200px",
			right: true,
			grow: 0,
		},
	];

	const customStyles = {
		rows: {
			style: {
				minHeight: "90px",
			},
		},
		cells: {
			style: {
				paddingLeft: "8px", // override the cell padding for data cells
				paddingRight: "8px",
			},
		},
	};

	return (
		<>
			<span
				className={historyExpanded ? classes.expanded : classes.handle}
				onClick={() => setHistoryExpanded(!historyExpanded)}
			>
				<Icon name={`angle ${historyExpanded ? "right" : "left"}`} />
				{!historyExpanded && <FormattedMessage id="Jobs History" />}
			</span>
			<span className={historyExpanded ? classes.expandedHistoryContent : classes.handleContent}>
				<Segment clearing className={classes.header}>
					<Header floated="left" className={classes.headerText}>
						{intl.formatMessage({ id: "Job History" })}
					</Header>
				</Segment>
				<DataTable
					noHeader
					columns={columns}
					data={jobHistory}
					customStyles={customStyles}
					noDataComponent={<FormattedMessage id="There are no records to display" />}
				/>
			</span>
		</>
	);
};

export default JobHistory;
