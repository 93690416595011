import { Button, Form, Grid, Header, Segment } from "semantic-ui-react";
import { FormattedMessage, useIntl } from "react-intl";
import React, { Fragment, useEffect, useState } from "react";
import { datesToUtcEpoch } from "@/utils/datetime";
import { createUseStyles, useTheme } from "react-jss";

import DatePickerDropdown from "@/components/date-picker-dropdown/date-picker-dropdown";
import DiffView from "@/components/diff-view";
import FailedRetry from "@/components/failed-retry";
import Loading from "@/components/loading";
import { readLocalStorage } from "@/api/local-storage";
import useAxios from "@/api/useAxios";
import { last7DaysDates } from "@/components/date-picker-dropdown/utils";

const useStyles = createUseStyles((theme) => ({
	...theme.configurationDialog,
	headerText: {
		...theme.configurationDialog.headerText,
		width: "unset",
	},
	header: {
		...theme.configurationDialog.form,
		backgroundColor: theme.colors.white,
	},
	group: {
		...theme.configurationDialog.group,
		border: "0px !important",
		borderRadius: "0px !important",
		height: "calc(100vh - 144px)",
		maxHeight: "calc(100vh - 144px)",
		overflowY: "auto !important",
	},
	form: {
		...theme.configurationDialog.form,
		height: "calc(100vh - 337px)",
		maxHeight: "calc(100vh - 337px)",
		minHeight: "200px",
		paddingTop: "8px !important",
		paddingBottom: "0px !important",
		paddingLeft: "0px !important",
		paddingRight: "0px !important",
	},
	grid: {
		marginLeft: "-.125rem !important",
		width: "100%",
	},
	gridColumns: {
		width: "99vw",
	},
}));

function ConfigurationAudit() {
	const intl = useIntl();
	const theme = useTheme();
	const classes = useStyles({ theme });
	const token = readLocalStorage("BEARER");
	const configurationAuditApi = useAxios("/PipelineAuditApi/api/v1/ConfigurationAudit", token);
	const [loading, setLoading] = useState(true);
	const [failed, setFailed] = useState(false);
	const [list, setList] = useState([]);

	const [dateRange, setDateRange] = useState(last7DaysDates());

	const load = () => {
		setFailed(false);
		setLoading(true);
		setList([]);
		const dates = datesToUtcEpoch(dateRange);

		configurationAuditApi.getWithUrl(
			`byStartDateUtcEpoch/${dates[0]}/byEndDateUtcEpoch/${dates[1]}`,
			(data) => {
				setList(data);
				setLoading(false);
			},
			(_) => {
				setLoading(false);
				setFailed(true);
			}
		);
	};

	useEffect(load, []);

	const localizedRedacted = intl.formatMessage({ id: "Redacted" });
	return (
		<Fragment>
			{loading ? (
				<Loading />
			) : failed ? (
				<FailedRetry retry={load} />
			) : (
				<Segment.Group className={classes.group}>
					<Segment className={classes.header}>
						<Header as="h3" textAlign={"left"} className={classes.headerText}>
							<FormattedMessage id="Configuration Audit" />
							<Button floated="right" primary disabled={loading} onClick={load}>
								{intl.formatMessage({ id: "Load" })}
							</Button>
							<Header.Subheader>
								<Form>
									<Form.Group widths="equal" className={classes.grid}>
										<Grid columns={3} relaxed className={classes.gridColumns}>
											<Grid.Column />
											<Grid.Column />
											<Grid.Column>
												<DatePickerDropdown dateRange={dateRange} setDateRange={setDateRange} />
											</Grid.Column>
										</Grid>
									</Form.Group>
								</Form>
							</Header.Subheader>
						</Header>
					</Segment>
					<Segment className={classes.form}>
						<div className={classes.diff}>
							<DiffView
								data={list}
								compareAll={true}
								additionalDataTransform={(data) => {
									if (data == null || typeof data !== "object") {
										console.error("Data for message was invalid or null");
										return {};
									}
									delete data.Data?.Id;
									delete data.Data?.TenantId;
									delete data.Data?.Hash;
									delete data.Data?.AdditionalTenants;
									if (data.Data && typeof data.Data === "object") {
										data.Data.UserName = localizedRedacted;
										data.Data.Email = localizedRedacted;
										data.Data.PhoneNumber = localizedRedacted;
										data.Data.FirstName = localizedRedacted;
										data.Data.LastName = localizedRedacted;
									}
									delete data.UserName;
									delete data.AssignedOperator;
									delete data.IotEdgeDeviceId;
									delete data.IotEdgeModuleId;
									delete data.Status;
									delete data.ProductionStatus;
									delete data.Error;
									delete data.IsReadyForWork;
									delete data.LastUpdated;
									delete data.AssignedOperatorId;
									delete data.ProductionEnabled;

									return data;
								}}
							/>
						</div>
					</Segment>
				</Segment.Group>
			)}
		</Fragment>
	);
}

export default ConfigurationAudit;
