import { readLocalStorage } from "@/api/local-storage";
import machineTypes from "@/constants/machineTypes";
import errorSeverity from "@/constants/errorSeverity";
import { useEffect, useRef } from "react";
import { HttpTransportType } from "@microsoft/signalr";
import { createSignalRContext } from "react-signalr";

const machineType = machineTypes.X7;
const SignalRContext = createSignalRContext();
const websocketUrl = "/x7api/hubs/x7machines";
const errorsChangedEventName = "OnErrorsUpdated";
const warningsChangedEventName = "OnWarningsUpdated";
const subscribeWarningsMethods = "SubscribeWarnings";
const subscribeErrorsMethods = "SubscribeErrors";
const unsubscribeErrorsMethod = "UnsubscribeErrors";
const unsubscribeWarningsMethod = "UnsubscribeWarnings";

function formatAlarmData(machineId, data, severity) {
	return (
		data
			?.filter((alarm) => alarm.isActive === true)
			.map((alarm) => {
				return {
					machineId,
					machineType,
					severity,
					code: alarm.alarmType,
					category: getAlarmCategory(alarm.alarmType, severity), // used to select appropriate string & image assets to display
				};
			}) ?? []
	);
}

function getAlarmCategory(alarmCode, alarmType) {
	if (alarmType === errorSeverity.error) {
		switch (alarmCode) {
			case 6:
				return "ReloadZFoldTrack1";
			case 102:
				return "ReloadZFoldTrack2";
			case 4921:
				return "GluerNotReady";
			case 4922:
			case 4923:
			case 4924:
			case 4925:
				return "GlueUnitError";
			case 4926:
				return "GlueUnitOff";
			case 4993:
			case 4994:
			case 4995:
			case 4996:
			case 5001:
			case 5002:
			case 5003:
			case 5004:
			case 5005:
			case 5006:
			case 5007:
			case 5337:
			case 5338:
			case 5339:
				return "EStopTriggered";
			case 5079:
				return "LabelUnitOff";
			case 5080:
			case 5083:
				return "LabelUnitError";
			case 5081:
				return "LabelUnitNoRibbon";
			case 5082:
			case 5102:
				return "LabelUnitNoLabels";
			case 5084:
				return "NoPadLabel";
			case 5101:
				return "DownstreamConveyorStoppedError";
			case 5209:
				return "PackNetConnectionError";
			case 5353:
			case 5354:
			case 5355:
			case 5356:
			case 5357:
				return "DoorUnsecuredError";
			case 8675309:
				return "CorrugateMismatch";
			default:
				return "DefaultError";
		}
	} else if (alarmType === errorSeverity.warning) {
		switch (alarmCode) {
			case 4975:
				return "GlueLevelLow";
			case 5009:
			case 5010:
			case 5011:
			case 5017:
			case 5018:
			case 5019:
			case 5020:
			case 5021:
			case 5022:
			case 5023:
			case 5024:
				return "DoorUnsecuredWarning";
			case 5122:
				return "LabelUnitLowRibbon";
			case 5123:
				return "LabelUnitLowLabels";
			case 5133:
				return "DownstreamConveyorStoppedWarning";
			case 6331:
				return "WaitingOnPackNet";
			default:
				return "DefaultWarning";
		}
	} else {
		return "";
	}
}

export default function X7ErrorsAndWarnings({ machines, onErrorsChanged, onWarningsChanged }) {
	const token = readLocalStorage("BEARER");
	const machineIds = useRef([]);

	useEffect(() => {
		console.info(`Machines list updated. Current count: ${machines.length}`);
		unsubscribeMachineGroup();
		machineIds.current = machines
			.filter((x) => x.machineType.toLowerCase() === machineType.toLowerCase())
			.map((x) => x.machineId);
		subscribeMachineGroup();
	}, [machines]);

	function subscribeMachineGroup() {
		machineIds.current.forEach((id) => {
			SignalRContext.invoke(subscribeErrorsMethods, id);
			SignalRContext.invoke(subscribeWarningsMethods, id);
		});
	}

	function unsubscribeMachineGroup() {
		machineIds.current.forEach((id) => {
			SignalRContext.invoke(unsubscribeErrorsMethod, id);
			SignalRContext.invoke(unsubscribeWarningsMethod, id);
		});
	}

	SignalRContext.useSignalREffect(errorsChangedEventName, (_, machineId, newErrors) => {
		if (machineIds.current.includes(machineId)) {
			console.info(`Processing ${errorsChangedEventName} SignalR event for machine: ${machineId}`);
			onErrorsChanged(machineId, formatAlarmData(machineId, newErrors, errorSeverity.error));
		} else {
			console.info(`Got an error over SignalR for machine ${machineId}, but that is not in the active Machine Group!`);
		}
	});

	SignalRContext.useSignalREffect(warningsChangedEventName, (_, machineId, newWarnings) => {
		if (machineIds.current.includes(machineId)) {
			console.info(`Processing ${warningsChangedEventName} SignalR event for machine: ${machineId}`);
			onWarningsChanged(machineId, formatAlarmData(machineId, newWarnings, errorSeverity.warning));
		} else {
			console.info(`Got a warning over SignalR for machine ${machineId}, but that is not in the active Machine Group!`);
		}
	});

	return (
		<SignalRContext.Provider
			connectEnabled={!!token}
			accessTokenFactory={() => token.replace("BEARER ", "")}
			dependencies={[token]}
			transport={HttpTransportType.WebSockets}
			url={websocketUrl}
			onOpen={() => {
				console.info(`${machineType} websocket opened and is in the ${SignalRContext.connection?.state} state`);
				subscribeMachineGroup();
			}}
			onReconnect={() => {
				console.info(`${machineType} websocket reconnected and is in the ${SignalRContext.connection?.state} state`);
			}}
			onError={() => {
				console.info(`${machineType} websocket errored`);
			}}
			onClosed={() => {
				console.info(`${machineType} websocket closed`);
			}}
		/>
	);
}
